/* TODO: We should update this file to use the fiocValidationHelper
and also move to said helper the third directive building function.
*/
// @vendors
const Immutable = require('immutable');
const React = require('react');

import FeatureTogglingRender from 'components/conditionalRender/featureTogglingRender';

// @checkingAccount
const anotherAccountChecking = require('../fioc/data/anotherAccount');
const contactDataChecking = require('../fioc/data/contactData');
const generalDataChecking = require('../fioc/data/generalData');
const personalDataChecking = require('../fioc/data/personalData');

// @payrollAccount
const anotherAccountPayroll = require('../fioc/data/anotherAccount');
const contactDataPayroll = require('../fioc/data/contactData');
const generalDataPayroll = require('../fioc/data/generalData');
const personalDataPayroll = require('../fioc/data/personalData');

// @Local components
const contractManager = require('../flows/contractManager');

// @core
const { FormattedText, formatText } = require('core/i18n').i18n;

// @utilities
const { findElementbyId } = require('../utilities/contractStateHelper');
const formHelper = require('../utilities/formHelper');
const formValidationHelper = require('../utilities/formValidationHelper');
const inputs = require('../fioc/inputs');
const mockDataHelper = require('../utilities/mockDataHelper');
const PersonalDataHelper = require('utilities/personalDataHelper');
const wizardComponentsHelper = require('../utilities/wizardComponenstHelper');
const IbanHelper = require('utilities/ibanHelper');
const { buildTerceraDirectiva } = require('../utilities/fiocValidationHelper');

// @commons
const { OKLink } = require('commonsComponents/OKLink');

// @constants
const { countryOptions } = require('constants/countries');
const { sortedTypesOfRoadsOptions } = require('constants/typeOfRoads');
const provincesOptions = require('constants/provinces');
const {
    ACCOUNT_INTERVENTION_OWNER_CODE,
    ACCOUNT_USE_PROFESSIONAL,
    ACCOUNT_USE_PROFESSIONAL_INVESTMENT,
    CONTRACT_SUMMARY_CONTACT_FAX_NO_PREFIX,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIF,
    FIJO_PREFIX,
    GENDER_FEMALE,
    GENDER_MALE,
    MOBILE_PREFIX,
    PRODUCT_CHECKING_ACCOUNT,
    PRODUCT_PAYROLL_ACCOUNT,
    RENTIER_CODE,
    TAX_IDENTIFICATION_NUMBER_MIN_LENGTH,
    WITHOUT_ACTIVITY_CODE
} = require('constants/index');
const { ATM_OFFICES } = require('constants/flagBasedFeatures');
const SIGNIFICATIVE_OPERATIONS = [
    'operations_dangerous_countries',
    'checks_holder',
    'checks_traveler',
    'buying_and_selling_foreing_banknotes',//Typo "foreing" instead of "foreign" in the original field name
    'delivery_or_cash_withdrawals',
    'foreing_trade_operations',//Typo "foreing" instead of "foreign" in the original field name
    'none_of_the_above',
    'payment_orders_or_collection_abroad'
];

// @utilities
const {
    PROC_CHECKING_ACCOUNT,
    PROC_FAIL,
    PROC_PAYROLL_ACCOUNT,
    PROC_SELECT_PIN,
    recordLink,
} = require('utilities/tagging');
import { COUNTRIES_CODES } from 'constants/countries';


const { ES } = COUNTRIES_CODES

function checkingPayrollAccount_instructionsDisabledDocumentation(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const holders = findElementbyId(cardDetails, 'holders');

    const holderWithIban =
        holders.get(0) &&
        holders
            .get(1)
            .find(
                (intervener, index) =>
                    intervener.get('accountRelation') === ACCOUNT_INTERVENTION_OWNER_CODE &&
                    !findElementbyId(cardDetails, `holder_${index + 1}_input_iban`).get(0)
            );

    const ibanMessage =
        holderWithIban &&
        <div className="contract-summary__instructions-paragraph">
            <FormattedText
                value="contractSummary-payrollAccountSuccessParagraph8"
                className="contract-summary__instructions-text"
            />
        </div>;

    const payrollAccountLastParagraph =
        contractWizard.get('name_contract') === 'payroll-account' &&
        <div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessTitle3"
                    className="contract-summary__instructions-title"
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph16One"
                    className="contract-summary__instructions-text"
                />
                <OKLink
                    modifier="inline"
                    to="https://www.openbank.es/assets/static/pdf/Cuenta%20N%C3%B3mina/Solicitud%20Autorizaci%C3%B3n%20Domiciliaci%C3%B3n%20N%C3%B3mina.pdf"
                    target="_blank"
                >
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph16Link"
                        className="contract-summary__instructions-link"
                    />
                </OKLink>
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph16Two"
                    className="contract-summary__instructions-text"
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph17One"
                    className="contract-summary__instructions-text"
                />
                <OKLink
                    modifier="inline"
                    to="https://www.openbank.es/assets/static/pdf/Cuenta%20N%C3%B3mina/Solicitud%20Autorizaci%C3%B3n%20Domiciliaci%C3%B3n%20Recibos.pdf"
                    target="_blank"
                >
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph17Link"
                        className="contract-summary__instructions-link"
                    />
                </OKLink>
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph17Two"
                    className="contract-summary__instructions-text"
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph18"
                    className="contract-summary__instructions-text"
                    injectedStrings={[CONTRACT_SUMMARY_CONTACT_FAX_NO_PREFIX]}
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph19"
                    className="contract-summary__instructions-text"
                />
            </div>
        </div>;

    const atmOffices = __CONTACT_CENTER__
        ?
        (<FormattedText
            value="contractSummary-payrollAccountSuccessParagraph12Two"
            className="contract-summary__instructions-text"
        />)
        :
        (<OKLink isOKLinkOld={true} modifier="inline" to={formatText('contractSummary-atms')} target="_blank">
            <FormattedText
                value="contractSummary-payrollAccountSuccessParagraph12Two"
                className="contract-summary__instructions-link"
            />
        </OKLink>);
    return (
        <div>
            <div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessTitle1"
                        className="contract-summary__instructions-title"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph1"
                        className="contract-summary__instructions-text"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph2"
                        className="contract-summary__instructions-text"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <p>
                        <FormattedText
                            value="contractSummary-payrollAccountSuccessParagraph3"
                            className="contract-summary__instructions-text"
                        />
                    </p>
                    <p>
                        <FormattedText
                            value="contractSummary-payrollAccountSuccessParagraph4"
                            className="contract-summary__instructions-text"
                        />
                    </p>
                </div>
                {ibanMessage}
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph10"
                        className="contract-summary__instructions-text"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <ul className="contract-summary__instructions-list">
                        <li>
                            <FormattedText
                                value="contractSummary-payrollAccountSuccessParagraph11"
                                className="contract-summary__instructions-text"
                            />
                        </li>
                        <FeatureTogglingRender notAllowedProductKey={ATM_OFFICES} >
                            <li>
                                <FormattedText
                                    value="contractSummary-payrollAccountSuccessParagraph12One"
                                    className="contract-summary__instructions-text"
                                />
                                {atmOffices}
                            </li>
                        </FeatureTogglingRender>
                    </ul>
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph13"
                        className="contract-summary__instructions-text"
                    />
                </div>
            </div>
            <div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessTitle2"
                        className="contract-summary__instructions-title"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph14"
                        className="contract-summary__instructions-text"
                    />
                </div>
                <div className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-payrollAccountSuccessParagraph15"
                        className="contract-summary__instructions-text"
                    />
                </div>
            </div>
            {payrollAccountLastParagraph}
        </div>
    );
}

// Instructions
function checkingPayrollAccount_instructions(contractWizard, isAllowedFiocUpdateAccountHiring) {
    const cardDetails = contractWizard.get('card_details');
    const showAddDocumentation = findElementbyId(cardDetails, 'tramitation_expedient', 'showAddDocumentation');
    const addDocumentationError = findElementbyId(cardDetails, 'tramitation_expedient', 'addDocumentationError');
    const isPassportOrFutureMode = findElementbyId(cardDetails, 'passport_or_future_mode', 'value');
    const hasPinError = findElementbyId(cardDetails, 'card_pin_change', 'showErrorInSummary');
    const holders = findElementbyId(cardDetails, 'holders');
    const hasHolders = holders.get(0);

    let topMessage;
    let bottomMessage;
    let documentationMessage;
    let pinMessage;

    if (showAddDocumentation || (!hasHolders && isAllowedFiocUpdateAccountHiring)) {
        if (showAddDocumentation && addDocumentationError) {
            documentationMessage = (
                <p className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-contractSummary_checkingPayrollAccountAddDocRedirect"
                        className="contract-summary__instructions-text"
                    />
                </p>
            );
        } else {
            const addDocumentationDetails = findElementbyId(cardDetails, 'tramitation_expedient', 'details');

            let documentBlock;
            let daalBlock;
            let receiptBlock;

            if (addDocumentationDetails.document) {
                documentBlock = (
                    <p className="contract-summary__instructions-paragraph">
                        <FormattedText
                            className="contract-summary__instructions-text"
                            value="contractSummary-contractSummary_checkingPayrollAccountAddDocDocument"
                        />
                    </p>
                );
            }

            if (addDocumentationDetails.daal) {
                daalBlock = (
                    <p className="contract-summary__instructions-paragraph">
                        <FormattedText
                            className="contract-summary__instructions-text"
                            value="contractSummary-contractSummary_checkingPayrollAccountAddDocDaal"
                        />
                    </p>
                );
            }

            if (addDocumentationDetails.receipt) {
                receiptBlock = (
                    <p className="contract-summary__instructions-paragraph">
                        <FormattedText
                            value="contractSummary-contractSummary_checkingPayrollAccountAddDocReceipt"
                            className="contract-summary__instructions-text"
                        />
                    </p>
                );
            }

            documentationMessage = (
                <div className="contract-summary__instructions-block">
                    <div className="contract-summary__instructions-paragraph">
                        <FormattedText
                            value="contractSummary-contractSummary_checkingPayrollAccountAddDocTitle"
                            className="contract-summary__instructions-title"
                        />
                    </div>
                    {documentBlock}
                    {daalBlock}
                    {receiptBlock}
                    <p className="contract-summary__instructions-paragraph">
                        <FormattedText
                            value="contractSummary-contractSummary_checkingPayrollAccountAddDocInstruction"
                            className="contract-summary__instructions-text"
                        />
                    </p>
                </div>
            );
        }

        topMessage = documentationMessage;
    } else {
        bottomMessage = checkingPayrollAccount_instructionsDisabledDocumentation(contractWizard);
    }

    if (isPassportOrFutureMode && hasPinError) {
        pinMessage = (
            <div className="contract-summary__instructions-block">
                <p className="contract-summary__instructions-paragraph">
                    <FormattedText
                        value="contractSummary-contractSummary_checkingPayrollAccountPinError"
                        className="contract-summary__instructions-text"
                    />
                </p>
            </div>
        );

        if (showAddDocumentation) {
            bottomMessage = pinMessage;
        } else {
            topMessage = pinMessage;
        }
    }

    return (
        <React.Fragment>
            {topMessage}
            {bottomMessage}
        </React.Fragment>
    );
}

// Activities
const generalActivities = [
    {
        label: 'No desarrollo actividad en ninguno de los sectores aquí nombrados',
        value: '54'
    },
    {
        label:
            'Fabricación, distribución y/o intermediación en el comercio de armas y otros productos militares',
        value: '55'
    },
    {
        label:
            'Servicios de pago o de cambios de moneda: transmisores de dinero, casas de cambio o sus respectivos agentes',
        value: '56'
    },
    {
        label: 'Casinos o entidades de apuestas debidamente autorizadas',
        value: '57'
    },
    {
        label: 'Importación o exportación de material y equipos de telefonía',
        value: '58'
    },
    {
        label: 'Importación o exportación de automóviles',
        value: '59'
    },
    {
        label: 'Importación o exportación de material informático o electrónico',
        value: '60'
    },
    {
        label: 'Comercio de joyas, piedras o metales preciosos',
        value: '61'
    },
    {
        label: 'Compraventa de automóviles de ocasión',
        value: '62'
    },
    {
        label: 'Reciclado de metales',
        value: '63'
    },
    {
        label: 'Actividad que lleva implícito manejo de importantes sumas de efectivo',
        value: '64'
    },
    {
        label: 'Marchante o anticuario',
        value: '65'
    }
];

const incomeAmounts = [
    {
        label: "< 15.000€",
        value: 109
    },
    {
        label: "15.000€ - 22.000€",
        value: 110
    },
    {
        label: "22.000€ - 35.000€",
        value: 111
    },
    {
        label: "35.000€ - 55.000€",
        value: 112
    },
    {
        label: "55.000€ - 90.000€",
        value: 113
    },
    {
        label: "90.000€ - 120.000€",
        value: 114
    },
    {
        label: "120.000€ - 150.000€",
        value: 115
    },
    {
        label: "> 150.000€",
        value: 116
    }
];

function checkingPayrollAccount_generalActivities() {
    return generalActivities;
}

function checkingPayrollAccount_loadCmbNationality() {
    return countryOptions();
}

function checkingPayrollAccount_loadIncomeAmounts() {
    return incomeAmounts;
}

function checkingPayrollAccount_loadCmbNationalityWithSpainDisabled() {
    return countryOptions().map(option => {
        let country = Object.assign({}, option);
        if (option.value === 'ES') {
            country.disabled = true;
        }
        return country;
    });
}

function checkingPayrollAccount_loadTypeOfRoad() {
    return sortedTypesOfRoadsOptions();
}

function checkingPayrollAccount_loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

function checkingPayrollAccount_loadCmbProvince() {
    return mockDataHelper.sortedElements(provincesOptions);
}

// Handlers
function checkingPayrollAccount_onChangeCmbPurpose(sourceEvent, component, state) {
    sourceEvent = formHelper.buildComboTargetObject(sourceEvent, component);

    return formHelper.saveData(sourceEvent, component, state);
}

function checkingPayrollAccount_handleDatePickerCallback(sourceEvent, component, state) {
    sourceEvent = formHelper.buildDatePickerTargetObject(sourceEvent, component);

    return formHelper.saveData(sourceEvent, component, state);
}

function checkingPayrollAccount_handleCheck(cardDetails, component, event) {
    cardDetails.find(detail => detail.id === component.id).value = event.target.checked;

    return cardDetails;
}

function checkingPayrollAccount_handleBlur(sourceEvent, component, state) {
    return formHelper.saveData(sourceEvent, component, state);
}

function checkingPayrollAccount_handleChange(sourceEvent, component, state) {
    sourceEvent = formHelper.buildComboTargetObject(sourceEvent, component);

    return formHelper.saveData(sourceEvent, component, state);
}

function checkingPayrollAccount_handleChangeRoadName(roadName) {
    return PersonalDataHelper.isValidRoadNameInput(roadName);
}

function checkingPayrollAccount_onChangeFioc(value, component, state) {
    const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc, component.prefix);
    const prevValue = state.find(item => item.id === component.id).value;

    if (value !== prevValue) {
        for (let key in inputsFioc) {
            if (inputsFioc[key].id !== component.id) {
                const component = state.find(
                    stateComponent => stateComponent.id === inputsFioc[key].id
                );

                component.value = null;
                component.isErrorVisible = false;
            }
        }
    }

    return state;
}

function checkingPayrollAccount_onBackClickedFioc(cardDetails) {
    return !!cardDetails && cardDetails.find(component => component.id === 'show_fioc_alert').value;
}

function checkingPayrollAccount_nextButtonDisabled(contractWizard, immPrecontractualDocumentation) {
    const precontractualDocumentation = immPrecontractualDocumentation.getIn(['documentation', 'documents'], []).toJS();
    const allDocumentationAreAccepted = precontractualDocumentation.every(document => document.isAccepted);
    return !allDocumentationAreAccepted
}

function checkingPayrollAccount_onConfirmBackClickedFioc(contractWizard, immFlagBasedFeatures, resetCardPin) {
    resetCardPin();
    return Immutable.fromJS(contractManager.loadContract(contractWizard.get('name_contract'), immFlagBasedFeatures).state.card_details);
}

function checkingPayrollAccount_onBackClicked(cardDetails) {
    return cardDetails && cardDetails.find(component => component.id === 'holders').value[0];
}

function clearFields(fields, contractDataFunctions, cardDetails) {
    contractDataFunctions.forEach(contractDataFunction => {
        fields.forEach((field, index) => {
            const prefix = `holder_${index + 1}_`;
            const data = contractDataFunction(prefix);

            for (let key in data) {
                if (typeof data[key] === 'object') {
                    data[key].forEach(element => {
                        element.forEach(component => {
                            cardDetails = cardDetails.update(
                                cardDetails.findIndex(item => item.get('id') === component.id),
                                item =>
                                    item.mergeDeep({
                                        isErrorVisible: false,
                                        value: component.default_value || null
                                    })
                            );
                        });
                    });
                }
            }
        });
    });

    return cardDetails;
}

function clearFioc(holders, cardDetails) {
    holders.forEach((holder, index) => {
        const prefix = `holder_${index + 1}_`;
        const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc, prefix);

        for (let key in inputsFioc) {
            cardDetails = cardDetails.update(
                cardDetails.findIndex(item => item.get('id') === inputsFioc[key].id),
                item =>
                    item.mergeDeep({
                        isErrorVisible: false,
                        value: null
                    })
            );
        }
    });

    return cardDetails;
}

function checkingPayrollAccount_onConfirmBackClicked(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const contractName =
        contractWizard.get('name_contract') === 'payroll-account' ? 'Payroll' : 'Checking';
    const holders = findElementbyId(cardDetails, 'holders').get(1);
    const data = {
        anotherAccountChecking,
        anotherAccountPayroll,
        contactDataChecking,
        contactDataPayroll,
        generalDataChecking,
        generalDataPayroll,
        personalDataChecking,
        personalDataPayroll
    };
    let contractData = [];

    for (var key in data) {
        key.includes(contractName) && contractData.push(data[key]);
    }

    return clearFioc(holders, clearFields(holders, contractData, cardDetails));
}

function checkingPayrollAccount_handleCardImageChange(id, state, updateCardDetails) {
    state.find(component => component.id === 'card_image').value = id;
    updateCardDetails(state);
}

//Extra
// THIS WILL STAY COMMENTED UNTIL THE POSTAL CODE ISN'T REQUIRED FOR 'AUTORIZADO' HIRING

// function checkingPayrollAccount_isHolder (contractWizard, component) {
//     const holders = contractWizard.get('card_details').find(x => x.get('id') === 'holders').get('value');
//     let shouldBuild = true;
//
//     if (holders.get(0)) {
//         holders.get(1).map((holder, index) => {
//             const postalCodeId = `holder_${index + 1}_postal_code`;

//             if (postalCodeId === component.id && holder.get('accountRelation') !== ACCOUNT_INTERVENTION_OWNER_CODE) {
//                 shouldBuild = false;
//             }
//         });
//     }
//
//     return shouldBuild;
// }

//combo data

function checkingPayrollAccount_loadForeignTaxRadioOptions() {
    return mockDataHelper.mockForeignTaxRadioOptions();
}

function checkingPayrollAccount_loadHoldersRadioOptions() {
    return mockDataHelper.mockHoldersRadioOptions();
}

function checkingPayrollAccount_loadTaxCountryCmb() {
    return mockDataHelper.mockCountries();
}

function checkingPayrollAccount_loadImtCmb() {
    return mockDataHelper.mockImtCmb();
}

function checkingPayrollAccount_loadIdentificationTypeCmb() {
    return mockDataHelper.mockIdentificationTypeCmb();
}

function checkingPayrollAccount_loadRelationTypeCmb() {
    return mockDataHelper.mockRelationTypeCmb();
}

// generic validations
function validateFields(cardDetails, validFields, mandatoryFields) {
    mandatoryFields.forEach(mandatoryField => {
        const criteria = findElementbyId(cardDetails, mandatoryField, undefined, false);
        let isVisible = true;
        let validatedCardDetails = {
            cardDetails,
            isValid: true
        };

        if (criteria && criteria.get('isVisible')) {
            const isVisibleTarget = criteria.getIn(['isVisible', 'target']);
            const associatedComponent = cardDetails.find(
                component => component.get('target') && component.get('target') === isVisibleTarget
            );
            const associatedComponentValue =
                associatedComponent && associatedComponent.get('value');

            isVisible =
                associatedComponentValue &&
                criteria.getIn(['isVisible', 'value']).includes(associatedComponentValue);
        }

        if (isVisible && (criteria.get('required') || criteria.get('validations'))) {
            validatedCardDetails = formValidationHelper.validateFields(
                cardDetails,
                criteria.toJS()
            );
        }

        cardDetails = formValidationHelper.updateValue(
            Immutable.fromJS(validatedCardDetails.cardDetails),
            mandatoryField,
            !validatedCardDetails.isValid
        );
        validFields.push(validatedCardDetails.isValid);
    });

    return {
        cardDetails,
        validFields
    };
}

function shouldValidate(prefix, componentsIds) {
    const mandatoryFields = [];

    componentsIds.forEach(componentId => {
        const id = prefix + componentId;

        document.getElementById(id) && mandatoryFields.push(id);
    });

    return mandatoryFields;
}

function validateCheckFields(cardDetails, validFields, mandatoryCheckFields) {
    let isChecksValid = true;

    mandatoryCheckFields.find(mandatoryField => {
        isChecksValid = !!findElementbyId(cardDetails, mandatoryField);
        cardDetails = formValidationHelper.updateValue(cardDetails, mandatoryField, !isChecksValid);

        return isChecksValid;
    });

    validFields.push(isChecksValid);

    return {
        cardDetails,
        validFields
    };
}

// especifics validations
function validateCapital(cardDetails, validFields, prefix = '') {
    prefix += 'capital_';
    const hasCapitalOther = findElementbyId(cardDetails, prefix + 'other');
    const componentsIds = [
        'activities',
        'business',
        'heritage',
        'label',
        'other',
        'realState',
        'work',
        'gambling'
    ];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    if (hasCapitalOther) {
        result = validateFields(cardDetails, validFields, [prefix + 'description']);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateIncome(cardDetails, validFields, prefix = '') {
    prefix += 'income_';
    const hasIncomeOther = findElementbyId(cardDetails, prefix + 'other');
    const componentsIds = ['activities', 'business', 'label', 'other', 'realState', 'work'];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    if (hasIncomeOther) {
        result = validateFields(cardDetails, validFields, [prefix + 'description']);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateHasPep(cardDetails, validFields, prefix = '') {
    const hasPep = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['has_pep']);
    const ocupation = findElementbyId(cardDetails, 'fioc_ocupation');
    let result = {};

    if (hasPep) {
        mandatoryFields.push(prefix + 'has_pep_details');

        if (ocupation !== RENTIER_CODE && ocupation !== WITHOUT_ACTIVITY_CODE) {
            result = validateIncome(cardDetails, validFields, prefix);
            cardDetails = result.cardDetails;
            validFields = result.validFields;
        }
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function getSignificativeOperations(prefix) {
    return SIGNIFICATIVE_OPERATIONS.map(option => prefix + option);
}

function validateAccountUse(cardDetails, validFields, prefix = '') {
    const accountUseValue = findElementbyId(cardDetails, prefix + 'account_use');
    const hasPepValue = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['account_use']);
    let result = {};

    if (
        accountUseValue === ACCOUNT_USE_PROFESSIONAL ||
        accountUseValue === ACCOUNT_USE_PROFESSIONAL_INVESTMENT
    ) {
        result = validateCheckFields(cardDetails, validFields, getSignificativeOperations(prefix));
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    if (accountUseValue === ACCOUNT_USE_PROFESSIONAL_INVESTMENT && hasPepValue === 0) {
        result = validateIncome(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateAccountUse2HasPep(cardDetails, validFields, prefix = '') {
    const accountUse2Value = findElementbyId(cardDetails, prefix + 'account_use_2');
    const hasPepValue = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['account_use_2']);
    let result = {};

    if (accountUse2Value === 2 || accountUse2Value === ACCOUNT_USE_PROFESSIONAL_INVESTMENT) {
        result = validateCheckFields(cardDetails, validFields, getSignificativeOperations(prefix));
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    if (accountUse2Value === ACCOUNT_USE_PROFESSIONAL_INVESTMENT && hasPepValue === 0) {
        result = validateIncome(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateIncomeOrigin(cardDetails, validFields, prefix = '') {
    prefix += 'income_origin_';
    const componentsIds = [
        'benefits',
        'family_help',
        'financial_investments',
        'investment_property',
    ];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateForeignTax(cardDetails, validFields, prefix = '') {
    const mandatoryArrayField = findElementbyId(cardDetails, prefix + 'foreign_tax');
    const isValid = mandatoryArrayField !== null && mandatoryArrayField !== '';
    let validations = [];

    validFields.push(isValid);
    validations[0] = !isValid;

    if (isValid && mandatoryArrayField.get(0)) {
        validations[1] = [];
        mandatoryArrayField.get(1).forEach((element, index) => {
            validations[1][index] = [];
            element.forEach((value, key) => {
                let isValid = value !== null && value !== '';
                if (isValid && key === 'tin') {
                    isValid = value.trim().length >= TAX_IDENTIFICATION_NUMBER_MIN_LENGTH;
                }
                validFields.push(isValid);
                validations[1][index][key] = !isValid;
            });
        });
    }

    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        prefix + 'foreign_tax',
        validations
    );

    return {
        cardDetails,
        validFields
    };
}

function validateSelfEmployedProfession(cardDetails, validFields, prefix = '') {
    let mandatoryFields = [prefix + 'fioc_cnae'];
    switch (findElementbyId(cardDetails, prefix + 'fioc_cnae')) {
        case 1:
            mandatoryFields.push(prefix + 'profession_cnae');
            break;
        case 2:
            mandatoryFields.push(prefix + 'profession_category');
            break;
    }
    return validateFields(cardDetails, validFields, mandatoryFields);
}

/*
Make the validation only when the previousField is filled
*/
function validateOnlyIfPreviousCompleted(cardDetails, validFields, previousFieldId, currentFieldId, prefix = '') {
    let response = {
        cardDetails,
        validFields
    };
    const previousFieldValue = findElementbyId(cardDetails, prefix + previousFieldId);
    if (previousFieldValue) {
        response = validateFields(cardDetails, validFields, [prefix + currentFieldId]);
    }
    return response;
}

function validateCurrentProfession(cardDetails, validFields, prefix = '') {
    return validateOnlyIfPreviousCompleted(cardDetails, validFields, 'profession_category', 'profession_current', prefix);
}

function validateProfessionalSector(cardDetails, validFields, prefix = '') {
    return validateOnlyIfPreviousCompleted(cardDetails, validFields, 'profession_sector_categories', 'profession_sector_specific', prefix);
}

function validateProfession(cardDetails, validFields, prefix = '') {
    return validateFields(cardDetails, validFields, [`${prefix}fioc_profession_category`]);
}

function validateCategoryItem(cardDetails, validFields, prefix = '') {
    return validateOnlyIfPreviousCompleted(cardDetails, validFields, 'fioc_profession_category', 'fioc_profession_search_option', prefix);
}

function validateCategory(cardDetails, validFields, prefix = '') {
    let response = {
        cardDetails,
        validFields
    };
    response = validateFields(cardDetails, validFields, [`${prefix}fioc_profession_category`]);
    return response;
}

// ocupation validations
function validateSelfEmployed(cardDetails, validFields, prefix = '') {
    const componentsIds = [
        'activities',
        'company_name',
        'incomes_annual',
        'profession_cnae',
        `${prefix}fioc_profession_category`
    ];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateSelfEmployedProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCurrentProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateProfessionalSector(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCategoryItem(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateAccountUse(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateForeignTax(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateEmployed(cardDetails, validFields, prefix = '') {
    const componentsIdsDefault = [
        'company_name',
        'incomes_annual',
        'profession_cno',
        'operations_account'
    ];
    const mandatoryFields = shouldValidate(prefix, componentsIdsDefault);

    let result = validateProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCategory(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateAccountUse2HasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateForeignTax(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateRentier(cardDetails, validFields, prefix = '') {
    const componentsIds = ['incomes_annual', 'operations_account'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateIncome(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateAccountUse(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateForeignTax(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validatePensioner(cardDetails, validFields, prefix = '') {
    const componentsIds = ['incomes_annual', 'operations_account'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateAccountUse(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateForeignTax(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateWithoutActivity(cardDetails, validFields, prefix = '') {
    const componentsIds = ['operations_account', 'your_job'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateIncomeOrigin(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHasPep(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateAccountUse(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateForeignTax(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateFioc(cardDetails, validFields, prefix = '') {
    const fiocOcupationValue = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    const mandatoryFields = shouldValidate(prefix, ['fioc_ocupation']);
    // DO NOT ORDER ALPHABETICALLY
    const validationFunctions = [
        validateSelfEmployed,
        validateEmployed,
        validateRentier,
        validatePensioner,
        validateWithoutActivity
    ];

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (fiocOcupationValue || fiocOcupationValue === 0) {
        result = validationFunctions[fiocOcupationValue](cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return result;
}

function validatePersonalData(cardDetails, validFields, prefix = '') {
    const mandatoryFields = [
        prefix + 'birthdate',
        prefix + 'document_number',
        prefix + 'document_type',
        prefix + 'resident_country'
    ];
    let fullNameIdValid = [];

    const fullName = cardDetails
        .find(detail => detail.get('id') === prefix + 'full_name')
        .get('value');

    let isValid = fullName.get('firstName') !== null && fullName.get('firstName') !== '';
    validFields.push(isValid);
    fullNameIdValid[0] = !isValid;

    isValid = fullName.get('lastName') !== null && fullName.get('lastName') !== '';
    validFields.push(isValid);
    fullNameIdValid[1] = !isValid;

    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        prefix + 'full_name',
        fullNameIdValid
    );

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateContactData(cardDetails, validFields, accountRelation, prefix = '') {
    const addressId = prefix + 'address_others';
    const addressOthers = findElementbyId(cardDetails, addressId);
    const mandatoryFields = [
        prefix + 'address_number',
        prefix + 'country',
        prefix + 'email',
        prefix + 'mobile_phone',
        prefix + 'postal_code',
        prefix + 'province',
        prefix + 'road_name',
        prefix + 'town',
        prefix + 'type_of_road',
        prefix + 'country_of_birth',
        prefix + 'province_of_birth',
        prefix + 'town_of_birth',
    ];

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (addressOthers) {
        result = formValidationHelper.validateAddressOthers(cardDetails, addressOthers, addressId);
        cardDetails = result.cardDetails;
        validFields.push(result.isValidStep);
    }

    return {
        cardDetails,
        validFields
    };
}

function validateGeneralData(cardDetails, validFields, prefix = '') {
    const mandatoryFields = [
        prefix + 'gender',
        prefix + 'nationality',
        prefix + 'nationality_other'
    ];
    const result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateIban(cardDetails, validFields, prefix = '') {
    const mandatoryArrayField = findElementbyId(cardDetails, prefix + 'input_iban');
    const isValid = !!mandatoryArrayField;
    let validations = [!isValid, false];

    validFields.push(isValid);

    if (isValid && mandatoryArrayField.get(0)) {
        const iban = mandatoryArrayField.get(1);
        const ibanValidations = iban.map(value =>
            value !== null && value !== '' && value.length === 4
        );

        validations[1] = ibanValidations.includes(false) || !IbanHelper.isValidIban(iban.join(''));
        validFields.push(!validations[1]);
    }

    cardDetails = formValidationHelper.updateValue(cardDetails, prefix + 'input_iban', validations);

    return {
        cardDetails,
        validFields
    };
}

function validateIntervinier(cardDetails, validFields, accountRelation, prefix = '') {
    let result = validatePersonalData(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateContactData(cardDetails, validFields, accountRelation, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateGeneralData(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (accountRelation === ACCOUNT_INTERVENTION_OWNER_CODE) {
        result = validateIban(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateFioc(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

function updateErrorValues(cardDetails, isValidForm, errorId) {
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'holder-validation-error',
        errorId === 'holder-validation-error' ? !isValidForm : false
    );
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'step3-validation-error',
        errorId === 'step3-validation-error' ? !isValidForm : false
    );
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'step2-validation-error',
        errorId === 'step2-validation-error' ? !isValidForm : false
    );

    return cardDetails;
}

// steps validations
function validateStep1(cardDetails) {
    cardDetails = updateErrorValues(cardDetails, true, 'step2-validation-error');
    return {
        card_details: cardDetails,
        isValidStep: true
    };
}

function validateStep2(cardDetails) {
    let isValidForm;
    if (!findElementbyId(cardDetails, 'show_fioc')) {
        isValidForm = true;
    } else {
        const fiocOcupationValue = findElementbyId(cardDetails, 'fioc_ocupation');
        // NO ORDENAR ALFABETICAMENTE
        const validationFunctions = [
            validateSelfEmployed,
            validateEmployed,
            validateRentier,
            validatePensioner,
            validateWithoutActivity
        ];
        let validFields = [];

        let result = validateFields(cardDetails, validFields, ['fioc_ocupation']);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        if (fiocOcupationValue || fiocOcupationValue === 0) {
            result = validationFunctions[fiocOcupationValue](cardDetails, validFields);
            cardDetails = result.cardDetails;
            validFields = result.validFields;
        }

        isValidForm = validFields.every(formValidationHelper.isValidElement);
        cardDetails = updateErrorValues(cardDetails, isValidForm, 'step2-validation-error');
    }
    return {
        card_details: cardDetails,
        isValidStep: isValidForm
    };
}

function validateHolders(cardDetails, validFields, interveners) {
    const mandatoryArrayField = findElementbyId(cardDetails, 'holders');
    const identificationValues = [];
    const isValid = !!mandatoryArrayField;
    let validations = [];

    validFields.push(isValid);
    validations[0] = !isValid;

    cardDetails = formValidationHelper.updateValue(cardDetails, 'holders-personal-info-disclaimer', false);
    if (isValid && mandatoryArrayField.get(0)) {
        validations[1] = [];
        mandatoryArrayField.get(1).forEach((element, index) => {
            let keyDocType;
            let keyDocValue;

            validations[1][index] = [];
            element.forEach((value, key) => {
                keyDocValue = key === 'identificationValue' ? key : keyDocValue;
                keyDocType = key === 'identificationType' ? key : keyDocType;
                let isValid = value !== null && value !== '';

                if (key === 'identificationValue') {
                    validFields.push(isValid);
                    isValid = isValid ? '' : 'contractsView-documentNumberRequiredError';
                    validations[1][index][key] = isValid;
                } else if (key !== 'secondlastName') {
                    validFields.push(isValid);
                    validations[1][index][key] = !isValid;
                }
            });

            if (keyDocType && element.get(keyDocType)) {
                if (DOCUMENT_TYPE_NIF === element.get(keyDocType)) {
                    const secondLastName = element.get('secondlastName');
                    const isValid = secondLastName !== null && secondLastName !== '';
                    validFields.push(isValid);
                    validations[1][index]['secondlastName'] = !isValid;
                }

                if (keyDocValue && element.get(keyDocValue)) {
                    const isValid = formValidationHelper.isValidDocumentNumberOfType(
                        element.get(keyDocValue),
                        element.get(keyDocType)
                    );
                    validations[1][index][keyDocValue] =
                        'contractsView-documentNumberRequiredError';
                    validFields.push(isValid);

                    if (isValid) {
                        validations[1][index][keyDocValue] = '';
                        identificationValues.push({
                            index: index,
                            key: keyDocValue,
                            value: element.get(keyDocValue)
                        });
                    }
                }
            }
        });

        const isValid = formValidationHelper.validateDifferentDocuments(
            identificationValues,
            interveners,
            validations
        );
        validFields.push(isValid);
    }

    cardDetails = formValidationHelper.updateValue(cardDetails, 'holders', validations);

    return {
        cardDetails,
        validFields
    };
}
function validateHoldersPersonalInfoDisclaimer(cardDetails, validFields) {
    const disclaimerComponent = findElementbyId(cardDetails, 'holders-personal-info-disclaimer', undefined, false);

    if (disclaimerComponent !== undefined) {
        const showError = disclaimerComponent.get('required') && !disclaimerComponent.get('value');
        const isValid = !showError;
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'holders-personal-info-disclaimer',
            showError
        );
        validFields.push(isValid);
    }

    return {
        cardDetails,
        validFields
    };
}
function validateStep3(cardDetails, interveners) {
    let validFields = [];

    let result = validateFields(cardDetails, validFields, ['holders']);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHolders(cardDetails, validFields, interveners);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateHoldersPersonalInfoDisclaimer(cardDetails, validFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    const isValidForm = validFields.every(formValidationHelper.isValidElement);
    cardDetails = updateErrorValues(cardDetails, isValidForm, 'step3-validation-error');

    return {
        card_details: cardDetails,
        isValidStep: isValidForm
    };
}

function validateStep4(cardDetails) {
    const holders = findElementbyId(cardDetails, 'holders');
    let isValidForm = true;
    let validFields = [];

    if (holders.get(0)) {
        holders.get(1).forEach((holder, index) => {
            const accountRelation = holder.get('accountRelation');
            const prefix = `holder_${index + 1}_`;
            const result = validateIntervinier(cardDetails, validFields, accountRelation, prefix);

            cardDetails = result.cardDetails;
            validFields = result.validFields;
        });

        isValidForm = validFields.every(formValidationHelper.isValidElement);
        cardDetails = updateErrorValues(cardDetails, isValidForm, 'holder-validation-error');
    }

    return {
        card_details: cardDetails,
        isValidStep: isValidForm
    };
}


function checkingPayrollAccount_handlePinBlur(isValid, state, updateCardDetails) {
    state.find(component => component.id === 'card_pin_change').isValid = isValid;
    updateCardDetails(state);
}

function validateStep5(cardDetails, state) {
    const isSetPinEnabled = cardDetails.find(component => component.get('id') === 'card_pin_change').get('isEnabled');
    const contractName = state.get('name_contract');

    let isCardPinChangeValid = true;
    let modCardDetails = cardDetails;

    if (isSetPinEnabled) {
        isCardPinChangeValid = cardDetails.find(component => component.get('id') === 'card_pin_change').get('isValid');
        modCardDetails = cardDetails.update(
            cardDetails.findIndex(item => item.get('id') === 'card_pin_change'),
            item => item.set('showError', !isCardPinChangeValid)
        );

        if (!isCardPinChangeValid && !!contractName) {
            let tealiumEventCategory;

            if (contractName === 'checking-account') {
                tealiumEventCategory = PROC_CHECKING_ACCOUNT;
            } else if (contractName === 'payroll-account') {
                tealiumEventCategory = PROC_PAYROLL_ACCOUNT;
            }

            recordLink(tealiumEventCategory, PROC_SELECT_PIN, PROC_FAIL);
        }
    }

    return {
        card_details: modCardDetails,
        isValidStep: isCardPinChangeValid,
    };
}

function validateStep6(cardDetails, confirmationCheckboxes, state) {
    const promotionalCodeChecked =
        !state.get('promotionalCode') || state.get('promotionalCodeAcceptanceCheckboxChecked');
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = confirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        promotionalCodeAcceptanceCheckboxErrorState: !promotionalCodeChecked,
        card_details: cardDetails,
        isValidStep: confirmationCheckboxes.get('areValid') && promotionalCodeChecked,
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateLegalWindowStep(cardDetails) {
    return {
        card_details: cardDetails,
        isValidStep: true,
    };
}

function checkingPayrollAccount_validateStep(step, state, isValidStep, interveners) {
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    //Remember, if steps order is changed override prop must be updated
    const overrideValidation = state.getIn(['steps', step - 1, 'override_validation_step']);
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    let stepToValidate = step;

    if (!!overrideValidation) {
        stepToValidate = overrideValidation;
    }

    switch (stepToValidate) {
        case 1:
            return validateStep1(immCardDetails);

        case 2:
            return validateStep2(immCardDetails);

        case 3:
            return validateStep3(immCardDetails, interveners);

        case 4:
            return validateStep4(immCardDetails);

        case 5:
            return validateStep5(immCardDetails, state);

        case 6:
        case 7:
            return validateStep6(immCardDetails, immConfirmationCheckboxes, state);

        case 'legalWindow':
            return validateLegalWindowStep(immCardDetails);
    }

}

// signing
function checkingPayrollAccount_SignParams() {
    let ret;
    return ret;
}

function tdCapitalAnswer(cardDetails, prefix) {
    let string = '';

    const capital_activities = findElementbyId(cardDetails, prefix + 'capital_activities');
    const capital_business = findElementbyId(cardDetails, prefix + 'capital_business');
    const capital_description = findElementbyId(cardDetails, prefix + 'capital_description');
    const capital_heritage = findElementbyId(cardDetails, prefix + 'capital_heritage');
    const capital_other = findElementbyId(cardDetails, prefix + 'capital_other');
    const capital_realState = findElementbyId(cardDetails, prefix + 'capital_realState');
    const capital_work = findElementbyId(cardDetails, prefix + 'capital_work');
    const capital_gambling = findElementbyId(cardDetails, prefix + 'capital_gambling');

    capital_work && (string += '66$');
    capital_activities && (string += '67$');
    capital_heritage && (string += '68$');
    capital_business && (string += '69$');
    capital_realState && (string += '70$');
    capital_gambling && (string += '104$');
    string += capital_other ? `${capital_description}\$` : ' ';

    return (string += '#');
}

function tdIncomeAnswer(cardDetails, prefix) {
    let string = '';

    const income_activities = findElementbyId(cardDetails, prefix + 'income_activities');
    const income_business = findElementbyId(cardDetails, prefix + 'income_business');
    const income_description = findElementbyId(cardDetails, prefix + 'income_description');
    const income_other = findElementbyId(cardDetails, prefix + 'income_other');
    const income_realState = findElementbyId(cardDetails, prefix + 'income_realState');
    const income_work = findElementbyId(cardDetails, prefix + 'income_work');

    income_work && (string += '76$');
    income_activities && (string += '77$');
    income_business && (string += '78$');
    income_realState && (string += '79$');
    string += income_other ? `${income_description}\$` : ' ';

    return (string += '#');
}

function tdPublicCharges(cardDetails, prefix) {
    let string = '';

    const has_pep = findElementbyId(cardDetails, prefix + 'has_pep');

    if (has_pep) {
        const has_pep_details = findElementbyId(cardDetails, prefix + 'has_pep_details');

        string += 'SI$';
        has_pep_details && (string += `${has_pep_details}\$`);
        string += '#';
    } else {
        string += '75#';
    }

    return string;
}

function tdThirdPartyAnswer(cardDetails, prefix) {
    const operations_account = findElementbyId(cardDetails, prefix + 'operations_account');
    return operations_account ? '81#' : '82#';
}

function tdAnnualIncome(cardDetails, prefix) {
    const incomes_annual = findElementbyId(cardDetails, prefix + 'incomes_annual');

    return `${incomes_annual}#`;
}

function tdOperationsAnswer(cardDetails, prefix) {
    let string = '';

    const operations_dangerous_countries = findElementbyId(
        cardDetails,
        prefix + 'operations_dangerous_countries'
    );

    const checks_holder = findElementbyId(
        cardDetails,
        prefix + 'checks_holder'
    );

    const checks_traveler = findElementbyId(
        cardDetails,
        prefix + 'checks_traveler'
    );

    const buying_and_selling_foreing_banknotes = findElementbyId(
        cardDetails,
        prefix + 'buying_and_selling_foreing_banknotes'
    );

    const delivery_or_cash_withdrawals = findElementbyId(
        cardDetails,
        prefix + 'delivery_or_cash_withdrawals'
    );
    const foreing_trade_operations = findElementbyId(
        cardDetails,
        prefix + 'foreing_trade_operations'
    );
    const none_of_the_above = findElementbyId(cardDetails, prefix + 'none_of_the_above');
    const payment_orders_or_collection_abroad = findElementbyId(
        cardDetails,
        prefix + 'payment_orders_or_collection_abroad'
    );

    operations_dangerous_countries && (string += '105$');
    checks_holder && (string += '106$');
    checks_traveler && (string += '86$');
    delivery_or_cash_withdrawals && (string += '87$');
    buying_and_selling_foreing_banknotes && (string += '88$');
    payment_orders_or_collection_abroad && (string += '89$');
    foreing_trade_operations && (string += '90$');
    none_of_the_above && (string += '91$');

    return (string += '#');
}

function tdPurpose(cardDetails, prefix) {
    const account_use = findElementbyId(cardDetails, prefix + 'account_use');
    let string = '';

    account_use === 1 && (string += '83#');
    account_use === 2 && (string += '84#');
    account_use === 3 && (string += '85#');

    return string;
}

function tdPurpose2(cardDetails, prefix) {
    const account_use = findElementbyId(cardDetails, prefix + 'account_use_2');
    let string = '';

    account_use === 1 && (string += '83#');
    account_use === 3 && (string += '85#');

    return string;
}


function getDatosContactoTitular(profile) {
    const email = PersonalDataHelper.getEmail(profile);
    const contactPhone = profile.get("telefonoContacto");
    return {
        email,
        ...(contactPhone && {
            movil: contactPhone.get("numero"),
            prefijoFijo: contactPhone.get("codigoPais"),
            prefijoMovil: contactPhone.get("codigoPais")
        })
    };
}

function getDatosContactoInterviniente(cardDetails, prefix) {
    const email = findElementbyId(cardDetails, prefix + 'email');
    const movil = findElementbyId(cardDetails, prefix + 'mobile_phone');
    const prefijoFijo = FIJO_PREFIX;
    const prefijoMovil = MOBILE_PREFIX;

    return {
        email,
        movil,
        prefijoFijo,
        prefijoMovil
    };
}

function getDomicilioTitular(profile) {
    const {
        addressCountry,
        locality,
        numberStreet,
        postalCode,
        province,
        restOfAddress,
        streetName,
        typeOfRoads
    } = PersonalDataHelper.getAddress(profile);
    const aditionalAddress = restOfAddress.split('-');

    return {
        addressCountry,
        aditionalAddress,
        locality,
        numberStreet,
        postalCode,
        province,
        restOfAddress,
        streetName,
        typeOfRoads
    };
}

function getDomicilioInterviniente(card_details, prefix) {
    const addressCountry = 'ES';
    const locality = findElementbyId(card_details, prefix + 'town');
    const numberStreet = findElementbyId(card_details, prefix + 'address_number');
    const postalCode = findElementbyId(card_details, prefix + 'postal_code');
    const province = findElementbyId(card_details, prefix + 'province');
    const restOfAddress = findElementbyId(card_details, prefix + 'address_others') || '';
    const streetName = findElementbyId(card_details, prefix + 'road_name');
    const typeOfRoads = findElementbyId(card_details, prefix + 'type_of_road');
    const aditionalAddress = restOfAddress.split('/');

    return {
        addressCountry,
        aditionalAddress,
        locality,
        numberStreet,
        postalCode,
        province,
        restOfAddress,
        streetName,
        typeOfRoads
    };
}

function getDatosFisicosTitular(profile) {
    const { province, locality } = PersonalDataHelper.getAddress(profile);
    const codigoDocumento = profile.getIn(['documentoIdentificacion', 'codigodocumpersonacorp']);
    const fechaNacimiento = formHelper.changeDateFormat(profile.get('fechaNacimiento'));
    const nacionalidad = profile.get('paisNacionalidad');
    const nombre = profile.get('nombreCliente').trim();
    const paisNacimiento = profile.get('paisNacimiento');
    const poblacionNacimiento = formHelper.cleanSpecialChars(locality);
    const primerApellido = profile.getIn(['apellidoUno', 'apellido']).trim();
    const provinciaNacimiento = province;
    const segundoApellido = profile.getIn(['apellidoDos', 'apellido']).trim();
    const sexo = profile.get('sexo');
    const tipoDocumento = profile.getIn(['documentoIdentificacion', 'tipodocumpersonacorp']);

    return {
        codigoDocumento,
        fechaNacimiento,
        nacionalidad,
        nombre,
        paisNacimiento,
        poblacionNacimiento,
        primerApellido,
        provinciaNacimiento,
        segundoApellido,
        sexo,
        tipoDocumento
    };
}

function getDatosFisicosInterviniente(cardDetails, prefix) {
    const documentType = findElementbyId(cardDetails, prefix + 'document_type');
    let tipoDocumento = DOCUMENT_TYPE_NIF;
    let nacionalidad;

    switch (documentType) {
        case 'DNI':
            tipoDocumento = DOCUMENT_TYPE_NIF;
            nacionalidad = findElementbyId(cardDetails, prefix + 'nationality');
            break;
        default:
            tipoDocumento = DOCUMENT_TYPE_NIE;
            nacionalidad = findElementbyId(cardDetails, prefix + 'nationality_other');
            break;
    }

    const codigoDocumento = findElementbyId(cardDetails, prefix + 'document_number');
    const fullName = findElementbyId(cardDetails, prefix + 'full_name');
    const nombre = fullName.get('firstName');
    const primerApellido = fullName.get('lastName');
    const segundoApellido = fullName.get('secondlastName');
    const paisNacimiento = findElementbyId(cardDetails, prefix + 'country_of_birth');
    const date = new Date(findElementbyId(cardDetails, prefix + 'birthdate').get('_d'));
    const fechaNacimiento =
        ('0' + date.getDate()).slice(-2) +
        '/' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '/' +
        date.getFullYear();
    const poblacionNacimiento = findElementbyId(cardDetails, prefix + 'town_of_birth');
    const provinciaNacimiento = findElementbyId(cardDetails, prefix + 'province_of_birth');
    const sexo =
        findElementbyId(cardDetails, prefix + 'gender') === 1 ? GENDER_FEMALE : GENDER_MALE;

    return {
        codigoDocumento,
        fechaNacimiento,
        nacionalidad,
        nombre,
        paisNacimiento,
        poblacionNacimiento,
        primerApellido,
        provinciaNacimiento,
        segundoApellido,
        sexo,
        tipoDocumento
    };
}

function buildDatosPersonales(
    cardDetails,
    profile,
    tipoIntervencion = ACCOUNT_INTERVENTION_OWNER_CODE,
    ordenIntervencion = 1,
    prefix = '',
) {
    let datosContacto = {};
    let domicilio = {};
    let datosFisicos = {};

    const cardImage = findElementbyId(cardDetails, 'card_image');
    const showFiocValue = !profile && !findElementbyId(cardDetails, 'show_fioc');

    let parsedCardImage;

    if (!!cardImage) {
        parsedCardImage = cardImage.split('.');
        parsedCardImage = `${parsedCardImage[1]}${parsedCardImage[2]}0000${parsedCardImage[3]}`;
    }

    if (profile) {
        datosContacto = getDatosContactoTitular(profile);
        domicilio = getDomicilioTitular(profile);
        datosFisicos = getDatosFisicosTitular(profile);
    } else {
        datosContacto = getDatosContactoInterviniente(cardDetails, prefix);
        domicilio = getDomicilioInterviniente(cardDetails, prefix);
        datosFisicos = getDatosFisicosInterviniente(cardDetails, prefix);
    }

    const {
        codigoDocumento,
        fechaNacimiento,
        nacionalidad,
        nombre,
        paisNacimiento,
        poblacionNacimiento,
        primerApellido,
        provinciaNacimiento,
        segundoApellido,
        sexo,
        tipoDocumento
    } = datosFisicos;

    // datosProfesionales
    let trabajasPor = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    let nombreEmpresa = '';
    let profesion = '';
    let activity = '';

    if (!trabajasPor && trabajasPor !== 0) {
        trabajasPor = 'N/A';
    }

    switch (trabajasPor) {
        case 0:
            trabajasPor = '01';
            nombreEmpresa = findElementbyId(cardDetails, prefix + 'company_name');
            profesion = findElementbyId(cardDetails, prefix + 'profession_cnae') || findElementbyId(cardDetails, prefix + 'profession_current');
            break;

        case 1:
            trabajasPor = '02';
            nombreEmpresa = findElementbyId(cardDetails, prefix + 'company_name');
            profesion = findElementbyId(cardDetails, prefix + 'fioc_profession_category');
            activity = findElementbyId(cardDetails, prefix + 'fioc_profession');
            break;

        case 2:
            trabajasPor = '05';
            profesion = 'X200B';
            break;

        case 3:
            trabajasPor = '05';
            profesion = 'PJ00';
            break;

        case 4:
            const your_job = findElementbyId(cardDetails, prefix + 'your_job');
            const professions = ['X300B', 'X200A', 'X300A'];

            trabajasPor = '05';
            profesion = professions[your_job];
    }

    let data = {
        datosPersonales: {
            datosContacto: {
                mail: datosContacto.email,
                movil: datosContacto.movil,
                prefijoMovil: formHelper.removeFirstZero(datosContacto.prefijoMovil),
                prefijoFijo: formHelper.removeFirstZero(datosContacto.prefijoFijo)
            },
            datosFisicos: {
                tipoDocumento: tipoDocumento,
                codigoDocumento: codigoDocumento,
                nombre: nombre,
                primerApellido: primerApellido,
                segundoApellido: segundoApellido,
                paisNacimiento: paisNacimiento,
                nacionalidad: nacionalidad,
                fechaNacimiento: fechaNacimiento,
                sexo: sexo,
                provinciaNacimiento: formHelper.cleanSpecialChars(provinciaNacimiento),
                poblacionNacimiento: formHelper.cleanSpecialChars(poblacionNacimiento)
            },
            datosProfesionales: {
                trabajasPor: trabajasPor,
                nombreEmpresa: nombreEmpresa,
                profesion: profesion,
                ingresosUnidadFamiliar: activity
            },
            domicilio: {
                tipoVia: domicilio.typeOfRoads,
                nombreVia: domicilio.streetName,
                numero: domicilio.numberStreet,
                poblacion: formHelper.cleanSpecialChars(domicilio.locality),
                provincia: domicilio.province,
                codigoPostal: domicilio.postalCode,
                paisResidencia: domicilio.addressCountry,
                piso: domicilio.aditionalAddress[0] || null,
                letra: domicilio.aditionalAddress[1] || null
            },
            productosInteres: '',
            terceraDirectiva: showFiocValue ? buildTerceraDirectiva(cardDetails, prefix) : '',
        },
        idiomaComunicacion: 'E',
        ordenIntervencion: ordenIntervencion,
        primerIngreso: {
            cuentaPrimerIgreso: {
                banco: '',
                dc: '',
                numeroCuenta: '',
                sucursal: ''
            },
            importePrimerIngreso: '',
            importeProducto: ''
        },
        residente: true,
        tarjeta: parsedCardImage,
        tipoIntervencion: tipoIntervencion
    };

    //Commenting this, as per OBK314-27116, API payload need this object in payload always
    //Check if 'Datos Profiesionales' applies in the request
    // if (!data.datosPersonales.datosProfesionales.trabajasPor) {
    //     delete data.datosPersonales.datosProfesionales;
    // }

    if (data.datosPersonales.datosFisicos.segundoApellido === '') {
        delete data.datosPersonales.datosFisicos.segundoApellido;
    }

    // Tributas
    const foreignTax = findElementbyId(cardDetails, prefix + 'foreign_tax');
    const tributas = foreignTax && foreignTax.get('0');
    let motivoTributacion = [];
    let paisTributacion = [];
    let tin = [];

    if (tributas) {
        const tributasList = foreignTax.get('1');
        tributasList.forEach(item => {
            switch (item.get('imt')) {
                case 1:
                    motivoTributacion.push('U');
                    break;
                case 2:
                    motivoTributacion.push('R');
                    break;
                case 3:
                    motivoTributacion.push('X');
                    break;
            }
            paisTributacion.push(item.get('taxCountry'));
            tin.push(item.get('tin'));
        });
    }

    //When no data has been filled, 'tributas' will come as false. If we send false to the WS, we get an error.
    data.datosPersonales.domicilio['tributas'] = tributas ? 'S' : 'N';
    motivoTributacion.forEach((motivo, index) => {
        data.datosPersonales.domicilio['motivoTributacion'] = motivo;
        data.datosPersonales.domicilio['paisTributacion'] = paisTributacion[index];
        data.datosPersonales.domicilio['tin'] = tin[index];
    });

    if (!profile) {
        const input_iban = findElementbyId(cardDetails, prefix + 'input_iban');
        if (input_iban && input_iban.get(0)) {
            let iban = '';
            input_iban.get(1).map(item => {
                iban = iban + item;
            });
            data.datosPersonales.cuentaOtraEntidad = iban;
        }
    }

    return data;
}

function checkingPayrollAccount_WarningContractMessage(contractWizard) {
    if (contractWizard) {
        const cardDetails = contractWizard.get('card_details');
        const hasHolders = (cardDetails && findElementbyId(cardDetails, 'holders').get(0)) ? 'With' : 'Without';
        const product = contractWizard.get('name_contract') === 'payroll-account' ? 'payroll' : 'checking';
        return `contractsView-${product}WarningMsg${hasHolders}Holders`;
    }
}

function checkingPayrollAccount_HireParams(contractWizard, profile, isAllowedFiocUpdateAccountHiring) {
    const cardDetails = contractWizard.get('card_details');
    const showFiocValue = findElementbyId(cardDetails, 'show_fioc');

    const holderData = buildDatosPersonales(cardDetails, profile);
    const holders = findElementbyId(cardDetails, 'holders');
    const hasHolders = holders.get(0);

    const interveners = [holderData];
    const product =
        contractWizard.get('name_contract') === 'payroll-account'
            ? PRODUCT_PAYROLL_ACCOUNT
            : PRODUCT_CHECKING_ACCOUNT;
    const promotionalCode = contractWizard.getIn(['promotionalCode', 'code']) || '';

    hasHolders &&
        holders.get(1).forEach((holder, index) => {
            const interventionOrder = index + 2;
            const interventionType = holder.get('accountRelation') || '';
            const prefix = `holder_${index + 1}_`;
            const intervener = buildDatosPersonales(
                cardDetails,
                null,
                interventionType,
                interventionOrder,
                prefix,
            );

            interveners.push(intervener);
        });

    return {
        fioc: showFiocValue,
        onlineInput: {
            aplicacion: __CONTACT_CENTER__ ? 'CCCIC' : 'CCDIG',
            bloqueoIdentFehaciente: 'S',
            canal: __CONTACT_CENTER__ ? 'CHV' : 'CHU',
            contratoLocal: {},
            contratoPartenon: {},
            ...(isAllowedFiocUpdateAccountHiring && !__CONTACT_CENTER__ ? { cuentaActivada: hasHolders ? 'N' : 'S' } : {}),
            divisas: {
                item: [{}]
            },
            gestor: '001234', // preguntar sobre esto
            idioma: '1',
            intervinientes: {
                item: interveners
            },
            medioOrigen: '',
            openSeguridad: {
                codigo: '',
                codigoColectivo: '',
                codigoDocumentoPadrino: '',
                codigoPromocion: promotionalCode,
                memorableQuestion: {
                    pregunta: '',
                    respuesta: ''
                },
                tipoDocumentoPadrino: ''
            },
            origenAplicacion: __CONTACT_CENTER__ ? 'CCCIC' : 'CCDIG',
            producto: product,
            tarjeta: '',
            tecnico: '',
            universitario: {
                /*
                "carrera": "",
                "colectivo": "",
                "curso": ""
                */
            }
        }
    };
}

function checkingPayrollAccount_onChangeSelect(selectedValue, component, state) {
    const findElement = element => {
        return state.find(component => component.id === element)
    };
    const { addFunction: { value, element }, target } = component;
    if (selectedValue !== value) {
        const elementObject = findElement(element);
        const targetObject = findElement(target);
        if (elementObject) {
            elementObject.openModal = true;
        }
        if (targetObject) {
            targetObject.value = '';
        }
    }
    return state;
}

function checkingCountryOfBirthPayrollAccount_onChangeSelect(selectedValue, component, state) {
    const findElement = element => {
        return state.find(component => component.id === element)
    };
    const { addFunction: { value, element } } = component;
    const targetObject = findElement(element);
    if (targetObject) {
        if (selectedValue !== value) {
            targetObject.disabled = true;
            targetObject.required = false;
            targetObject.value = '';
        } else {
            targetObject.disabled = false;
            targetObject.required = true;
        }
    }
    return state;
}

function checkingPayrollAccount_onBlurValidateCondition(component, state) {
    const { addFunction: { element } } = component;
    //This is used to only make the validation in the blur event and with the modal closed
    return !state.find(component => component.id === element).openModal;
}

function checkingPayrollAccount_disableFirstStepButton(state) {
    return __CONTACT_CENTER__ && !state.getIn(['confirmationCheckboxes', 'areValid']);
}

module.exports = {
    checkingPayrollAccount_generalActivities,
    checkingPayrollAccount_handleBlur,
    checkingPayrollAccount_handleCardImageChange,
    checkingPayrollAccount_handleChange,
    checkingPayrollAccount_handleChangeRoadName,
    checkingPayrollAccount_handleCheck,
    checkingPayrollAccount_handleDatePickerCallback,
    checkingPayrollAccount_HireParams,
    checkingPayrollAccount_handlePinBlur,
    checkingPayrollAccount_instructions,
    // THIS WILL STAY COMMENTED UNTIL THE POSTAL CODE ISN'T REQUIRED FOR 'AUTORIZADO' HIRING
    // checkingPayrollAccount_isHolder,
    checkingPayrollAccount_loadCmbNationality,
    checkingPayrollAccount_loadCmbNationalityWithSpainDisabled,
    checkingPayrollAccount_loadCmbProvince,
    checkingPayrollAccount_loadForeignTaxRadioOptions,
    checkingPayrollAccount_loadHoldersRadioOptions,
    checkingPayrollAccount_loadIdentificationTypeCmb,
    checkingPayrollAccount_loadImtCmb,
    checkingPayrollAccount_loadRelationTypeCmb,
    checkingPayrollAccount_loadTaxCountryCmb,
    checkingPayrollAccount_loadTypeOfRoad,
    checkingPayrollAccount_onBackClicked,
    checkingPayrollAccount_onBackClickedFioc,
    checkingPayrollAccount_onChangeCmbPurpose,
    checkingPayrollAccount_onChangeFioc,
    checkingPayrollAccount_onConfirmBackClicked,
    checkingPayrollAccount_onConfirmBackClickedFioc,
    checkingPayrollAccount_SignParams,
    checkingPayrollAccount_validateStep,
    checkingPayrollAccount_onChangeSelect,
    checkingPayrollAccount_onBlurValidateCondition,
    checkingPayrollAccount_WarningContractMessage,
    checkingPayrollAccount_loadIncomeAmounts,
    checkingCountryOfBirthPayrollAccount_onChangeSelect,
    checkingPayrollAccount_nextButtonDisabled,
    checkingPayrollAccount_disableFirstStepButton,
    checkingPayrollAccount_loadTermsAndConditionsLegalWindow,
};