const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP
} = require('constants/index');
const hireNewCreditCardStep1 = require('./hireNewCreditCard/hireNewCreditCardStep1');
const hireNewCreditCardStep2 = require('./hireNewCreditCard/hireNewCreditCardStep2');
const hireNewCreditCardStep3 = require('./hireNewCreditCard/hireNewCreditCardStep3');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorDir: false,
    errorMsg: '',
    hiringStatus: '',
    isFetching: false,
    steps: [
        hireNewCreditCardStep1(),
        hireNewCreditCardStep2(),
        hireNewCreditCardStep3(),

    ],
    standard: '',
    submitSuccessful: false,
    subtype: 0,
    type: 0,
    visibleStep: 1,
    willCancel: false,
    loadingFlag: false,
    documentINE: false,
    errorINE: false,
    errorIinit: false,
    scrollDown: false,
    checkButtonRadioPolicy: false,
    printDocument: false,
    printDocumentPolicy: false,
    cardsDocumentsPrintedAndSigned: undefined,
    cardsDocumentsPrintedAndSignedPolicy: null,
    cardsDocumentsPrintedAndSignedIneDir: null,
    mailSent: false,
    checkboxChecked: false,
    hasBeenSentEmail: false,
    scrollDownPolicy: false,
    cards: [{ type: '', subtype: '', standard: '' }],
    cardsImages: [],
    agreeIneCheckbox: false,
    initialDocumentsList: [],
    allDocumentRead: true,
    cardData: false,
    closeModal: false,
    showModal: false,
});

function updateStep(index, steps, action, ineDir) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireNewCreditCardStep1(step, action);
            case SECOND_STEP:
                return hireNewCreditCardStep2(step, action);
            case THIRD_STEP:
                return hireNewCreditCardStep3(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;
    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }
    return nextStep;
}

function hireNewCreditCard(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET:
            return initialState;
        case actionTypes.HIRE_NEW_CREDIT_CARD_SET_DATA_CARD:
            return state.merge({
                cards: action.payload.map(card => ({
                    standard: card.standard.substr(-7),
                    subtype: String(card.subtype),
                    type: String(card.type),
                })),
                cardImageIds: action.payload.map(card =>
                    `B.${card.type}.${card.subtype}.${card.standard.substr(-3)}`
                ),
                standard: String(action.payload[0].standard.substr(-7)),
                subtype: String(action.payload[0].subtype),
                type: String(action.payload[0].type),
                cardData: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET_CHECKBOX:
            return state.merge({
                scrollDown: false,
                agreeIneCheckbox: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR:
            return state.merge({
                initialDocumentsList: []
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_PRINT_DOCUMENT:
            return state.merge({
                printDocument: action.payload.value,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON:
            return state.merge({
                checkButtonRadio: action.payload.check,
                cardsDocumentsPrintedAndSigned: action.payload.check
            })
        case actionTypes.HIRE_NEW_CREDIT_CARD_LOADING:
            return state.merge({
                loadingFlag: action.payload.loadingFlag
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                hiringStatus: action.payload.hiringStatus,
                isFetching: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.HIRE_CARD_CREDIT_CARD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SET_ACCOUNT:
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SHOW_MODAL:
            return state.merge({
                showModal: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CLOSE_MODAL:
            return state.merge({
                showModal: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_NEXT_CLOSE_MODAL:
            return state.merge({
                showModal: false,
                closeModal: true
            });
        default:
            return state;
    }
}

module.exports = hireNewCreditCard;
