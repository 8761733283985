// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const { NOT_MAX_LIMIT } = require('constants/index');

const initialState = Immutable.fromJS({
    creditLimit: 0,
    creditLimitModalValue: 0,
    error: '',
    fetchSuccess: false,
    isFetching: false,
    marks: null,
    maxValue: null,
    minValue: null,
    modalIsOpen: false,
    productType: '',
    productSubType: '',
    referenceStandard: '',
    valid: false,
    maximumLimitAuthorize: false
});

function validateStep(state) {

    return state.merge({
        valid: state.get('creditLimit') > 0
    });
}

function mapIncreaseLimits({ defaultLimit, marks, maxValue, maxValueToAuthorize, productType, productSubType, referenceStandard, maximumLimitAuthorize }) {
    const minValue = Math.min(...marks);
    let creditLimit = minValue;
    if (maximumLimitAuthorize) {
        creditLimit = maximumLimitAuthorize === NOT_MAX_LIMIT ? defaultLimit : maximumLimitAuthorize;

    } else {

        if (!!defaultLimit && marks.includes(defaultLimit.toString())) {
            creditLimit = defaultLimit;
        }
    }
    if (maxValueToAuthorize > 0 && !marks.includes(parseInt(maxValueToAuthorize).toString())) {
        let previousLimit = 0;
        // if maxLimitToAuthorize is not included in the list of marks limits
        // we assign maxLimitToAuthorize the closest minimum value of the list
        marks.some(currentLimit => {
            const isClosest = maxValueToAuthorize > previousLimit && maxValueToAuthorize < currentLimit;
            maxValueToAuthorize = isClosest ? parseInt(previousLimit) : maxValueToAuthorize;
            previousLimit = currentLimit;
            return isClosest;
        })
    }

    return {
        creditLimit,
        creditLimitModalValue: maxValueToAuthorize,
        marks,
        maxValue: parseFloat(maxValue),
        maxValueToAuthorize,
        minValue,
        productType,
        productSubType,
        referenceStandard,
        maximumLimitAuthorize
    };
}

function hireCreditLimitReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CREDIT_LIMIT_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.HIRE_CREDIT_LIMIT_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                fetchSuccess: false
            });

        case actionTypes.HIRE_CREDIT_LIMIT_SUCCESS:
            return state.merge(
                {
                    isFetching: false,
                    fetchSuccess: true
                },
                mapIncreaseLimits(action.payload)
            );

        case actionTypes.HIRE_CREDIT_LIMIT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });

        case actionTypes.HIRE_CREDIT_LIMIT_SET:
            return state.merge(
                {
                    creditLimit: action.payload.creditLimit,
                    creditLimitModalValue: action.payload.resetModalValue ? state.get('maxValueToAuthorize') : state.get('creditLimitModalValue')
                }
            );

        case actionTypes.HIRE_CREDIT_LIMIT_SET_MODAL_VALUE:
            return state.merge({
                creditLimitModalValue: parseInt(action.payload.creditLimitModalValue, 10)
            });

        case actionTypes.HIRE_CREDIT_LIMIT_TOGGLE_MODAL:
            return state.merge({
                modalIsOpen: action.payload.modalIsOpen
            });

        case actionTypes.HIRE_CREDIT_LIMIT_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireCreditLimitReducer;
