// PLEASE KEEP ALPHABETIC ORDER!

module.exports = {
  ACCESS_KEY_CHANGE_RESET: 'ACCESS_KEY_CHANGE_RESET',
  ACCESS_KEY_CHANGE_SET_FIELD: 'ACCESS_KEY_CHANGE_SET_FIELD',
  ACCESS_KEY_CHANGE_SET_VISIBLE_STEP: 'ACCESS_KEY_CHANGE_SET_VISIBLE_STEP',
  ACCESS_KEY_CHANGE_SUCCESS: 'ACCESS_KEY_CHANGE_SUCCESS',
  ACCESS_KEY_CHANGE_VALIDATE_STEP: 'ACCESS_KEY_CHANGE_VALIDATE_STEP',
  ACCESS_KEY_CHANGE_WILL_CANCEL: 'ACCESS_KEY_CHANGE_WILL_CANCEL',
  ACCESS_KEY_CHANGE_WILL_LEAVE: 'ACCESS_KEY_CHANGE_WILL_LEAVE',
  ACCOUNT_ADD_DOCUMENTATION_ADD_FILE: 'ACCOUNT_ADD_DOCUMENTATION_ADD_FILE',
  ACCOUNT_ADD_DOCUMENTATION_CHANGE_NAME: 'ACCOUNT_ADD_DOCUMENTATION_CHANGE_NAME',
  ACCOUNT_ADD_DOCUMENTATION_ENTRY_FROM_MENU: 'ACCOUNT_ADD_DOCUMENTATION_ENTRY_FROM_MENU',
  ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_FAILURE:
    'ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_FAILURE',
  ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_REQUEST:
    'ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_REQUEST',
  ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_RESET:
    'ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_RESET',
  ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_SUCCESS:
    'ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_SUCCESS',
  ACCOUNT_ADD_DOCUMENTATION_REMOVE_FILE: 'ACCOUNT_ADD_DOCUMENTATION_REMOVE_FILE',
  ACCOUNT_ADD_DOCUMENTATION_RETRY_UPLOAD: 'ACCOUNT_ADD_DOCUMENTATION_RETRY_UPLOAD',
  ACCOUNT_ADD_DOCUMENTATION_SET_ACCOUNT_INFO: 'ACCOUNT_ADD_DOCUMENTATION_SET_ACCOUNT_INFO',
  ACCOUNT_ADD_DOCUMENTATION_SET_DONE_FILES: 'ACCOUNT_ADD_DOCUMENTATION_SET_DONE_FILES',
  ACCOUNT_ADD_DOCUMENTATION_SET_ERROR_FILE: 'ACCOUNT_ADD_DOCUMENTATION_SET_ERROR_FILE',
  ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE: 'ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE',
  ACCOUNT_ADD_DOCUMENTATION_UPLOAD_REQUEST: 'ACCOUNT_ADD_DOCUMENTATION_UPLOAD_REQUEST',
  ACCOUNT_ADD_DOCUMENTATION_UPLOAD_SUCCESS: 'ACCOUNT_ADD_DOCUMENTATION_UPLOAD_SUCCESS',
  ACCOUNT_ADD_DOCUMENTATION_TRAMITATION_FLAG_FAILURE:
    'ACCOUNT_ADD_DOCUMENTATION_TRAMITATION_FLAG_FAILURE',
  ACCOUNT_BUY_AMAZON_GIFT_ACCOUNT_AVAILABLE_BALANCE:
    'ACCOUNT_BUY_AMAZON_GIFT_ACCOUNT_AVAILABLE_BALANCE',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_RESET: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_RESET',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_ACCOUNT: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_ACCOUNT',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_AMOUNT: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_AMOUNT',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_VISIBLE_STEP: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_VISIBLE_STEP',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_VALIDATE_STEP: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_VALIDATE_STEP',
  ACCOUNT_BUY_AMAZON_GIFT_CARD_WILL_CANCEL: 'ACCOUNT_BUY_AMAZON_GIFT_CARD_WILL_CANCEL',
  ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_FAILURE:
    'ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_FAILURE',
  ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_REQUEST:
    'ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_REQUEST',
  ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_SUCCESS:
    'ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_SUCCESS',
  ACCOUNT_BUY_AMAZON_GIFT_SET_AGREEMENT_CHECKBOX: 'ACCOUNT_BUY_AMAZON_GIFT_SET_AGREEMENT_CHECKBOX',
  ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_FAILURE: 'ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_FAILURE',
  ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_REQUEST: 'ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_REQUEST',
  ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_SUCCESS: 'ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_SUCCESS',
  ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_VOUCHER_AMOUNT:
    'ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_VOUCHER_AMOUNT',
  ACCOUNT_BUY_AMAZON_GIFT_SET_PRE_CONTRACT_CHECKBOX:
    'ACCOUNT_BUY_AMAZON_GIFT_SET_PRE_CONTRACT_CHECKBOX',
  ACCOUNT_CANCEL_CHECKS_AMOUNT_CHANGED: 'ACCOUNT_CANCEL_CHECKS_AMOUNT_CHANGED',
  ACCOUNT_CANCEL_CHECKS_CHEQUE_CHANGED: 'ACCOUNT_CANCEL_CHECKS_CHEQUE_CHANGED',
  ACCOUNT_CANCEL_CHECKS_REASON_CHANGED: 'ACCOUNT_CANCEL_CHECKS_REASON_CHANGED',
  ACCOUNT_CANCEL_CHECKS_RESET_VIEW: 'ACCOUNT_CANCEL_CHECKS_RESET_VIEW',
  ACCOUNT_CANCEL_CHECKS_SETUP_FIRST_STEP: 'ACCOUNT_CANCEL_CHECKS_SETUP_FIRST_STEP',
  ACCOUNT_CANCEL_CHECKS_SET_VISIBLE_STEP: 'ACCOUNT_CANCEL_CHECKS_SET_VISIBLE_STEP',
  ACCOUNT_CANCEL_CHECKS_SUCCESS: 'ACCOUNT_CANCEL_CHECKS_SUCCESS',
  ACCOUNT_CANCEL_CHECKS_VALIDATE_API_FAILURE: 'ACCOUNT_CANCEL_CHECKS_VALIDATE_API_FAILURE',
  ACCOUNT_CANCEL_CHECKS_VALIDATE_API_REQUEST: 'ACCOUNT_CANCEL_CHECKS_VALIDATE_API_REQUEST',
  ACCOUNT_CANCEL_CHECKS_VALIDATE_API_SUCCESS: 'ACCOUNT_CANCEL_CHECKS_VALIDATE_API_SUCCESS',
  ACCOUNT_CANCEL_CHECKS_VALIDATE_STEP: 'ACCOUNT_CANCEL_CHECKS_VALIDATE_STEP',
  ACCOUNT_CANCEL_CHECKS_WILL_CANCEL: 'ACCOUNT_CANCEL_CHECKS_WILL_CANCEL',
  ACCOUNT_CANCEL_CHEQUEBOOK_RESET: 'ACCOUNT_CANCEL_CHEQUEBOOK_RESET',
  ACCOUNT_CANCEL_CHEQUEBOOK_SET_MOTIVE: 'ACCOUNT_CANCEL_CHEQUEBOOK_SET_MOTIVE',
  ACCOUNT_CANCEL_CHEQUEBOOK_SET_VISIBLE_STEP: 'ACCOUNT_CANCEL_CHEQUEBOOK_SET_VISIBLE_STEP',
  ACCOUNT_CANCEL_CHEQUEBOOK_SUCCESS: 'ACCOUNT_CANCEL_CHEQUEBOOK_SUCCESS',
  ACCOUNT_CANCEL_CHEQUEBOOK_VALIDATE_STEP: 'ACCOUNT_CANCEL_CHEQUEBOOK_VALIDATE_STEP',
  ACCOUNT_CANCEL_CHEQUEBOOK_WILL_CANCEL: 'ACCOUNT_CANCEL_CHEQUEBOOK_WILL_CANCEL',
  ACCOUNT_CANCEL_CHEQUE_FAILURE: 'ACCOUNT_CANCEL_CHEQUE_FAILURE',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR: 'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_OPTION: 'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_OPTION',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_FAILURE:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_FAILURE',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_REQUEST:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_REQUEST',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_SUCCESS:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_SUCCESS',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SELECT_CARD:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SELECT_CARD',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT',
  ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST:
    'ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST',
  ACCOUNT_DEBITS_EDIT_RESET: 'ACCOUNT_DEBITS_EDIT_RESET',
  ACCOUNT_DEBITS_EDIT_RESET_STEP: 'ACCOUNT_DEBITS_EDIT_RESET_STEP',
  ACCOUNT_DEBITS_EDIT_SET_ACCOUNT: 'ACCOUNT_DEBITS_EDIT_SET_ACCOUNT',
  ACCOUNT_DEBITS_EDIT_SET_VISIBLE_STEP: 'ACCOUNT_DEBITS_EDIT_SET_VISIBLE_STEP',
  ACCOUNT_DEBITS_EDIT_VALIDATE_STEP: 'ACCOUNT_DEBITS_EDIT_VALIDATE_STEP',
  ACCOUNT_DEBITS_EDIT_WILL_CANCEL: 'ACCOUNT_DEBITS_EDIT_WILL_CANCEL',
  ACCOUNT_DIRECT_DEBIT_ADD_FILE: 'ACCOUNT_DIRECT_DEBIT_ADD_FILE',
  ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_ERROR: 'ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_ERROR',
  ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_SUCCESS: 'ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_SUCCESS',
  ACCOUNT_DIRECT_DEBIT_REMOVE_FILE: 'ACCOUNT_DIRECT_DEBIT_REMOVE_FILE',
  ACCOUNT_DIRECT_DEBIT_RESET: 'ACCOUNT_DIRECT_DEBIT_RESET',
  ACCOUNT_DIRECT_DEBIT_SET_ACCOUNT: 'ACCOUNT_DIRECT_DEBIT_SET_ACCOUNT',
  ACCOUNT_DIRECT_DEBIT_SET_DESCRIPTION: 'ACCOUNT_DIRECT_DEBIT_SET_DESCRIPTION',
  ACCOUNT_DIRECT_DEBIT_SET_VISIBLE_STEP: 'ACCOUNT_DIRECT_DEBIT_SET_VISIBLE_STEP',
  ACCOUNT_DIRECT_DEBIT_UPLOAD_ERROR: 'ACCOUNT_DIRECT_DEBIT_UPLOAD_ERROR',
  ACCOUNT_DIRECT_DEBIT_UPLOAD_PARTIAL_SUCCESS: 'ACCOUNT_DIRECT_DEBIT_UPLOAD_PARTIAL_SUCCESS',
  ACCOUNT_DIRECT_DEBIT_UPLOAD_REQUEST: 'ACCOUNT_DIRECT_DEBIT_UPLOAD_REQUEST',
  ACCOUNT_DIRECT_DEBIT_UPLOAD_SUCCESS: 'ACCOUNT_DIRECT_DEBIT_UPLOAD_SUCCESS',
  ACCOUNT_DIRECT_DEBIT_VALIDATE_STEP: 'ACCOUNT_DIRECT_DEBIT_VALIDATE_STEP',
  ACCOUNT_DIRECT_DEBIT_WILL_CANCEL: 'ACCOUNT_DIRECT_DEBIT_WILL_CANCEL',
  ACCOUNT_INTERVENERS_REMOVE_FAILURE: 'ACCOUNT_INTERVENERS_REMOVE_FAILURE',
  ACCOUNT_INTERVENERS_REMOVE_REQUEST: 'ACCOUNT_INTERVENERS_REMOVE_REQUEST',
  ACCOUNT_INTERVENERS_REMOVE_RESET: 'ACCOUNT_INTERVENERS_REMOVE_RESET',
  ACCOUNT_INTERVENERS_REMOVE_SUCCESS: 'ACCOUNT_INTERVENERS_REMOVE_SUCCESS',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_CLEAR: 'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_CLEAR',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_FAILURE:
    'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_FAILURE',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_REQUEST:
    'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_REQUEST',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_SUCCESS:
    'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_SUCCESS',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP:
    'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP:
    'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP',
  ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL: 'ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL',
  ACCOUNT_RETENTIONS_OUTDATED: 'ACCOUNT_RETENTIONS_OUTDATED',
  ACCOUNT_SETTLEMENTS_CLEAR_FILTERS: 'ACCOUNT_SETTLEMENTS_CLEAR_FILTERS',
  ACCOUNT_TRANSACTIONS_CHANGE_CATEGORY: 'ACCOUNT_TRANSACTIONS_CHANGE_CATEGORY',
  ACCOUNT_TRANSACTIONS_DOWNLOAD_ERROR: 'ACCOUNT_TRANSACTION_DOWNLOAD_ERROR',
  ACCOUNT_TRANSACTIONS_DOWNLOAD_REQUEST: 'ACCOUNT_TRANSACTION_DOWNLOAD_REQUEST',
  ACCOUNT_TRANSACTIONS_DOWNLOAD_SUCCESS: 'ACCOUNT_TRANSACTION_DOWNLOAD_SUCCESS',
  ACCOUNT_TRANSACTIONS_OUTDATED: 'ACCOUNT_TRANSACTIONS_OUTDATED',
  ADD_BENEFICIARY_ACCOUNT_NUMBER: 'ADD_BENEFICIARY_ACCOUNT_NUMBER',
  ADD_BENEFICIARY_ADDRESS: 'ADD_BENEFICIARY_ADDRESS',
  ADD_BENEFICIARY_ALIAS: 'ADD_BENEFICIARY_ALIAS',
  ADD_BENEFICIARY_BANK_ADDRESS: 'ADD_BENEFICIARY_BANK_ADDRESS',
  ADD_BENEFICIARY_BANK_CITY: 'ADD_BENEFICIARY_BANK_CITY',
  ADD_BENEFICIARY_BANK_NAME: 'ADD_BENEFICIARY_BANK_NAME',
  ADD_BENEFICIARY_BANK_OPTION: 'ADD_BENEFICIARY_BANK_OPTION',
  ADD_BENEFICIARY_BIC: 'ADD_BENEFICIARY_BIC',
  ADD_BENEFICIARY_CITY: 'ADD_BENEFICIARY_CITY',
  ADD_BENEFICIARY_COUNTRY: 'ADD_BENEFICIARY_COUNTRY',
  ADD_BENEFICIARY_CURRENCY: 'ADD_BENEFICIARY_CURRENCY',
  ADD_BENEFICIARY_FILL_FORM: 'ADD_BENEFICIARY_FILL_FORM',
  ADD_BENEFICIARY_FILL_FORM_FROM_TRANSFER: 'ADD_BENEFICIARY_FILL_FORM_FROM_TRANSFER',
  ADD_BENEFICIARY_IBAN: 'ADD_BENEFICIARY_IBAN',
  ADD_BENEFICIARY_NAME: 'ADD_BENEFICIARY_NAME',
  ADD_BENEFICIARY_NOT_DEDUCTIBLE_MESSAGE: 'ADD_BENEFICIARY_NOT_DEDUCTIBLE_MESSAGE',
  ADD_BENEFICIARY_REQUEST_FAILURE: 'ADD_BENEFICIARY_REQUEST_FAILURE',
  ADD_BENEFICIARY_REQUEST_REQUEST: 'ADD_BENEFICIARY_REQUEST_REQUEST',
  ADD_BENEFICIARY_REQUEST_SUCCESS: 'ADD_BENEFICIARY_REQUEST_SUCCESS',
  ADD_BENEFICIARY_RESET: 'ADD_BENEFICIARY_RESET',
  ADD_BENEFICIARY_RESET_LOAD_FROM_TRANSFER: 'ADD_BENEFICIARY_RESET_LOAD_FROM_TRANSFER',
  ADD_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE: 'ADD_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE',
  ADD_BENEFICIARY_SET_VISIBLE_STEP: 'ADD_BENEFICIARY_SET_VISIBLE_STEP',
  ADD_BENEFICIARY_VALIDATE_STEP_FAILURE: 'ADD_BENEFICIARY_VALIDATE_STEP_FAILURE',
  ADD_BENEFICIARY_VALIDATE_STEP_REQUEST: 'ADD_BENEFICIARY_VALIDATE_STEP_REQUEST',
  ADD_BENEFICIARY_VALIDATE_STEP_SUCCESS: 'ADD_BENEFICIARY_VALIDATE_STEP_SUCCESS',
  ADD_BENEFICIARY_VALIDATE_UI_STEP: 'ADD_BENEFICIARY_VALIDATE_UI_STEP',
  ADD_BENEFICIARY_WILL_CANCEL: 'ADD_BENEFICIARY_WILL_CANCEL',
  ADD_BENEFICIARY_WILL_LEAVE: 'ADD_BENEFICIARY_WILL_LEAVE',
  ADD_TOOLTIP: 'ADD_TOOLTIP',
  ADVANCED_INTERSTITIAL_GET_PROMOTION_FAILURE: 'ADVANCED_INTERSTITIAL_GET_PROMOTION_FAILURE',
  ADVANCED_INTERSTITIAL_GET_PROMOTION_REQUEST: 'ADVANCED_INTERSTITIAL_GET_PROMOTION_REQUEST',
  ADVANCED_INTERSTITIAL_GET_PROMOTION_SUCCESS: 'ADVANCED_INTERSTITIAL_GET_PROMOTION_SUCCESS',
  ADVANCED_INTERSTITIAL_RESET_FLAG: 'ADVANCED_INTERSTITIAL_RESET_FLAG',
  ADVANCED_INTERSTITIAL_SET_FLAG: 'ADVANCED_INTERSTITIAL_SET_FLAG',
  AGGREGATOR_ADD_ENTITY_CLEAR: 'AGGREGATOR_ADD_ENTITY_CLEAR',
  AGGREGATOR_ADD_ENTITY_CLEAR_FORM_VALUES: 'AGGREGATOR_ADD_ENTITY_CLEAR_FORM_VALUES',
  AGGREGATOR_ADD_ENTITY_GET_BANKS_FAILURE: 'AGGREGATOR_ADD_ENTITY_GET_BANKS_FAILURE',
  AGGREGATOR_ADD_ENTITY_GET_BANKS_REQUEST: 'AGGREGATOR_ADD_ENTITY_GET_BANKS_REQUEST',
  AGGREGATOR_ADD_ENTITY_GET_BANKS_SUCCESS: 'AGGREGATOR_ADD_ENTITY_GET_BANKS_SUCCESS',
  AGGREGATOR_ADD_ENTITY_GET_FORM_FAILURE: 'AGGREGATOR_ADD_ENTITY_GET_FORM_FAILURE',
  AGGREGATOR_ADD_ENTITY_GET_FORM_REQUEST: 'AGGREGATOR_ADD_ENTITY_GET_FORM_REQUEST',
  AGGREGATOR_ADD_ENTITY_GET_FORM_SUCCESS: 'AGGREGATOR_ADD_ENTITY_GET_FORM_SUCCESS',
  AGGREGATOR_ADD_ENTITY_RESET_FORM_VALUES: 'AGGREGATOR_ADD_ENTITY_RESET_FORM_VALUES',
  AGGREGATOR_ADD_ENTITY_SELECT_BANK: 'AGGREGATOR_ADD_ENTITY_SELECT_BANK',
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_FAILURE: 'AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_FAILURE',
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_REQUEST: 'AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_REQUEST',
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_SUCCESS: 'AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_SUCCESS',
  AGGREGATOR_ADD_ENTITY_SET_FORM_VALUE: 'AGGREGATOR_ADD_ENTITY_SET_FORM_VALUE',
  AGGREGATOR_ADD_ENTITY_SET_VISIBLE_STEP: 'AGGREGATOR_ADD_ENTITY_SET_VISIBLE_STEP',
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_FAILURE:
    'AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_FAILURE',
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_REQUEST:
    'AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_REQUEST',
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_SUCCESS:
    'AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_SUCCESS',
  AGGREGATOR_ADD_ENTITY_VALIDATE_STEP: 'AGGREGATOR_ADD_ENTITY_VALIDATE_STEP',
  AGGREGATOR_ADD_ENTITY_WILL_CANCEL: 'AGGREGATOR_ADD_ENTITY_WILL_CANCEL',
  AGGREGATOR_ADD_ENTITY_PSD2_INIT: 'AGGREGATOR_ADD_ENTITY_PSD2_INIT',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_REQUEST: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_REQUEST',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_SUCCESS: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_SUCCESS',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_FAILURE: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_FAILURE',
  AGGREGATOR_CONSENTS: '',
  AGGREGATOR_CONSENTS_WIZARD_RESET: 'AGGREGATOR_CONSENTS_WIZARD_RESET',
  AGGREGATOR_CONSENTS_FETCH_ERROR: 'AGGREGATOR_CONSENTS_FETCH_ERROR',
  AGGREGATOR_CONSENTS_OTP_ERROR: 'AGGREGATOR_CONSENTS_OTP_ERROR',
  AGGREGATOR_CONSENTS_OTP_FETCHING: 'AGGREGATOR_CONSENTS_OTP_FETCHING',
  AGGREGATOR_CONSENTS_OTP_SUCCESS: 'AGGREGATOR_CONSENTS_OTP_SUCCESS',
  AGGREGATOR_CONSENTS_FETCH_CHANGE_CONSENTS: 'AGGREGATOR_CONSENTS_FETCH_CHANGE_CONSENTS',
  AGGREGATOR_CONSENTS_FETCH_IN_PROGRESS: 'AGGREGATOR_CONSENTS_FETCH_IN_PROGRESS',
  AGGREGATOR_CONSENTS_FETCH_SUCCESS: 'AGGREGATOR_CONSENTS_FETCH_SUCCESS',
  AGGREGATOR_CONSENTS_OWN_BUSINESS_SWITCH_CHANGED:
    'AGGREGATOR_CONSENTS_OWN_BUSINESS_SWITCH_CHANGED',
  AGGREGATOR_CONSENTS_THIRD_PARTIES_BUSINESS_SWITCH_CHANGED:
    'AGGREGATOR_CONSENTS_THIRD_PARTIES_BUSINESS_SWITCH_CHANGED',
  AGGREGATOR_CONSENTS_WIZARD_NEXT_STEP: 'AGGREGATOR_CONSENTS_WIZARD_NEXT_STEP',
  AGGREGATOR_CONSENTS_WIZARD_PREVIOUS_STEP: 'AGGREGATOR_CONSENTS_WIZARD_PREVIOUS_STEP',
  AGGREGATOR_CONSENTS_PSD2_INIT: 'AGGREGATOR_CONSENTS_PSD2_INIT',
  AGGREGATOR_CONSENTS_PSD2_GET_URL_REQUEST: 'AGGREGATOR_CONSENTS_PSD2_GET_URL_REQUEST',
  AGGREGATOR_CONSENTS_PSD2_GET_URL_SUCCESS: 'AGGREGATOR_CONSENTS_PSD2_GET_URL_SUCCESS',
  AGGREGATOR_CONSENTS_PSD2_GET_URL_FAILURE: 'AGGREGATOR_CONSENTS_PSD2_GET_URL_FAILURE',
  AGGREGATOR_CONSENTS_PSD2_GET_INFO_REQUEST: 'AGGREGATOR_CONSENTS_PSD2_GET_INFO_REQUEST',
  AGGREGATOR_CONSENTS_PSD2_GET_INFO_SUCCESS: 'AGGREGATOR_CONSENTS_PSD2_GET_INFO_SUCCESS',
  AGGREGATOR_CONSENTS_PSD2_GET_INFO_FAILURE: 'AGGREGATOR_CONSENTS_PSD2_GET_INFO_FAILURE',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_REQUEST: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_REQUEST',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_SUCCESS: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_SUCCESS',
  AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_FAILURE: 'AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_FAILURE',
  AGGREGATOR_GET_DETAILS_FAILURE: 'AGGREGATOR_GET_DETAILS_FAILURE',
  AGGREGATOR_GET_DETAILS_REQUEST: 'AGGREGATOR_GET_DETAILS_REQUEST',
  AGGREGATOR_GET_DETAILS_SUCCESS: 'AGGREGATOR_GET_DETAILS_SUCCESS',
  AGGREGATOR_GET_ENTITIES_DATA_FAILURE: 'AGGREGATOR_GET_ENTITIES_DATA_FAILURE',
  AGGREGATOR_GET_ENTITIES_DATA_REQUEST: 'AGGREGATOR_GET_ENTITIES_DATA_REQUEST',
  AGGREGATOR_GET_ENTITIES_DATA_SUCCESS: 'AGGREGATOR_GET_ENTITIES_DATA_SUCCESS',
  AGGREGATOR_REMOVE_ENTITY_FAILURE: 'AGGREGATOR_REMOVE_ENTITY_FAILURE',
  AGGREGATOR_REMOVE_ENTITY_REQUEST: 'AGGREGATOR_REMOVE_ENTITY_REQUEST',
  AGGREGATOR_REMOVE_ENTITY_SUCCESS: 'AGGREGATOR_REMOVE_ENTITY_SUCCESS',
  AGGREGATOR_SEND_SECOND_KEY_AUTH_FAILURE: 'AGGREGATOR_SEND_SECOND_KEY_AUTH_FAILURE',
  AGGREGATOR_SEND_SECOND_KEY_AUTH_REQUEST: 'AGGREGATOR_SEND_SECOND_KEY_AUTH_REQUEST',
  AGGREGATOR_SEND_SECOND_KEY_AUTH_RESET: 'AGGREGATOR_SEND_SECOND_KEY_AUTH_RESET',
  AGGREGATOR_SEND_SECOND_KEY_AUTH_SUCCESS: 'AGGREGATOR_SEND_SECOND_KEY_AUTH_SUCCESS',
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_FAILURE: 'AGGREGATOR_TOGGLE_ACTIVE_ENTITY_FAILURE',
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_REQUEST: 'AGGREGATOR_TOGGLE_ACTIVE_ENTITY_REQUEST',
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_SUCCESS: 'AGGREGATOR_TOGGLE_ACTIVE_ENTITY_SUCCESS',
  AGGREGATOR_TINK_LINK_FAILURE: 'AGGREGATOR_TINK_LINK_FAILURE',
  AGGREGATOR_TINK_LINK_REQUEST: 'AGGREGATOR_TINK_LINK_REQUEST',
  AGGREGATOR_TINK_LINK_SUCCESS: 'AGGREGATOR_TINK_LINK_SUCCESS',
  AGGREGATOR_CONSENTS_SUCCESS: 'AGGREGATOR_CONSENTS_SUCCESS',
  ALL_PUBLIC_INFORMATION_LOADED_SUCCCESS: 'ALL_PUBLIC_INFORMATION_LOADED_SUCCCESS',
  AMAZON_GET_COUPON_CODE_ERROR: 'AMAZON_GET_COUPON_CODE_ERROR',
  AMAZON_GET_COUPON_CODE_REQUEST: 'AMAZON_GET_COUPON_CODE_REQUEST',
  AMAZON_GET_COUPON_CODE_SUCCESS: 'AMAZON_GET_COUPON_CODE_SUCCESS',
  AMAZON_GET_GIFT_CARD_IMAGE_ERROR: 'AMAZON_GET_GIFT_CARD_IMAGE_ERROR',
  AMAZON_GET_GIFT_CARD_IMAGE_IS_FETCHING: 'AMAZON_GET_GIFT_CARD_IMAGE_IS_FETCHING',
  AMAZON_GET_GIFT_CARD_IMAGE_SUCCESS: 'AMAZON_GET_GIFT_CARD_IMAGE_SUCCESS',
  AMAZON_GET_PROMO_CODE_ERROR: 'AMAZON_GET_PROMO_CODE_ERROR',
  AMAZON_GET_PROMO_CODE_IS_FETCHING: 'AMAZON_GET_PROMO_CODE_IS_FETCHING',
  AMAZON_GET_PROMO_CODE_SUCCESS: 'AMAZON_GET_PROMO_CODE_SUCCESS',
  AMAZON_GET_PROMOTION_CARD_ERROR: 'AMAZON_GET_PROMOTION_CARD_ERROR',
  AMAZON_GET_PROMOTION_CARD_IS_FETCHING: 'AMAZON_GET_PROMOTION_CARD_IS_FETCHING',
  AMAZON_GET_PROMOTION_CARD_SUCCESS: 'AMAZON_GET_PROMOTION_CARD_SUCCESS',
  AMAZON_GIFT_CARD_CLEAR_FILTERS: 'AMAZON_GIFT_CARD_CLEAR_FILTERS',
  AMAZON_GIFT_CARD_RESET_FILTER: 'AMAZON_GIFT_CARD_RESET_FILTER',
  AMAZON_GIFT_CARD_RETURN_FAILURE: 'AMAZON_GIFT_CARD_RETURN_FAILURE',
  AMAZON_GIFT_CARD_RETURN_REQUEST: 'AMAZON_GIFT_CARD_RETURN_REQUEST',
  AMAZON_GIFT_CARD_RETURN_STATE_RESET: 'AMAZON_GIFT_CARD_RETURN_STATE_RESET',
  AMAZON_GIFT_CARD_RETURN_SUCCESS: 'AMAZON_GIFT_CARD_RETURN_SUCCESS',
  AMAZON_GIFT_CARD_SET_FILTERS: 'AMAZON_GIFT_CARD_SET_FILTERS',
  AMAZON_SET_CONSULT_TYPE: 'AMAZON_SET_CONSULT_TYPE',
  APPLYFOR_SET_STEP_VALID: 'APPLYFOR_SET_STEP_VALID',
  APPLYFOR_SET_VISIBLE_STEP: 'APPLYFOR_SET_VISIBLE_STEP',
  ASSOCIATED_CARD_ACCOUNT_DETAILS_FAILURE: 'ASSOCIATED_CARD_ACCOUNT_DETAILS_FAILURE',
  ASSOCIATED_CARD_ACCOUNT_DETAILS_REQUEST: 'ASSOCIATED_CARD_ACCOUNT_DETAILS_REQUEST',
  ASSOCIATED_CARD_ACCOUNT_DETAILS_SUCCESS: 'ASSOCIATED_CARD_ACCOUNT_DETAILS_SUCCESS',
  AUTHORIZATIONS_FILTER: 'AUTHORIZATIONS_FILTER',
  AUTHORIZATION_ACTIVATION: 'AUTHORIZATION_ACTIVATION',
  AUTHORIZATION_ACTIVATION_FAILURE: 'AUTHORIZATION_ACTIVATION_FAILURE',
  AUTHORIZATION_ACTIVATION_RESET: 'AUTHORIZATION_ACTIVATION_RESET',
  AUTHORIZATION_ACTIVATION_SUCCESS: 'AUTHORIZATION_ACTIVATION_SUCCESS',
  AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED: 'AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED',
  AUTHORIZATION_MODIFY_ACCOUNT_FAILURE: 'AUTHORIZATION_MODIFY_ACCOUNT_FAILURE',
  AUTHORIZATION_MODIFY_ACCOUNT_REQUEST: 'AUTHORIZATION_MODIFY_ACCOUNT_REQUEST',
  AUTHORIZATION_MODIFY_ACCOUNT_RESET: 'AUTHORIZATION_MODIFY_ACCOUNT_RESET',
  AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP: 'AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP',
  AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS: 'AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS',
  AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP: 'AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP',
  BENEFICIARY_DETAILS_FAILURE: 'BENEFICIARY_DETAILS_FAILURE',
  BENEFICIARY_DETAILS_FETCH: 'BENEFICIARY_DETAILS_FETCH',
  BENEFICIARY_DETAILS_SUCCESS: 'BENEFICIARY_DETAILS_SUCCESS',
  BIOCATCH_RESET: 'BIOCATCH_RESET',
  BIOCATCH_SET_INITIALIZED: 'BIOCATCH_SET_INITIALIZED',
  BLOCK_TRUSTED_DEVICE_ERROR: 'BLOCK_TRUSTED_DEVICE_ERROR',
  BLOCK_TRUSTED_DEVICE_INFO: 'BLOCK_TRUSTED_DEVICE_INFO',
  BLOCK_TRUSTED_DEVICE_REQUEST: 'BLOCK_TRUSTED_DEVICE_REQUEST',
  BLOCK_TRUSTED_DEVICE_RESET: 'BLOCK_TRUSTED_DEVICE_RESET',
  BLOCK_TRUSTED_DEVICE_SUCCESS: 'BLOCK_TRUSTED_DEVICE_SUCCESS',
  BLOCKED_ACCOUNTS_FAILURE: 'BLOCKED_ACCOUNTS_FAILURE',
  BLOCKED_ACCOUNTS_REQUEST: 'BLOCKED_ACCOUNTS_REQUEST',
  BLOCKED_ACCOUNTS_RESET: 'BLOCKED_ACCOUNTS_RESET',
  BLOCKED_ACCOUNTS_SUCCESS: 'BLOCKED_ACCOUNTS_SUCCESS',
  FETCHING_BLOCKED_ACCOUNT_BY_SEPBLAC: 'FETCHING_BLOCKED_ACCOUNT_BY_SEPBLAC',
  BROKER_ADVANCED_SEARCH_ACCUMULATION_SELECT: 'BROKER_ADVANCED_SEARCH_ACCUMULATION_SELECT', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_BUY_BUTTON_CLICK: 'BROKER_ADVANCED_SEARCH_BUY_BUTTON_CLICK',
  BROKER_ADVANCED_SEARCH_CHANGE_TAB: 'BROKER_ADVANCED_SEARCH_CHANGE_TAB', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_CLEAR_RESULTS: 'BROKER_ADVANCED_SEARCH_CLEAR_RESULTS', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_FAILURE: 'BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_FAILURE',
  BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_SUCCESS: 'BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_SUCCESS',
  BROKER_ADVANCED_SEARCH_EDIT: 'BROKER_ADVANCED_SEARCH_EDIT',
  BROKER_ADVANCED_SEARCH_FETCTHING_DONE: 'BROKER_ADVANCED_SEARCH_FETCTHING_DONE', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_FIELDS_ARE_FETCHING: 'BROKER_ADVANCED_SEARCH_FIELDS_ARE_FETCHING', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_FIELD_CHANGE: 'BROKER_ADVANCED_SEARCH_FIELD_CHANGE', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_FILTERS_CLEAR: 'BROKER_ADVANCED_SEARCH_FILTERS_CLEAR',
  BROKER_ADVANCED_SEARCH_FILTERS_CLEAR_TRACKING: 'BROKER_ADVANCED_SEARCH_FILTERS_CLEAR_TRACKING',
  BROKER_ADVANCED_SEARCH_FILTERS_FAILURE: 'BROKER_ADVANCED_SEARCH_FILTERS_FAILURE',
  BROKER_ADVANCED_SEARCH_FILTERS_LIMITED: 'BROKER_ADVANCED_SEARCH_FILTERS_LIMITED',
  BROKER_ADVANCED_SEARCH_FILTERS_REQUEST: 'BROKER_ADVANCED_SEARCH_FILTERS_REQUEST',
  BROKER_ADVANCED_SEARCH_FILTERS_SUCCESS: 'BROKER_ADVANCED_SEARCH_FILTERS_SUCCESS',
  BROKER_ADVANCED_SEARCH_FILTERS_TRACKING: 'BROKER_ADVANCED_SEARCH_FILTERS_TRACKING',
  BROKER_ADVANCED_SEARCH_FILTER_CHANGE: 'BROKER_ADVANCED_SEARCH_FILTER_CHANGE',
  BROKER_ADVANCED_SEARCH_GET_FIELDS_FAILURE: 'BROKER_ADVANCED_SEARCH_GET_FIELDS_FAILURE', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_GET_FIELDS_SUCCESS: 'BROKER_ADVANCED_SEARCH_GET_FIELDS_SUCCESS', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_KEEP_FILTERS_PRODUCT_TRACKING:
    'BROKER_ADVANCED_SEARCH_KEEP_FILTERS_PRODUCT_TRACKING',
  BROKER_ADVANCED_SEARCH_KEEP_FILTERS_TRACKING: 'BROKER_ADVANCED_SEARCH_KEEP_FILTERS_TRACKING',
  BROKER_ADVANCED_SEARCH_MULTIPLE_ASSET_FILTER_CHANGE:
    'BROKER_ADVANCED_SEARCH_MULTIPLE_ASSET_FILTER_CHANGE',
  BROKER_ADVANCED_SEARCH_MULTIPLE_PRODUCT_FILTER_CHANGE:
    'BROKER_ADVANCED_SEARCH_MULTIPLE_PRODUCT_FILTER_CHANGE',
  BROKER_ADVANCED_SEARCH_RESET: 'BROKER_ADVANCED_SEARCH_RESET', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_RESULTS_CLEAR: 'BROKER_ADVANCED_SEARCH_RESULTS_CLEAR',
  BROKER_ADVANCED_SEARCH_RESULTS_FAILURE: 'BROKER_ADVANCED_SEARCH_RESULTS_FAILURE',
  BROKER_ADVANCED_SEARCH_RESULTS_REQUEST: 'BROKER_ADVANCED_SEARCH_RESULTS_REQUEST',
  BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS: 'BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS',
  BROKER_ADVANCED_SEARCH_SAVE_CHECKED_LIST: 'BROKER_ADVANCED_SEARCH_SAVE_CHECKED_LIST', // deprecate on R15 - Buscadores
  BROKER_ADVANCED_SEARCH_SAVE_FILTERS: 'BROKER_ADVANCED_SEARCH_SAVE_FILTERS',
  BROKER_ADVANCED_SEARCH_SHOULD_SEARCH: 'BROKER_ADVANCED_SEARCH_SHOULD_SEARCH',
  BROKER_ADVANCED_SEARCH_WIDGET_CHANGE_INPUT: 'BROKER_ADVANCED_SEARCH_WIDGET_CHANGE_INPUT',
  BROKER_ALERTS_CONFIG_CANCEL_ALERT: 'BROKER_ALERTS_CONFIG_CANCEL_ALERT',
  BROKER_ALERTS_CONFIG_CONFIGURATION_TYPE_CHANGE: 'BROKER_ALERTS_CONFIG_CONFIGURATION_TYPE_CHANGE',
  BROKER_ALERTS_CONFIG_CREATE_ALERT: 'BROKER_ALERTS_CONFIG_CREATE_ALERT',
  BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE: 'BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE',
  BROKER_ALERTS_CONFIG_CREATE_ALERT_REQUEST: 'BROKER_ALERTS_CONFIG_CREATE_ALERT_REQUEST',
  BROKER_ALERTS_CONFIG_CREATE_ALERT_SUCCESS: 'BROKER_ALERTS_CONFIG_CREATE_ALERT_SUCCESS',
  BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_FAILURE:
    'BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_FAILURE',
  BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_REQUEST:
    'BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_REQUEST',
  BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_SUCCESS:
    'BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_SUCCESS',
  BROKER_ALERTS_CONFIG_DISABLE_PRODUCT_ERROR: 'BROKER_ALERTS_CONFIG_DISABLE_PRODUCT_ERROR',
  BROKER_ALERTS_CONFIG_DISABLE_QUOTE_TYPE_ERROR: 'BROKER_ALERTS_CONFIG_DISABLE_QUOTE_TYPE_ERROR',
  BROKER_ALERTS_CONFIG_DISABLE_QUOTE_VALUE_ERROR: 'BROKER_ALERTS_CONFIG_DISABLE_QUOTE_VALUE_ERROR',
  BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT: 'BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT',
  BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_FAILURE: 'BROKER_ALERTS_CONFIG_EDIT_CUSTOM_FAILURE_ALERT',
  BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_REQUEST: 'BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_REQUEST',
  BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_SUCCESS: 'BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_SUCCESS',
  BROKER_ALERTS_CONFIG_GET_ALERTS_FAILURE: 'BROKER_ALERTS_CONFIG_GET_ALERTS_FAILURE',
  BROKER_ALERTS_CONFIG_GET_ALERTS_REQUEST: 'BROKER_ALERTS_CONFIG_GET_ALERTS_REQUEST',
  BROKER_ALERTS_CONFIG_GET_ALERTS_SUCCESS: 'BROKER_ALERTS_CONFIG_GET_ALERTS_SUCCESS',
  BROKER_ALERTS_CONFIG_GET_CONDITIONS_FAILURE: 'BROKER_ALERTS_CONFIG_GET_CONDITIONS_FAILURE',
  BROKER_ALERTS_CONFIG_GET_CONDITIONS_REQUEST: 'BROKER_ALERTS_CONFIG_GET_CONDITIONS_REQUEST',
  BROKER_ALERTS_CONFIG_GET_CONDITIONS_SUCCESS: 'BROKER_ALERTS_CONFIG_GET_CONDITIONS_SUCCESS',
  BROKER_ALERTS_CONFIG_GET_DEFAULT_ALERTS: 'BROKER_ALERTS_CONFIG_GET_DEFAULT_ALERTS',
  BROKER_ALERTS_CONFIG_INPUT_RATE_VALUE: 'BROKER_ALERTS_CONFIG_INPUT_RATE_VALUE',
  BROKER_ALERTS_CONFIG_RESET: 'BROKER_ALERTS_CONFIG_RESET',
  BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_FOR_INDEX:
    'BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_FOR_INDEX',
  BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_STOCKS:
    'BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_STOCKS',
  BROKER_ALERTS_CONFIG_SELECT_PRODUCT: 'BROKER_ALERTS_CONFIG_SELECT_PRODUCT',
  BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE: 'BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE',
  BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE_FOR_INDEX:
    'BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE_FOR_INDEX',
  BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE: 'BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE',
  BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE_FOR_INDEX:
    'BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE_FOR_INDEX',
  BROKER_ALERTS_CONFIG_UPDATE_CUSTOM_ALERT: 'BROKER_ALERTS_CONFIG_UPDATE_CUSTOM_ALERT',
  BROKER_ALERTS_CONFIG_VALIDATE_FORM: 'BROKER_ALERTS_CONFIG_VALIDATE_FORM',
  BROKER_ALERTS_CONFIG_VALIDATE_INDEX_FORM: 'BROKER_ALERTS_CONFIG_VALIDATE_INDEX_FORM',
  BROKER_ALL_INDEXES_FETCHING: 'BROKER_ALL_INDEXES_FETCHING',
  BROKER_ALL_INDEXES_FETCHING_FAILURE: 'BROKER_ALL_INDEXES_FETCHING_FAILURE',
  BROKER_ALL_INDEXES_FETCHING_SUCCESS: 'BROKER_ALL_INDEXES_FETCHING_SUCCESS',
  BROKER_ATTETION_PLAN_MODAL_CLOSE: 'BROKER_ATTETION_PLAN_MODAL_CLOSE',
  BROKER_ATTETION_PLAN_MODAL_CLOSE_AND_REMEMBER: 'BROKER_ATTETION_PLAN_MODAL_CLOSE_AND_REMEMBER',
  BROKER_ATTETION_PLAN_MODAL_GET_DATES_SUCCESS: 'BROKER_ATTETION_PLAN_MODAL_GET_DATES_SUCCESS',
  BROKER_ATTETION_PLAN_MODAL_INIT: 'BROKER_ATTETION_PLAN_MODAL_INIT',
  BROKER_BANNER_UPDATE_CLOSE_OR_AGREED: 'BROKER_BANNER_UPDATE_CLOSE_OR_AGREED',
  BROKER_BANNER_UPDATE_DATA: 'BROKER_BANNER_UPDATE_DATA',
  BROKER_BANNER_UPDATE_HIDE: 'BROKER_BANNER_UPDATE_HIDE',
  BROKER_BANNER_UPDATE_SHOW: 'BROKER_BANNER_UPDATE_SHOW',
  BROKER_CALLMEBACK_BLUR_TEL_CODE: 'BROKER_CALLMEBACK_BLUR_TEL_CODE',
  BROKER_CALLMEBACK_CHANGE_CALL_TIME: 'BROKER_CALLMEBACK_CHANGE_CALL_TIME',
  BROKER_CALLMEBACK_CHANGE_COMMENT: 'BROKER_CALLMEBACK_CHANGE_COMMENT',
  BROKER_CALLMEBACK_CHANGE_TEL: 'BROKER_CALLMEBACK_CHANGE_TEL',
  BROKER_CALLMEBACK_CHANGE_TEL_CODE: 'BROKER_CALLMEBACK_CHANGE_TEL_CODE',
  BROKER_CALLMEBACK_GET_CALL_TIME: 'BROKER_CALLMEBACK_GET_CALL_TIME',
  BROKER_CALLMEBACK_RESET: 'BROKER_CALLMEBACK_RESET',
  BROKER_CALLMEBACK_SET_PROFILE_PHONE: 'BROKER_CALLMEBACK_SET_PROFILE_PHONE',
  BROKER_CALLMEBACK_TOGGLE_MODAL: 'BROKER_CALLMEBACK_TOGGLE_MODAL',
  BROKER_CALLMEBACK_VALIDATION: 'BROKER_CALLMEBACK_VALIDATION',
  BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS: 'BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS',
  BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT: 'BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT',
  BROKER_CASH_CHARGE_FINISH: 'BROKER_CASH_CHARGE_FINISH',
  BROKER_CASH_CHARGE_SET_VISIBLE_STEP: 'BROKER_CASH_CHARGE_SET_VISIBLE_STEP',
  BROKER_CASH_CHARGE_START: 'BROKER_CASH_CHARGE_START',
  BROKER_CASH_CHARGE_VALIDATE_STEP: 'BROKER_CASH_CHARGE_VALIDATE_STEP',
  BROKER_CASH_CHARGE_WILL_CANCEL: 'BROKER_CASH_CHARGE_WILL_CANCEL',
  BROKER_CHANGE_MY_WALLET_EVOLUTION_DATA: 'BROKER_CHANGE_MY_WALLET_EVOLUTION_DATA',
  BROKER_CHANGE_SELECTED_VIEW: 'BROKER_CHANGE_SELECTED_VIEW',
  BROKER_CHECK_CLIENT_PERSONAL_INFO_ERROR: 'BROKER_CHECK_CLIENT_PERSONAL_INFO_ERROR',
  BROKER_CHECK_CLIENT_PERSONAL_INFO_REQUEST: 'BROKER_CHECK_CLIENT_PERSONAL_INFO_REQUEST',
  BROKER_CHECK_CLIENT_PERSONAL_INFO_SUCCESS: 'BROKER_CHECK_CLIENT_PERSONAL_INFO_SUCCESS',
  BROKER_CLEAR_ALL_FUNDS_CONTRACT_INTERVENERS: 'BROKER_CLEAR_ALL_FUNDS_CONTRACT_INTERVENERS',
  BROKER_CONSULT_MIFID_FETCHING: 'BROKER_CONSULT_MIFID_FETCHING',
  BROKER_CONSULT_MIFID_FETCHING_ERROR: 'BROKER_CONSULT_MIFID_FETCHING_ERROR',
  BROKER_CONSULT_MIFID_FETCHING_SUCCESS: 'BROKER_CONSULT_MIFID_FETCHING_SUCCESS',
  BROKER_CONTRACT_ALL_VALUES_SUCCESS: 'BROKER_CONTRACT_ALL_VALUES_SUCCESS',
  BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS: 'BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS',
  BROKER_CONTRACT_VALUES_FAILURE: 'BROKER_CONTRACT_VALUES_FAILURE',
  BROKER_CONTRACT_VALUES_REQUEST: 'BROKER_CONTRACT_VALUES_REQUEST',
  BROKER_CONTRACT_VALUES_SUCCESS: 'BROKER_CONTRACT_VALUES_SUCCESS',
  BROKER_CONVENIENCE_MIFID_TEST_CONSULT: 'BROKER_CONVENIENCE_MIFID_TEST_CONSULT',
  BROKER_CONVENIENCE_MIFID_TEST_FETCH_GET_TEST_SUCCESS:
    'BROKER_CONVENIENCE_MIFID_TEST_FETCH_GET_TEST_SUCCESS',
  BROKER_CONVENIENCE_MIFID_TEST_FINISH: 'BROKER_CONVENIENCE_MIFID_TEST_FINISH',
  BROKER_CONVENIENCE_MIFID_TEST_FINISH_ERROR: 'BROKER_CONVENIENCE_MIFID_TEST_FINISH_ERROR',
  BROKER_CONVENIENCE_MIFID_TEST_FINISH_LOADING: 'BROKER_CONVENIENCE_MIFID_TEST_FINISH_LOADING',
  BROKER_CONVENIENCE_MIFID_TEST_HARD_RESET: 'BROKER_CONVENIENCE_MIFID_TEST_HARD_RESET',
  BROKER_CONVENIENCE_MIFID_TEST_OMIT: 'BROKER_CONVENIENCE_MIFID_TEST_OMIT',
  BROKER_CONVENIENCE_MIFID_TEST_RESET: 'BROKER_CONVENIENCE_MIFID_TEST_RESET',
  BROKER_CONVENIENCE_MIFID_TEST_RESULT_DISPLAYED: 'BROKER_CONVENIENCE_MIFID_TEST_RESULT_DISPLAYED',
  BROKER_CONVENIENCE_MIFID_TEST_RETAKE_TEST: 'BROKER_CONVENIENCE_MIFID_TEST_RETAKE_TEST',
  BROKER_CONVENIENCE_MIFID_TEST_SET_OPERATION_CODE_US_PERSON:
    'BROKER_CONVENIENCE_MIFID_TEST_SET_OPERATION_CODE_US_PERSON',
  BROKER_CONVENIENCE_MIFID_TEST_SET_VISIBLE_STEP: 'BROKER_CONVENIENCE_MIFID_TEST_SET_VISIBLE_STEP',
  BROKER_CONVENIENCE_MIFID_TEST_START: 'BROKER_CONVENIENCE_MIFID_TEST_START',
  BROKER_CONVENIENCE_MIFID_TEST_STEP1_CHANGE_EXPERIENCE:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP1_CHANGE_EXPERIENCE',
  BROKER_CONVENIENCE_MIFID_TEST_STEP1_CHANGE_STUDY_LEVEL:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP1_CHANGE_STUDY_LEVEL',
  BROKER_CONVENIENCE_MIFID_TEST_STEP2_CHANGE_FINANCIAL_CULTURE:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP2_CHANGE_FINANCIAL_CULTURE',
  BROKER_CONVENIENCE_MIFID_TEST_STEP3_CHANGE_EXPERIENCE_AS_INVESTOR:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP3_CHANGE_EXPERIENCE_AS_INVESTOR',
  BROKER_CONVENIENCE_MIFID_TEST_STEP3_CLOSE_INVALID_ANSWERS_MODAL:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP3_CLOSE_INVALID_ANSWERS_MODAL',
  BROKER_CONVENIENCE_MIFID_TEST_STEP3_VERIFY_ANSWERS:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP3_VERIFY_ANSWERS',
  BROKER_CONVENIENCE_MIFID_TEST_STEPS_INITIAL_STEPS:
    'BROKER_CONVENIENCE_MIFID_TEST_STEPS_INITIAL_STEPS',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CANCEL:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CANCEL',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CHANGE_TRANSCRIPTION:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CHANGE_TRANSCRIPTION',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_ERROR:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_ERROR',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_SUCCESS:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_SUCCESS',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_GO_TO_MAKE_TEST:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_GO_TO_MAKE_TEST',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CHANGE_ACCEPT_EVALUATION:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CHANGE_ACCEPT_EVALUATION',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CONTINUE_OPERATION:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CONTINUE_OPERATION',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CONTINUE_OPERATION_VALIDATE:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_CONTINUE_OPERATION_VALIDATE',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_GO_TO_MY_WALLET:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_GO_TO_MY_WALLET',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_NOT_CONVENIENT_ACCEPT:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_NOT_CONVENIENT_ACCEPT',
  BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_OPERATE_OMITTING_TEST:
    'BROKER_CONVENIENCE_MIFID_TEST_STEP_SUCCESS_OPERATE_OMITTING_TEST',
  BROKER_CONVENIENCE_MIFID_TEST_VALIDATE_STEP: 'BROKER_CONVENIENCE_MIFID_TEST_VALIDATE_STEP',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_FAILURE:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_FAILURE',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_REQUEST:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_REQUEST',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_SUCCESS:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_SUCCESS',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_ERROR:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_ERROR',
  BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_SUCCESS:
    'BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_SUCCESS',
  BROKER_CORPORATES_OPERATIONS_RESET: 'BROKER_CORPORATES_OPERATIONS_RESET',
  BROKER_CORPORATES_OPERATIONS_STOCK_CONTRACT_CHANGED:
    'BROKER_CORPORATES_OPERATIONS_STOCK_CONTRACT_CHANGED',
  BROKER_CREATE_NEW_LIST: 'BROKER_CREATE_NEW_LIST',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_BEGIN_OPERATION:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_BEGIN_OPERATION',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_ACCOUNT:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_ACCOUNT',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_DERIVATIVE_ORIGIN:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_DERIVATIVE_ORIGIN',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH: 'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_ERROR:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_ERROR',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_SUCCESS:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_SUCCESS',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_RESET: 'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_RESET',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_SET_VISIBLE_STEP:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_SET_VISIBLE_STEP',
  BROKER_CREATE_TRANSFER_OF_DERIVATIVES_VALIDATE_STEP:
    'BROKER_CREATE_TRANSFER_OF_DERIVATIVES_VALIDATE_STEP',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CANCELED: 'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CANCELED',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CHECKBOX_CHANGED:
    'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CHECKBOX_CHANGED',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CONFIRMED_FAIL:
    'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CONFIRMED_FAIL',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CONFIRMED_SUCCESS:
    'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_CONFIRMED_SUCCESS',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_ERROR: 'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_ERROR',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_REQUEST: 'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_REQUEST',
  BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_SUCCESS: 'BROKER_CUSTOM_WALLET_CHANGE_DEFAULT_SUCCESS',
  BROKER_CUSTOM_WALLET_CONTRACT_ITEM_CHANGE_ENABLED:
    'BROKER_CUSTOM_WALLET_CONTRACT_ITEM_CHANGE_ENABLED',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_ERROR:
    'BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_ERROR',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_REQUEST:
    'BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_REQUEST',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_SUCCESS:
    'BROKER_CUSTOM_WALLET_CONTRACT_LIST_BY_PORTFOLIO_SUCCESS',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_ERROR: 'BROKER_CUSTOM_WALLET_CONTRACT_LIST_ERROR',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_REQUEST: 'BROKER_CUSTOM_WALLET_CONTRACT_LIST_REQUEST',
  BROKER_CUSTOM_WALLET_CONTRACT_LIST_SUCCESS: 'BROKER_CUSTOM_WALLET_CONTRACT_LIST_SUCCESS',
  BROKER_CUSTOM_WALLET_CREATE_VIEW_INIT: 'BROKER_CUSTOM_WALLET_CREATE_VIEW_INIT',
  BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_DISABLE_BUTTON:
    'BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_DISABLE_BUTTON',
  BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_ERROR: 'BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_ERROR',
  BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_REQUEST:
    'BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_REQUEST',
  BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_SUCCESS:
    'BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_SUCCESS',
  BROKER_CUSTOM_WALLET_INTERVENERS_ERROR: 'BROKER_CUSTOM_WALLET_INTERVENERS_ERROR',
  BROKER_CUSTOM_WALLET_INTERVENERS_REQUEST: 'BROKER_CUSTOM_WALLET_INTERVENERS_REQUEST',
  BROKER_CUSTOM_WALLET_INTERVENERS_SUCCESS: 'BROKER_CUSTOM_WALLET_INTERVENERS_SUCCESS',
  BROKER_CUSTOM_WALLET_INTERVENERS_VIEW_CONTRACT: 'BROKER_CUSTOM_WALLET_INTERVENERS_VIEW_CONTRACT',
  BROKER_CUSTOM_WALLET_LOAD_PORTFOLIO_BUFFER: 'BROKER_CUSTOM_WALLET_LOAD_PORTFOLIO_BUFFER',
  BROKER_CUSTOM_WALLET_NAME_CHANGE: 'BROKER_CUSTOM_WALLET_NAME_CHANGE',
  BROKER_CUSTOM_WALLET_PORFOLIO_DISABLE_ALL_CONTRACTS_BY_TYPE:
    'BROKER_CUSTOM_WALLET_PORFOLIO_DISABLE_ALL_CONTRACTS_BY_TYPE',
  BROKER_CUSTOM_WALLET_PORFOLIO_ENABLE_ALL_CONTRACTS_BY_TYPE:
    'BROKER_CUSTOM_WALLET_PORFOLIO_ENABLE_ALL_CONTRACTS_BY_TYPE',
  BROKER_CUSTOM_WALLET_PORTFOLIO_CANCEL_EDITION: 'BROKER_CUSTOM_WALLET_PORTFOLIO_CANCEL_EDITION',
  BROKER_CUSTOM_WALLET_PORTFOLIO_INIT: 'BROKER_CUSTOM_WALLET_PORTFOLIO_INIT',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_MODE_CANCELED:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_MODE_CANCELED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_CANCEL_MODE_CONFIRMED:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_CANCEL_MODE_CONFIRMED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_CANCEL_MODE_REQUEST:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_CANCEL_MODE_REQUEST',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_DELETE_MODE_CONFIRMED:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_DELETE_MODE_CONFIRMED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_DELETE_MODE_REQUEST:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_DELETE_MODE_REQUEST',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_EDITION_MODE_CONFIRMED:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_EDITION_MODE_CONFIRMED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_EDITION_MODE_REQUEST:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_EDITION_MODE_REQUEST',
  BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_OTHER_PAGE_CONFIRMED:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_LEAVE_EDITION_TO_OTHER_PAGE_CONFIRMED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SAVE_EDIT_MODE_SUCCESS:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_SAVE_EDIT_MODE_SUCCESS',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SET_DEFAULT_ON_CREATE:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_SET_DEFAULT_ON_CREATE',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SET_EXPANDED: 'BROKER_CUSTOM_WALLET_PORTFOLIO_SET_EXPANDED',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_EDIT_MODE_ACCEPT:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_EDIT_MODE_ACCEPT',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_EDIT_MODE_CANCEL:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_EDIT_MODE_CANCEL',
  BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_NEXT_EDIT_MODE_ACCEPT:
    'BROKER_CUSTOM_WALLET_PORTFOLIO_SET_ON_NEXT_EDIT_MODE_ACCEPT',
  BROKER_CUSTOM_WALLET_REMOVE_CANCELED: 'BROKER_CUSTOM_WALLET_REMOVE_CANCELED',
  BROKER_CUSTOM_WALLET_REMOVE_DATA_CHANGED: 'BROKER_CUSTOM_WALLET_REMOVE_DATA_CHANGED',
  BROKER_CUSTOM_WALLET_REMOVE_ERROR: 'BROKER_CUSTOM_WALLET_REMOVE_ERROR',
  BROKER_CUSTOM_WALLET_REMOVE_REQUEST: 'BROKER_CUSTOM_WALLET_REMOVE_REQUEST',
  BROKER_CUSTOM_WALLET_REMOVE_SUCCESS: 'BROKER_CUSTOM_WALLET_REMOVE_SUCCESS',
  BROKER_CUSTOM_WALLET_SAVE_ERROR: 'BROKER_CUSTOM_WALLET_SAVE_ERROR',
  BROKER_CUSTOM_WALLET_SAVE_REQUEST: 'BROKER_CUSTOM_WALLET_SAVE_REQUEST',
  BROKER_CUSTOM_WALLET_SAVE_SUCCESS: 'BROKER_CUSTOM_WALLET_SAVE_SUCCESS',
  BROKER_CUSTOM_WALLET_SHOW_VALIDATION_ERRORS: 'BROKER_CUSTOM_WALLET_SHOW_VALIDATION_ERRORS',
  BROKER_CUSTOM_WALLET_UPDATE_CONTRACTS_LIST_BY_PORTFOLIO:
    'CUSTOM_WALLET_UPDATE_CONTRACTS_LIST_BY_PORTFOLIO',
  BROKER_CUSTOM_WALLET_VALIDATE: 'BROKER_CUSTOM_WALLET_VALIDATE',
  BROKER_CUSTOM_WALLET_VIEW_INTERVENERS_CANCELED: 'BROKER_CUSTOM_WALLET_VIEW_INTERVENERS_CANCELED',
  BROKER_CUSTOM_WALLET_VIEW_INTERVENERS_DISPLAY_MODAL:
    'BROKER_CUSTOM_WALLET_VIEW_INTERVENERS_DISPLAY_MODAL',
  BROKER_DELETE_PRODUCT_FROM_LIST_ERROR: 'BROKER_DELETE_PRODUCT_FROM_LIST_ERROR',
  BROKER_DELETE_PRODUCT_FROM_LIST_REQUEST: 'BROKER_DELETE_PRODUCT_FROM_LIST_REQUEST',
  BROKER_DELETE_PRODUCT_FROM_LIST_SUCCESS: 'BROKER_DELETE_PRODUCT_FROM_LIST_SUCCESS',
  BROKER_DELETE_WATCHLIST_ERROR: 'BROKER_DELETE_WATCHLIST_ERROR',
  BROKER_DELETE_WATCHLIST_REQUEST: 'BROKER_DELETE_WATCHLIST_REQUEST',
  BROKER_DELETE_WATCHLIST_SUCCESS: 'BROKER_DELETE_WATCHLIST_SUCCESS',
  BROKER_EDIT_WATCHLIST_NAME: 'BROKER_EDIT_WATCHLIST_NAME',
  BROKER_EDIT_WATCHLIST_NAME_ERROR: 'BROKER_EDIT_WATCHLIST_NAME_ERROR',
  BROKER_EDIT_WATCHLIST_NAME_IN_PROGRESS: 'BROKER_EDIT_WATCHLIST_NAME_IN_PROGRESS',
  BROKER_ERROR: 'BROKER_ERROR',
  BROKER_ETFS_SELL_CONFIRM_FAILURE: 'BROKER_ETFS_SELL_CONFIRM_FAILURE',
  BROKER_ETFS_SELL_CONFIRM_REQUEST: 'BROKER_ETFS_SELL_CONFIRM_REQUEST',
  BROKER_ETFS_SELL_CONFIRM_SUCCESS: 'BROKER_ETFS_SELL_CONFIRM_SUCCESS',
  BROKER_ETFS_SELL_EX_ANTE_SUCCESS: 'BROKER_ETFS_SELL_EX_ANTE_SUCCESS',
  BROKER_ETFS_SELL_EX_ANTE_FAILURE: 'BROKER_ETFS_SELL_EX_ANTE_FAILURE',
  BROKER_ETFS_SELL_EX_ANTE_FETCHING: 'BROKER_ETFS_SELL_EX_ANTE_FETCHING',
  BROKER_ETFS_SELL_ORDER_TYPE_OPTION_CHANGED: 'BROKER_ETFS_SELL_ORDER_TYPE_OPTION_CHANGED',
  BROKER_ETFS_SELL_ORDER_TYPE_VALUE_CHANGED: 'BROKER_ETFS_SELL_ORDER_TYPE_VALUE_CHANGED',
  BROKER_ETFS_SELL_ORDER_TYPE_VALUE_RESET: 'BROKER_ETFS_SELL_ORDER_TYPE_VALUE_RESET',
  BROKER_ETFS_SELL_RESET: 'BROKER_ETFS_SELL_RESET',
  BROKER_ETFS_SELL_SET_VISIBLE_STEP: 'BROKER_ETFS_SELL_SET_VISIBLE_STEP',
  BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_REQUEST: 'BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_REQUEST',
  BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_SUCCESS: 'BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_SUCCESS',
  BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_ERROR: 'BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_ERROR',
  BROKER_ETFS_SELL_SIGNATURE_SUCCESS: 'BROKER_ETFS_SELL_SIGNATURE_SUCCESS',
  BROKER_ETFS_SELL_STOCK_CONTRACT_CHANGED: 'BROKER_ETFS_SELL_STOCK_CONTRACT_CHANGED',
  BROKER_ETFS_SELL_TITLE_QUANTITY_CHANGED: 'BROKER_ETFS_SELL_TITLE_QUANTITY_CHANGED',
  BROKER_ETFS_SELL_VALIDATE_FAILURE: 'BROKER_ETFS_SELL_VALIDATE_FAILURE',
  BROKER_ETFS_SELL_VALIDATE_REQUEST: 'BROKER_ETFS_SELL_VALIDATE_REQUEST',
  BROKER_ETFS_SELL_VALIDATE_STEP: 'BROKER_ETFS_SELL_VALIDATE_STEP',
  BROKER_ETFS_SELL_VALIDATE_SUCCESS: 'BROKER_ETFS_SELL_VALIDATE_SUCCESS',
  BROKER_ETFS_SELL_VALIDITY_DATE_CHANGED: 'BROKER_ETFS_SELL_VALIDITY_DATE_CHANGED',
  BROKER_ETFS_SELL_WILL_CANCEL: 'BROKER_ETFS_SELL_WILL_CANCEL',
  BROKER_ETF_BUY_BUYFOR_OPTION_CHANGED: 'BROKER_ETF_BUY_BUYFOR_OPTION_CHANGED',
  BROKER_ETF_BUY_BUYFOR_VALUE_CHANGED: 'BROKER_ETF_BUY_BUYFOR_VALUE_CHANGED',
  BROKER_ETF_BUY_BUYFOR_VALUE_RESET: 'BROKER_ETF_BUY_BUYFOR_VALUE_RESET',
  BROKER_ETF_BUY_CONFIRM_FAILURE: 'BROKER_ETF_BUY_CONFIRM_FAILURE',
  BROKER_ETF_BUY_CONFIRM_REQUEST: 'BROKER_ETF_BUY_CONFIRM_REQUEST',
  BROKER_ETF_BUY_CONFIRM_SUCCESS: 'BROKER_ETF_BUY_CONFIRM_SUCCESS',
  BROKER_ETF_BUY_DOCUMENT_STORAGE_ERROR: 'BROKER_ETF_BUY_DOCUMENT_STORAGE_ERROR',
  BROKER_ETF_BUY_DOCUMENT_STORAGE_REQUEST: 'BROKER_ETF_BUY_DOCUMENT_STORAGE_REQUEST',
  BROKER_ETF_BUY_DOCUMENT_STORAGE_SUCCESS: 'BROKER_ETF_BUY_DOCUMENT_STORAGE_SUCCESS',
  BROKER_ETF_BUY_DOCUMENT_DFI_FETCHING: 'BROKER_ETF_BUY_DOCUMENT_DFI_FETCHING',
  BROKER_ETF_BUY_DOCUMENT_DFI_SUCCESS: 'BROKER_ETF_BUY_DOCUMENT_DFI_SUCCESS',
  BROKER_ETF_BUY_DOCUMENT_DFI_ERROR: 'BROKER_ETF_BUY_DOCUMENT_DFI_ERROR',
  BROKER_ETF_BUY_TOGGLE_DOCUMENTS_CHECKBOX: 'BROKER_ETF_BUY_TOGGLE_DOCUMENTS_CHECKBOX',
  BROKER_ETF_BUY_TOGGLE_RISK_CHECKBOX: 'BROKER_ETF_BUY_TOGGLE_RISK_CHECKBOX',
  BROKER_ETF_BUY_RESET_CHECKBOX: 'BROKER_ETF_BUY_RESET_CHECKBOX',
  BROKER_ETF_BUY_FINISH: 'BROKER_ETF_BUY_FINISH',
  BROKER_ETF_BUY_MIFID_CLAUSE_CHANGED: 'BROKER_ETF_BUY_MIFID_CLAUSE_CHANGED',
  BROKER_ETF_BUY_ORDER_TYPE_OPTION_CHANGED: 'BROKER_ETF_BUY_ORDER_TYPE_OPTION_CHANGED',
  BROKER_ETF_BUY_ORDER_TYPE_VALUE_CHANGED: 'BROKER_ETF_BUY_ORDER_TYPE_VALUE_CHANGED',
  BROKER_ETF_BUY_ORDER_TYPE_VALUE_RESET: 'BROKER_ETF_BUY_ORDER_TYPE_VALUE_RESET',
  BROKER_ETF_BUY_RESET: 'BROKER_ETF_BUY_RESET',
  BROKER_ETF_BUY_SET_HOLIDAY_ERROR: 'BROKER_ETF_BUY_SET_HOLIDAY_ERROR',
  BROKER_ETF_BUY_SET_VISIBLE_STEP: 'BROKER_ETF_BUY_SET_VISIBLE_STEP',
  BROKER_ETF_BUY_SIGNATURE_SUCCESS: 'BROKER_ETF_BUY_SIGNATURE_SUCCESS',
  BROKER_ETF_BUY_STOCK_CONTRACT_CHANGED: 'BROKER_ETF_BUY_STOCK_CONTRACT_CHANGED',
  BROKER_ETF_BUY_VALIDATE_FAILURE: 'BROKER_ETF_BUY_VALIDATE_FAILURE',
  BROKER_ETF_BUY_VALIDATE_REQUEST: 'BROKER_ETF_BUY_VALIDATE_REQUEST',
  BROKER_ETF_BUY_VALIDATE_STEP: 'BROKER_ETF_BUY_VALIDATE_STEP',
  BROKER_ETF_BUY_VALIDATE_SUCCESS: 'BROKER_ETF_BUY_VALIDATE_SUCCESS',
  BROKER_ETF_BUY_VALIDITY_DATE_CHANGED: 'BROKER_ETF_BUY_VALIDITY_DATE_CHANGED',
  BROKER_ETF_BUY_WILL_CANCEL: 'BROKER_ETF_BUY_WILL_CANCEL',
  BROKER_ETF_CATALOGUE_FETCHING: 'BROKER_ETF_CATALOGUE_FETCHING',
  BROKER_ETF_CATALOGUE_SUCCESS: 'BROKER_ETF_CATALOGUE_SUCCESS',
  BROKER_ETF_EXTANTCOST_ERROR: 'BROKER_ETF_EXTANTCOST_ERROR',
  BROKER_ETF_EXTANTCOST_FECHING: 'BROKER_ETF_EXTANTCOST_FECHING',
  BROKER_ETF_EXTANTCOST_SUCESS: 'BROKER_ETF_EXTANTCOST_SUCESS',
  BROKER_ETF_CATALOGUE_FAILURE: 'BROKER_ETF_CATALOGUE_FAILURE',
  BROKER_ETF_PRODUCT_ANOTHER_SUCCESS: 'BROKER_ETF_PRODUCT_ANOTHER_SUCCESS',
  BROKER_ETF_PRODUCT_DOCUMENTATION_FAILURE: 'BROKER_ETF_PRODUCT_DOCUMENTATION_FAILURE',
  BROKER_ETF_PRODUCT_DOCUMENTATION_FETCHING: 'BROKER_ETF_PRODUCT_DOCUMENTATION_FETCHING',
  BROKER_ETF_PRODUCT_DOCUMENTATION_SUCCESS: 'BROKER_ETF_PRODUCT_DOCUMENTATION_SUCCESS',
  BROKER_ETF_PRODUCT_FAILURE: 'BROKER_ETF_PRODUCT_FAILURE',
  BROKER_ETF_PRODUCT_FETCHING: 'BROKER_ETF_PRODUCT_FETCHING',
  BROKER_ETF_PRODUCT_SUCCESS: 'BROKER_ETF_PRODUCT_SUCCESS',
  BROKER_ETF_SELL_FINISH: 'BROKER_ETF_SELL_FINISH',
  BROKER_EVOLUTION_DATA_FAILURE: 'BROKER_EVOLUTION_DATA_FAILURE',
  BROKER_EVOLUTION_DATA_FETCHING: 'BROKER_EVOLUTION_DATA_FETCHING',
  BROKER_EVOLUTION_DATA_SUCCESS: 'BROKER_EVOLUTION_DATA_SUCCESS',
  BROKER_EXCHANGE_AVAILABILITY_ERROR: 'BROKER_EXCHANGE_AVAILABILITY_ERROR',
  BROKER_EXCHANGE_AVAILABILITY_SUCCESS: 'BROKER_EXCHANGE_AVAILABILITY_SUCCESS',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_FETCHING:
    'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_FETCHING',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_ERROR:
    'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_ERROR',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_SUCCESS:
    'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_SUCCESS',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_RESET: 'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_RESET',
  BROKER_EXCHANGE_INDEXES_FAILURE: 'BROKER_EXCHANGE_INDEXES_FAILURE',
  BROKER_EXCHANGE_INDEXES_PLAYERS_FAILURE: 'BROKER_EXCHANGE_INDEXES_PLAYERS_FAILURE',
  BROKER_EXCHANGE_INDEXES_PLAYERS_REQUEST: 'BROKER_EXCHANGE_INDEXES_PLAYERS_REQUEST',
  BROKER_EXCHANGE_INDEXES_PLAYERS_SUCCESS: 'BROKER_EXCHANGE_INDEXES_PLAYERS_SUCCESS',
  BROKER_EXCHANGE_INDEXES_REQUEST: 'BROKER_EXCHANGE_INDEXES_REQUEST',
  BROKER_EXCHANGE_INDEXES_SUCCESS: 'BROKER_EXCHANGE_INDEXES_SUCCESS',
  BROKER_EXTANT_COST_FETCH_FAILURE: 'BROKER_EXTANT_COST_FETCH_FAILURE',
  BROKER_EXTANT_COST_FETCH_FETCHING: 'BROKER_EXTANT_COST_FETCH_FETCHING',
  BROKER_EXTANT_COST_FETCH_SUCCESS: 'BROKER_EXTANT_COST_FETCH_SUCCESS',
  BROKER_FETCH_CHECKING_ACCOUNTS_FAILURE: 'BROKER_FETCH_CHECKING_ACCOUNTS_FAILURE',
  BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_FAILURE:
    'BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_FAILURE',
  BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_REQUEST:
    'BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_REQUEST',
  BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_SUCCESS:
    'BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_SUCCESS',
  BROKER_FETCH_CHECKING_ACCOUNTS_REQUEST: 'BROKER_FETCH_CHECKING_ACCOUNTS_REQUEST',
  BROKER_FETCH_CHECKING_ACCOUNTS_SUCCESS: 'BROKER_FETCH_CHECKING_ACCOUNTS_SUCCESS',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_REQUEST: 'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_REQUEST',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_SUCCESS: 'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_SUCCESS',
  BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_ERROR: 'BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_ERROR',
  BROKER_FINANCES_NEWS_ERROR: 'BROKER_FINANCES_NEWS_ERROR',
  BROKER_FINANCES_NEWS_FETCHING: 'BROKER_FINANCES_NEWS_ERROR',
  BROKER_FINANCES_NEWS_SUCCESS: 'BROKER_FINANCES_NEWS_SUCCESS',
  BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_FROM: 'BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_FROM',
  BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_TO: 'BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_TO',
  BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SEARCH_TYPE: 'BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SEARCH_TYPE',
  BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SECTOR: 'BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SECTOR',
  BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_VALUE: 'BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_VALUE',
  BROKER_FUNDAMENTAL_ANALYSIS_CLEAR: 'BROKER_FUNDAMENTAL_ANALYSIS_CLEAR',
  BROKER_FUNDAMENTAL_ANALYSIS_SUBMIT: 'BROKER_FUNDAMENTAL_ANALYSIS_SUBMIT',
  BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH: 'BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH',
  BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_ERROR: 'BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_ERROR',
  BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_SUCCESS: 'BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_SUCCESS',
  FETCH_ALTERNATIVE_DOCUMENTS_SUCCESS: 'FETCH_ALTERNATIVE_DOCUMENTS_SUCCESS',
  BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_FETCH:
    'BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_FETCH',
  BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_SUCCESS:
    'BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_SUCCESS',
  BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_ERROR:
    'BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_ERROR',
  BROKER_FUND_LEGAL_DOCUMENT_MARKETING_REPORTS_FETCH_SUCCESS:
    'BROKER_FUND_LEGAL_DOCUMENT_MARKETING_REPORTS_FETCH_SUCCESS',
  BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH:
    'BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH',
  BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR:
    'BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR',
  BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS:
    'BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS',
  BROKER_FUND_ORDER_CANCEL_CLEAR: 'BROKER_FUND_ORDER_CANCEL_CLEAR',
  BROKER_FUND_ORDER_CANCEL_FAILURE: 'BROKER_FUND_ORDER_CANCEL_FAILURE',
  BROKER_FUND_ORDER_CANCEL_REQUEST: 'BROKER_FUND_ORDER_CANCEL_REQUEST',
  BROKER_FUND_ORDER_CANCEL_SIGNATURE_FAILURE: 'BROKER_FUND_ORDER_CANCEL_SIGNATURE_FAILURE',
  BROKER_FUND_ORDER_CANCEL_SUCCESS: 'BROKER_FUND_ORDER_CANCEL_SUCCESS',
  BROKER_FUTURES_ACCESS_PLATFORM_CONFIRMATION_FAILURE:
    'BROKER_FUTURES_ACCESS_PLATFORM_CONFIRMATION_FAILURE',
  BROKER_FUTURES_ACCESS_PLATFORM_SIGNATURE_PATTERN_FAILURE:
    'BROKER_FUTURES_ACCESS_PLATFORM_SIGNATURE_PATTERN_FAILURE',
  BROKER_GENERATE_NATIONAL_ID_DOC_ERROR: 'BROKER_GENERATE_NATIONAL_ID_DOC_ERROR',
  BROKER_GENERATE_NATIONAL_ID_DOC_REQUEST: 'BROKER_GENERATE_NATIONAL_ID_DOC_REQUEST',
  BROKER_GENERATE_NATIONAL_ID_DOC_SUCCESS: 'BROKER_GENERATE_NATIONAL_ID_DOC_SUCCESS',
  BROKER_GET_ALERTS_BUY: 'BROKER_GET_ALERTS_BUY',
  BROKER_GET_ALERTS_FAILURE: 'BROKER_GET_ALERTS_FAILURE',
  BROKER_GET_ALERTS_FETCHING: 'BROKER_GET_ALERTS_FETCHING',
  BROKER_GET_ALERTS_SUCCESS: 'BROKER_GET_ALERTS_SUCCESS',
  BROKER_GET_COMMENTARY: 'BROKER_GET_COMMENTARY',
  BROKER_GET_CONTRACT_ACTIVITIES_CLEAR_FILTER: 'BROKER_GET_CONTRACT_ACTIVITIES_CLEAR_FILTER',
  BROKER_GET_CONTRACT_ACTIVITIES_FAILURE: 'BROKER_GET_CONTRACT_ACTIVITIES_FAILURE',
  BROKER_GET_CONTRACT_ACTIVITIES_REQUEST: 'BROKER_GET_CONTRACT_ACTIVITIES_REQUEST',
  BROKER_GET_CONTRACT_ACTIVITIES_SET_FILTER: 'BROKER_GET_CONTRACT_ACTIVITIES_SET_FILTER',
  BROKER_GET_CONTRACT_ACTIVITIES_SUCCESS: 'BROKER_GET_CONTRACT_ACTIVITIES_SUCCESS',
  BROKER_GET_CONTRACT_ACTIVITY_DETAILS_FAILURE: 'BROKER_GET_CONTRACT_ACTIVITY_DETAILS_FAILURE',
  BROKER_GET_CONTRACT_ACTIVITY_DETAILS_REQUEST: 'BROKER_GET_CONTRACT_ACTIVITY_DETAILS_REQUEST',
  BROKER_GET_CONTRACT_ACTIVITY_DETAILS_SUCCESS: 'BROKER_GET_CONTRACT_ACTIVITY_DETAILS_SUCCESS',
  BROKER_GET_CONTRIBUTION_LIST_REQUEST: 'BROKER_GET_CONTRIBUTION_LIST_REQUEST',
  BROKER_GET_CONTRIBUTION_LIST_SUCCESS: 'BROKER_GET_CONTRIBUTION_LIST_SUCCESS',
  BROKER_GET_CONTRIBUTION_LIST_FAILURE: 'BROKER_GET_CONTRIBUTION_LIST_FAILURE',
  BROKER_GET_DEFAULT_CONFIG_FAILURE: 'BROKER_GET_DEFAULT_CONFIG_FAILURE',
  BROKER_GET_DEFAULT_CONFIG_REQUEST: 'BROKER_GET_DEFAULT_CONFIG_REQUEST',
  BROKER_GET_DEFAULT_CONFIG_SUCCESS: 'BROKER_GET_DEFAULT_CONFIG_SUCCESS',
  BROKER_GET_DEFAULT_VIEW_ID_FAILURE: 'BROKER_GET_DEFAULT_VIEW_ID_FAILURE',
  BROKER_GET_DEFAULT_VIEW_ID_SUCCESS: 'BROKER_GET_DEFAULT_VIEW_ID_SUCCESS',
  BROKER_GET_DEFAULT_VIEW_RESET: 'BROKER_GET_DEFAULT_VIEW_RESET',
  BROKER_GET_DERECHOS_INFO_MESSAGE: 'BROKER_GET_DERECHOS_INFO_MESSAGE',
  BROKER_GET_HISTORICAL_FAILURE: 'BROKER_GET_HISTORICAL_FAILURE',
  BROKER_GET_HISTORICAL_FETCHING: 'BROKER_GET_HISTORICAL_FETCHING',
  BROKER_GET_HISTORICAL_SUCCESS: 'BROKER_GET_HISTORICAL_SUCCESS',
  BROKER_GET_ID_DEFAULT_VIEW_FAILURE: 'BROKER_GET_ID_DEFAULT_VIEW_FAILURE',
  BROKER_GET_ID_DEFAULT_VIEW_SUCCESS: 'BROKER_GET_ID_DEFAULT_VIEW_SUCCESS',
  BROKER_GET_INDEXES_FAILURE: 'BROKER_GET_INDEXES_FAILURE',
  BROKER_GET_INDEXES_FETCHING: 'BROKER_GET_INDEXES_FETCHING',
  BROKER_GET_INDEXES_LIST: 'BROKER_GET_INDEXES_LIST',
  BROKER_GET_INDEXES_SUCCESS: 'BROKER_GET_INDEXES_SUCCESS',
  BROKER_GET_INTERVENERS_FAILURE: 'BROKER_GET_INTERVENERS_FAILURE',
  BROKER_GET_INTERVENERS_REQUEST: 'BROKER_GET_INTERVENERS_REQUEST',
  BROKER_GET_INTERVENERS_SUCCESS: 'BROKER_GET_INTERVENERS_SUCCESS',
  BROKER_GET_INVESTMENTS_ISIN_FUNDS_SUCCESS: 'BROKER_GET_INVESTMENTS_ISIN_FUNDS_SUCCESS',
  BROKER_GET_INVESTMENTS_ISIN_PLANS_SUCCESS: 'BROKER_GET_INVESTMENTS_ISIN_PLANS_SUCCESS',
  BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_FAILURE:
    'BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_FAILURE',
  BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_REQUEST:
    'BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_REQUEST',
  BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_SUCCESS:
    'BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_SUCCESS',
  BROKER_GET_MARKET_TREND_ACTIVITY_FAILURE: 'BROKER_GET_MARKET_TREND_ACTIVITY_FAILURE',
  BROKER_GET_MARKET_TREND_ACTIVITY_REQUEST: 'BROKER_GET_MARKET_TREND_ACTIVITY_REQUEST',
  BROKER_GET_MARKET_TREND_ACTIVITY_SUCCESS: 'BROKER_GET_MARKET_TREND_ACTIVITY_SUCCESS',
  BROKER_GET_MY_INVESTMENTS: 'BROKER_GET_MY_INVESTMENTS',
  BROKER_GET_MY_INVESTMENTS_FAILURE: 'BROKER_GET_MY_INVESTMENTS_FAILURE',
  BROKER_GET_MY_INVESTMENTS_REQUEST: 'BROKER_GET_MY_INVESTMENTS_REQUEST',
  BROKER_GET_MY_INVESTMENTS_SET_OUTDATED: 'BROKER_GET_MY_INVESTMENTS_SET_OUTDATED',
  BROKER_GET_MY_INVESTMENTS_SUCCESS: 'BROKER_GET_MY_INVESTMENTS_SUCCESS',
  BROKER_GET_NEWS_FAILURE: 'BROKER_GET_NEWS_FAILURE',
  BROKER_GET_NEWS_REQUEST: 'BROKER_GET_NEWS_REQUEST',
  BROKER_GET_NEWS_SUCCESS: 'BROKER_GET_NEWS_SUCCESS',
  BROKER_GET_POSSESSIONS_FAILURE: 'BROKER_GET_POSSESSIONS_FAILURE',
  BROKER_GET_POSSESSIONS_REQUEST: 'BROKER_GET_POSSESSIONS_REQUEST',
  BROKER_GET_POSSESSIONS_SUCCESS: 'BROKER_GET_POSSESSIONS_SUCCESS',
  BROKER_GET_PRODUCT_FILE: 'BROKER_GET_PRODUCT_FILE',
  BROKER_GET_PRODUCT_FILE_FAILURE: 'BROKER_GET_PRODUCT_FILE_FAILURE',
  BROKER_GET_PRODUCT_FILE_FETCHING: 'BROKER_GET_PRODUCT_FILE_FETCHING',
  BROKER_GET_PRODUCT_FILE_SUCCESS: 'BROKER_GET_PRODUCT_FILE_SUCCESS',
  BROKER_GET_PRODUCT_CATALOGUE_FETCHING: 'BROKER_GET_PRODUCT_CATALOGUE_FETCHING',
  BROKER_GET_PRODUCT_CATALOGUE_SUCCESS: 'BROKER_GET_PRODUCT_CATALOGUE_SUCCESS',
  BROKER_GET_PRODUCT_CATALOGUE_FAILURE: 'BROKER_GET_PRODUCT_CATALOGUE_FAILURE',
  BROKER_GET_SEARCH_RESULTS_LIST_FAILURE: 'BROKER_GET_SEARCH_RESULTS_LIST_FAILURE',
  BROKER_GET_SEARCH_RESULTS_LIST_FETCHING: 'BROKER_GET_SEARCH_RESULTS_LIST_FETCHING',
  BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS: 'BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS',
  BROKER_GET_SPECIALISTS: 'BROKER_GET_SPECIALISTS',
  BROKER_GET_SPECIALISTS_FAILURE: 'BROKER_GET_SPECIALISTS_FAILURE',
  BROKER_GET_SPECIALISTS_SUCCESS: 'BROKER_GET_SPECIALISTS_SUCCESS',
  BROKER_GET_STOCKS_BY_TYPE_FAILURE: 'BROKER_GET_STOCKS_BY_TYPE_FAILURE',
  BROKER_GET_STOCKS_BY_TYPE_FOR_THE_FIRST_TIME: 'BROKER_GET_STOCKS_BY_TYPE_FOR_THE_FIRST_TIME',
  BROKER_GET_STOCKS_BY_TYPE_REQUEST: 'BROKER_GET_STOCKS_BY_TYPE_REQUEST',
  BROKER_GET_STOCKS_BY_TYPE_SUCCESS: 'BROKER_GET_STOCKS_BY_TYPE_SUCCESS',
  BROKER_GET_STOCKS_BY_WALLET_FAILURE: 'BROKER_GET_STOCKS_BY_WALLET_FAILURE',
  BROKER_GET_STOCKS_BY_WALLET_REQUEST: 'BROKER_GET_STOCKS_BY_WALLET_REQUEST',
  BROKER_GET_STOCKS_BY_WALLET_SUCCESS: 'BROKER_GET_STOCKS_BY_WALLET_SUCCESS',
  BROKER_GET_STOCKS_CONTRACTS_FAILURE: 'BROKER_GET_STOCKS_CONTRACTS_FAILURE',
  BROKER_GET_STOCKS_CONTRACTS_SUCCESS: 'BROKER_GET_STOCKS_CONTRACTS_SUCCESS',
  BROKER_GET_TODAY_NEWS_LIST: 'BROKER_GET_TODAY_NEWS_LIST',
  BROKER_GET_TRASPASS_FUNDS_FAILURE: 'BROKER_GET_TRASPASS_FUNDS_FAILURE',
  BROKER_GET_TRASPASS_FUNDS_SUCCESS: 'BROKER_GET_TRASPASS_FUNDS_SUCCESS',
  BROKER_GET_WALLET_LIST_FAILURE: 'BROKER_GET_WALLET_LIST_FAILURE',
  BROKER_GET_WALLET_LIST_SUCCESS: 'BROKER_GET_WALLET_LIST_SUCCESS',
  BROKER_GET_WATCHLIST_FAILURE: 'BROKER_GET_WATCHLIST_FAILURE',
  BROKER_GET_WATCHLIST_REQUEST: 'BROKER_GET_WATCHLIST_REQUEST',
  BROKER_GET_WATCHLIST_SUCCESS: 'BROKER_GET_WATCHLIST_SUCCESS',
  BROKER_HIDE_CLIENT_WARNING_INFO: 'BROKER_HIDE_CLIENT_WARNING_INFO',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_ADD_INTERVENER: 'BROKER_HIRE_DERIVATIVES_ACCOUNT_ADD_INTERVENER',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ACCEPT_MANIFEST:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ACCEPT_MANIFEST',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ASSOCIATED_ACCOUNT:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ASSOCIATED_ACCOUNT',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS_SUCCESS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS_SUCCESS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS_SUCCESS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS_SUCCESS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_FINISH: 'BROKER_HIRE_DERIVATIVES_ACCOUNT_FINISH',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_REMOVE_INTERVENER:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_REMOVE_INTERVENER',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET: 'BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET_INTERVENERS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET_INTERVENERS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_SET_VISIBLE_STEP:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_SET_VISIBLE_STEP',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_UPDATE_INTERVENERS_API_SUCCESS:
    'BROKER_HIRE_DERIVATIVES_ACCOUNT_UPDATE_INTERVENERS_API_SUCCESS',
  BROKER_HIRE_DERIVATIVES_ACCOUNT_VALIDATE_STEP: 'BROKER_HIRE_DERIVATIVES_ACCOUNT_VALIDATE_STEP',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_REQUEST:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_REQUEST',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_SUCCESS:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_SUCCESS',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_FAILURE:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_FAILURE',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_REQUEST:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_REQUEST',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS',
  BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE:
    'BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE',
  BROKER_HIRE_PENSION_PLAN_BUILD_LINK: 'BROKER_HIRE_PENSION_PLAN_BUILD_LINK',
  BROKER_HIRE_PENSION_PLAN_CONTRIBUTION_ANSWER: 'BROKER_HIRE_PENSION_PLAN_CONTRIBUTION_ANSWER',
  BROKER_HIRE_PENSION_PLAN_DOCUMENTS_READ: 'BROKER_HIRE_PENSION_PLAN_DOCUMENTS_READ',
  BROKER_HIRE_PENSION_PLAN_FAILURE: 'BROKER_HIRE_PENSION_PLAN_FAILURE',
  BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_FAILURE:
    'BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_FAILURE',
  BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_REQUEST:
    'BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_REQUEST',
  BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS:
    'BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS',
  BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_FAILURE:
    'BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_FAILURE',
  BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_REQUEST:
    'BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_REQUEST',
  BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_SUCCESS:
    'BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_SUCCESS',
  BROKER_HIRE_PENSION_PLAN_IN_WALLET: 'BROKER_HIRE_PENSION_PLAN_IN_WALLET',
  BROKER_HIRE_PENSION_PLAN_REQUEST: 'BROKER_HIRE_PENSION_PLAN_REQUEST',
  BROKER_HIRE_PENSION_PLAN_RESET: 'BROKER_HIRE_PENSION_PLAN_RESET',
  BROKER_HIRE_PENSION_PLAN_SEND_REQUEST: 'BROKER_HIRE_PENSION_PLAN_SEND_REQUEST',
  BROKER_HIRE_PENSION_PLAN_SEND_SUCCESS: 'BROKER_HIRE_PENSION_PLAN_SEND_SUCCESS',
  BROKER_HIRE_PENSION_PLAN_SEND_FAILURE: 'BROKER_HIRE_PENSION_PLAN_SEND_FAILURE',
  BROKER_HIRE_PENSION_PLAN_SET_ACCOUNT_NUMBER: 'BROKER_HIRE_PENSION_PLAN_SET_ACCOUNT_NUMBER',
  BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT: 'BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT',
  BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT_PERCENTAGE:
    'BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT_PERCENTAGE',
  BROKER_HIRE_PENSION_PLAN_SET_DATE: 'BROKER_HIRE_PENSION_PLAN_SET_DATE',
  BROKER_HIRE_PENSION_PLAN_SET_INITIAL_CONTRIBUTION:
    'BROKER_HIRE_PENSION_PLAN_SET_INITIAL_CONTRIBUTION',
  BROKER_HIRE_PENSION_PLAN_SET_INTERVENER: 'BROKER_HIRE_PENSION_PLAN_SET_INTERVENER',
  BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY: 'BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY',
  BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY_AMOUNT:
    'BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY_AMOUNT',
  BROKER_HIRE_PENSION_PLAN_SET_VISIBLE_STEP: 'BROKER_HIRE_PENSION_PLAN_SET_VISIBLE_STEP',
  BROKER_HIRE_PENSION_PLAN_SUCCESS: 'BROKER_HIRE_PENSION_PLAN_SUCCESS',
  BROKER_HIRE_PENSION_PLAN_TOGGLE_DISCLAIMER: 'BROKER_HIRE_PENSION_PLAN_TOGGLE_DISCLAIMER',
  BROKER_HIRE_PENSION_PLAN_VALIDATE_FAILURE: 'BROKER_HIRE_PENSION_PLAN_VALIDATE_FAILURE',
  BROKER_HIRE_PENSION_PLAN_VALIDATE_REQUEST: 'BROKER_HIRE_PENSION_PLAN_VALIDATE_REQUEST',
  BROKER_HIRE_PENSION_PLAN_VALIDATE_STEP: 'BROKER_HIRE_PENSION_PLAN_VALIDATE_STEP',
  BROKER_HIRE_PENSION_PLAN_VALIDATE_SUCCESS: 'BROKER_HIRE_PENSION_PLAN_VALIDATE_SUCCESS',
  BROKER_HIRE_PENSION_PLAN_WILL_CANCEL: 'BROKER_HIRE_PENSION_PLAN_WILL_CANCEL',
  BROKER_HIRE_PENSION_PLAN_WILL_LEAVE: 'BROKER_HIRE_PENSION_PLAN_WILL_LEAVE',
  BROKER_IA_GET_PREDICTION_DETAIL_FETCHING: 'BROKER_IA_GET_PREDICTION_DETAIL_FETCHING',
  BROKER_IA_GET_PREDICTION_DETAIL_FAILURE: 'BROKER_IA_GET_PREDICTION_DETAIL_FAILURE',
  BROKER_IA_GET_PREDICTION_DETAIL_SUCCESS: 'BROKER_IA_GET_PREDICTION_DETAIL_SUCCESS',
  BROKER_IA_GET_PREDICTION_MARKETS_FETCHING: 'BROKER_IA_GET_PREDICTION_MARKETS_FETCHING',
  BROKER_IA_GET_PREDICTION_MARKETS_FAILURE: 'BROKER_IA_GET_PREDICTION_MARKETS_FAILURE',
  BROKER_IA_GET_PREDICTION_MARKETS_SUCCESS: 'BROKER_IA_GET_PREDICTION_MARKETS_SUCCESS',
  BROKER_IA_GET_PREDICTION_SEARCH_FETCHING: 'BROKER_IA_GET_PREDICTION_SEARCH_FETCHING',
  BROKER_IA_GET_PREDICTION_SEARCH_FAILURE: 'BROKER_IA_GET_PREDICTION_SEARCH_FAILURE',
  BROKER_IA_GET_PREDICTION_SEARCH_SUCCESS: 'BROKER_IA_GET_PREDICTION_SEARCH_SUCCESS',
  BROKER_IS_US_PERSON_FETCHING: 'BROKER_IS_US_PERSON_FETCHING',
  BROKER_IS_US_PERSON_ERROR: 'BROKER_IS_US_PERSON_ERROR',
  BROKER_IS_US_PERSON_SUCCESS: 'BROKER_IS_US_PERSON_SUCCESS',
  BROKER_INDEXES_DATA_ERROR: 'BROKER_INDEXES_DATA_ERROR',
  BROKER_INDEXES_DATA_FETCHING: 'BROKER_INDEXES_DATA_FETCHING',
  BROKER_INDEXES_DATA_SUCCESS: 'BROKER_INDEXES_DATA_SUCCESS',
  BROKER_INFORMATIVE_POPUPS_FETCHING: 'BROKER_INFORMATIVE_POPUPS_FETCHING',
  BROKER_INFORMATIVE_POPUPS_SUCCESS: 'BROKER_INFORMATIVE_POPUPS_SUCCESS',
  BROKER_INFORMATIVE_POPUPS_ERROR: 'BROKER_INFORMATIVE_POPUPS_ERROR',
  BROKER_INIT_LEGAL_DOCUMENTATION: 'BROKER_INIT_LEGAL_DOCUMENTATION',
  BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_INPUT_VALUE:
    'BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_INPUT_VALUE',
  BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_TRANSFER_TYPE:
    'BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_TRANSFER_TYPE',
  BROKER_KNOW_THESE_STOCKS_FAILURE: 'BROKER_KNOW_THESE_STOCKS_FAILURE',
  BROKER_KNOW_THESE_STOCKS_FETCHING: 'BROKER_KNOW_THESE_STOCKS_FETCHING',
  BROKER_KNOW_THESE_STOCKS_REQUEST: 'BROKER_KNOW_THESE_STOCKS_REQUEST',
  BROKER_KNOW_THESE_STOCKS_SUCCESS: 'BROKER_KNOW_THESE_STOCKS_SUCCESS',
  BROKER_LANDING_CHANGE_TAB: 'BROKER_LANDING_CHANGE_TAB',
  BROKER_LANDING_RESET_TAB: 'BROKER_LANDING_RESET_TAB',
  BROKER_LAST_NEWS_ERROR: 'BROKER_LAST_NEWS_ERROR',
  BROKER_LAST_NEWS_FETCHING: 'BROKER_LAST_NEWS_FETCHING',
  BROKER_LAST_NEWS_SUCCESS: 'BROKER_LAST_NEWS_SUCCESS',
  BROKER_LAST_NEWS_TAB_SUCCESS: 'BROKER_LAST_NEWS_TAB_SUCCESS',
  BROKER_LAST_NEWS_BODY_SUCCESS: 'BROKER_LAST_NEWS_BODY_SUCCESS',
  BROKER_LIMITED_SEARCH_PRODUCT_SUCCESS: 'BROKER_LIMITED_SEARCH_PRODUCT_SUCCESS',
  BROKER_MARKETS_AGENDA_CHANGE_DAY: 'BROKER_MARKETS_AGENDA_CHANGE_DAY',
  BROKER_MARKETS_DAY_CHANGED: 'BROKER_MARKETS_DAY_CHANGED',
  BROKER_MARKET_CONSENSUS_GET_FAILURE: 'BROKER_MARKET_CONSENSUS_GET_FAILURE',
  BROKER_MARKET_CONSENSUS_GET_SUCCESS: 'BROKER_MARKET_CONSENSUS_GET_SUCCESS',
  BROKER_MARKET_CONSENSUS_IS_FETCHING: 'BROKER_MARKET_CONSENSUS_IS_FETCHING',
  BROKER_MIFID_ETF_DISPLAYED: 'BROKER_MIFID_ETF_DISPLAYED',
  BROKER_MIFID_ETF_DISPLAYED_RESET: 'BROKER_MIFID_ETF_DISPLAYED_RESET',
  BROKER_MIFID_FUND_DISPLAYED: 'BROKER_MIFID_FUND_DISPLAYED',
  BROKER_MIFID_FUND_DISPLAYED_RESET: 'BROKER_MIFID_FUND_DISPLAYED_RESET',
  BROKER_MIFID_FUTURES_ACCESS_DISPLAYED: 'BROKER_MIFID_FUTURES_ACCESS_DISPLAYED',
  BROKER_MIFID_FUTURES_ACCESS_DISPLAYED_RESET: 'BROKER_MIFID_FUTURES_ACCESS_DISPLAYED_RESET',
  BROKER_MIFID_GET_TEST_RESET: 'BROKER_MIFID_GET_TEST_RESET',
  BROKER_MIFID_PRODUCTS_FETCH: 'BROKER_MIFID_PRODUCTS_FETCH',
  BROKER_MIFID_PRODUCTS_FETCH_FAILURE: 'BROKER_MIFID_PRODUCTS_FETCH_FAILURE',
  BROKER_MIFID_PRODUCTS_FETCH_SUCCESS: 'BROKER_MIFID_PRODUCTS_FETCH_SUCCESS',
  BROKER_MIFID_REQUIRED_SCORE_FETCH: 'BROKER_MIFID_REQUIRED_SCORE_FETCH',
  BROKER_MIFID_REQUIRED_SCORE_FETCH_ERROR: 'BROKER_MIFID_REQUIRED_SCORE_FETCH_ERROR',
  BROKER_MIFID_REQUIRED_SCORE_FETCH_SUCCESS: 'BROKER_MIFID_REQUIRED_SCORE_FETCH_SUCCESS',
  BROKER_MIFID_SCORE_FETCHING: 'BROKER_MIFID_SCORE_FETCHING',
  BROKER_MIFID_SCORE_FETCHING_ERROR: 'BROKER_MIFID_SCORE_FETCHING_ERROR',
  BROKER_MIFID_SCORE_FETCHING_SUCCESS: 'BROKER_MIFID_SCORE_FETCHING_SUCCESS',
  BROKER_MIFID_STOCK_DISPLAYED: 'BROKER_MIFID_STOCK_DISPLAYED',
  BROKER_MIFID_STOCK_DISPLAYED_RESET: 'BROKER_MIFID_STOCK_DISPLAYED_RESET',
  BROKER_MIFID_US_PERSON_FETCHING: 'BROKER_MIFID_US_PERSON_FETCHING',
  BROKER_MIFID_US_PERSON_FETCHING_SUCCESS: 'BROKER_MIFID_US_PERSON_FETCHING_SUCCESS',
  BROKER_MIFID_US_PERSON_RESET: 'BROKER_MIFID_US_PERSON_RESET',
  BROKER_MIFID_WARRANT_DISPLAYED: 'BROKER_MIFID_WARRANT_DISPLAYED',
  BROKER_MIFID_WARRANT_DISPLAYED_RESET: 'BROKER_MIFID_WARRANT_DISPLAYED_RESET',
  BROKER_MORNINGSTAR_URL_PLANS_FAILURE: 'BROKER_MORNINGSTAR_URL_PLANS_FAILURE',
  BROKER_MORNINGSTAR_URL_PLANS_REQUEST: 'BROKER_MORNINGSTAR_URL_PLANS_REQUEST',
  BROKER_MORNINGSTAR_URL_PLANS_SUCCESS: 'BROKER_MORNINGSTAR_URL_PLANS_SUCCESS',
  BROKER_GET_RENTABILITY_LAST_YEAR_FUNDS_SUCCESS: 'BROKER_GET_RENTABILITY_LAST_YEAR_FUNDS_SUCCESS',
  BROKER_GET_RENTABILITY_LAST_YEAR_PLANS_SUCCESS: 'BROKER_GET_RENTABILITY_LAST_YEAR_PLANS_SUCCESS',
  BROKER_MOST_POPULAR_OF_MONTH_FAILURE: 'BROKER_MOST_POPULAR_OF_MONTH_FAILURE',
  BROKER_MOST_POPULAR_OF_MONTH_REQUEST: 'BROKER_MOST_POPULAR_OF_MONTH_REQUEST',
  BROKER_MOST_POPULAR_OF_MONTH_SUCCESS: 'BROKER_MOST_POPULAR_OF_MONTH_SUCCESS',
  BROKER_MOVE_TO: 'BROKER_MOVE_TO',
  BROKER_MYWALLET_CHANGE_TAB: 'BROKER_MYWALLET_CHANGE_TAB',
  BROKER_MY_INVESTMENTS_SET_ACORDION_EXPANDED: 'BROKER_MY_INVESTMENTS_SET_ACORDION_EXPANDED',
  BROKER_MY_INVESTMENTS_SET_VIEW: 'BROKER_MY_INVESTMENTS_SET_VIEW',
  BROKER_MY_WALLET_TOGGLE_EVOLUTION_MODAL: 'BROKER_MY_WALLET_TOGGLE_EVOLUTION_MODAL',
  BROKER_MY_WALLET_TOGGLE_MODAL: 'BROKER_MY_WALLET_TOGGLE_MODAL',
  BROKER_NO_STOCK_ACCOUNT_MODAL_HIDE: 'BROKER_NO_STOCK_ACCOUNT_MODAL_HIDE',
  BROKER_NO_STOCK_ACCOUNT_MODAL_SHOW: 'BROKER_NO_STOCK_ACCOUNT_MODAL_SHOW',
  BROKER_OPEN_TRADER_FETCH_LINK_FAILURE: 'BROKER_OPEN_TRADER_FETCH_LINK_FAILURE',
  BROKER_OPEN_TRADER_FETCH_LINK_SUCCESS: 'BROKER_OPEN_TRADER_FETCH_LINK_SUCCESS',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_CLEAR: 'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_CLEAR',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_FAILURE: 'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_FAILURE',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_REQUEST: 'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_REQUEST',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_SUCCESS: 'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_SUCCESS',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_FAILURE:
    'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_FAILURE',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_REQUEST:
    'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_REQUEST',
  BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_SUCCESS:
    'BROKER_ORDERS_AND_MOVEMENTS_CANCEL_VERIFY_SUCCESS',
  BROKER_ORDERS_AND_MOVEMENTS_FILTERS_UPDATE: 'BROKER_ORDERS_AND_MOVEMENTS_FILTERS_UPDATE',
  BROKER_ORDERS_AND_MOVEMENTS_RESET: 'BROKER_ORDERS_AND_MOVEMENTS_RESET',
  BROKER_ORDERS_AND_MOVEMENTS_SELECTED_OPTION_CHANGED:
    'BROKER_ORDERS_AND_MOVEMENTS_SELECTED_OPTION_CHANGED',
  BROKER_ORDERS_AND_MOVEMENTS_SET_ACCESS_FROM: 'BROKER_ORDERS_AND_MOVEMENTS_SET_ACCESS_FROM',
  BROKER_ORDERS_AND_MOVEMENTS_SET_CONTRACT_NUMBER:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_CONTRACT_NUMBER',
  BROKER_ORDERS_AND_MOVEMENTS_SET_STATE_OPERATIONS_FILTER:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_STATE_OPERATIONS_FILTER',
  BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_CONTRIBUTIONS:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_CONTRIBUTIONS',
  BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_SUBSCRIPTION:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_SUBSCRIPTION',
  BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_REIMBURSEMENT:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_REIMBURSEMENT',
  BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_TRANSFER:
    'BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_TRANSFER',
  BROKER_ORDERS_AND_MOVEMENTS_SET__MAX_DATE: 'BROKER_ORDERS_AND_MOVEMENTS_SET__MAX_DATE',
  BROKER_ORDERS_AND_MOVEMENTS_SET__MIN_DATE: 'BROKER_ORDERS_AND_MOVEMENTS_SET__MIN_DATE',
  BROKER_CONTRIBUTION_FUNDS_SHOW_LOADING: 'BROKER_CONTRIBUTION_FUNDS_SHOW_LOADING',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_REQUEST:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_REQUEST',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_SUCCESS:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_SUCCESS',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_FAILURE:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_FAILURE',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_REQUEST:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_REQUEST',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS',
  BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE:
    'BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE',
  BROKER_WIZARD_CONTRIBUTION_SET_PREVIOUS_DATA: 'BROKER_WIZARD_CONTRIBUTION_SET_PREVIOUS_DATA',
  BROKER_WIZARD_CONTRIBUTION_SET_DATEPICKER_ERROR:
    'BROKER_WIZARD_CONTRIBUTION_SET_DATEPICKER_ERROR',
  BROKER_WIZARD_CONTRIBUTION_CHANGE_READCHECKED: 'BROKER_WIZARD_CONTRIBUTION_CHANGE_READCHECKED',
  BROKER_WIZARD_CONTRIBUTION_CHANGE_SUCCESS: 'BROKER_WIZARD_CONTRIBUTION_CHANGE_SUCCESS',
  BROKER_WIZARD_CONTRIBUTION_CHANGE_VALIDATE_STEP:
    'BROKER_WIZARD_CONTRIBUTION_CHANGE_VALIDATE_STEP',
  BROKER_WIZARD_CONTRIBUTION_CHANGE_WILL_CANCEL: 'BROKER_WIZARD_CONTRIBUTION_CHANGE_WILL_CANCEL',
  BROKER_WIZARD_CONTRIBUTION_CHANGE_WILL_LEAVE: 'BROKER_WIZARD_CONTRIBUTION_CHANGE_WILL_LEAVE',
  BROKER_WIZARD_CONTRIBUTION_CLEAR_FETCHED_PRE: 'BROKER_WIZARD_CONTRIBUTION_CLEAR_FETCHED_PRE',
  BROKER_WIZARD_CONTRIBUTION_RESET: 'BROKER_WIZARD_CONTRIBUTION_RESET',
  BROKER_WIZARD_CONTRIBUTION_SET_ACCOUNT_NUMBER: 'BROKER_WIZARD_CONTRIBUTION_SET_ACCOUNT_NUMBER',
  BROKER_WIZARD_CONTRIBUTION_SET_AMOUNT: 'BROKER_WIZARD_CONTRIBUTION_SET_AMOUNT',
  BROKER_WIZARD_CONTRIBUTION_SET_IS_VALID_SCHEMA: 'BROKER_WIZARD_CONTRIBUTION_SET_IS_VALID_SCHEMA',
  BROKER_WIZARD_CONTRIBUTION_SET_VISIBLE_STEP: 'BROKER_WIZARD_CONTRIBUTION_SET_VISIBLE_STEP',
  BROKER_WIZARD_CONTRIBUTION_SET_DATE_VALUE: 'BROKER_WIZARD_CONTRIBUTION_SET_DATE_VALUE',
  BROKER_WIZARD_CONTRIBUTION_SET_PERIODICITY: 'BROKER_WIZARD_CONTRIBUTION_SET_PERIODICITY',
  BROKER_WIZARD_CONTRIBUTION_SET_CONTRACT_NUMBER: 'BROKER_WIZARD_CONTRIBUTION_SET_CONTRACT_NUMBER',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SKIP_CONTRIBUTION_STEP: 'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SKIP_CONTRIBUTION_STEP',
  BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING: 'BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING',
  BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_ERROR:
    'BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_ERROR',
  BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_SUCCESS:
    'BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_SUCCESS',
  BROKER_PENSION_PLAN_CANCEL_VALIDATION_SUCCESS: 'BROKER_PENSION_PLAN_CANCEL_VALIDATION_SUCCESS',
  BROKER_PENSION_PLAN_CONTRIBUTION_CANCEL_SUCCESS:
    'BROKER_PENSION_PLAN_CONTRIBUTION_CANCEL_SUCCESS',
  BROKER_PENSION_PLAN_CONTRIBUTION_ERROR: 'BROKER_PENSION_PLAN_CONTRIBUTION_ERROR',
  BROKER_PENSION_PLAN_CONTRIBUTION_REQUEST: 'BROKER_PENSION_PLAN_CONTRIBUTION_REQUEST',
  BROKER_PENSION_PLAN_CONTRIBUTION_RESET: 'BROKER_PENSION_PLAN_CONTRIBUTION_RESET',
  BROKER_PENSION_PLAN_CONTRIBUTION_RESET_FLAG_ERROR_REVANUAL:
    'BROKER_PENSION_PLAN_CONTRIBUTION_RESET_FLAG_ERROR_REVANUAL',
  BROKER_PENSION_PLAN_CONTRIBUTION_SET_CONTRACT: 'BROKER_PENSION_PLAN_CONTRIBUTION_SET_CONTRACT',
  BROKER_PENSION_PLAN_CONTRIBUTION_SET_ORIGINAL_AMOUNT:
    'BROKER_PENSION_PLAN_CONTRIBUTION_SET_ORIGINAL_AMOUNT',
  BROKER_PENSION_PLAN_CONTRIBUTION_SET_PERIODICITY:
    'BROKER_PENSION_PLAN_CONTRIBUTION_SET_PERIODICITY',
  BROKER_PENSION_PLAN_CONTRIBUTION_SET_REVANUAL_PERCENTAGE_AVAILABLE:
    'BROKER_PENSION_PLAN_CONTRIBUTION_SET_REVANUAL_PERCENTAGE_AVAILABLE',
  BROKER_PENSION_PLAN_CONTRIBUTION_SET_VISIBLE_STEP:
    'BROKER_PENSION_PLAN_CONTRIBUTION_SET_VISIBLE_STEP',
  BROKER_PENSION_PLAN_CONTRIBUTION_SUCCESS: 'BROKER_PENSION_PLAN_CONTRIBUTION_SUCCESS',
  BROKER_PENSION_PLAN_CONTRIBUTION_VALIDATE_STEP: 'BROKER_PENSION_PLAN_CONTRIBUTION_VALIDATE_STEP',
  BROKER_PENSION_PLAN_CONTRIBUTION_WILL_CANCEL: 'BROKER_PENSION_PLAN_CONTRIBUTION_WILL_CANCEL',
  BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_DOCUMENTS_READ:
    'BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_DOCUMENTS_READ',
  BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_ERROR:
    'BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_ERROR',
  BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_SUCCESS:
    'BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_SUCCESS',
  BROKER_PENSION_PLAN_PERCENTAGE_PERIODICLY_VALUE:
    'BROKER_PENSION_PLAN_PERCENTAGE_PERIODICLY_VALUE',
  BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_ERROR:
    'BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_ERROR',
  BROKER_PENSION_PLAN_PERIODICAL_VALIDATION_ERROR:
    'BROKER_PENSION_PLAN_PERIODICAL_VALIDATION_ERROR',
  BROKER_PENSION_PLAN_PERIODICLY_AMOUNT_VALUE: 'BROKER_PENSION_PLAN_PERIODICLY_AMOUNT_VALUE',
  BROKER_PENSION_PLAN_PERIODICLY_MONTH_VALUE: 'BROKER_PENSION_PLAN_PERIODICLY_MONTH_VALUE',
  BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_ERROR:
    'BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_ERROR',
  BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_SUCCESS:
    'BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_SUCCESS',
  BROKER_PENSION_PLAN_REV_ANUAL_VALUE: 'BROKER_PENSION_PLAN_REV_ANUAL_VALUE',
  BROKER_PENSION_PLAN_SET_CONTRACT_NUMBER: 'BROKER_PENSION_PLAN_SET_CONTRACT_NUMBER',
  BROKER_PENSION_PLAN_TOGGLE_DISCLAIMER: 'BROKER_PENSION_PLAN_TOGGLE_DISCLAIMER',
  BROKER_PENSION_PLAN_VALIDATION_EDIT_SUCCESS: 'BROKER_PENSION_PLAN_VALIDATION_EDIT_SUCCESS',
  BROKER_PENSION_PLAN_VALIDATION_ERROR: 'BROKER_PENSION_PLAN_VALIDATION_ERROR',
  BROKER_PENSION_PLAN_VALIDATION_SUCCESS: 'BROKER_PENSION_PLAN_VALIDATION_SUCCESS',
  BROKER_PENSION_PLAN_VALIDITY_DATE_VALUE: 'BROKER_PENSION_PLAN_VALIDITY_DATE_VALUE',
  BROKER_PENSION_PLAN_SEND_DOCUMENTATION_SUCCESS: 'BROKER_PENSION_PLAN_SEND_DOCUMENTATION_SUCCESS',
  BROKER_PENSION_PLAN_SEND_DOCUMENTATION_FAILURE: 'BROKER_PENSION_PLAN_SEND_DOCUMENTATION_FAILURE',
  BROKER_PERIODICAL_CONTRIBUTION_CHANGE_PAGE: 'BROKER_PERIODICAL_CONTRIBUTION_CHANGE_PAGE',
  BROKER_PERIODICAL_CONTRIBUTION_SELECTED: 'BROKER_PERIODICAL_CONTRIBUTION_SELECTED',
  BROKER_PERIODICAL_CONTRIBUTION_SIGNATURE_SUCCESS:
    'BROKER_PERIODICAL_CONTRIBUTION_SIGNATURE_SUCCESS',
  BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH:
    'BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH',
  BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR:
    'BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR',
  BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS:
    'BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS',
  BROKER_PLAN_LEGAL_DOCUMENT_SUBSCRIPTION_NEWSLETTER_FETCH_SUCCESS:
    'BROKER_PLAN_LEGAL_DOCUMENT_SUBSCRIPTION_NEWSLETTER_FETCH_SUCCESS',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_ERROR:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_ERROR',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_IS_STORED:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_IS_STORED',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_REQUEST:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_REQUEST',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_SUCCESS:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_SUCCESS',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR: 'BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR_EDIT: 'BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR_EDIT',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST: 'BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST_EDIT:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST_EDIT',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_RESET: 'BROKER_PLAN_PERIODICAL_CONTRIBUTION_RESET',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS: 'BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS',
  BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS_EDIT:
    'BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS_EDIT',
  BROKER_PREVENT_GENERATE_NATIONAL_ID_DOCUMENT_ERROR:
    'BROKER_PREVENT_GENERATE_NATIONAL_ID_DOCUMENT_ERROR',
  BROKER_PRODUCT_ADVANCED_SEARCH_LINK_NAME_TRACKING:
    'BROKER_PRODUCT_ADVANCED_SEARCH_LINK_NAME_TRACKING',
  BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES_TRACKING:
    'BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES_TRACKING',
  BROKER_PRODUCT_FILE_RESET: 'BROKER_PRODUCT_FILE_RESET',
  BROKER_REMOVE_LEGAL_DOCUMENTATION: 'BROKER_REMOVE_LEGAL_DOCUMENTATION',
  BROKER_REQUEST_DEFAULT_VIEW_ID: 'BROKER_REQUEST_DEFAULT_VIEW_ID',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_OPERATION_CHECKBOX:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_OPERATION_CHECKBOX',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_TITLES_AMOUNT:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_TITLES_AMOUNT',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_DOCUMENTS_READ:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_DOCUMENTS_READ',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_FAILURE:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_FAILURE',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SET_VISIBLE_STEP:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SET_VISIBLE_STEP',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_START_OPERATION:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_START_OPERATION',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SUCCESS:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SUCCESS',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_VALIDATE_STEP:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_VALIDATE_STEP',
  BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_WILL_CANCEL:
    'BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_WILL_CANCEL',
  BROKER_RIGHTS_BUY_CONFIRM_FAILURE: 'BROKER_RIGHTS_BUY_CONFIRM_FAILURE',
  BROKER_RIGHTS_BUY_CONFIRM_REQUEST: 'BROKER_RIGHTS_BUY_CONFIRM_REQUEST',
  BROKER_RIGHTS_BUY_CONFIRM_SUCCESS: 'BROKER_RIGHTS_BUY_CONFIRM_SUCCESS',
  BROKER_RIGHTS_BUY_CONTINUE_FROM_MIFID: 'BROKER_RIGHTS_BUY_CONTINUE_FROM_MIFID',
  BROKER_RIGHTS_BUY_DISCLAIMER_CLAUSE_CHANGED: 'BROKER_RIGHTS_BUY_DISCLAIMER_CLAUSE_CHANGED',
  BROKER_RIGHTS_BUY_DOCUMENTS_READ: 'BROKER_RIGHTS_BUY_DOCUMENTS_READ',
  BROKER_RIGHTS_BUY_FINISH: 'BROKER_RIGHTS_BUY_FINISH',
  BROKER_RIGHTS_BUY_LIMITED_VALUE_CHANGED: 'BROKER_RIGHTS_BUY_LIMITED_VALUE_CHANGED',
  BROKER_RIGHTS_BUY_ORDER_TYPE_CHANGED: 'BROKER_RIGHTS_BUY_ORDER_TYPE_CHANGED',
  BROKER_RIGHTS_BUY_RESET: 'BROKER_RIGHTS_BUY_RESET',
  BROKER_RIGHTS_BUY_RIGHTS_QUANTITY_CHANGED: 'BROKER_RIGHTS_BUY_RIGHTS_QUANTITY_CHANGED',
  BROKER_RIGHTS_BUY_SELECTED_CORPORATE_INDEX_CHANGED:
    'BROKER_RIGHTS_BUY_SELECTED_CORPORATE_INDEX_CHANGED',
  BROKER_RIGHTS_BUY_SET_VISIBLE_STEP: 'BROKER_RIGHTS_BUY_SET_VISIBLE_STEP',
  BROKER_RIGHTS_BUY_VALIDATE_FAILURE: 'BROKER_RIGHTS_BUY_VALIDATE_FAILURE',
  BROKER_RIGHTS_BUY_VALIDATE_REQUEST: 'BROKER_RIGHTS_BUY_VALIDATE_REQUEST',
  BROKER_RIGHTS_BUY_VALIDATE_SUCCESS: 'BROKER_RIGHTS_BUY_VALIDATE_SUCCESS',
  BROKER_RIGHTS_BUY_VALIDATE_UI_STEP: 'BROKER_RIGHTS_BUY_VALIDATE_UI_STEP',
  BROKER_RIGHTS_BUY_VALIDITY_DATE_CHANGED: 'BROKER_RIGHTS_BUY_VALIDITY_DATE_CHANGED',
  BROKER_RIGHTS_BUY_WILL_CANCEL: 'BROKER_RIGHTS_BUY_WILL_CANCEL',
  BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_LEGAL_OPTION:
    'BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_LEGAL_OPTION',
  BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_MANIFEST: 'BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_MANIFEST',
  BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_QUANTITY_INPUT:
    'BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_QUANTITY_INPUT',
  BROKER_RIGHTS_CASH_EXCHANGE_CONFIRM_REQUEST: 'BROKER_RIGHTS_CASH_EXCHANGE_CONFIRM_REQUEST',
  BROKER_RIGHTS_CASH_EXCHANGE_DOCUMENTS_READ: 'BROKER_RIGHTS_CASH_EXCHANGE_DOCUMENTS_READ',
  BROKER_RIGHTS_CASH_EXCHANGE_RESET: 'BROKER_RIGHTS_CASH_EXCHANGE_RESET',
  BROKER_RIGHTS_CASH_EXCHANGE_SET_CONFIRM_SUCCESS:
    'BROKER_RIGHTS_CASH_EXCHANGE_SET_CONFIRM_SUCCESS',
  BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE: 'BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE',
  BROKER_RIGHTS_CASH_EXCHANGE_SET_OPERATION_DATA: 'BROKER_RIGHTS_CASH_EXCHANGE_SET_OPERATION_DATA',
  BROKER_RIGHTS_CASH_EXCHANGE_SET_VISIBLE_STEP: 'BROKER_RIGHTS_CASH_EXCHANGE_SET_VISIBLE_STEP',
  BROKER_RIGHTS_CASH_EXCHANGE_SET_WILL_CANCEL: 'BROKER_RIGHTS_CASH_EXCHANGE_SET_WILL_CANCEL',
  BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_CC_SUCCESS:
    'BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_CC_SUCCESS',
  BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_FAILURE:
    'BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_FAILURE',
  BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_SUCCESS:
    'BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_SUCCESS',
  BROKER_RIGHTS_CASH_EXCHANGE_VALIDATION_REQUESTED:
    'BROKER_RIGHTS_CASH_EXCHANGE_VALIDATION_REQUESTED',
  BROKER_RIGHTS_CASH_EXCHANGE_VALID_LEGAL_CONFIRMATION:
    'BROKER_RIGHTS_CASH_EXCHANGE_VALID_LEGAL_CONFIRMATION',
  BROKER_RIGHTS_SELL_CONFIRM_FAILURE: 'BROKER_RIGHTS_SELL_CONFIRM_FAILURE',
  BROKER_RIGHTS_SELL_CONFIRM_REQUEST: 'BROKER_RIGHTS_SELL_CONFIRM_REQUEST',
  BROKER_RIGHTS_SELL_CONFIRM_SUCCESS: 'BROKER_RIGHTS_SELL_CONFIRM_SUCCESS',
  BROKER_RIGHTS_EXTANTCOST_ERROR: 'BROKER_RIGHTS_EXTANTCOST_ERROR',
  BROKER_RIGHTS_EXTANTCOST_FECHING: 'BROKER_RIGHTS_EXTANTCOST_FECHING',
  BROKER_RIGHTS_EXTANTCOST_SUCESS: 'BROKER_RIGHTS_EXTANTCOST_SUCESS',
  BROKER_RIGHTS_SELL_DISCLAIMER_CLAUSE_CHANGED: 'BROKER_RIGHTS_SELL_DISCLAIMER_CLAUSE_CHANGED',
  BROKER_RIGHTS_SELL_DOCUMENTS_READ: 'BROKER_RIGHTS_SELL_DOCUMENTS_READ',
  BROKER_RIGHTS_SELL_FINISH: 'BROKER_RIGHTS_SELL_FINISH',
  BROKER_RIGHTS_SELL_LIMITED_VALUE_CHANGED: 'BROKER_RIGHTS_SELL_LIMITED_VALUE_CHANGED',
  BROKER_RIGHTS_SELL_ORDER_TYPE_CHANGED: 'BROKER_RIGHTS_SELL_ORDER_TYPE_CHANGED',
  BROKER_RIGHTS_SELL_RESET: 'BROKER_RIGHTS_SELL_RESET',
  BROKER_RIGHTS_SELL_RIGHTS_QUANTITY_CHANGED: 'BROKER_RIGHTS_SELL_RIGHTS_QUANTITY_CHANGED',
  BROKER_RIGHTS_SELL_SELECTED_CORPORATE_INDEX_CHANGED:
    'BROKER_RIGHTS_SELL_SELECTED_CORPORATE_INDEX_CHANGED',
  BROKER_RIGHTS_SELL_SET_VISIBLE_STEP: 'BROKER_RIGHTS_SELL_SET_VISIBLE_STEP',
  BROKER_RIGHTS_SELL_VALIDATE_FAILURE: 'BROKER_RIGHTS_SELL_VALIDATE_FAILURE',
  BROKER_RIGHTS_SELL_VALIDATE_REQUEST: 'BROKER_RIGHTS_SELL_VALIDATE_REQUEST',
  BROKER_RIGHTS_SELL_VALIDATE_SUCCESS: 'BROKER_RIGHTS_SELL_VALIDATE_SUCCESS',
  BROKER_RIGHTS_SELL_VALIDATE_UI_STEP: 'BROKER_RIGHTS_SELL_VALIDATE_UI_STEP',
  BROKER_RIGHTS_SELL_VALIDITY_DATE_CHANGED: 'BROKER_RIGHTS_SELL_VALIDITY_DATE_CHANGED',
  BROKER_RIGHTS_SELL_WILL_CANCEL: 'BROKER_RIGHTS_SELL_WILL_CANCEL',
  BROKER_RIGHTS_SELL_EX_ANTE_FETCHING: 'BROKER_RIGHTS_SELL_EX_ANTE_FETCHING',
  BROKER_RIGHTS_SELL_EX_ANTE_SUCCESS: 'BROKER_RIGHTS_SELL_EX_ANTE_SUCCESS',
  BROKER_RIGHTS_SELL_EX_ANTE_FAILURE: 'BROKER_RIGHTS_SELL_EX_ANTE_FAILURE',
  BROKER_SAVE_LIST_NAME: 'BROKER_SAVE_LIST_NAME',
  BROKER_SEARCH_PRODUCT_FAILURE: 'BROKER_SEARCH_PRODUCT_FAILURE',
  BROKER_SEARCH_PRODUCT_FETCHING: 'BROKER_SEARCH_PRODUCT_FETCHING',
  BROKER_SEARCH_PRODUCT_RESET: 'BROKER_SEARCH_PRODUCT_RESET',
  BROKER_SEARCH_PRODUCT_SUCCESS: 'BROKER_SEARCH_PRODUCT_SUCCESS',
  BROKER_SEARCH_SELECTED_RESULT_RESET: 'BROKER_SEARCH_SELECTED_RESULT_RESET',
  BROKER_SEARCH_SET_PLANS_LIST: 'BROKER_SEARCH_SET_PLANS_LIST',
  BROKER_SEARCH_WIDGET_CHANGE_INPUT: 'BROKER_SEARCH_WIDGET_CHANGE_INPUT',
  BROKER_SEARCH_WIDGET_CLEAR_RESULTS: 'BROKER_SEARCH_WIDGET_CLEAR_RESULTS',
  BROKER_SEARCH_WIDGET_FAILURE: 'BROKER_SEARCH_WIDGET_FAILURE',
  BROKER_SEARCH_WIDGET_HIDE: 'BROKER_SEARCH_WIDGET_HIDE',
  BROKER_SEARCH_WIDGET_REQUEST: 'BROKER_SEARCH_WIDGET_REQUEST',
  BROKER_SEARCH_WIDGET_RESET: 'BROKER_SEARCH_WIDGET_RESET',
  BROKER_SEARCH_WIDGET_SHOW: 'BROKER_SEARCH_WIDGET_SHOW',
  BROKER_SEARCH_WIDGET_SUCCESS: 'BROKER_SEARCH_WIDGET_SUCCESS',
  BROKER_SEARCH_WIDGET_SUCCESS_TRACKING: 'BROKER_SEARCH_WIDGET_SUCCESS_TRACKING',
  BROKER_SELECTED_MARKETS_EXCHANGE_AVAILABILITY_ERROR:
    'BROKER_SELECTED_MARKETS_EXCHANGE_AVAILABILITY_ERROR',
  BROKER_SELECTED_MARKETS_EXCHANGE_AVAILABILITY_SUCCESS:
    'BROKER_SELECTED_MARKETS_EXCHANGE_AVAILABILITY_SUCCESS',
  BROKER_SELL_CONTRACT_NUMBER: 'BROKER_SELL_CONTRACT_NUMBER',
  BROKER_SET_CONTRACT_ID_PERIODICAL_CONTRIBUTIONS:
    'BROKER_SET_CONTRACT_ID_PERIODICAL_CONTRIBUTIONS',
  BROKER_SET_GENERAL_VIEW_TOTAL: 'BROKER_SET_GENERAL_VIEW_TOTAL',
  BROKER_SET_LEGAL_DOCUMENTATION_CHECKED: 'BROKER_SET_LEGAL_DOCUMENTATION_CHECKED',
  BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE: 'BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE',
  BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS: 'BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS',
  BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING:
    'BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING',
  BROKER_SET_SUMMARIZATION_DATA: 'BROKER_SET_SUMMARIZATION_DATA',
  BROKER_SHOW_CLIENT_WARNING_INFO: 'BROKER_SHOW_CLIENT_WARNING_INFO',
  BROKER_SIMPLE_SEARCH_APPLY_FILTER: 'BROKER_SIMPLE_SEARCH_APPLY_FILTER', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_CHANGE_SEARCH_INPUT: 'BROKER_SIMPLE_SEARCH_CHANGE_SEARCH_INPUT', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_CLEAR: 'BROKER_SIMPLE_SEARCH_CLEAR', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_CLEAR_FILTERS: 'BROKER_SIMPLE_SEARCH_CLEAR_FILTERS', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_FAILURE: 'BROKER_SIMPLE_SEARCH_FAILURE', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_PRODUCT_TRACKING: 'BROKER_SIMPLE_SEARCH_PRODUCT_TRACKING',
  BROKER_SIMPLE_SEARCH_REQUEST: 'BROKER_SIMPLE_SEARCH_REQUEST', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_RESULTS_RESET: 'BROKER_SIMPLE_SEARCH_RESULTS_RESET',
  BROKER_SIMPLE_SEARCH_SELECT_FILTER: 'BROKER_SIMPLE_SEARCH_SELECT_FILTER', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_SUCCESS: 'BROKER_SIMPLE_SEARCH_SUCCESS', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SEARCH_TOGGLE_MODAL_FILTER: 'BROKER_SIMPLE_SEARCH_TOGGLE_MODAL_FILTER', // deprecate on R15 - Buscadores
  BROKER_SIMPLE_SPECIFIC_SEARCH_SUCCESS: 'BROKER_SIMPLE_SPECIFIC_SEARCH_SUCCESS',
  BROKER_SPIN_OFF_MODAL_BEFORE_ENTER_BROKER: 'BROKER_SPIN_OFF_MODAL_BEFORE_ENTER_BROKER',
  BROKER_SPIN_OFF_MODAL_CLOSE_MODAL: 'BROKER_SPIN_OFF_MODAL_CLOSE_MODAL',
  BROKER_SPIN_OFF_MODAL_FINISHED_VIDEO: 'BROKER_SPIN_OFF_MODAL_FINISHED_VIDEO',
  BROKER_SPIN_OFF_MODAL_HAS_USER_SET_MENU: 'BROKER_SPIN_OFF_MODAL_HAS_USER_SET_MENU',
  BROKER_SPIN_OFF_MODAL_PLAY_VIDEO: 'BROKER_SPIN_OFF_MODAL_PLAY_VIDEO',
  BROKER_STOCKS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_CONFIRM_FAILURE: 'BROKER_STOCKS_ADVANCED_BUY_CONFIRM_FAILURE',
  BROKER_STOCKS_ADVANCED_BUY_CONFIRM_REQUEST: 'BROKER_STOCKS_ADVANCED_BUY_CONFIRM_REQUEST',
  BROKER_STOCKS_ADVANCED_BUY_CONFIRM_SUCCESS: 'BROKER_STOCKS_ADVANCED_BUY_CONFIRM_SUCCESS',
  BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_FETCHING: 'BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_FETCHING',
  BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_SUCCESS: 'BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_SUCCESS',
  BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_ERROR: 'BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_ERROR',
  BROKER_STOCKS_ADVANCED_BUY_FINISH: 'BROKER_STOCKS_ADVANCED_BUY_FINISH',
  BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_RESET:
    'BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_RESET',
  BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED:
    'BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED',
  BROKER_STOCKS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_LIMITED_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_ORDER_TERMS_CHANGED: 'BROKER_STOCKS_ADVANCED_BUY_ORDER_TERMS_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_ORDER_TYPE_CHANGED: 'BROKER_STOCKS_ADVANCED_BUY_ORDER_TYPE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_RESET: 'BROKER_STOCKS_ADVANCED_BUY_RESET',
  BROKER_STOCKS_ADVANCED_BUY_SET_LIMITED_VALUE_CURRENCY:
    'BROKER_STOCKS_ADVANCED_BUY_SET_LIMITED_VALUE_CURRENCY',
  BROKER_STOCKS_ADVANCED_BUY_SET_ORDER_ID: 'BROKER_STOCKS_ADVANCED_BUY_SET_ORDER_ID',
  BROKER_STOCKS_ADVANCED_BUY_SET_VISIBLE_STEP: 'BROKER_STOCKS_ADVANCED_BUY_SET_VISIBLE_STEP',
  BROKER_STOCKS_ADVANCED_BUY_SIGNATURE_SUCCESS: 'BROKER_STOCKS_ADVANCED_BUY_SIGNATURE_SUCCESS',
  BROKER_STOCKS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_ERROR: 'BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_ERROR',
  BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_REQUEST: 'BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_REQUEST',
  BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_SUCCESS: 'BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_SUCCESS',
  BROKER_STOCKS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_VALIDATE_FAILURE: 'BROKER_STOCKS_ADVANCED_BUY_VALIDATE_FAILURE',
  BROKER_STOCKS_ADVANCED_BUY_VALIDATE_REQUEST: 'BROKER_STOCKS_ADVANCED_BUY_VALIDATE_REQUEST',
  BROKER_STOCKS_ADVANCED_BUY_VALIDATE_SUCCESS: 'BROKER_STOCKS_ADVANCED_BUY_VALIDATE_SUCCESS',
  BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP: 'BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP',
  BROKER_STOCKS_ADVANCED_BUY_VALIDITY_DATE_CHANGED:
    'BROKER_STOCKS_ADVANCED_BUY_VALIDITY_DATE_CHANGED',
  BROKER_STOCKS_ADVANCED_BUY_WILL_CANCEL: 'BROKER_STOCKS_ADVANCED_BUY_WILL_CANCEL',
  BROKER_STOCKS_ADVANCED_SELL_CONDITION_TYPE_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_CONDITION_TYPE_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_CONFIRM_FAILURE: 'BROKER_STOCKS_ADVANCED_SELL_CONFIRM_FAILURE',
  BROKER_STOCKS_ADVANCED_SELL_CONFIRM_REQUEST: 'BROKER_STOCKS_ADVANCED_SELL_CONFIRM_REQUEST',
  BROKER_STOCKS_ADVANCED_SELL_CONFIRM_SUCCESS: 'BROKER_STOCKS_ADVANCED_SELL_CONFIRM_SUCCESS',
  BROKER_STOCKS_ADVANCED_SELL_FINISH: 'BROKER_STOCKS_ADVANCED_SELL_FINISH',
  BROKER_STOCKS_ADVANCED_SELL_INPUT_SEARCH_PRODUCT_SELECTED:
    'BROKER_STOCKS_ADVANCED_SELL_INPUT_SEARCH_PRODUCT_SELECTED',
  BROKER_STOCKS_ADVANCED_SELL_LIMITED_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_LIMITED_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_MIFID_CLAUSE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_MIFID_CLAUSE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_TYPE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_TYPE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_VALUE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_VALUE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_ORDER_TERMS_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_ORDER_TERMS_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_ORDER_TERM_VALIDITY_DATE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_ORDER_TERM_VALIDITY_DATE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_ORDER_TYPE_CHANGED: 'BROKER_STOCKS_ADVANCED_SELL_ORDER_TYPE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_RESET: 'BROKER_STOCKS_ADVANCED_SELL_RESET',
  BROKER_STOCKS_ADVANCED_SELL_SET_LIMITED_VALUE_CURRENCY:
    'BROKER_STOCKS_ADVANCED_SELL_SET_LIMITED_VALUE_CURRENCY',
  BROKER_STOCKS_ADVANCED_SELL_SET_ORDER_ID: 'BROKER_STOCKS_ADVANCED_SELL_SET_ORDER_ID',
  BROKER_STOCKS_ADVANCED_SELL_SET_VISIBLE_STEP: 'BROKER_STOCKS_ADVANCED_SELL_SET_VISIBLE_STEP',
  BROKER_STOCKS_ADVANCED_SELL_SIGNATURE_SUCCESS: 'BROKER_STOCKS_ADVANCED_SELL_SIGNATURE_SUCCESS',
  BROKER_STOCKS_ADVANCED_SELL_STOCK_CONTRACT_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_STOCK_CONTRACT_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_TITLE_QUANTITY_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_TITLE_QUANTITY_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_VALIDATE_FAILURE: 'BROKER_STOCKS_ADVANCED_SELL_VALIDATE_FAILURE',
  BROKER_STOCKS_ADVANCED_SELL_VALIDATE_REQUEST: 'BROKER_STOCKS_ADVANCED_SELL_VALIDATE_REQUEST',
  BROKER_STOCKS_ADVANCED_SELL_VALIDATE_SUCCESS: 'BROKER_STOCKS_ADVANCED_SELL_VALIDATE_SUCCESS',
  BROKER_STOCKS_ADVANCED_SELL_VALIDATE_UI_STEP: 'BROKER_STOCKS_ADVANCED_SELL_VALIDATE_UI_STEP',
  BROKER_STOCKS_ADVANCED_SELL_VALIDITY_DATE_CHANGED:
    'BROKER_STOCKS_ADVANCED_SELL_VALIDITY_DATE_CHANGED',
  BROKER_STOCKS_ADVANCED_SELL_WILL_CANCEL: 'BROKER_STOCKS_ADVANCED_SELL_WILL_CANCEL',
  BROKER_STOCKS_BUY_CONFIRM_FAILURE: 'BROKER_STOCKS_BUY_CONFIRM_FAILURE',
  BROKER_STOCKS_BUY_CONFIRM_REQUEST: 'BROKER_STOCKS_BUY_CONFIRM_REQUEST',
  BROKER_STOCKS_BUY_CONFIRM_SUCCESS: 'BROKER_STOCKS_BUY_CONFIRM_SUCCESS',
  BROKER_STOCKS_BUY_FINISH: 'BROKER_STOCKS_BUY_FINISH',
  BROKER_STOCKS_BUY_RESET: 'BROKER_STOCKS_BUY_RESET',
  BROKER_STOCKS_BUY_RESET_ERRORS_BY_LOT: 'BROKER_STOCKS_BUY_RESET_ERRORS_BY_LOT',
  BROKER_STOCKS_BUY_RESET_STEP_1_APPROX_IMPORT_INPUT:
    'BROKER_STOCKS_BUY_RESET_STEP_1_APPROX_IMPORT_INPUT',
  BROKER_STOCKS_BUY_RESET_STEP_1_LIMITED_INPUT: 'BROKER_STOCKS_BUY_RESET_STEP_1_LIMITED_INPUT',
  BROKER_STOCKS_BUY_RESET_STEP_1_TITLES_INPUT: 'BROKER_STOCKS_BUY_RESET_STEP_1_TITLES_INPUT',
  BROKER_STOCKS_BUY_SEND_EMAIL_ERROR: 'BROKER_STOCKS_BUY_SEND_EMAIL_ERROR',
  BROKER_STOCKS_BUY_SEND_EMAIL_REQUEST: 'BROKER_STOCKS_BUY_SEND_EMAIL_REQUEST',
  BROKER_STOCKS_BUY_SEND_EMAIL_SUCCESS: 'BROKER_STOCKS_BUY_SEND_EMAIL_SUCCESS',
  BROKER_STOCKS_BUY_SET_HOLIDAY_ERROR: 'BROKER_STOCKS_BUY_SET_HOLIDAY_ERROR',
  BROKER_STOCKS_BUY_SET_VISIBLE_STEP: 'BROKER_STOCKS_BUY_SET_VISIBLE_STEP',
  BROKER_STOCKS_BUY_SIGNATURE_SUCCESS: 'BROKER_STOCKS_BUY_SIGNATURE_SUCCESS',
  BROKER_STOCKS_BUY_UPDATE_STEP_1_FORM: 'BROKER_STOCKS_BUY_UPDATE_STEP_1_FORM',
  BROKER_STOCKS_BUY_VALIDATE_FAILURE: 'BROKER_STOCKS_BUY_VALIDATE_FAILURE',
  BROKER_STOCKS_BUY_VALIDATE_REQUEST: 'BROKER_STOCKS_BUY_VALIDATE_REQUEST',
  BROKER_STOCKS_BUY_VALIDATE_STEP: 'BROKER_STOCKS_BUY_VALIDATE_STEP',
  BROKER_STOCKS_BUY_VALIDATE_SUCCESS: 'BROKER_STOCKS_BUY_VALIDATE_SUCCESS',
  BROKER_STOCKS_BUY_WILL_CANCEL: 'BROKER_STOCKS_BUY_WILL_CANCEL',
  BROKER_STOCKS_BUY_WILL_LEAVE: 'BROKER_STOCKS_BUY_WILL_LEAVE',
  BROKER_STOCKS_BY_INDEX_FAILURE: 'BROKER_STOCKS_BY_INDEX_FAILURE',
  BROKER_STOCKS_BY_INDEX_REQUEST: 'BROKER_STOCKS_BY_INDEX_REQUEST',
  BROKER_STOCKS_BY_INDEX_SUCCESS: 'BROKER_STOCKS_BY_INDEX_SUCCESS',
  BROKER_STOCKS_CONTRACT_ADD_INTERVENER: 'BROKER_STOCKS_CONTRACT_ADD_INTERVENER',
  BROKER_STOCKS_CONTRACT_CONFIRM_FAILURE: 'BROKER_STOCKS_CONTRACT_CONFIRM_FAILURE',
  BROKER_STOCKS_CONTRACT_CONFIRM_REQUEST: 'BROKER_STOCKS_CONTRACT_CONFIRM_REQUEST',
  BROKER_STOCKS_CONTRACT_CONFIRM_SUCCESS: 'BROKER_STOCKS_CONTRACT_CONFIRM_SUCCESS',
  BROKER_STOCKS_CONTRACT_FINISH_READING_PRECONTRACTUAL_DOCUMENT:
    'BROKER_STOCKS_CONTRACT_FINISH_READING_PRECONTRACTUAL_DOCUMENT',
  BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION: 'BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION',
  BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_ERROR:
    'BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_ERROR',
  BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_SUCCESS:
    'BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_SUCCESS',
  BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION:
    'BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION',
  BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE:
    'BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE',
  BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING:
    'BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING',
  BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_NONRESIDENTIAL_MODAL_TOGGLE:
    'BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_NONRESIDENTIAL_MODAL_TOGGLE',
  BROKER_STOCKS_CONTRACT_REMOVE_INTERVENER: 'BROKER_STOCKS_CONTRACT_REMOVE_INTERVENER',
  BROKER_STOCKS_CONTRACT_RESET: 'BROKER_STOCKS_CONTRACT_RESET',
  BROKER_STOCKS_CONTRACT_RESET_ACCOUNT: 'BROKER_STOCKS_CONTRACT_RESET_ACCOUNT',
  BROKER_STOCKS_CONTRACT_RESTORE_DEFAULT_INTERVENERS:
    'BROKER_STOCKS_CONTRACT_RESTORE_DEFAULT_INTERVENERS',
  BROKER_STOCKS_CONTRACT_SET_ACCOUNT: 'BROKER_STOCKS_CONTRACT_SET_ACCOUNT',
  BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_NAME:
    'BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_NAME',
  BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_TYPE:
    'BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_TYPE',
  BROKER_STOCKS_CONTRACT_SET_TERMS_AND_CONDITIONS:
    'BROKER_STOCKS_CONTRACT_SET_TERMS_AND_CONDITIONS',
  BROKER_STOCKS_CONTRACT_SET_VISIBLE_STEP: 'BROKER_STOCKS_CONTRACT_SET_VISIBLE_STEP',
  BROKER_STOCKS_CONTRACT_SIGNATURE_PATTERN_SUCCESS:
    'BROKER_STOCKS_CONTRACT_SIGNATURE_PATTERN_SUCCESS',
  BROKER_STOCKS_CONTRACT_VALIDATE_STEP: 'BROKER_STOCKS_CONTRACT_VALIDATE_STEP',
  BROKER_STOCKS_CONTRACT_WILL_CANCEL: 'BROKER_STOCKS_CONTRACT_WILL_CANCEL',
  BROKER_STOCKS_CONTRACT_WILL_LEAVE: 'BROKER_STOCKS_CONTRACT_WILL_LEAVE',
  BROKER_STOCKS_EXTANTCOST_FECHING: 'BROKER_STOCKS_EXTANTCOST_FECHING',
  BROKER_STOCKS_EXTANTCOST_SUCESS: 'BROKER_STOCKS_EXTANTCOST_SUCESS',
  BROKER_STOCKS_EXTANTCOST_ERROR: 'BROKER_STOCKS_EXTANTCOST_ERROR',
  BROKER_STOCKS_PURCHASE_ORDER_CHANGE_AMOUNT: 'BROKER_STOCKS_PURCHASE_ORDER_CHANGE_AMOUNT',
  BROKER_STOCKS_PURCHASE_ORDER_CHANGE_MANIFEST: 'BROKER_STOCKS_PURCHASE_ORDER_CHANGE_MANIFEST',
  BROKER_STOCKS_PURCHASE_ORDER_CONFIRM_FAILURE: 'BROKER_STOCKS_PURCHASE_ORDER_CONFIRM_FAILURE',
  BROKER_STOCKS_PURCHASE_ORDER_DOCUMENTS_READ: 'BROKER_STOCKS_PURCHASE_ORDER_DOCUMENTS_READ',
  BROKER_STOCKS_PURCHASE_ORDER_FINISH: 'BROKER_STOCKS_PURCHASE_ORDER_FINISH',
  BROKER_STOCKS_PURCHASE_ORDER_LOADING: 'BROKER_STOCKS_PURCHASE_ORDER_LOADING',
  BROKER_STOCKS_PURCHASE_ORDER_SET_STEP: 'BROKER_STOCKS_PURCHASE_ORDER_SET_STEP',
  BROKER_STOCKS_PURCHASE_ORDER_START: 'BROKER_STOCKS_PURCHASE_ORDER_START',
  BROKER_STOCKS_PURCHASE_ORDER_VALIDATE_STEP: 'BROKER_STOCKS_PURCHASE_ORDER_VALIDATE_STEP',
  BROKER_STOCKS_SALE_ORDER_CHANGE_MANIFEST: 'BROKER_STOCKS_SALE_ORDER_CHANGE_MANIFEST',
  BROKER_STOCKS_SALE_ORDER_CHANGE_TITLES_AMOUNT: 'BROKER_STOCKS_SALE_ORDER_CHANGE_TITLES_AMOUNT',
  BROKER_STOCKS_SALE_ORDER_CONFIRM_FAILURE: 'BROKER_STOCKS_SALE_ORDER_CONFIRM_FAILURE',
  BROKER_STOCKS_SALE_ORDER_DOCUMENTS_READ: 'BROKER_STOCKS_SALE_ORDER_DOCUMENTS_READ',
  BROKER_STOCKS_SALE_ORDER_FINISH: 'BROKER_STOCKS_SALE_ORDER_FINISH',
  BROKER_STOCKS_SALE_ORDER_FINISH_VALIDATION_STARTED:
    'BROKER_STOCKS_SALE_ORDER_FINISH_VALIDATION_STARTED',
  BROKER_STOCKS_SALE_ORDER_LOADING: 'BROKER_STOCKS_SALE_ORDER_LOADING',
  BROKER_STOCKS_SALE_ORDER_SET_OPERATION_INDEX: 'BROKER_STOCKS_SALE_ORDER_SET_OPERATION_INDEX',
  BROKER_STOCKS_SALE_ORDER_SET_VISIBLE_STEP: 'BROKER_STOCKS_SALE_ORDER_SET_VISIBLE_STEP',
  BROKER_STOCKS_SALE_ORDER_START: 'BROKER_STOCKS_SALE_ORDER_START',
  BROKER_STOCKS_SALE_ORDER_VALIDATE: 'BROKER_STOCKS_SALE_ORDER_VALIDATE',
  BROKER_STOCKS_SELL_CONFIRM_FAILURE: 'BROKER_STOCKS_SELL_CONFIRM_FAILURE',
  BROKER_STOCKS_SELL_CONFIRM_REQUEST: 'BROKER_STOCKS_SELL_CONFIRM_REQUEST',
  BROKER_STOCKS_SELL_CONFIRM_SUCCESS: 'BROKER_STOCKS_SELL_CONFIRM_SUCCESS',
  BROKER_STOCKS_SELL_CONTRACT_NUMBER: 'BROKER_STOCKS_SELL_CONTRACT_NUMBER',
  BROKER_STOCKS_SELL_EX_ANTE_FETCHING: 'BROKER_STOCKS_SELL_EX_ANTE_FETCHING',
  BROKER_STOCKS_SELL_EX_ANTE_SUCCESS: 'BROKER_STOCKS_SELL_EX_ANTE_SUCCESS',
  BROKER_STOCKS_SELL_EX_ANTE_FAILURE: 'BROKER_STOCKS_SELL_EX_ANTE_FAILURE',
  BROKER_STOCKS_SELL_FINISH: 'BROKER_STOCKS_SELL_FINISH',
  BROKER_STOCKS_SELL_LIMITED_VALUE_CHANGED: 'BROKER_STOCKS_SELL_LIMITED_VALUE_CHANGED',
  BROKER_STOCKS_SELL_ORDER_TYPE_CHANGED: 'BROKER_STOCKS_SELL_ORDER_TYPE_CHANGED',
  BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_REQUEST: 'BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_REQUEST',
  BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_SUCCESS: 'BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_SUCCESS',
  BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_ERROR: 'BROKER_STOCKS_SELL_SEND_ORDER_SUMMARY_ERROR',
  BROKER_STOCKS_SELL_RESET: 'BROKER_STOCKS_SELL_RESET',
  BROKER_STOCKS_SELL_SET_CODES: ' BROKER_STOCKS_SELL_SET_CODES',
  BROKER_STOCKS_SELL_SET_VISIBLE_STEP: 'BROKER_STOCKS_SELL_SET_VISIBLE_STEP',
  BROKER_STOCKS_SELL_STOCK_CONTRACT_CHANGED: 'BROKER_STOCKS_SELL_STOCK_CONTRACT_CHANGED',
  BROKER_STOCKS_SELL_TITLE_QUANTITY_CHANGED: 'BROKER_STOCKS_SELL_TITLE_QUANTITY_CHANGED',
  BROKER_STOCKS_SELL_VALIDATE_FAILURE: 'BROKER_STOCKS_SELL_VALIDATE_FAILURE',
  BROKER_STOCKS_SELL_VALIDATE_REQUEST: 'BROKER_STOCKS_SELL_VALIDATE_REQUEST',
  BROKER_STOCKS_SELL_VALIDATE_STEP: 'BROKER_STOCKS_SELL_VALIDATE_STEP',
  BROKER_STOCKS_SELL_VALIDATE_SUCCESS: 'BROKER_STOCKS_SELL_VALIDATE_SUCCESS',
  BROKER_STOCKS_SELL_VALIDITY_DATE_CHANGED: 'BROKER_STOCKS_SELL_VALIDITY_DATE_CHANGED',
  BROKER_STOCKS_SELL_WILL_CANCEL: 'BROKER_STOCKS_SELL_WILL_CANCEL',
  BROKER_STOCKS_SELL_WILL_LEAVE: 'BROKER_STOCKS_SELL_WILL_LEAVE',
  BROKER_SUBSCRIPTION_CHANGE_MANIFEST: 'BROKER_SUBSCRIPTION_CHANGE_MANIFEST',
  BROKER_SUBSCRIPTION_DOCUMENTS_READ: 'BROKER_SUBSCRIPTION_DOCUMENTS_READ',
  BROKER_SUBSCRIPTION_FINISH: 'BROKER_SUBSCRIPTION_FINISH',
  BROKER_SUBSCRIPTION_START: 'BROKER_SUBSCRIPTION_START',
  BROKER_SUBSCRIPTION_VALIDATE_STEP: 'BROKER_SUBSCRIPTION_VALIDATE_STEP',
  BROKER_SUMMARIZE_INVESTMENTS_BY_TYPE: 'BROKER_SUMMARIZE_INVESTMENTS_BY_TYPE',
  BROKER_TODAYS_MAIN_STOCKS_FAILURE: 'BROKER_TODAYS_MAIN_STOCKS_FAILURE',
  BROKER_TODAYS_MAIN_STOCKS_MARKET_CHANGED: 'BROKER_TODAYS_MAIN_STOCKS_MARKET_CHANGED',
  BROKER_TODAYS_MAIN_STOCKS_REQUEST: 'BROKER_TODAYS_MAIN_STOCKS_REQUEST',
  BROKER_TODAYS_MAIN_STOCKS_RESET_SHOW_MORE_SELECTED_TAB:
    'BROKER_TODAYS_MAIN_STOCKS_RESET_SHOW_MORE_SELECTED_TAB',
  BROKER_TODAYS_MAIN_STOCKS_RESET_STOCKS_MARKET: 'BROKER_TODAYS_MAIN_STOCKS_RESET_STOCKS_MARKET',
  BROKER_TODAYS_MAIN_STOCKS_SET_PREVIOUS_PAGE: 'BROKER_TODAYS_MAIN_STOCKS_SET_PREVIOUS_PAGE',
  BROKER_TODAYS_MAIN_STOCKS_SET_SHOW_MORE_SELECTED_TAB:
    'BROKER_TODAYS_MAIN_STOCKS_SET_SHOW_MORE_SELECTED_TAB',
  BROKER_TODAYS_MAIN_STOCKS_SUCCESS: 'BROKER_TODAYS_MAIN_STOCKS_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_FAILURE: 'BROKER_TRANSFER_EXTERNAL_FAILURE',
  BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_ERROR: 'BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_ERROR',
  BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_SUCCESS: 'BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_GET_EMITION_VALUE_CODE_SUCCESS:
    'BROKER_TRANSFER_EXTERNAL_GET_EMITION_VALUE_CODE_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_GET_VALUE_AND_EMITION_CODE_ERROR:
    'BROKER_TRANSFER_EXTERNAL_GET_VALUE_AND_EMITION_CODE_ERROR',
  BROKER_TRANSFER_EXTERNAL_REQUEST: 'BROKER_TRANSFER_EXTERNAL_REQUEST',
  BROKER_TRANSFER_EXTERNAL_RESET: 'BROKER_TRANSFER_VALUES_RESET',
  BROKER_TRANSFER_EXTERNAL_SET_ACCOUNT_TO: 'BROKER_TRANSFER_EXTERNAL_SET_ACCOUNT_TO',
  BROKER_TRANSFER_EXTERNAL_SET_AGENCIE: 'BROKER_TRANSFER_EXTERNAL_SET_AGENCIE',
  BROKER_TRANSFER_EXTERNAL_SET_AGENCIE_CONTRACT: 'BROKER_TRANSFER_EXTERNAL_SET_AGENCIE_CONTRACT',
  BROKER_TRANSFER_EXTERNAL_SET_CONTRACT: 'BROKER_TRANSFER_EXTERNAL_SET_CONTRACT',
  BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_BANK: 'BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_BANK',
  BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_DC: 'BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_DC',
  BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_OFFICE: 'BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_OFFICE',
  BROKER_TRANSFER_EXTERNAL_SET_ISN_ISO: 'BROKER_TRANSFER_EXTERNAL_SET_ISN_ISO',
  BROKER_TRANSFER_EXTERNAL_SET_TITLE_AMOUNT: 'BROKER_TRANSFER_EXTERNAL_SET_TITLE_AMOUNT',
  BROKER_TRANSFER_EXTERNAL_SET_TYPE_AGENCIE: 'BROKER_TRANSFER_EXTERNAL_SET_TYPE_AGENCIE',
  BROKER_TRANSFER_EXTERNAL_SET_TYPE_BOB: 'BROKER_TRANSFER_EXTERNAL_SET_TYPE_BOB',
  BROKER_TRANSFER_EXTERNAL_SET_VISIBLE_STEP: 'BROKER_TRANSFER_EXTERNAL_SET_VISIBLE_STEP',
  BROKER_TRANSFER_EXTERNAL_SUCCESS: 'BROKER_TRANSFER_EXTERNAL_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_VALIDATE_STEP: 'BROKER_TRANSFER_EXTERNAL_VALIDATE_STEP',
  BROKER_TRANSFER_EXTERNAL_VALUES_FAILURE: 'BROKER_TRANSFER_EXTERNAL_VALUES_FAILURE',
  BROKER_TRANSFER_EXTERNAL_VALUES_SUCCESS: 'BROKER_TRANSFER_EXTERNAL_VALUES_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_FAILURE:
    'BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_FAILURE',
  BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_SUCCESS:
    'BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_SUCCESS',
  BROKER_TRANSFER_EXTERNAL_WILL_CANCEL: 'BROKER_TRANSFER_EXTERNAL_WILL_CANCEL',
  BROKER_TRANSFER_OF_DERIVATIVES_CANCEL: 'BROKER_TRANSFER_OF_DERIVATIVES_CANCEL',
  BROKER_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT: 'BROKER_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT',
  BROKER_TRANSFER_OF_DERIVATIVES_FETCHING: 'BROKER_TRANSFER_OF_DERIVATIVES_FETCHING',
  BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_ERROR: 'BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_ERROR',
  BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_SUCCESS:
    'BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_SUCCESS',
  BROKER_TRANSFER_OF_DERIVATIVES_MODIFY: 'BROKER_TRANSFER_OF_DERIVATIVES_MODIFY',
  BROKER_TRANSFER_OF_DERIVATIVES_MODIFY_CHANGE_CONCEPT:
    'BROKER_TRANSFER_OF_DERIVATIVES_MODIFY_CHANGE_CONCEPT',
  BROKER_TRANSFER_OF_DERIVATIVES_REMOVE: 'BROKER_TRANSFER_OF_DERIVATIVES_REMOVE',
  BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_ERROR: 'BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_ERROR',
  BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_SUCCESS: 'BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_SUCCESS',
  BROKER_TRANSFER_OF_DERIVATIVES_SAVE: 'BROKER_TRANSFER_OF_DERIVATIVES_SAVE',
  BROKER_TRANSFER_OF_DERIVATIVES_SAVE_ERROR: 'BROKER_TRANSFER_OF_DERIVATIVES_SAVE_ERROR',
  BROKER_TRANSFER_OF_DERIVATIVES_SAVE_SUCCESS: 'BROKER_TRANSFER_OF_DERIVATIVES_SAVE_SUCCESS',
  BROKER_TRANSFER_OF_DERIVATIVES_SET_ORIGIN_CONTRACT:
    'BROKER_TRANSFER_OF_DERIVATIVES_SET_ORIGIN_CONTRACT',
  BROKER_TRANSFER_OF_DERIVATIVES_TOGGLE_DELETE_MODAL:
    'BROKER_TRANSFER_OF_DERIVATIVES_TOGGLE_DELETE_MODAL',
  BROKER_TRANSFER_OF_DERIVATIVES_VALIDATE: 'BROKER_TRANSFER_OF_DERIVATIVES_VALIDATE',
  BROKER_TRANSFER_PENSION_PLAN_EXTERNAL_ASSOCIATE_ACCOUNT:
    'BROKER_TRANSFER_PENSION_PLAN_EXTERNAL_ASSOCIATE_ACCOUNT',
  BROKER_TRANSFER_PENSION_PLAN_INTERNAL_ASSOCIATE_ACCOUNT:
    'BROKER_TRANSFER_PENSION_PLAN_INTERNAL_ASSOCIATE_ACCOUNT',
  BROKER_TRANSFER_VALUES_FAILURE: 'BROKER_TRANSFER_VALUES_FAILURE',
  BROKER_TRANSFER_VALUES_REQUEST: 'BROKER_TRANSFER_VALUES_REQUEST',
  BROKER_TRANSFER_VALUES_RESET: 'BROKER_TRANSFER_VALUES_RESET',
  BROKER_TRANSFER_VALUES_RESET_CONTRACTS_ERRORS: 'BROKER_TRANSFER_VALUES_RESET_CONTRACTS_ERRORS',
  BROKER_TRANSFER_VALUES_SET_ACCOUNTS_FROM_OPTIONS:
    'BROKER_TRANSFER_VALUES_SET_ACCOUNTS_FROM_OPTIONS',
  BROKER_TRANSFER_VALUES_SET_ACCOUNTS_TO_OPTIONS: 'BROKER_TRANSFER_VALUES_SET_ACCOUNTS_TO_OPTIONS',
  BROKER_TRANSFER_VALUES_SET_ACCOUNT_FROM: 'BROKER_TRANSFER_VALUES_SET_ACCOUNT_FROM',
  BROKER_TRANSFER_VALUES_SET_ACCOUNT_TO: 'BROKER_TRANSFER_VALUES_SET_ACCOUNT_TO',
  BROKER_TRANSFER_VALUES_SET_TITLES: 'BROKER_TRANSFER_VALUES_SET_TITLES',
  BROKER_TRANSFER_VALUES_SET_TYPE: 'BROKER_TRANSFER_VALUES_SET_TYPE',
  BROKER_TRANSFER_VALUES_SET_VISIBLE_STEP: 'BROKER_TRANSFER_VALUES_SET_VISIBLE_STEP',
  BROKER_TRANSFER_VALUES_SUCCESS: 'BROKER_TRANSFER_VALUES_SUCCESS',
  BROKER_TRANSFER_VALUES_TOGGLE_CHECK: 'BROKER_TRANSFER_VALUES_TOGGLE_CHECK',
  BROKER_TRANSFER_VALUES_VALIDATE_STEP: 'BROKER_TRANSFER_VALUES_VALIDATE_STEP',
  BROKER_TRANSFER_VALUES_WILL_CANCEL: 'BROKER_TRANSFER_VALUES_WILL_CANCEL',
  BROKER_VALIDATE_CHECK: 'BROKER_VALIDATE_CHECK',
  BROKER_VALUE_MOVEMENTS_CLEAR_FILTER: 'BROKER_VALUE_MOVEMENTS_CLEAR_FILTER',
  BROKER_VALUE_MOVEMENTS_FAILURE: 'BROKER_VALUE_MOVEMENTS_FAILURE',
  BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_REQUEST:
    'BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_REQUEST',
  BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_SUCCESS:
    'BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_SUCCESS',
  BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_FAILURE:
    'BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_FAILURE',
  BROKER_VALUE_MOVEMENTS_REQUEST: 'BROKER_VALUE_MOVEMENTS_REQUEST',
  BROKER_VALUE_MOVEMENTS_SET_FILTER: 'BROKER_VALUE_MOVEMENTS_SET_FILTER',
  BROKER_VALUE_MOVEMENTS_SUCCESS: 'BROKER_VALUE_MOVEMENTS_SUCCESS',
  BROKER_WARRANTS_ADVANCED_BUY_APPROX_IMPORT_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_APPROX_IMPORT_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_FAILURE: 'BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_FAILURE',
  BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_REQUEST: 'BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_REQUEST',
  BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_SUCCESS: 'BROKER_WARRANTS_ADVANCED_BUY_CONFIRM_SUCCESS',
  BROKER_WARRANTS_ADVANCED_BUY_FINISH: 'BROKER_WARRANTS_ADVANCED_BUY_FINISH',
  BROKER_WARRANTS_ADVANCED_BUY_FOR_CHANGED: 'BROKER_WARRANTS_ADVANCED_BUY_FOR_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED:
    'BROKER_WARRANTS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED',
  BROKER_WARRANTS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_LIMITED_VALUE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_MIFID_CLAUSE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_MIFID_CLAUSE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERMS_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERMS_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_RESET: 'BROKER_WARRANTS_ADVANCED_BUY_RESET',
  BROKER_WARRANTS_ADVANCED_BUY_SET_ORDER_ID: 'BROKER_WARRANTS_ADVANCED_BUY_SET_ORDER_ID',
  BROKER_WARRANTS_ADVANCED_BUY_SET_VISIBLE_STEP: 'BROKER_WARRANTS_ADVANCED_BUY_SET_VISIBLE_STEP',
  BROKER_WARRANTS_ADVANCED_BUY_SIGNATURE_SUCCESS: 'BROKER_WARRANTS_ADVANCED_BUY_SIGNATURE_SUCCESS',
  BROKER_WARRANTS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
    'BROKER_WARRANTS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED',
  BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_FAILURE: 'BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_FAILURE',
  BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_REQUEST: 'BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_REQUEST',
  BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_SUCCESS: 'BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_SUCCESS',
  BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_UI_STEP: 'BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_UI_STEP',
  BROKER_WARRANTS_ADVANCED_BUY_WILL_CANCEL: 'BROKER_WARRANTS_ADVANCED_BUY_WILL_CANCEL',
  BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_FAILURE: 'BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_FAILURE',
  BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_REQUEST: 'BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_REQUEST',
  BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_SUCCESS: 'BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_SUCCESS',
  BROKER_WARRANTS_ADVANCED_SELL_SET_ORDER_ID: 'BROKER_WARRANTS_ADVANCED_SELL_SET_ORDER_ID',
  BROKER_WARRANTS_ADVANCED_SELL_SIGNATURE_SUCCESS:
    'BROKER_WARRANTS_ADVANCED_SELL_SIGNATURE_SUCCESS',
  BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_FAILURE: 'BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_FAILURE',
  BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_REQUEST: 'BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_REQUEST',
  BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_SUCCESS: 'BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_SUCCESS',
  BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_UI_STEP: 'BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_UI_STEP',
  BROKER_WARRANTS_BUY_BUYFOR_OPTION_CHANGED: 'BROKER_WARRANTS_BUY_BUYFOR_OPTION_CHANGED',
  BROKER_WARRANTS_BUY_BUYFOR_VALUE_CHANGED: 'BROKER_WARRANTS_BUY_BUYFOR_VALUE_CHANGED',
  BROKER_WARRANTS_BUY_BUYFOR_VALUE_RESET: 'BROKER_WARRANTS_BUY_BUYFOR_VALUE_RESET',
  BROKER_WARRANTS_BUY_CONFIRM_FAILURE: 'BROKER_WARRANTS_BUY_CONFIRM_FAILURE',
  BROKER_WARRANTS_BUY_CONFIRM_REQUEST: 'BROKER_WARRANTS_BUY_CONFIRM_REQUEST',
  BROKER_WARRANTS_BUY_CONFIRM_SUCCESS: 'BROKER_WARRANTS_BUY_CONFIRM_SUCCESS',
  BROKER_WARRANTS_BUY_DFI_DOCUMENT_FETCH_SUCCESS: 'BROKER_WARRANTS_BUY_DFI_DOCUMENT_FETCH_SUCCESS',
  BROKER_WARRANTS_BUY_DOCUMENTS_READ: 'BROKER_WARRANTS_BUY_DOCUMENTS_READ',
  BROKER_WARRANTS_BUY_FINISH: 'BROKER_WARRANTS_BUY_FINISH',
  BROKER_WARRANTS_BUY_LEGAL_MAIL_RESET: 'BROKER_WARRANTS_BUY_LEGAL_MAIL_RESET',
  BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_FAILURE: 'BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_FAILURE',
  BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_REQUEST: 'BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_REQUEST',
  BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_SUCCESS: 'BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_SUCCESS',
  BROKER_WARRANTS_BUY_LIMIT_PRICE_CHANGED: 'BROKER_WARRANTS_BUY_LIMIT_PRICE_CHANGED',
  BROKER_WARRANTS_BUY_RESET: 'BROKER_WARRANTS_BUY_RESET',
  BROKER_WARRANTS_BUY_SEND_EMAIL_ERROR: 'BROKER_WARRANTS_BUY_SEND_EMAIL_ERROR',
  BROKER_WARRANTS_BUY_SEND_EMAIL_REQUEST: 'BROKER_WARRANTS_BUY_SEND_EMAIL_REQUEST',
  BROKER_WARRANTS_BUY_SEND_EMAIL_SUCCESS: 'BROKER_WARRANTS_BUY_SEND_EMAIL_SUCCESS',
  BROKER_WARRANTS_BUY_SET_VISIBLE_STEP: 'BROKER_WARRANTS_BUY_SET_VISIBLE_STEP',
  BROKER_WARRANTS_BUY_SIGNATURE_SUCCESS: 'BROKER_WARRANTS_BUY_SIGNATURE_SUCCESS',
  BROKER_WARRANTS_BUY_STOCK_CONTRACT_CHANGED: 'BROKER_WARRANTS_BUY_STOCK_CONTRACT_CHANGED',
  BROKER_WARRANTS_BUY_VALIDATE_FAILURE: 'BROKER_WARRANTS_BUY_VALIDATE_FAILURE',
  BROKER_WARRANTS_BUY_VALIDATE_REQUEST: 'BROKER_WARRANTS_BUY_VALIDATE_REQUEST',
  BROKER_WARRANTS_BUY_VALIDATE_STEP: 'BROKER_WARRANTS_BUY_VALIDATE_STEP',
  BROKER_WARRANTS_BUY_VALIDATE_SUCCESS: 'BROKER_WARRANTS_BUY_VALIDATE_SUCCESS',
  BROKER_WARRANTS_BUY_WILL_CANCEL: 'BROKER_WARRANTS_BUY_WILL_CANCEL',
  BROKER_WARRANTS_BUY_FETCHING_DFI_SUCCESS: 'BROKER_WARRANTS_BUY_FETCHING_DFI_SUCCESS',
  BROKER_WARRANTS_BUY_FETCHING_DFI_FAILED: 'BROKER_WARRANTS_BUY_FETCHING_DFI_FAILED',
  BROKER_WARRANT_EXANTCOST_FECHING: 'BROKER_WARRANT_EXANTCOST_FECHING',
  BROKER_WARRANT_EXANTCOST_SUCESS: 'BROKER_WARRANT_EXANTCOST_SUCESS',
  BROKER_WARRANT_EXANTCOST_ERROR: 'BROKER_WARRANT_EXANTCOST_ERROR',
  BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH: 'BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH',
  BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_ERROR:
    'BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_ERROR',
  BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_SUCCESS:
    'BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_SUCCESS',
  BROKER_WARRANTS_SELL_CONFIRM_FAILURE: 'BROKER_WARRANTS_SELL_CONFIRM_FAILURE',
  BROKER_WARRANTS_SELL_CONFIRM_REQUEST: 'BROKER_WARRANTS_SELL_CONFIRM_REQUEST',
  BROKER_WARRANTS_SELL_CONFIRM_SUCCESS: 'BROKER_WARRANTS_SELL_CONFIRM_SUCCESS',
  BROKER_WARRANTS_SELL_DOCUMENTS_READ: 'BROKER_WARRANTS_SELL_DOCUMENTS_READ',
  BROKER_WARRANTS_SELL_EX_ANTE_FETCHING: 'BROKER_WARRANTS_SELL_EX_ANTE_FETCHING',
  BROKER_WARRANTS_SELL_EX_ANTE_SUCCESS: 'BROKER_WARRANTS_SELL_EX_ANTE_SUCCESS',
  BROKER_WARRANTS_SELL_EX_ANTE_FAILURE: 'BROKER_WARRANTS_SELL_EX_ANTE_FAILURE',
  BROKER_WARRANTS_SELL_FINISH: 'BROKER_WARRANTS_SELL_FINISH',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_ERROR: 'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_ERROR',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_FETCH: 'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_FETCH',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_SUCCESS:
    'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_SUCCESS',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_ERROR:
    'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_ERROR',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_REQUEST:
    'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_REQUEST',
  BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_SUCCESS:
    'BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_SUCCESS',
  BROKER_WARRANTS_SELL_LEGAL_MAIL_RESET: 'BROKER_WARRANTS_SELL_LEGAL_MAIL_RESET',
  BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_FAILURE: 'BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_FAILURE',
  BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_REQUEST: 'BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_REQUEST',
  BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_SUCCESS: 'BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_SUCCESS',
  BROKER_WARRANTS_SELL_LIMIT_PRICE_CHANGED: 'BROKER_WARRANTS_SELL_LIMIT_PRICE_CHANGED',
  BROKER_WARRANTS_SELL_RESET: 'BROKER_WARRANTS_SELL_RESET',
  BROKER_WARRANTS_SELL_SET_SELL_PRICE: 'BROKER_WARRANTS_SELL_SET_SELL_PRICE',
  BROKER_WARRANTS_SELL_SET_VISIBLE_STEP: 'BROKER_WARRANTS_SELL_SET_VISIBLE_STEP',
  BROKER_WARRANTS_SELL_SIGNATURE_SUCCESS: 'BROKER_WARRANTS_SELL_SIGNATURE_SUCCESS',
  BROKER_WARRANTS_SELL_STOCK_CONTRACT_CHANGED: 'BROKER_WARRANTS_SELL_STOCK_CONTRACT_CHANGED',
  BROKER_WARRANTS_SELL_SUBSCRIPTION_CHANGE_READCHECKED:
    'BROKER_WARRANTS_SELL_SUBSCRIPTION_CHANGE_READCHECKED',
  BROKER_WARRANTS_SELL_TITLE_QUANTITY_CHANGED: 'BROKER_WARRANTS_SELL_TITLE_QUANTITY_CHANGED',
  BROKER_WARRANTS_SELL_VALIDATE_CHECK: 'BROKER_WARRANTS_SELL_VALIDATE_CHECK',
  BROKER_WARRANTS_SELL_VALIDATE_FAILURE: 'BROKER_WARRANTS_SELL_VALIDATE_FAILURE',
  BROKER_WARRANTS_SELL_VALIDATE_REQUEST: 'BROKER_WARRANTS_SELL_VALIDATE_REQUEST',
  BROKER_WARRANTS_SELL_VALIDATE_STEP: 'BROKER_WARRANTS_SELL_VALIDATE_STEP',
  BROKER_WARRANTS_SELL_VALIDATE_SUCCESS: 'BROKER_WARRANTS_SELL_VALIDATE_SUCCESS',
  BROKER_WARRANTS_SELL_VALIDITY_DATE_CHANGED: 'BROKER_WARRANTS_SELL_VALIDITY_DATE_CHANGED',
  BROKER_WARRANTS_SELL_WILL_CANCEL: 'BROKER_WARRANTS_SELL_WILL_CANCEL',
  BROKER_WARRANTS_SUBSCRIPTION_CHANGE_READCHECKED:
    'BROKER_WARRANTS_SUBSCRIPTION_CHANGE_READCHECKED',
  BROKER_WARRANTS_SUBSCRIPTION_CHANGE_WARNING_OPERATION_CHECKED:
    'BROKER_WARRANTS_SUBSCRIPTION_CHANGE_WARNING_OPERATION_CHECKED',
  BROKER_WATCHLIST_ADDED: 'BROKER_WATCHLIST_ADDED',
  BROKER_WATCHLIST_CREATE_NEW_LIST: 'BROKER_WATCHLIST_CREATE_NEW_LIST',
  BROKER_WATCHLIST_EDIT_MODEL: 'BROKER_WATCHLIST_EDIT_MODEL',
  BROKER_WATCHLIST_ID_UPDATE: 'BROKER_WATCHLIST_ID_UPDATE',
  BROKER_WATCHLIST_MODAL_TOGGLE: 'BROKER_WATCHLIST_MODAL_TOGGLE',
  BROKER_WATCHLIST_RESET: 'BROKER_WATCHLIST_RESET',
  BROKER_WATCHLIST_SAVE_PRODUCT_DETAILS: 'BROKER_WATCHLIST_SAVE_PRODUCT_DETAILS',
  BROKER_WATCHLIST_SELECTED_LIST: 'BROKER_WATCHLIST_SELECTED_LIST',
  BROKER_WATCHLIST_SELECTED_LIST_FOR_PARAM: 'BROKER_WATCHLIST_SELECTED_LIST_FOR_PARAM',
  BROKER_WATCHLIST_SORT: 'BROKER_WATCHLIST_SORT',
  BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_ERROR: 'BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_ERROR',
  BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_REQUEST:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_REQUEST',
  BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CANCEL_MIFID:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CANCEL_MIFID',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CONTINUE_FROM_MIFID:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CONTINUE_FROM_MIFID',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_DOCUMENTS_READ:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_DOCUMENTS_READ',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_FAILURE: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_FAILURE',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_FINISH_MIFID:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_FINISH_MIFID',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_FUNDS_IN_PROGRESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_FUNDS_IN_PROGRESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_FUNDS_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_FUNDS_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_IN_PROGRESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_IN_PROGRESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_IN_PROGRESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_IN_PROGRESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_HIDE_HELP: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_HIDE_HELP',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_IN_PROGRESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_IN_PROGRESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_IN_PROGRESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_IN_PROGRESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_SUCCESS:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_SUCCESS',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_AMOUNT_DIRTY:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_AMOUNT_DIRTY',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_FUND:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_FUND',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SELECT_FUND:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SELECT_FUND',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_ACCOUNT:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_ACCOUNT',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_AMOUNT:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_AMOUNT',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_CHECKBOX:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_CHECKBOX',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_DESTINATION_FUND:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_DESTINATION_FUND',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_FUND: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_FUND',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_INSTITUTION_NAME:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_INSTITUTION_NAME',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_HAS_NIF:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_HAS_NIF',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_NIF: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_NIF',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_TYPE: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_TYPE',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_SUBTYPE:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_SUBTYPE',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_VISIBLE_STEP:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_VISIBLE_STEP',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SHOW_HELP: 'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SHOW_HELP',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_START_MIFID:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_START_MIFID',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
    'BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_WILL_CANCEL: 'BROKER_WIZARD_REIMBURSE_FUND_WILL_CANCEL',
  BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_WILL_LEAVE: 'BROKER_WIZARD_REIMBURSE_FUND_WILL_LEAVE',
  BROKER_WIZARD_INTERNAL_TRANSFER_CHECKBOX: 'BROKER_WIZARD_INTERNAL_TRANSFER_CHECKBOX',
  BROKER_WIZARD_INTERNAL_TRANSFER_FAILURE: 'BROKER_WIZARD_INTERNAL_TRANSFER_FAILURE',
  BROKER_WIZARD_INTERNAL_TRANSFER_NEW_FUND_SELECTED:
    'BROKER_WIZARD_INTERNAL_TRANSFER_NEW_FUND_SELECTED',
  BROKER_WIZARD_INTERNAL_TRANSFER_POST_SUCCESS: 'BROKER_WIZARD_INTERNAL_TRANSFER_POST_SUCCESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FETCHING:
    'BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FETCHING',
  BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FAILURE:
    'BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FAILURE',
  BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_SUCCESS:
    'BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_SUCCESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_PROGRESS: 'BROKER_WIZARD_INTERNAL_TRANSFER_PROGRESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_RESET: 'BROKER_WIZARD_INTERNAL_TRANSFER_RESET',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_AMOUNT: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_AMOUNT',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_DESTINATION_FUND_ID:
    'BROKER_WIZARD_INTERNAL_TRANSFER_SET_DESTINATION_FUND_ID',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_FUND_TYPE: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_FUND_TYPE',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_ORIGIN_FUND_ID:
    'BROKER_WIZARD_INTERNAL_TRANSFER_SET_ORIGIN_FUND_ID',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_TYPE: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_TYPE',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_VISIBLE_STEP:
    'BROKER_WIZARD_INTERNAL_TRANSFER_SET_VISIBLE_STEP',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_ERROR:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_ERROR',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_HIDE_MODAL:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_HIDE_MODAL',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_LOADING:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_LOADING',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SHOW_MODAL:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SHOW_MODAL',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SUCCESS:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SUCCESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_VALIDATE:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_VALIDATE',
  BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_ERROR:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_ERROR',
  BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_REQUEST:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_REQUEST',
  BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_SUCCESS:
    'BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_SUCCESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_TOGGLE_DISCLAIMER:
    'BROKER_WIZARD_INTERNAL_TRANSFER_TOGGLE_DISCLAIMER',
  BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_FAILURE:
    'BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_FAILURE',
  BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_REQUEST:
    'BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_REQUEST',
  BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_SUCCESS:
    'BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_SUCCESS',
  BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_STEP: 'BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_STEP',
  BROKER_WIZARD_INTERNAL_TRANSFER_WILL_CANCEL: 'BROKER_WIZARD_INTERNAL_TRANSFER_WILL_CANCEL',
  BROKER_WIZARD_INTERNAL_TRANSFER_WILL_LEAVE: 'BROKER_WIZARD_INTERNAL_TRANSFER_WILL_LEAVE',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_INITIAL_ORIGIN_CONTRIBUTION_STEP: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_INITIAL_ORIGIN_CONTRIBUTION_STEP',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_ACCOUNT_NUMBER: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_ACCOUNT_NUMBER',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_AMOUNT: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_AMOUNT',
  BROKER_WIZARD_INTERNAL_TRANSFER_CHANGE_CONTRIBUTION_VALIDATE_STEP: 'BROKER_WIZARD_INTERNAL_TRANSFER_CHANGE_CONTRIBUTION_VALIDATE_STEP',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATE_VALUE: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATE_VALUE',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATEPICKER_ERROR: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATEPICKER_ERROR',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_PERIODICITY: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_PERIODICITY',
  BROKER_WIZARD_INTERNAL_TRANSFER_SET_PREVIOUS_CONTRIBUTION_DATA: 'BROKER_WIZARD_INTERNAL_TRANSFER_SET_PREVIOUS_CONTRIBUTION_DATA',
  BROKER_WIZARD_INTERNAL_TRANSFER_SKIP_CONTRIBUTION_STEP: 'BROKER_WIZARD_INTERNAL_TRANSFER_SKIP_CONTRIBUTION_STEP',
  BROKER_WIZARD_INTERNAL_TRANSFER_REMOVE_ORIGIN_CONTRIBUTION_STEP: 'BROKER_WIZARD_INTERNAL_TRANSFER_REMOVE_ORIGIN_CONTRIBUTION_STEP',
  BROKER_WIZARD_REIMBURSE_FUND_POST_SUCCESS: 'BROKER_WIZARD_REIMBURSE_FUND_POST_SUCCESS',
  BROKER_WIZARD_REIMBURSE_FUND_RESET: 'BROKER_WIZARD_REIMBURSE_FUND_RESET',
  BROKER_WIZARD_REIMBURSE_FUND_SET_AMOUNT: 'BROKER_WIZARD_REIMBURSE_FUND_SET_AMOUNT',
  BROKER_WIZARD_REIMBURSE_FUND_SET_TYPE: 'BROKER_WIZARD_REIMBURSE_FUND_SET_TYPE',
  BROKER_WIZARD_REIMBURSE_FUND_SET_VISIBLE_STEP: 'BROKER_WIZARD_REIMBURSE_FUND_SET_VISIBLE_STEP',
  BROKER_WIZARD_REIMBURSE_FUND_VALIDATE_STEP: 'BROKER_WIZARD_REIMBURSE_FUND_VALIDATE_STEP',
  BROKER_WIZARD_REIMBURSE_FUND_WILL_CANCEL: 'BROKER_WIZARD_REIMBURSE_FUND_WILL_CANCEL',
  BROKER_WIZARD_REIMBURSE_FUND_WILL_LEAVE: 'BROKER_WIZARD_REIMBURSE_FUND_WILL_LEAVE',
  BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_REQUEST:
    'BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_REQUEST',
  BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_SUCCESS:
    'BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_SUCCESS',
  BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_ERROR:
    'BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_ERROR',
  BROKER_WIZARD_SUBSCRIPTION_ADD_INTERVENER: 'BROKER_WIZARD_SUBSCRIPTION_ADD_INTERVENER',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_AUTHORIZATION:
    'BROKER_WIZARD_SUBSCRIPTION_CHANGE_AUTHORIZATION',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_CONTRACT: 'BROKER_WIZARD_SUBSCRIPTION_CHANGE_CONTRACT',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_READCHECKED: 'BROKER_WIZARD_SUBSCRIPTION_CHANGE_READCHECKED',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_SUCCESS: 'BROKER_WIZARD_SUBSCRIPTION_CHANGE_SUCCESS',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_VALIDATE_STEP:
    'BROKER_WIZARD_SUBSCRIPTION_CHANGE_VALIDATE_STEP',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_CANCEL: 'BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_CANCEL',
  BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_LEAVE: 'BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_LEAVE',
  BROKER_WIZARD_SUBSCRIPTION_CLEAR_FETCHED_PRE: 'BROKER_WIZARD_SUBSCRIPTION_CLEAR_FETCHED_PRE',
  BROKER_WIZARD_SUBSCRIPTION_CLEAR_FUND_CONTRACT: 'BROKER_WIZARD_SUBSCRIPTION_CLEAR_FUND_CONTRACT',
  BROKER_WIZARD_SUBSCRIPTION_CREATE_NEW_CONTRACT: 'BROKER_WIZARD_SUBSCRIPTION_CREATE_NEW_CONTRACT',
  BROKER_WIZARD_SUBSCRIPTION_IS_NOT_SUBSCRIPTION: 'BROKER_WIZARD_SUBSCRIPTION_IS_NOT_SUBSCRIPTION',
  BROKER_WIZARD_SUBSCRIPTION_IS_SUBSCRIPTION: 'BROKER_WIZARD_SUBSCRIPTION_IS_SUBSCRIPTION',
  BROKER_WIZARD_SUBSCRIPTION_REMOVE_INTERVENER: 'BROKER_WIZARD_SUBSCRIPTION_REMOVE_INTERVENER',
  BROKER_WIZARD_SUBSCRIPTION_RESET: 'BROKER_WIZARD_SUBSCRIPTION_RESET',
  BROKER_WIZARD_SUBSCRIPTION_RESTORE_DEFAULT_INTERVENERS:
    'BROKER_WIZARD_SUBSCRIPTION_RESTORE_DEFAULT_INTERVENERS',
  BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_NAME:
    'BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_NAME',
  BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_TYPE:
    'BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_TYPE',
  BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_NUMBER: 'BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_NUMBER',
  BROKER_WIZARD_SUBSCRIPTION_SET_AMOUNT: 'BROKER_WIZARD_SUBSCRIPTION_SET_AMOUNT',
  BROKER_WIZARD_SUBSCRIPTION_SET_IS_VALID_SCHEMA: 'BROKER_WIZARD_SUBSCRIPTION_SET_IS_VALID_SCHEMA',
  BROKER_WIZARD_SUBSCRIPTION_SET_IS_INVALID_SCHEMA:
    'BROKER_WIZARD_SUBSCRIPTION_SET_IS_INVALID_SCHEMA',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_AMOUNT:
    'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_AMOUNT',
  BROKER_WIZARD_SUBSCRIPTION_SET_CUSTOM_MINIMUM_ADDITIONAL_PURCHASE_AMOUNT: 'BROKER_WIZARD_SUBSCRIPTION_SET_CUSTOM_MINIMUM_ADDITIONAL_PURCHASE_AMOUNT',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_DATE:
    'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_DATE',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_PERIODICITY:
    'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_PERIODICITY',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_VISIBLE_STEP:
    'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_VISIBLE_STEP',
  BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
    'BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP',

  BROKER_WIZARD_SUBSCRIPTION_SET_VISIBLE_STEP: 'BROKER_WIZARD_SUBSCRIPTION_SET_VISIBLE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_AMOUNT:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_AMOUNT',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_DESTINATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_DESTINATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_FISCALIZATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_FISCALIZATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_TRANSFER_TYPE_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_CHANGE_TRANSFER_TYPE_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_DISCLAIMER_REQUIRED:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_DISCLAIMER_REQUIRED',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_DISCLAIMER_TOGGLE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_DISCLAIMER_TOGGLE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_ATTACH_FILE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_ATTACH_FILE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_AMOUNT:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_AMOUNT',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_DESTINATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_DESTINATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_FILE_DESCRIPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_FILE_DESCRIPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_FISCALIZATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_FISCALIZATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_TRANSFER_TYPE_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_CHANGE_TRANSFER_TYPE_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_DESTINATION_PLANS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_DESTINATION_PLANS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_DISCLAIMER_TOGGLE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_DISCLAIMER_TOGGLE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_ALL_PLANS_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_DATA_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_DATA_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_NOT_FOUND:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_NOT_FOUND',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_PDF_SERVICE_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_REMOVE_FILE_ATTACHED:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_REMOVE_FILE_ATTACHED',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_RESET:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_RESET',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECTED_IN_SEARCHER_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECTED_IN_SEARCHER_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECT_DESTINATION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECT_DESTINATION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECT_ORIGIN:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECT_ORIGIN',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_AMOUNT_RANGE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_AMOUNT_RANGE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_VISIBLE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_VISIBLE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_VALIDATE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_VALIDATE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_WILL_CANCEL:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_WILL_CANCEL',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_WILL_LEAVE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_WILL_LEAVE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_IN_SEARCHER_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_IN_SEARCHER_FAILURE', //VER SI ES NECESARIO
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_DATA_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_DATA_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_NOT_FOUND:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_NOT_FOUND',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_AMOUNT:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_AMOUNT',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_DESTINATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_DESTINATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_FISCALIZATION_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_FISCALIZATION_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_TRANSFER_TYPE_OPTION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_TRANSFER_TYPE_OPTION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_DISCLAIMER_TOGGLE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_DISCLAIMER_TOGGLE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_DATA_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_DATA_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_NOT_FOUND:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_NOT_FOUND',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_RESET:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_RESET',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECTED_IN_SEARCHER_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECTED_IN_SEARCHER_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_DESTINATION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_DESTINATION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_ORIGIN:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_ORIGIN',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_AMOUNT_RANGE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_AMOUNT_RANGE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_VISIBLE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_VISIBLE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_UPDATE_DESTINATION_PLANS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_UPDATE_DESTINATION_PLANS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_CANCEL:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_CANCEL',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_LEAVE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_LEAVE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_FAILURE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_FAILURE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_REQUEST:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_REQUEST',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_PDF_SERVICE_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_PREVIOUS_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_PREVIOUS_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_RESET: 'BROKER_WIZARD_TRANSFER_PENSION_PLANS_RESET',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECTED_IN_SEARCHER_SUCCESS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECTED_IN_SEARCHER_SUCCESS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECT_DESTINATION:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECT_DESTINATION',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECT_ORIGIN:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_SELECT_ORIGIN',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_SET_AMOUNT_RANGE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_SET_AMOUNT_RANGE',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_SET_VISIBLE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_SET_VISIBLE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_UPDATE_DESTINATION_PLANS:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_UPDATE_DESTINATION_PLANS',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_VALIDATE_STEP:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_VALIDATE_STEP',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_WILL_CANCEL:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_WILL_CANCEL',
  BROKER_WIZARD_TRANSFER_PENSION_PLANS_WILL_LEAVE:
    'BROKER_WIZARD_TRANSFER_PENSION_PLANS_WILL_LEAVE',
  CALLMEBACK_FAILURE: 'CALLMEBACK_FAILURE',
  CALLMEBACK_GET_CALL_TIME: 'CALLMEBACK_GET_CALL_TIME',
  CALLMEBACK_IN_PROGRESS: 'CALLMEBACK_IN_PROGRESS',
  CALLMEBACK_RESET: 'CALLMEBACK_RESET',
  CALLMEBACK_SUCCESS: 'CALLMEBACK_SUCCESS',
  CANCEL_POST_PAYMENT_CANCEL: 'CANCEL_POST_PAYMENT_CANCEL',
  CANCEL_POST_PAYMENT_FAILURE: 'CANCEL_POST_PAYMENT_FAILURE',
  CANCEL_POST_PAYMENT_REQUEST: 'CANCEL_POST_PAYMENT_REQUEST',
  CANCEL_POST_PAYMENT_RESET: 'CANCEL_POST_PAYMENT_RESET',
  CANCEL_POST_PAYMENT_SET_VISIBLE_STEP: 'CANCEL_POST_PAYMENT_SET_VISIBLE_STEP',
  CANCEL_POST_PAYMENT_STEP_VALIDATE: 'CANCEL_POST_PAYMENT_STEP_VALIDATE',
  CANCEL_POST_PAYMENT_SUCCESS: 'CANCEL_POST_PAYMENT_SUCCESS',
  CARDS_ADS_SET_LOADING: 'CARDS_ADS_SET_LOADING',
  CARDS_CANCELED_FAILURE: 'CARDS_CANCELED_FAILURE',
  CARDS_CANCELED_REQUEST: 'CARDS_CANCELED_REQUEST',
  CARDS_CANCELED_SET_HOLDER_SUCCESS: 'CARDS_CANCELED_SET_HOLDER_SUCCESS',
  CARDS_CANCELED_SUCCESS: 'CARDS_CANCELED_SUCCESS',
  CARDS_CANCEL_DEFER_PURCHASE_FAILURE: 'CARDS_CANCEL_DEFER_PURCHASE_FAILURE',
  CARDS_CANCEL_DEFER_PURCHASE_INPROGRESS: 'CARDS_CANCEL_DEFER_PURCHASE_INPROGRESS',
  CARDS_CANCEL_DEFER_PURCHASE_SET_VISIBLE_STEP: 'CARDS_CANCEL_DEFER_PURCHASE_SET_VISIBLE_STEP',
  CARDS_CANCEL_DEFER_PURCHASE_SUCCESS: 'CARDS_CANCEL_DEFER_PURCHASE_SUCCESS',
  CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP: 'CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP',
  CARDS_CANCEL_DEFER_PURCHASE_WILL_CANCEL: 'CARDS_CANCEL_DEFER_PURCHASE_WILL_CANCEL',
  CARDS_CANCEL_PAY_LATER_FAILURE: 'CARDS_CANCEL_PAY_LATER_FAILURE',
  CARDS_CANCEL_PAY_LATER_INPROGRESS: 'CARDS_CANCEL_PAY_LATER_INPROGRESS',
  CARDS_CANCEL_PAY_LATER_SET_VISIBLE_STEP: 'CARDS_CANCEL_PAY_LATER_SET_VISIBLE_STEP',
  CARDS_CANCEL_PAY_LATER_SUCCESS: 'CARDS_CANCEL_PAY_LATER_SUCCESS',
  CARDS_CANCEL_PAY_LATER_VALIDATE_STEP: 'CARDS_CANCEL_PAY_LATER_VALIDATE_STEP',
  CARDS_CANCEL_PAY_LATER_WILL_CANCEL: 'CARDS_CANCEL_PAY_LATER_WILL_CANCEL',
  CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_ERROR: 'CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_ERROR',
  CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_INPROGRESS:
    'CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_INPROGRESS',
  CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_SUCCESS: 'CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_SUCCESS',
  CARDS_PAYMENT_CYCLE_FAILURE: 'CARDS_PAYMENT_CYCLE_FAILURE',
  CARDS_PAYMENT_CYCLE_REQUEST: 'CARDS_PAYMENT_CYCLE_REQUEST',
  CARDS_PAYMENT_CYCLE_SUCCESS: 'CARDS_PAYMENT_CYCLE_SUCCESS',
  CARDS_PIN_RESET: 'CARDS_PIN_RESET',
  CARDS_TRANSACTIONS_CHANGE_CATEGORY: 'CARDS_TRANSACTIONS_CHANGE_CATEGORY',
  CARDS_TRANSACTIONS_OUTDATED: 'CARDS_TRANSACTIONS_OUTDATED',
  CARDS_TRANSACTIONS_SET_FILTERS: 'CARDS_TRANSACTIONS_SET_FILTERS',
  CARD_BENEFITS_SET_BENEFIT_LIST: 'CARD_BENEFITS_SET_BENEFIT_LIST',
  CARD_BLOCK_AND_REPLACE_SUCCESS: 'CARD_BLOCK_AND_REPLACE_SUCCESS',
  CARD_BLOCK_COMMENTS: 'CARD_BLOCK_COMMENTS',
  CARD_BLOCK_FAILURE: 'CARD_BLOCK_FAILURE',
  CARD_BLOCK_MOTIVE: 'CARD_BLOCK_MOTIVE',
  CARD_BLOCK_REQUEST: 'CARD_BLOCK_REQUEST',
  CARD_BLOCK_REQUEST_CARD_REPLACE: 'CARD_BLOCK_REQUEST_CARD_REPLACE',
  CARD_BLOCK_RESET: 'CARD_BLOCK_RESET',
  CARD_BLOCK_RESET_STEP: 'CARD_BLOCK_RESET_STEP',
  CARD_BLOCK_SET_VISIBLE_STEP: 'CARD_BLOCK_SET_VISIBLE_STEP',
  CARD_BLOCK_SUCCESS: 'CARD_BLOCK_SUCCESS',
  CARD_BLOCK_VALIDATE_STEP: 'CARD_BLOCK_VALIDATE_STEP',
  CARD_BLOCK_WILL_CANCEL: 'CARD_BLOCK_WILL_CANCEL',
  CARD_CHANGE_PAYMENT_METHOD_CLEAR: 'CARD_CHANGE_PAYMENT_METHOD_CLEAR',
  CARD_CHANGE_PAYMENT_METHOD_FAILURE: 'CARD_CHANGE_PAYMENT_METHOD_FAILURE',
  CARD_CHANGE_PAYMENT_METHOD_INSTALLMENTS: 'CARD_CHANGE_PAYMENT_METHOD_INSTALLMENTS',
  CARD_CHANGE_PAYMENT_METHOD_METHOD: 'CARD_CHANGE_PAYMENT_METHOD_METHOD',
  CARD_CHANGE_PAYMENT_METHOD_REQUEST: 'CARD_CHANGE_PAYMENT_METHOD_REQUEST',
  CARD_CHANGE_PAYMENT_METHOD_RESET_STEP: 'CARD_CHANGE_PAYMENT_METHOD_RESET_STEP',
  CARD_CHANGE_PAYMENT_METHOD_SET_VISIBLE_STEP: 'CARD_CHANGE_PAYMENT_METHOD_SET_VISIBLE_STEP',
  CARD_CHANGE_PAYMENT_METHOD_SET_DISABLED_BUTTON: 'CARD_CHANGE_PAYMENT_METHOD_SET_DISABLED_BUTTON',
  CARD_CHANGE_PAYMENT_METHOD_SUCCESS: 'CARD_CHANGE_PAYMENT_METHOD_SUCCESS',
  CARD_CHANGE_PAYMENT_METHOD_VALIDATE_STEP: 'CARD_CHANGE_PAYMENT_METHOD_VALIDATE_STEP',
  CARD_CHANGE_PAYMENT_METHOD_SET_AGREEMENT_CHECKBOX:
    'CARD_CHANGE_PAYMENT_METHOD_SET_AGREEMENT_CHECKBOX',
  CARD_CHANGE_PAYMENT_METHOD_WILL_CANCEL: 'CARD_CHANGE_PAYMENT_METHOD_WILL_CANCEL',
  CARD_CHANGE_PAYMENT_VALID_PATTERN: 'CARD_CHANGE_PAYMENT_VALID_PATTERN',
  CARD_CHANNELS_GET_FAILED: 'CARD_CHANNELS_GET_FAILED',
  CARD_CHANNELS_GET_REQUESTED: 'CARD_CHANNELS_GET_REQUESTED',
  CARD_CHANNELS_GET_SUCCEEDED: 'CARD_CHANNELS_GET_SUCCEEDED',
  CARD_CHANNELS_SET_FAILED: 'CARD_CHANNELS_SET_FAILED',
  CARD_CHANNELS_SET_REQUESTED: 'CARD_CHANNELS_SET_REQUESTED',
  CARD_CHANNELS_SET_SUCCEEDED: 'CARD_CHANNELS_SET_SUCCEEDED',
  CARD_CHARGE_ACCOUNT: 'CARD_CHARGE_ACCOUNT',
  CARD_CHARGE_AMOUNT: 'CARD_CHARGE_AMOUNT',
  CARD_CHARGE_CLEAR: 'CARD_CHARGE_CLEAR',
  CARD_CHARGE_CONFIRMATION_FAILURE: 'CARD_CHARGE_CONFIRMATION_FAILURE',
  CARD_CHARGE_CONFIRMATION_REQUEST: 'CARD_CHARGE_CONFIRMATION_REQUEST',
  CARD_CHARGE_CONFIRMATION_SUCCESS: 'CARD_CHARGE_CONFIRMATION_SUCCESS',
  CARD_CHARGE_DATE: 'CARD_CHARGE_DATE',
  CARD_CHARGE_EDIT_STEP1: 'CARD_CHARGE_EDIT_STEP1',
  CARD_CHARGE_OPERATION_TYPE: 'CARD_CHARGE_OPERATION_TYPE',
  CARD_CHARGE_RESET_STEP: 'CARD_CHARGE_RESET_STEP',
  CARD_CHARGE_SET_ALL_ACCOUNTS_TO_INACTIVE: 'CARD_CHARGE_SET_ALL_ACCOUNTS_TO_INACTIVE',
  CARD_CHARGE_SET_CARD: 'CARD_CHARGE_SET_CARD',
  CARD_CHARGE_SET_COSTS: 'CARD_CHARGE_SET_COSTS',
  CARD_CHARGE_SET_PERIODICITY_CONCEPT: 'CARD_CHARGE_SET_PERIODICITY_CONCEPT',
  CARD_CHARGE_SET_PERIODICITY_COUNT: 'CARD_CHARGE_SET_PERIODICITY_COUNT',
  CARD_CHARGE_SET_PERIODICITY_DATE: 'CARD_CHARGE_SET_PERIODICITY_DATE',
  CARD_CHARGE_SET_PERIODICITY_ENABLED: 'CARD_CHARGE_SET_PERIODICITY_ENABLED',
  CARD_CHARGE_SET_PERIODICITY_FLAG: 'CARD_CHARGE_SET_PERIODICITY_FLAG',
  CARD_CHARGE_SET_PERIODICITY_RANGE: 'CARD_CHARGE_SET_PERIODICITY_RANGE',
  CARD_CHARGE_SET_PERIODICITY_TYPE: 'CARD_CHARGE_SET_PERIODICITY_TYPE',
  CARD_CHARGE_SET_TREATMENT_NON_WORKABLE_DAY: 'CARD_CHARGE_SET_TREATMENT_NON_WORKABLE_DAY',
  CARD_CHARGE_SET_VISIBLE_STEP: 'CARD_CHARGE_SET_VISIBLE_STEP',
  CARD_CHARGE_TODAY_DATE: 'CARD_CHARGE_TODAY_DATE',
  CARD_CHARGE_VALIDATE_UI_STEP: 'CARD_CHARGE_VALIDATE_UI_STEP',
  CARD_CHARGE_VALIDATION_FAILURE: 'CARD_CHARGE_VALIDATION_FAILURE',
  CARD_CHARGE_VALIDATION_REQUEST: 'CARD_CHARGE_VALIDATION_REQUEST',
  CARD_CHARGE_VALIDATION_SUCCESS: 'CARD_CHARGE_VALIDATION_SUCCESS',
  CARD_CHARGE_VALID_PATTERN: 'CARD_CHARGE_VALID_PATTERN',
  CARD_CHARGE_VIEW: 'CARD_CHARGE_VIEW',
  CARD_CHARGE_WILL_CANCEL: 'CARD_CHARGE_WILL_CANCEL',
  CARD_CONTROL_ON_FAILURE: 'CARD_CONTROL_ON_FAILURE',
  CARD_CONTROL_ON_REQUEST: 'CARD_CONTROL_ON_REQUEST',
  CARD_CONTROL_ON_RESET: 'CARD_CONTROL_ON_RESET',
  CARD_CONTROL_ON_SUCCESS: 'CARD_CONTROL_ON_SUCCESS',
  CARD_COUNTRIES_FILTER_CHANGE: 'CARD_COUNTRIES_FILTER_CHANGE',
  CARD_COUNTRIES_FILTER_RESET: 'CARD_COUNTRIES_FILTER_RESET',
  CARD_COUNTRIES_GET_FAILED: 'CARD_COUNTRIES_GET_FAILED',
  CARD_COUNTRIES_GET_REQUESTED: 'CARD_COUNTRIES_GET_REQUESTED',
  CARD_COUNTRIES_GET_SUCCEEDED: 'CARD_COUNTRIES_GET_SUCCEEDED',
  CARD_COUNTRIES_ONLY_SET_FAILED: 'CARD_COUNTRIES_ONLY_SET_FAILED',
  CARD_COUNTRIES_ONLY_SET_REQUESTED: 'CARD_COUNTRIES_ONLY_SET_REQUESTED',
  CARD_COUNTRIES_ONLY_SET_SUCCEEDED: 'CARD_COUNTRIES_ONLY_SET_SUCCEEDED',
  CARD_COUNTRY_SET_FAILED: 'CARD_COUNTRY_SET_FAILED',
  CARD_COUNTRY_SET_REQUESTED: 'CARD_COUNTRY_SET_REQUESTED',
  CARD_COUNTRY_SET_SUCCEEDED: 'CARD_COUNTRY_SET_SUCCEEDED',
  CARD_CVV_RESET: 'CARD_CVV_RESET',
  CARD_DEPOSIT_MONEY_CLEAR: 'CARD_DEPOSIT_MONEY_CLEAR',
  CARD_DEPOSIT_MONEY_FAILURE: 'CARD_DEPOSIT_MONEY_FAILURE',
  CARD_DEPOSIT_MONEY_REQUEST: 'CARD_DEPOSIT_MONEY_REQUEST',
  CARD_DEPOSIT_MONEY_SET_ACCOUNT: 'CARD_DEPOSIT_MONEY_SET_ACCOUNT',
  CARD_DEPOSIT_MONEY_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'CARD_DEPOSIT_MONEY_SET_ALL_ACCOUNTS_TO_INACTIVE',
  CARD_DEPOSIT_MONEY_SET_AMOUNT: 'CARD_DEPOSIT_MONEY_SET_AMOUNT',
  CARD_DEPOSIT_MONEY_SET_CREDIT_CARD_DATA: 'CARD_DEPOSIT_MONEY_SET_CREDIT_CARD_DATA',
  CARD_DEPOSIT_MONEY_SET_VISIBLE_STEP: 'CARD_DEPOSIT_MONEY_SET_VISIBLE_STEP',
  CARD_DEPOSIT_MONEY_SUCCESS: 'CARD_DEPOSIT_MONEY_SUCCESS',
  CARD_DEPOSIT_MONEY_VALIDATE_STEP: 'CARD_DEPOSIT_MONEY_VALIDATE_STEP',
  CARD_DEPOSIT_MONEY_WILL_CANCEL: 'CARD_DEPOSIT_MONEY_WILL_CANCEL',
  CARD_DOWNLOAD_PDF_FAILURE: 'CARD_DOWNLOAD_PDF_FAILURE',
  CARD_DOWNLOAD_PDF_RESET: 'CARD_DOWNLOAD_PDF_RESET',
  CARD_DOWNLOAD_PDF_SUCCESS: 'CARD_DOWNLOAD_PDF_SUCCESS',
  CARD_GET_LIMITS: 'CARD_GET_LIMITS',
  CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE: 'CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE',
  CARD_GET_PAYMENT_METHOD_INFORMATION_REQUEST: 'CARD_GET_PAYMENT_METHOD_INFORMATION_REQUEST',
  CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS: 'CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS',
  CARD_GET_PAY_LATER_INFORMATION_FAILURE: 'CARD_GET_PAY_LATER_INFORMATION_FAILURE',
  CARD_GET_PAY_LATER_INFORMATION_REQUEST: 'CARD_GET_PAY_LATER_INFORMATION_REQUEST',
  CARD_GET_PAY_LATER_INFORMATION_SUCCESS: 'CARD_GET_PAY_LATER_INFORMATION_SUCCESS',
  CARD_INCREASE_LIMITS_REQUEST: 'CARD_INCREASE_LIMITS_REQUEST',
  CARD_INCREASE_LIMITS_SUCCESS: 'CARD_INCREASE_LIMITS_SUCCESS',
  CARD_INFO_GET_FAILED: 'CARD_INFO_GET_FAILED',
  CARD_INFO_GET_REQUESTED: 'CARD_INFO_GET_REQUESTED',
  CARD_INFO_GET_SUCCEEDED: 'CARD_INFO_GET_SUCCEEDED',
  CARD_MOBILE_RECHARGE_AMOUNT_CHANGED: 'CARD_MOBILE_RECHARGE_AMOUNT_CHANGED',
  CARD_MOBILE_RECHARGE_MOBILE_PHONE_CHANGED: 'CARD_MOBILE_RECHARGE_MOBILE_PHONE_CHANGED',
  CARD_MOBILE_RECHARGE_OPERATOR_CHANGED: 'CARD_MOBILE_RECHARGE_OPERATOR_CHANGED',
  CARD_MOBILE_RECHARGE_REQUEST_DROPDOWN_VALUES: 'CARD_MOBILE_RECHARGE_REQUEST_DROPDOWN_VALUES',
  CARD_MOBILE_RECHARGE_RESET_VIEW: 'CARD_MOBILE_RECHARGE_RESET_VIEW',
  CARD_MOBILE_RECHARGE_SETUP_STEP: 'CARD_MOBILE_RECHARGE_SETUP_STEP',
  CARD_MOBILE_RECHARGE_SET_VISIBLE_STEP: 'CARD_MOBILE_RECHARGE_SET_VISIBLE_STEP',
  CARD_MOBILE_RECHARGE_VALIDATE_STEP: 'CARD_MOBILE_RECHARGE_VALIDATE_STEP',
  CARD_MOBILE_RECHARGE_WILL_CANCEL: 'CARD_MOBILE_RECHARGE_WILL_CANCEL',
  CARD_MODIFY_DEBIT_LIMITS_FETCH_FAILURE: 'CARD_MODIFY_DEBIT_LIMITS_FETCH_FAILURE',
  CARD_MODIFY_DEBIT_LIMITS_FETCH_REQUEST: 'CARD_MODIFY_DEBIT_LIMITS_FETCH_REQUEST',
  CARD_MODIFY_DEBIT_LIMITS_FETCH_SUCCESS: 'CARD_MODIFY_DEBIT_LIMITS_FETCH_SUCCESS',
  CARD_MODIFY_DEBIT_LIMITS_RESET: 'CARD_MODIFY_DEBIT_LIMITS_RESET',
  CARD_MODIFY_DEBIT_LIMITS_SETUP_STEP: 'CARD_MODIFY_DEBIT_LIMITS_SETUP_STEP',
  CARD_MODIFY_DEBIT_LIMITS_SET_LIMIT: 'CARD_MODIFY_DEBIT_LIMITS_SET_LIMIT',
  CARD_MODIFY_DEBIT_LIMITS_SET_VISIBLE_STEP: 'CARD_MODIFY_DEBIT_LIMITS_SET_VISIBLE_STEP',
  CARD_MODIFY_DEBIT_LIMITS_SET_WILL_CANCEL: 'CARD_MODIFY_DEBIT_LIMITS_SET_WILL_CANCEL',
  CARD_MODIFY_DEBIT_LIMITS_SUBMIT_FAILURE: 'CARD_MODIFY_DEBIT_LIMITS_SUBMIT_FAILURE',
  CARD_MODIFY_DEBIT_LIMITS_SUBMIT_REQUEST: 'CARD_MODIFY_DEBIT_LIMITS_SUBMIT_REQUEST',
  CARD_MODIFY_DEBIT_LIMITS_SUBMIT_SUCCESS: 'CARD_MODIFY_DEBIT_LIMITS_SUBMIT_SUCCESS',
  CARD_MODIFY_DEBIT_LIMITS_VALIDATE_STEP: 'CARD_MODIFY_DEBIT_LIMITS_VALIDATE_STEP',
  CARD_MODIFY_LIMIT_CLEAR: 'CARD_MODIFY_LIMIT_CLEAR',
  CARD_MODIFY_LIMIT_CLOSE_MODAL: 'CARD_MODIFY_LIMIT_CLOSE_MODAL',
  CARD_MODIFY_LIMIT_DECREASE_FAILURE: 'CARD_MODIFY_LIMIT_DECREASE_FAILURE',
  CARD_MODIFY_LIMIT_DECREASE_REQUEST: 'CARD_MODIFY_LIMIT_DECREASE_REQUEST',
  CARD_MODIFY_LIMIT_DECREASE_SUCCESS: 'CARD_MODIFY_LIMIT_DECREASE_SUCCESS',
  CARD_MODIFY_LIMIT_FAILURE: 'CARD_MODIFY_LIMIT_FAILURE',
  CARD_MODIFY_LIMIT_INCREASE_FAILURE: 'CARD_MODIFY_LIMIT_INCREASE_FAILURE',
  CARD_MODIFY_LIMIT_INCREASE_REQUEST: 'CARD_MODIFY_LIMIT_INCREASE_REQUEST',
  CARD_MODIFY_LIMIT_INCREASE_SUCCESS: 'CARD_MODIFY_LIMIT_INCREASE_SUCCESS',
  CARD_MODIFY_LIMIT_RESET_STEP: 'CARD_MODIFY_LIMIT_RESET_STEP',
  CARD_MODIFY_LIMIT_SETUP_STEP: 'CARD_MODIFY_LIMIT_SETUP_STEP',
  CARD_MODIFY_LIMIT_SET_AGREEMENT_CHECKBOX: 'CARD_MODIFY_LIMIT_SET_AGREEMENT_CHECKBOX',
  CARD_MODIFY_LIMIT_SET_LIMIT: 'CARD_MODIFY_LIMIT_SET_LIMIT',
  CARD_MODIFY_LIMIT_SET_VISIBLE_STEP: 'CARD_MODIFY_LIMIT_SET_VISIBLE_STEP',
  CARD_MODIFY_LIMIT_SHOW_MODAL: 'CARD_MODIFY_LIMIT_SHOW_MODAL',
  CARD_MODIFY_LIMIT_SUCCESS: 'CARD_MODIFY_LIMIT_SUCCESS',
  CARD_MODIFY_LIMIT_VALIDATE_STEP: 'CARD_MODIFY_LIMIT_VALIDATE_STEP',
  CARD_MODIFY_LIMIT_WILL_CANCEL: 'CARD_MODIFY_LIMIT_WILL_CANCEL',
  CARD_MOVEMENT_INSTALLMENTS_CHART_FAILURE: 'CARD_MOVEMENT_INSTALLMENTS_CHART_FAILURE',
  CARD_MOVEMENT_INSTALLMENTS_CHART_REQUEST: 'CARD_MOVEMENT_INSTALLMENTS_CHART_REQUEST',
  CARD_MOVEMENT_INSTALLMENTS_CHART_SUCCESS: 'CARD_MOVEMENT_INSTALLMENTS_CHART_SUCCESS',
  CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR: 'CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR',
  CARD_PAYMENT_PURCHASE_DEFERRAL_SET_DEFAULT_INSTALLMENTS_QUANTITY:
    'CARD_PAYMENT_PURCHASE_DEFERRAL_SET_DEFAULT_INSTALLMENTS_QUANTITY',
  CARD_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP:
    'CARD_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP',
  CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT:
    'CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT',
  CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST:
    'CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST',
  CARD_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP: 'CARD_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP',
  CARD_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL: 'CARD_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL',
  CARD_PAYMENT_PURCHASE_SET_DEFERRAL_OPTION: 'CARD_PAYMENT_PURCHASE_SET_DEFERRAL_OPTION',
  CARD_PAYMENT_PURCHASE_SET_QUOTA_QUANTITY: 'CARD_PAYMENT_PURCHASE_SET_QUOTA_QUANTITY',
  CARD_PAY_LATER_CLEAR: 'CARD_PAY_LATER_CLEAR',
  CARD_PAY_LATER_FAILURE: 'CARD_PAY_LATER_FAILURE',
  CARD_PAY_LATER_IMPORT_SET: 'CARD_PAY_LATER_IMPORT_SET',
  CARD_PAY_LATER_IMPORT_SET_MAX_MIN: 'CARD_PAY_LATER_IMPORT_SET_MAX_MIN',
  CARD_PAY_LATER_REQUEST: 'CARD_PAY_LATER_REQUEST',
  CARD_PAY_LATER_RESET_STEP: 'CARD_PAY_LATER_RESET_STEP',
  CARD_PAY_LATER_SET_VISIBLE_STEP: 'CARD_PAY_LATER_SET_VISIBLE_STEP',
  CARD_PAY_LATER_SUCCESS: 'CARD_PAY_LATER_SUCCESS',
  CARD_PAY_LATER_VALIDATE_STEP: 'CARD_PAY_LATER_VALIDATE_STEP',
  CARD_PAY_LATER_WILL_CANCEL: 'CARD_PAY_LATER_WILL_CANCEL',
  CARD_PIN_CHANGE_FAILURE: 'CARD_PIN_CHANGE_FAILURE',
  CARD_PIN_CHANGE_REQUEST: 'CARD_PIN_CHANGE_REQUEST',
  CARD_PIN_CHANGE_RESET_VIEW: 'CARD_PIN_CHANGE_RESET_VIEW',
  CARD_PIN_CHANGE_SET_NEW_PIN: 'CARD_PIN_CHANGE_SET_NEW_PIN',
  CARD_PIN_CHANGE_SET_NEW_PIN_CONFIRMATION: 'CARD_PIN_CHANGE_SET_NEW_PIN_CONFIRMATION',
  CARD_PIN_CHANGE_SET_VISIBLE_STEP: 'CARD_PIN_CHANGE_SET_VISIBLE_STEP',
  CARD_PIN_CHANGE_SUCCESFUL: 'CARD_PIN_CHANGE_SUCCESFUL',
  CARD_PIN_CHANGE_VALIDATE_STEP: 'CARD_PIN_CHANGE_VALIDATE_STEP',
  CARD_REPAYMENT_FAILURE: 'CARD_REPAYMENT_FAILURE',
  CARD_REPAYMENT_REQUEST: 'CARD_REPAYMENT_REQUEST',
  CARD_REPAYMENT_SUCCESS: 'CARD_REPAYMENT_SUCCESS',
  CARD_REQUEST_LIMITS_FAILURE: 'CARD_REQUEST_LIMITS_FAILURE',
  CARD_SECURE_PAYMENT_NO_SECURE_COMMERCE_CHANGES: 'CARD_SECURE_PAYMENT_NO_SECURE_COMMERCE_CHANGES',
  CARD_SECURE_PAYMENT_RESET_STEP: 'CARD_SECURE_PAYMENT_RESET_STEP',
  CARD_SECURE_PAYMENT_RESET_VIEW: 'CARD_SECURE_PAYMENT_RESET_VIEW',
  CARD_SECURE_PAYMENT_SAVE_SETUP_FAILURE: 'CARD_SECURE_PAYMENT_SAVE_SETUP_FAILURE',
  CARD_SECURE_PAYMENT_SAVE_SETUP_REQUEST: 'CARD_SECURE_PAYMENT_SAVE_SETUP_REQUEST',
  CARD_SECURE_PAYMENT_SAVE_SETUP_SUCCESS: 'CARD_SECURE_PAYMENT_SAVE_SETUP_SUCCESS',
  CARD_SECURE_PAYMENT_SECURE_COMMERCE_CHANGES: 'CARD_SECURE_PAYMENT_SECURE_COMMERCE_CHANGES',
  CARD_SECURE_PAYMENT_SETUP_FAILURE: 'CARD_SECURE_PAYMENT_SETUP_FAILURE',
  CARD_SECURE_PAYMENT_SETUP_SUCCESS: 'CARD_SECURE_PAYMENT_SETUP_SUCCESS',
  CARD_SECURE_PAYMENT_SET_VISIBLE_STEP: 'CARD_SECURE_PAYMENT_SET_VISIBLE_STEP',
  CARD_SECURE_PAYMENT_VALIDATE_STEP: 'CARD_SECURE_PAYMENT_VALIDATE_STEP',
  CARD_SECURE_PAYMENT_VALID_PATTERN: 'CARD_SECURE_PAYMENT_VALID_PATTERN',
  CARD_SECURE_PAYMENT_WILL_CANCEL: 'CARD_SECURE_PAYMENT_WILL_CANCEL',
  CARD_TRANSFER_DETAILS_ERROR: 'CARD_TRANSFER_DETAILS_ERROR',
  CARD_TRANSFER_DETAILS_REQUEST: 'CARD_TRANSFER_DETAILS_REQUEST',
  CARD_TRANSFER_DETAILS_SUCCESS: 'CARD_TRANSFER_DETAILS_SUCCESS',
  CARD_TRANSFER_MONEY_AVAILABLE_BALANCE: 'CARD_TRANSFER_MONEY_AVAILABLE_BALANCE',
  CARD_TRANSFER_MONEY_CLEAR: 'CARD_TRANSFER_MONEY_CLEAR',
  CARD_TRANSFER_MONEY_FAILURE: 'CARD_TRANSFER_MONEY_FAILURE',
  CARD_TRANSFER_MONEY_REQUEST: 'CARD_TRANSFER_MONEY_REQUEST',
  CARD_TRANSFER_MONEY_SET_AMOUNT: 'CARD_TRANSFER_MONEY_SET_AMOUNT',
  CARD_TRANSFER_MONEY_SET_VISIBLE_STEP: 'CARD_TRANSFER_MONEY_SET_VISIBLE_STEP',
  CARD_TRANSFER_MONEY_SUCCESS: 'CARD_TRANSFER_MONEY_SUCCESS',
  CARD_TRANSFER_MONEY_VALIDATE_STEP: 'CARD_TRANSFER_MONEY_VALIDATE_STEP',
  CARD_TRANSFER_MONEY_WILL_CANCEL: 'CARD_TRANSFER_MONEY_WILL_CANCEL',
  CARD_TRAVEL_ON_FAILURE: 'CARD_TRAVEL_ON_FAILURE',
  CARD_TRAVEL_ON_REQUEST: 'CARD_TRAVEL_ON_REQUEST',
  CARD_TRAVEL_ON_RESET: 'CARD_TRAVEL_ON_RESET',
  CARD_TRAVEL_ON_SUCCESS: 'CARD_TRAVEL_ON_SUCCESS',
  CARD_WITHDRAW_TOTAL_AMOUNT: 'CARD_WITHDRAW_TOTAL_AMOUNT',
  CARD_ZONE_ENTER: 'CARD_ZONE_ENTER',
  CARD_ZONE_SELECTED: 'CARD_ZONE_SELECTED',
  CARD_ZONE_SET_FAILED: 'CARD_ZONE_SET_FAILED',
  CARD_ZONE_SET_REQUESTED: 'CARD_ZONE_SET_REQUESTED',
  CARD_ZONE_SET_SUCCEEDED: 'CARD_ZONE_SET_SUCCEEDED',
  CARD_ZONE_SPAIN_SUCCEEDED: 'CARD_ZONE_SPAIN_SUCCEEDED',
  CATEGORIES_LIST_FETCHING: 'CATEGORIES_LIST_FETCHING',
  CATEGORIES_LIST__FAILURE: 'CATEGORIES_LIST__FAILURE',
  CATEGORIES_LIST__SUCCESS: 'CATEGORIES_LIST__SUCCESS',
  CATEGORIES_SUMMARY_BOX_FAILURE: 'CATEGORIES_SUMMARY_BOX_FAILURE',
  CATEGORIES_SUMMARY_BOX_FETCHING: 'CATEGORIES_SUMMARY_BOX_FETCHING',
  CATEGORIES_SUMMARY_BOX_SUCCESS: 'CATEGORIES_SUMMARY_BOX_SUCCESS',
  CATEGORIES_TAB_SELECTOR_FAILURE: 'CATEGORIES_TAB_SELECTOR_FAILURE',
  CATEGORIES_TAB_SELECTOR_FETCHING: 'CATEGORIES_TAB_SELECTOR_FETCHING',
  CATEGORIES_TAB_SELECTOR_SUCCESS: 'CATEGORIES_TAB_SELECTOR_SUCCESS',
  CATEGORY_UPDATE_CLOSE: 'CATEGORY_UPDATE_CLOSE',
  CATEGORY_UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',
  CATEGORY_UPDATE_OPEN: 'CATEGORY_UPDATE_OPEN',
  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  CATEGORY_UPDATE_SET_CATEGORY: 'CATEGORY_UPDATE_SET_CATEGORY',
  CATEGORY_UPDATE_SET_SUBCATEGORY: 'CATEGORY_UPDATE_SET_SUBCATEGORY',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CHANGE_GET_MOVEMENTS_PRODUCTS: 'CHANGE_GET_MOVEMENTS_PRODUCTS',
  CHANGE_TAB: 'CHANGE_TAB',
  CHAT_ADD_WELCOME_MESSAGE: 'CHAT_ADD_WELCOME_MESSAGE',
  CHAT_AVAILABILITY_STATUS_FAILURE: 'CHAT_AVAILABILITY_STATUS_FAILURE',
  CHAT_AVAILABILITY_STATUS_REQUEST: 'CHAT_AVAILABILITY_STATUS_REQUEST',
  CHAT_AVAILABILITY_STATUS_SUCCESS: 'CHAT_AVAILABILITY_STATUS_SUCCESS',
  CHAT_CLOSE_ASK: 'CHAT_CLOSE_ASK',
  CHAT_CLOSE_CANCEL: 'CHAT_CLOSE_CANCEL',
  CHAT_CLOSE_CONFIRM: 'CHAT_CLOSE_CONFIRM',
  CHAT_DISCONNECTED_NOTICE: 'CHAT_DISCONNECTED_NOTICE',
  CHAT_ENDED_CONVERSATION: 'CHAT_ENDED_CONVERSATION',
  CHAT_END_WHEN_CONNECTED: 'CHAT_END_WHEN_CONNECTED',
  CHAT_MESSAGE_ERROR: 'CHAT_MESSAGE_ERROR',
  CHAT_MESSAGE_SENDING: 'CHAT_MESSAGE_SENDING',
  CHAT_MESSAGE_SENT: 'CHAT_MESSAGE_SENT',
  CHAT_RESET_TIMER: 'CHAT_RESET_TIMER',
  CHAT_RETRIEVE_MESSAGES_FAILURE: 'CHAT_RETRIEVE_MESSAGES_FAILURE',
  CHAT_RETRIEVE_MESSAGES_IS_FETCHING: 'CHAT_RETRIEVE_MESSAGES_IS_FETCHING',
  CHAT_RETRIEVE_MESSAGES_SUCCESS: 'CHAT_RETRIEVE_MESSAGES_SUCCESS',
  CHAT_SET_TIMER: 'CHAT_SET_TIMER',
  CHAT_START_CONVERSATION_FAILURE: 'CHAT_START_CONVERSATION_FAILURE',
  CHAT_START_CONVERSATION_FETCHING: 'CHAT_START_CONVERSATION_FETCHING',
  CHAT_START_CONVERSATION_SUCCESS: 'CHAT_START_CONVERSATION_SUCCESS',
  CHAT_WINDOW_TOGGLE: 'CHAT_WINDOW_TOGGLE',
  CLEAR_AD_LOCATION: 'CLEAR_AD_LOCATION',
  CLEAR_EMITED_TRANSFERS_FILTERS: 'CLEAR_EMITED_TRANSFERS_FILTERS',
  CLEAR_MORTGAGE_MOVEMENTS_FILTERS: 'CLEAR_MORTGAGE_MOVEMENTS_FILTERS',
  CLEAR_RECEIVED_TRANSFERS_FILTERS: 'CLEAR_RECEIVED_TRANSFERS_FILTERS',
  CLEAR_SCHEDULED_TRANSFERS_FILTERS: 'CLEAR_SCHEDULED_TRANSFERS_FILTERS',
  PERSONETICS_SUBSCRIPTION_UNSUBSCRIPTION_CLOSE_MODAL:
    'PERSONETICS_SUBSCRIPTION_UNSUBSCRIPTION_CLOSE_MODAL',
  COLLECTIVE_BANNER_STATUS_FAILURE: 'COLLECTIVE_BANNER_STATUS_FAILURE',
  COLLECTIVE_BANNER_STATUS_REQUEST: 'COLLECTIVE_BANNER_STATUS_REQUEST',
  COLLECTIVE_BANNER_STATUS_SUCCESS: 'COLLECTIVE_BANNER_STATUS_SUCCESS',
  COLLECTIVE_CARD_CONTENT_ERROR: 'COLLECTIVE_CARD_CONTENT_ERROR',
  COLLECTIVE_CARD_CONTENT_REQUEST: 'COLLECTIVE_CARD_CONTENT_REQUEST',
  COLLECTIVE_CARD_CONTENT_SUCCESS: 'COLLECTIVE_CARD_CONTENT_SUCCESS',
  COLLECTIVE_COMISSION_DATA_FAILURE: 'COLLECTIVE_COMISSION_DATA_FAILURE',
  COLLECTIVE_COMISSION_DATA_REQUEST: 'COLLECTIVE_COMISSION_DATA_REQUEST',
  COLLECTIVE_COMISSION_DATA_SUCCESS: 'COLLECTIVE_COMISSION_DATA_SUCCESS',
  COLLECTIVE_LANDING_PRODUCT_LIST_ERROR: 'COLLECTIVE_LANDING_PRODUCT_LIST_ERROR',
  COLLECTIVE_LANDING_PRODUCT_LIST_IS_FETCHING: 'COLLECTIVE_LANDING_PRODUCT_LIST_IS_FETCHING',
  COLLECTIVE_LANDING_PRODUCT_LIST_SUCCESS: 'COLLECTIVE_LANDING_PRODUCT_LIST_SUCCESS',
  COLLECTIVE_PRODUCT_LIST_FAILURE: 'COLLECTIVE_PRODUCT_LIST_FAILURE',
  COLLECTIVE_PRODUCT_LIST_REQUEST: 'COLLECTIVE_PRODUCT_LIST_REQUEST',
  COLLECTIVE_PRODUCT_LIST_SUCCESS: 'COLLECTIVE_PRODUCT_LIST_SUCCESS',
  COLLECTIVE_SEND_NOTIFICATION_FAILURE: 'COLLECTIVE_SEND_NOTIFICATION_FAILURE',
  COLLECTIVE_SEND_NOTIFICATION_REQUEST: 'COLLECTIVE_SEND_NOTIFICATION_REQUEST',
  COLLECTIVE_SEND_NOTIFICATION_SUCCESS: 'COLLECTIVE_SEND_NOTIFICATION_SUCCESS',
  CONFIRM_CLAUSE_FAILURE: 'CONFIRM_CLAUSE_FAILURE',
  CONFIRM_CLAUSE_SUCCESS: 'CONFIRM_CLAUSE_SUCCESS',
  CONSENTS_PROFILE_FAILURE: 'CONSENTS_PROFILE_FAILURE',
  CONSENTS_PROFILE_REQUEST: 'CONSENTS_PROFILE_REQUEST',
  CONSENTS_PROFILE_SUCCESS: 'CONSENTS_PROFILE_SUCCESS',
  CONTRACT_GENERIC_FETCHING: 'CONTRACT_GENERIC_FETCHING',
  CONTRACT_GENERIC_LOADING: 'CONTRACT_GENERIC_LOADING',
  CONTRACT_HIRE_SET_ALL_ACCOUNTS_TO_INACTIVE: 'CONTRACT_HIRE_SET_ALL_ACCOUNTS_TO_INACTIVE',
  CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE:
    'CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE',
  CONTRACT_SET_RENEWAL_ACCOUNT_BLOCKED: 'CONTRACT_SET_RENEWAL_ACCOUNT_BLOCKED',
  CONTRACT_SET_IMPOSITION_AMOUNT: 'CONTRACT_SET_IMPOSITION_AMOUNT',
  CONTRACT_SET_RENEWAL_DEPOSIT_ID: 'CONTRACT_SET_RENEWAL_DEPOSIT_ID',
  CONTRACT_SET_RENEWAL_OR_TRANSFER_TOKEN: 'CONTRACT_SET_RENEWAL_OR_TRANSFER_TOKEN',
  CONTRACT_SET_MAX_IMPORT_AMOUNT: 'CONTRACT_SET_MAX_IMPORT_AMOUNT',
  CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING:
    'CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING',
  CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS:
    'CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS',
  CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE:
    'CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE',
  CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING:
    'CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING',
  CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS:
    'CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS',
  CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_FAILURE:
    'CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_FAILURE',
  CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_IS_FETCHING:
    'CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_IS_FETCHING',
  CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_SUCCESS:
    'CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_SUCCESS',
  CONTRACT_METHOD_13_MONTHS_DEPOSIT_IS_USER_ABLE_TO_HIRE:
    'CONTRACT_METHOD_13_MONTHS_DEPOSIT_IS_USER_ABLE_TO_HIRE',
  CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_FAILURE:
    'CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_FAILURE',
  CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_IS_FETCHING:
    'CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_IS_FETCHING',
  CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_SUCCESS:
    'CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_SUCCESS',
  CONTRACT_METHOD_ADD_INTERVENER: 'CONTRACT_METHOD_ADD_INTERVENER',
  CONTRACT_METHOD_ADD_SIMPLE_INTERVENER: 'CONTRACT_METHOD_ADD_SIMPLE_INTERVENER',
  CONTRACT_METHOD_CANCEL_PROMOTIONAL_CODE: 'CONTRACT_METHOD_CANCEL_PROMOTIONAL_CODE',
  CONTRACT_METHOD_CLEAR_INTERVENERS: 'CONTRACT_METHOD_CLEAR_INTERVENERS',
  CONTRACT_METHOD_CLOSE_MODAL: 'CONTRACT_METHOD_CLOSE_MODAL',
  CONTRACT_METHOD_DELETE_ADDITIONAL_TAX_COUNTRY: 'CONTRACT_METHOD_DELETE_ADDITIONAL_TAX_COUNTRY',
  CONTRACT_METHOD_DOWNLOAD_DOCUMENT: 'CONTRACT_METHOD_DOWNLOAD_DOCUMENT',
  CONTRACT_METHOD_DOWNLOAD_DOCUMENT_ERROR: 'CONTRACT_METHOD_DOWNLOAD_DOCUMENT_ERROR',
  CONTRACT_METHOD_DOWNLOAD_DOCUMENT_SUCCESS: 'CONTRACT_METHOD_DOWNLOAD_DOCUMENT_SUCCESS',
  CONTRACT_METHOD_EXCLUDE_INTERVENER: 'CONTRACT_METHOD_EXCLUDE_INTERVENER',
  CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE:
    'CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE',
  CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_IN_PROGRESS:
    'CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_IN_PROGRESS',
  CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS:
    'CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS',
  CONTRACT_METHOD_GET_CONTRACT_INFORMATION: 'CONTRACT_METHOD_GET_CONTRACT_INFORMATION',
  CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE:
    'CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE',
  CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING:
    'CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING',
  CONTRACT_METHOD_GET_HIRING_DOCUMENTS: 'CONTRACT_METHOD_GET_HIRING_DOCUMENTS',
  CONTRACT_METHOD_GET_HIRING_DOCUMENTS_ERROR: 'CONTRACT_METHOD_GET_HIRING_DOCUMENTS_ERROR',
  CONTRACT_METHOD_GET_HIRING_DOCUMENTS_SUCCESS: 'CONTRACT_METHOD_GET_HIRING_DOCUMENTS_SUCCESS',
  CONTRACT_METHOD_HIRE: 'CONTRACT_METHOD_HIRE',
  CONTRACT_METHOD_HIRE_INTERMMEDIATE_CREDIT: 'CONTRACT_METHOD_HIRE_INTERMMEDIATE_CREDIT',
  CONTRACT_METHOD_IN_PROGRESS: 'CONTRACT_METHOD_IN_PROGRESS',
  CONTRACT_METHOD_IS_SERVICE_CALLED: 'CONTRACT_METHOD_IS_SERVICE_CALLED',
  CONTRACT_METHOD_LOAD_STATE: 'CONTRACT_METHOD_LOAD_STATE',
  CONTRACT_METHOD_NEXT_STEP: 'CONTRACT_METHOD_NEXT_STEP',
  CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_SUCCESS:
    'CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_SUCCESS',
  CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_REQUEST:
    'CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_REQUEST',
  CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_FAILURE:
    'CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_FAILURE',
  CONTRACT_METHOD_REMOVE_INTERVENER: 'CONTRACT_METHOD_REMOVE_INTERVENER',
  CONTRACT_METHOD_RESET: 'CONTRACT_METHOD_RESET',
  CONTRACT_METHOD_RESET_DOCUMENTS_AGREE: 'CONTRACT_METHOD_RESET_DOCUMENTS_AGREE',
  CONTRACT_METHOD_RESTORE_DEFAULT_INTERVENERS: 'CONTRACT_METHOD_RESTORE_DEFAULT_INTERVENERS',
  CONTRACT_METHOD_SET_ACCOUNT_INTERVENER_TYPE: 'CONTRACT_METHOD_SET_ACCOUNT_INTERVENER_TYPE',
  CONTRACT_METHOD_SET_CARD_IMAGE: 'CONTRACT_METHOD_SET_CARD_IMAGE',
  CONTRACT_METHOD_SET_CONTRACT: 'CONTRACT_METHOD_SET_CONTRACT',
  CONTRACT_METHOD_SET_DEPOSIT_POLLED: 'CONTRACT_METHOD_SET_DEPOSIT_POLLED',
  CONTRACT_METHOD_SET_VISIBLE_STEP: 'CONTRACT_METHOD_SET_VISIBLE_STEP',
  CONTRACT_METHOD_TOGGLE_AGREE: 'CONTRACT_METHOD_TOGGLE_AGREE',
  CONTRACT_METHOD_UPDATE_ACCOUNTS: 'CONTRACT_METHOD_UPDATE_ACCOUNTS',
  CONTRACT_METHOD_UPDATE_ACCOUNTS_IN_PROGRESS: 'CONTRACT_METHOD_UPDATE_ACCOUNTS_IN_PROGRESS',
  CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL: 'CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL',
  CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL_FAILURE:
    'CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL_FAILURE',
  CONTRACT_METHOD_UPDATE_CARD_DETAILS: 'CONTRACT_METHOD_UPDATE_CARD_DETAILS',
  CONTRACT_METHOD_UPDATE_CONDITIONS_CONFIRMATION: 'CONTRACT_METHOD_UPDATE_CONDITIONS_CONFIRMATION',
  CONTRACT_METHOD_UPDATE_CONFIRMATION_CHECKBOXES: 'CONTRACT_METHOD_UPDATE_CONFIRMATION_CHECKBOXES',
  CONTRACT_METHOD_UPDATE_DEPOSIT_PRODUCT: 'CONTRACT_METHOD_UPDATE_DEPOSIT_PRODUCT',
  CONTRACT_METHOD_TOGGLE_INFO_MODAL: 'CONTRACT_METHOD_TOGGLE_INFO_MODAL',
  CONTRACT_METHOD_UPDATE_INTERVENERS_IN_PROGRESS: 'CONTRACT_METHOD_UPDATE_INTERVENERS_IN_PROGRESS',
  CONTRACT_METHOD_UPDATE_SIMPLE_INTERVENER: 'CONTRACT_METHOD_UPDATE_SIMPLE_INTERVENER',
  CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL: 'CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL',
  CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL_FAILURE:
    'CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL_FAILURE',
  CONTRACT_METHOD_UPDATE_INTERVENER_RELATIONSHIP: 'CONTRACT_METHOD_UPDATE_INTERVENER_RELATIONSHIP',
  CONTRACT_METHOD_UPDATE_SLIDER_VALUE: 'CONTRACT_METHOD_UPDATE_SLIDER_VALUE',
  CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_ACCEPTANCE_CHECKED:
    'CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_ACCEPTANCE_CHECKED',
  CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_FAILURE:
    'CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_FAILURE',
  CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_IN_PROGRESS:
    'CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_IN_PROGRESS',
  CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SET_DIRTY:
    'CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SET_DIRTY',
  CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SUCCESS:
    'CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SUCCESS',
  CONTRACT_METHOD_VALIDATE_STEP: 'CONTRACT_METHOD_VALIDATE_STEP',
  CONTRACT_METHOD_WILL_CANCEL: 'CONTRACT_METHOD_WILL_CANCEL',
  CONTRACTUAL_POLLING_DOCUMENTS_FAILURE: 'CONTRACTUAL_POLLING_DOCUMENTS_FAILURE',
  CONTRACTUAL_POLLING_DOCUMENTS_REQUEST: 'CONTRACTUAL_POLLING_DOCUMENTS_REQUEST',
  CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS: 'CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS',
  CONTRACTUAL_POLLING_INIT_FAILURE: 'CONTRACTUAL_POLLING_INIT_FAILURE',
  CONTRACTUAL_POLLING_INIT_REQUEST: 'CONTRACTUAL_POLLING_INIT_REQUEST',
  CONTRACTUAL_POLLING_INIT_SUCCESS: 'CONTRACTUAL_POLLING_INIT_SUCCESS',
  CONTRACTUAL_POLLING_MERGE_FAILURE: 'CONTRACTUAL_POLLING_MERGE_FAILURE',
  CONTRACTUAL_POLLING_MERGE_REQUEST: 'CONTRACTUAL_POLLING_MERGE_REQUEST',
  CONTRACTUAL_POLLING_MERGE_SUCCESS: 'CONTRACTUAL_POLLING_MERGE_SUCCESS',
  CONTRACTUAL_POLLING_RESET: 'CONTRACTUAL_POLLING_RESET',
  CREATE_CHORE_AVAILABLE_BALANCE: 'CREATE_CHORE_AVAILABLE_BALANCE',
  CREATE_CHORE_CLEAR: 'CREATE_CHORE_CLEAR',
  CREATE_CHORE_CONCEPT_CHANGED: 'CREATE_CHORE_CONCEPT_CHANGED',
  CREATE_CHORE_CONFIRM_ERROR: 'CREATE_CHORE_CONFIRM_ERROR',
  CREATE_CHORE_CONFIRM_REQUEST: 'CREATE_CHORE_CONFIRM_REQUEST',
  CREATE_CHORE_CONFIRM_SUCCESS: 'CREATE_CHORE_CONFIRM_SUCCESS',
  CREATE_CHORE_DETAILS_ERROR: 'CREATE_CHORE_DETAILS_ERROR',
  CREATE_CHORE_DETAILS_REQUEST: 'CREATE_CHORE_DETAILS_REQUEST',
  CREATE_CHORE_DETAILS_SUCCESS: 'CREATE_CHORE_DETAILS_SUCCESS',
  CREATE_CHORE_RESET: 'CREATE_CHORE_RESET',
  CREATE_CHORE_SET_AMOUNT: 'CREATE_CHORE_SET_AMOUNT',
  CREATE_CHORE_SET_CHORE_TYPE: 'CREATE_CHORE_SET_CHORE_TYPE',
  CREATE_CHORE_SET_REPETITION_OPTION_LIST: 'CREATE_CHORE_SET_REPETITION_OPTION_LIST',
  CREATE_CHORE_SET_REPETITION_TYPE: 'CREATE_CHORE_SET_REPETITION_TYPE',
  CREATE_CHORE_SET_VISIBLE_STEP: 'CREATE_CHORE_SET_VISIBLE_STEP',
  CREATE_CHORE_VALIDATE_STEP: 'CREATE_CHORE_VALIDATE_STEP',
  CREATE_CHORE_VALIDITY_DATE_CHANGED: 'CREATE_CHORE_VALIDITY_DATE_CHANGED',
  CREATE_CHORE_WILL_CANCEL: 'CREATE_CHORE_WILL_CANCEL',
  CREATE_LOGOUT_TIMER: 'CREATE_LOGOUT_TIMER',
  CREDITS_CANCEL_PREGRANTED_CLEAR: 'CREDITS_CANCEL_PREGRANTED_CLEAR',
  CREDITS_CANCEL_PREGRANTED_FAILURE: 'CREDITS_CANCEL_PREGRANTED_FAILURE',
  CREDITS_CANCEL_PREGRANTED_REQUEST: 'CREDITS_CANCEL_PREGRANTED_REQUEST',
  CREDITS_CANCEL_PREGRANTED_SUCCESS: 'CREDITS_CANCEL_PREGRANTED_SUCCESS',
  CREDITS_PREGRANTED_FAILURE: 'CREDITS_PREGRANTED_FAILURE',
  CREDITS_PREGRANTED_REQUEST: 'CREDITS_PREGRANTED_REQUEST',
  CREDITS_PREGRANTED_SUCCESS: 'CREDITS_PREGRANTED_SUCCESS',
  CREDIT_CARD_PAY_LATER_INFO_ERROR: 'CREDIT_CARD_PAY_LATER_INFO_ERROR',
  CREDIT_CARD_PAY_LATER_INFO_SUCCESS: 'CREDIT_CARD_PAY_LATER_INFO_SUCCESS',
  CREDIT_CARD_PAY_LATER_REQUEST: 'CREDIT_CARD_PAY_LATER_REQUEST',
  CREDIT_CARD_PAY_LATER_RESET: 'CREDIT_CARD_PAY_LATER_RESET',
  CREDIT_PREGRANTED_DETAILS_FAILURE: 'CREDIT_PREGRANTED_DETAILS_FAILURE',
  CREDIT_PREGRANTED_DETAILS_REQUEST: 'CREDIT_PREGRANTED_DETAILS_REQUEST',
  CREDIT_PREGRANTED_DETAILS_SUCCESS: 'CREDIT_PREGRANTED_DETAILS_SUCCESS',
  CREDIT_PREGRANTED_LIMIT_VARIATION_CLEAR: 'CREDIT_PREGRANTED_LIMIT_VARIATION_CLEAR',
  CREDIT_PREGRANTED_LIMIT_VARIATION_FAILURE: 'CREDIT_PREGRANTED_LIMIT_VARIATION_FAILURE',
  CREDIT_PREGRANTED_LIMIT_VARIATION_REQUEST: 'CREDIT_PREGRANTED_LIMIT_VARIATION_REQUEST',
  CREDIT_PREGRANTED_LIMIT_VARIATION_SET_LIMIT: 'CREDIT_PREGRANTED_LIMIT_VARIATION_SET_LIMIT',
  CREDIT_PREGRANTED_LIMIT_VARIATION_SET_VISIBLE_STEP:
    'CREDIT_PREGRANTED_LIMIT_VARIATION_SET_VISIBLE_STEP',
  CREDIT_PREGRANTED_LIMIT_VARIATION_SUCCESS: 'CREDIT_PREGRANTED_LIMIT_VARIATION_SUCCESS',
  CREDIT_PREGRANTED_LIMIT_VARIATION_VALIDATE_STEP:
    'CREDIT_PREGRANTED_LIMIT_VARIATION_VALIDATE_STEP',
  CREDIT_PREGRANTED_LIMIT_VARIATION_WILL_CANCEL: 'CREDIT_PREGRANTED_LIMIT_VARIATION_WILL_CANCEL',
  CREDIT_PREGRANTED_LIMIT_VARIATION_WILL_LEAVE: 'CREDIT_PREGRANTED_LIMIT_VARIATION_WILL_LEAVE',
  CW_GET_INVESTMENTS_ISIN_PLANS_SUCCESS: 'CW_GET_INVESTMENTS_ISIN_PLANS_SUCCESS',
  CW_GET_MY_INVESTMENTS_FAILURE: 'CW_GET_MY_INVESTMENTS_FAILURE',
  CW_GET_MY_INVESTMENTS_REQUEST: 'CW_GET_MY_INVESTMENTS_REQUEST',
  CW_GET_MY_INVESTMENTS_SET_OUTDATED: 'CW_GET_MY_INVESTMENTS_SET_OUTDATED',
  CW_GET_MY_INVESTMENTS_SUCCESS: 'CW_GET_MY_INVESTMENTS_SUCCESS',
  CW_BROKER_GET_CONTRIBUTION_LIST_REQUEST: 'CW_BROKER_GET_CONTRIBUTION_LIST_REQUEST',
  CW_BROKER_GET_CONTRIBUTION_LIST_SUCCESS: 'CW_BROKER_GET_CONTRIBUTION_LIST_SUCCESS',
  CW_BROKER_GET_CONTRIBUTION_LIST_FAILURE: 'CW_BROKER_GET_CONTRIBUTION_LIST_FAILURE',
  CW_SET_WALLET_ALIAS_FAILURE: 'CW_SET_WALLET_ALIAS_FAILURE',
  CW_SET_WALLET_ALIAS_REQUEST: 'CW_SET_WALLET_ALIAS_REQUEST',
  CW_SET_WALLET_ALIAS_SUCCESS: 'CW_SET_WALLET_ALIAS_SUCCESS',
  DEBIT_ANNULATION_CANCEL: 'SET_DEBIT_ANNULATION_CANCEL',
  DEBIT_ANNULATION_FAILURE: 'SET_DEBIT_ANNULATION_FAILURE',
  DEBIT_ANNULATION_REQUEST: 'SET_DEBIT_ANNULATION_REQUEST',
  DEBIT_ANNULATION_SUCCESS: 'SET_DEBIT_ANNULATION_SUCCESS',
  DEBIT_RETURN_CANCEL: 'SET_DEBIT_RETURN_CANCEL',
  DEBIT_RETURN_FAILURE: 'SET_DEBIT_RETURN_FAILURE',
  DEBIT_RETURN_REQUEST: 'SET_DEBIT_RETURN_REQUEST',
  DEBIT_RETURN_SUCCESS: 'SET_DEBIT_RETURN_SUCCESS',
  DECREASE_CARD_LIMIT_FAILURE: 'DECREASE_CARD_LIMIT_FAILURE',
  DECREASE_CARD_LIMIT_REQUEST: 'DECREASE_CARD_LIMIT_REQUEST',
  DECREASE_CARD_LIMIT_SUCCESS: 'DECREASE_CARD_LIMIT_SUCCESS',
  DELETE_PERIODIC_LOAD_ERROR: 'DELETE_PERIODIC_LOAD_ERROR',
  DELETE_PERIODIC_LOAD_REQUEST: 'DELETE_PERIODIC_LOAD_REQUEST',
  DELETE_PERIODIC_LOAD_SUCCESS: 'DELETE_PERIODIC_LOAD_SUCCESS',
  DEPOSITS_RESET: 'DEPOSITS_RESET',
  CONTRACT_DEPOSITS_INTEREST_FETCHING: 'CONTRACT_DEPOSITS_INTEREST_FETCHING',
  CONTRACT_DEPOSITS_INTEREST_SUCCESS: 'CONTRACT_DEPOSITS_INTEREST_SUCCESS',
  CONTRACT_DEPOSITS_INTEREST_FAILURE: 'CONTRACT_DEPOSITS_INTEREST_FAILURE',
  ECARD_INFORMATION_FAILURE: 'ECARD_INFORMATION_FAILURE',
  ECARD_INFORMATION_REQUEST: 'ECARD_INFORMATION_REQUEST',
  ECARD_INFORMATION_SUCCESS: 'ECARD_INFORMATION_SUCCESS',
  ECOMMERCE_KEY_RESET: 'ECOMMERCE_KEY_RESET',
  ECOMMERCE_MODIFY_KEY_RESET: 'ECOMMERCE_MODIFY_KEY_RESET',
  ECOMMERCE_MODIFY_KEY_CONFIRM_STEP: 'ECOMMERCE_MODIFY_KEY_CONFIRM_STEP',
  EMITED_TRANSFER_SET_PDF_FETCHING: 'EMITED_TRANSFER_SET_PDF_FETCHING',
  EXPAND_ROW: 'EXPAND_ROW',
  EXTERNAL_TRANSFER_VALUES_ADD_FILE: 'EXTERNAL_TRANSFER_VALUES_ADD_FILE',
  EXTERNAL_TRANSFER_VALUES_ADD_PRODUCT_EMPTY_ROW: 'EXTERNAL_TRANSFER_VALUES_ADD_PRODUCT_EMPTY_ROW',
  EXTERNAL_TRANSFER_VALUES_CURRENT_ACCOUNT_CHANGE:
    'EXTERNAL_TRANSFER_VALUES_CURRENT_ACCOUNT_CHANGE',
  EXTERNAL_TRANSFER_VALUES_DELETE_PRODUCT: 'EXTERNAL_TRANSFER_VALUES_DELETE_PRODUCT',
  EXTERNAL_TRANSFER_VALUES_ERROR: 'EXTERNAL_TRANSFER_VALUES_ERROR',
  EXTERNAL_TRANSFER_VALUES_REMOVE_FILE: 'EXTERNAL_TRANSFER_VALUES_REMOVE_FILE',
  EXTERNAL_TRANSFER_VALUES_REQUEST: 'EXTERNAL_TRANSFER_VALUES_REQUEST',
  EXTERNAL_TRANSFER_VALUES_RESET: 'EXTERNAL_TRANSFER_VALUES_RESET',
  EXTERNAL_TRANSFER_VALUES_RESET_DUPLICATE_ERROR: 'EXTERNAL_TRANSFER_VALUES_RESET_DUPLICATE_ERROR',
  EXTERNAL_TRANSFER_VALUES_SET_BANK_NAME: 'EXTERNAL_TRANSFER_VALUES_SET_BANK_NAME',
  EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH: 'EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH',
  EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH_INPUT_FIELD:
    'EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH_INPUT_FIELD',
  EXTERNAL_TRANSFER_VALUES_SET_CONTRACT_NUMBER: 'EXTERNAL_TRANSFER_VALUES_SET_CONTRACT_NUMBER',
  EXTERNAL_TRANSFER_VALUES_SET_DESTINATION_ACCOUNT:
    'EXTERNAL_TRANSFER_VALUES_SET_DESTINATION_ACCOUNT',
  EXTERNAL_TRANSFER_VALUES_SET_NO_OF_PRODUCTS: 'EXTERNAL_TRANSFER_VALUES_SET_NO_OF_PRODUCTS',
  EXTERNAL_TRANSFER_VALUES_SET_VISIBLE_STEP: 'EXTERNAL_TRANSFER_VALUES_SET_VISIBLE_STEP',
  EXTERNAL_TRANSFER_VALUES_SUCCESS: 'EXTERNAL_TRANSFER_VALUES_SUCCESS',
  EXTERNAL_TRANSFER_VALUES_UNDO_DELETION: 'EXTERNAL_TRANSFER_VALUES_UNDO_DELETION',
  EXTERNAL_TRANSFER_VALUES_UPLOAD_ERROR: 'EXTERNAL_TRANSFER_VALUES_UPLOAD_ERROR',
  EXTERNAL_TRANSFER_VALUES_VALIDATE_STEP: 'EXTERNAL_TRANSFER_VALUES_VALIDATE_STEP',
  EXTERNAL_TRANSFER_VALUES_WILL_CANCEL: 'EXTERNAL_TRANSFER_VALUES_WILL_CANCEL',
  FATCA_CLOSE_MODAL: 'FATCA_CLOSE_MODAL',
  FATCA_OPEN_MODAL: 'FATCA_OPEN_MODAL',
  FETCH_ACCESS_KEY_DATA_FAILURE: 'FETCH_ACCESS_KEY_DATA_FAILURE',
  FETCH_ACCESS_KEY_DATA_REQUEST: 'FETCH_ACCESS_KEY_DATA_REQUEST',
  FETCH_ACCESS_KEY_DATA_SUCCESS: 'FETCH_ACCESS_KEY_DATA_SUCCESS',
  FETCH_ACCOUNTS_FOR_DEPOSIT_FAILURE: 'FETCH_ACCOUNTS_FOR_DEPOSIT_FAILURE',
  FETCH_ACCOUNTS_FOR_DEPOSIT_REQUEST: 'FETCH_ACCOUNTS_FOR_DEPOSIT_REQUEST',
  FETCH_ACCOUNTS_FOR_DEPOSIT_SUCCESS: 'FETCH_ACCOUNTS_FOR_DEPOSIT_SUCCESS',
  FETCH_ACCOUNT_CHEQUEBOOK_FAILURE: 'FETCH_ACCOUNT_CHEQUEBOOK_FAILURE',
  FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_REQUEST: 'FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_REQUEST',
  FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_SUCCESS: 'FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_SUCCESS',
  FETCH_ACCOUNT_CHEQUEBOOK_REQUEST: 'FETCH_ACCOUNT_CHEQUEBOOK_REQUEST',
  FETCH_ACCOUNT_CHEQUEBOOK_SET_FILTER: 'FETCH_ACCOUNT_CHEQUEBOOK_SET_FILTER',
  FETCH_ACCOUNT_CHEQUEBOOK_SUCCESS: 'FETCH_ACCOUNT_CHEQUEBOOK_SUCCESS',
  FETCH_ACCOUNT_CHEQUES_FAILURE: 'FETCH_ACCOUNT_CHEQUES_FAILURE',
  FETCH_ACCOUNT_CHEQUES_PAGE_SUCCESS: 'FETCH_ACCOUNT_CHEQUES_PAGE_SUCCESS',
  FETCH_ACCOUNT_CHEQUES_REQUEST: 'FETCH_ACCOUNT_CHEQUES_REQUEST',
  FETCH_ACCOUNT_CHEQUES_SUCCESS: 'FETCH_ACCOUNT_CHEQUES_SUCCESS',
  FETCH_ACCOUNT_DEBITS_DETAIL_FAILURE: 'FETCH_ACCOUNT_DEBITS_DETAIL_FAILURE',
  FETCH_ACCOUNT_DEBITS_DETAIL_REQUEST: 'FETCH_ACCOUNT_DEBITS_DETAIL_REQUEST',
  FETCH_ACCOUNT_DEBITS_DETAIL_SUCCESS: 'FETCH_ACCOUNT_DEBITS_DETAIL_SUCCESS',
  FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE: 'FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE',
  FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST: 'FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST',
  FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS: 'FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS',
  FETCH_ACCOUNT_DEBITS_EDIT_FAILURE: 'FETCH_ACCOUNT_DEBITS_EDIT_FAILURE',
  FETCH_ACCOUNT_DEBITS_EDIT_REQUEST: 'FETCH_ACCOUNT_DEBITS_EDIT_REQUEST',
  FETCH_ACCOUNT_DEBITS_EDIT_SUCCESS: 'FETCH_ACCOUNT_DEBITS_EDIT_SUCCESS',
  FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_FAILURE: 'FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_FAILURE',
  FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_REQUEST: 'FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_REQUEST',
  FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_SUCCESS: 'FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_SUCCESS',
  FETCH_ACCOUNT_DEBITS_FAILURE: 'FETCH_ACCOUNT_DEBITS_FAILURE',
  FETCH_ACCOUNT_DEBITS_REQUEST: 'FETCH_ACCOUNT_DEBITS_REQUEST',
  FETCH_ACCOUNT_DEBITS_SUCCESS: 'FETCH_ACCOUNT_DEBITS_SUCCESS',
  FETCH_ACCOUNT_INTERVENERS_FAILURE: 'FETCH_ACCOUNT_INTERVENERS_FAILURE',
  FETCH_ACCOUNT_INTERVENERS_REQUEST: 'FETCH_ACCOUNT_INTERVENERS_REQUEST',
  FETCH_ACCOUNT_INTERVENERS_RESET: 'FETCH_ACCOUNT_INTERVENERS_RESET',
  FETCH_ACCOUNT_INTERVENERS_SUCCESS: 'FETCH_ACCOUNT_INTERVENERS_SUCCESS',
  FETCH_ACCOUNT_RETENTIONS_FAILURE: 'FETCH_ACCOUNT_RETENTIONS_FAILURE',
  FETCH_ACCOUNT_RETENTIONS_REQUEST: 'FETCH_ACCOUNT_RETENTIONS_REQUEST',
  FETCH_ACCOUNT_RETENTIONS_SUCCESS: 'FETCH_ACCOUNT_RETENTIONS_SUCCESS',
  FETCH_ACCOUNT_SETTLEMENTS_DETAILS_FAILURE: 'FETCH_ACCOUNT_SETTLEMENTS_DETAILS_FAILURE',
  FETCH_ACCOUNT_SETTLEMENTS_DETAILS_REQUEST: 'FETCH_ACCOUNT_SETTLEMENTS_DETAILS_REQUEST',
  FETCH_ACCOUNT_SETTLEMENTS_DETAILS_SUCCESS: 'FETCH_ACCOUNT_SETTLEMENTS_DETAILS_SUCCESS',
  FETCH_ACCOUNT_SETTLEMENTS_FAILURE: 'FETCH_ACCOUNT_SETTLEMENTS_FAILURE',
  FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE: 'FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE',
  FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_FAILURE: 'FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_FAILURE',
  FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_SUCCESS: 'FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_SUCCESS',
  FETCH_ACCOUNT_SETTLEMENTS_REQUEST: 'FETCH_ACCOUNT_SETTLEMENTS_REQUEST',
  FETCH_ACCOUNT_SETTLEMENTS_SUCCESS: 'FETCH_ACCOUNT_SETTLEMENTS_SUCCESS',
  FETCH_ACCOUNT_TRANSACTIONS_DETAILS_FAILURE: 'FETCH_ACCOUNT_TRANSACTIONS_DETAILS_FAILURE',
  FETCH_ACCOUNT_TRANSACTIONS_DETAILS_REQUEST: 'FETCH_ACCOUNT_TRANSACTIONS_DETAILS_REQUEST',
  FETCH_ACCOUNT_TRANSACTIONS_DETAILS_SUCCESS: 'FETCH_ACCOUNT_TRANSACTIONS_DETAILS_SUCCESS',
  FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_FAILURE: 'FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_FAILURE',
  FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_REQUEST: 'FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_REQUEST',
  FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SUCCESS: 'FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SUCCESS',
  FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED:
    'FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED',
  FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_RESET: 'FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_RESET',
  FETCH_ACCOUNT_TRANSACTIONS_FAILURE: 'FETCH_ACCOUNT_TRANSACTIONS_FAILURE',
  FETCH_ACCOUNT_TRANSACTIONS_FILTER: 'FETCH_ACCOUNT_TRANSACTIONS_FILTER',
  FETCH_ACCOUNT_TRANSACTIONS_FILTER_CLEAR: 'FETCH_ACCOUNT_TRANSACTIONS_FILTER_CLEAR',
  FETCH_ACCOUNT_TRANSACTIONS_PAGE_SUCCESS: 'FETCH_ACCOUNT_TRANSACTIONS_PAGE_SUCCESS',
  FETCH_ACCOUNT_TRANSACTIONS_REQUEST: 'FETCH_ACCOUNT_TRANSACTIONS_REQUEST',
  FETCH_ACCOUNT_TRANSACTIONS_SUCCESS: 'FETCH_ACCOUNT_TRANSACTIONS_SUCCESS',
  FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_FINISHED:
    'FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_FINISHED',
  FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_REQUEST:
    'FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_REQUEST',
  FETCH_ADDITIONALDOCUMENTATION_FAILURE: 'FETCH_ADDITIONALDOCUMENTATION_FAILURE',
  FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS:
    'FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS',
  FETCH_ADDITIONALDOCUMENTATION_REQUEST: 'FETCH_ADDITIONALDOCUMENTATION_REQUEST',
  FETCH_ADDITIONALDOCUMENTATION_SUCCESS: 'FETCH_ADDITIONALDOCUMENTATION_SUCCESS',
  FETCH_ADS_FAILURE: 'FETCH_ADS_FAILURE',
  FETCH_ADS_REQUEST_RESET: 'FETCH_ADS_REQUEST_RESET',
  FETCH_ADS_REQUEST: 'FETCH_ADS_REQUEST',
  FETCH_ADS_SUCCESS: 'FETCH_ADS_SUCCESS',
  FETCH_AGENT_PROFILE_VALIDATE_SUCCESS: 'FETCH_AGENT_PROFILE_VALIDATE_SUCCESS',
  FETCH_BANK_ENTITY_FAILURE: 'FETCH_BANK_ENTITY_FAILURE',
  FETCH_BANK_ENTITY_REQUEST: 'FETCH_BANK_ENTITY_REQUEST',
  FETCH_BANK_ENTITY_RESET: 'FETCH_BANK_ENTITY_RESET',
  FETCH_BANK_ENTITY_SUCCESS: 'FETCH_BANK_ENTITY_SUCCESS',
  FETCH_BENEFICIARIES_FAILURE: 'FETCH_BENEFICIARIES_FAILURE',
  FETCH_BENEFICIARIES_REQUEST: 'FETCH_BENEFICIARIES_REQUEST',
  FETCH_BENEFICIARIES_SUCCESS: 'FETCH_BENEFICIARIES_SUCCESS',
  FETCH_BIOCATCH_SCORE_FAILURE: 'FETCH_BIOCATCH_SCORE_FAILURE',
  FETCH_BIOCATCH_SCORE_REQUEST: 'FETCH_BIOCATCH_SCORE_REQUEST',
  FETCH_BIOCATCH_SCORE_SUCCESS: 'FETCH_BIOCATCH_SCORE_SUCCESS',
  FETCH_BROKER_ADD_SUBSCRIPTION: 'FETCH_BROKER_ADD_SUBSCRIPTION',
  FETCH_BROKER_ADD_SUBSCRIPTION_DETAILS: 'FETCH_BROKER_ADD_SUBSCRIPTION_DETAILS',
  FETCH_BROKER_CONTRIBUTION_FUNDS_FAILURE: 'FETCH_BROKER_CONTRIBUTION_FUNDS_FAILURE',
  FETCH_BROKER_CONTRIBUTION_FUNDS_NOT_FOUND: 'FETCH_BROKER_CONTRIBUTION_FUNDS_NOT_FOUND',
  FETCH_BROKER_CONTRIBUTION_FUNDS_REQUEST: 'FETCH_BROKER_CONTRIBUTION_FUNDS_REQUEST',
  FETCH_BROKER_CONTRIBUTION_FUNDS_SUCCESS: 'FETCH_BROKER_CONTRIBUTION_FUNDS_SUCCESS',
  FETCH_BROKER_GET_ACCOUNTS_CONTRIBUTION_SUCCESS: 'FETCH_BROKER_GET_ACCOUNTS_CONTRIBUTION_SUCCESS',
  FETCH_BROKER_GET_ACCOUNTS_HIRE_SUCCESS: 'FETCH_BROKER_GET_ACCOUNTS_HIRE_SUCCESS',
  FETCH_BROKER_GET_ALL_FUND_CONTRACT_INTERVENERS_SUCCESS:
    'FETCH_BROKER_GET_ALL_FUND_CONTRACT_INTERVENERS_SUCCESS',
  FETCH_BROKER_GET_INTERVENERS_CONTRACT_SUCCESS: 'FETCH_BROKER_GET_INTERVENERS_CONTRACT_SUCCESS',
  FETCH_BROKER_GET_INTERVENERS_FAILURE: 'FETCH_BROKER_GET_INTERVENERS_FAILURE',
  FETCH_BROKER_GET_INTERVENERS_SUCCESS: 'FETCH_BROKER_GET_INTERVENERS_SUCCESS',
  FETCH_BROKER_HIRE_SUCCESS: 'FETCH_BROKER_HIRE_SUCCESS',
  FETCH_BROKER_INVESTMENT_FUNDS_FAILURE: 'FETCH_BROKER_INVESTMENT_FUNDS_FAILURE',
  FETCH_BROKER_INVESTMENT_FUNDS_NOT_FOUND: 'FETCH_BROKER_INVESTMENT_FUNDS_NOT_FOUND',
  FETCH_BROKER_INVESTMENT_FUNDS_PRE_FAILURE: 'FETCH_BROKER_INVESTMENT_FUNDS_PRE_FAILURE',
  FETCH_BROKER_INVESTMENT_FUNDS_PRE_REQUEST: 'FETCH_BROKER_INVESTMENT_FUNDS_PRE_REQUEST',
  FETCH_BROKER_INVESTMENT_FUNDS_REQUEST: 'FETCH_BROKER_INVESTMENT_FUNDS_REQUEST',
  FETCH_BROKER_INVESTMENT_FUNDS_SUCCESS: 'FETCH_BROKER_INVESTMENT_FUNDS_SUCCESS',
  FETCH_BROKER_INVESTMENT_ALTERNATIVE_FUNDS_SUCCESS:
    'FETCH_BROKER_INVESTMENT_ALTERNATIVE_FUNDS_SUCCESS',
  FETCH_BROKER_INVESTMENT_ALTERNATIVE_FUNDS_SET_SHOW_WELCOME:
    'FETCH_BROKER_INVESTMENT_ALTERNATIVE_FUNDS_SET_SHOW_WELCOME',
  BROKER_WIZARD_SUBSCRIPTION_IS_ALTERNATIVE_FUND: 'BROKER_WIZARD_SUBSCRIPTION_IS_ALTERNATIVE_FUND',
  BROKER_WIZARD_SUBSCRIPTION_SET_CLOSE_PRICE: 'BROKER_WIZARD_SUBSCRIPTION_SET_CLOSE_PRICE',
  FETCH_BROKER_MARKET_CALENDAR_EVENTS_FAILURE: 'FETCH_BROKER_MARKET_CALENDAR_EVENTS_FAILURE',
  FETCH_BROKER_MARKET_CALENDAR_EVENTS_REQUEST: 'FETCH_BROKER_MARKET_CALENDAR_EVENTS_REQUEST',
  FETCH_BROKER_MARKET_CALENDAR_EVENTS_SUCCESS: 'FETCH_BROKER_MARKET_CALENDAR_EVENTS_SUCCESS',
  FETCH_BROKER_MARKET_PULSES_FAILURE: 'FETCH_BROKER_MARKET_PULSES_FAILURE',
  FETCH_BROKER_MARKET_PULSES_REQUEST: 'FETCH_BROKER_MARKET_PULSES_REQUEST',
  FETCH_BROKER_MARKET_PULSES_SUCCESS: 'FETCH_BROKER_MARKET_PULSES_SUCCESS',
  FETCH_BROKER_MORNINGSTAR_SCREENER_FAILURE: 'FETCH_BROKER_MORNINGSTAR_SCREENER_FAILURE',
  FETCH_BROKER_MORNINGSTAR_SCREENER_REQUEST: 'FETCH_BROKER_MORNINGSTAR_SCREENER_REQUEST',
  FETCH_BROKER_MORNINGSTAR_SCREENER_SUCCESS: 'FETCH_BROKER_MORNINGSTAR_SCREENER_SUCCESS',
  FETCH_BROKER_PENSION_PLAN_FAILURE: 'FETCH_BROKER_PENSION_PLAN_FAILURE',
  FETCH_BROKER_PENSION_PLAN_NOT_FOUND: 'FETCH_BROKER_PENSION_PLAN_NOT_FOUND',
  FETCH_BROKER_PENSION_PLAN_REQUEST: 'FETCH_BROKER_PENSION_PLAN_REQUEST',
  FETCH_BROKER_PENSION_PLAN_SUCCESS: 'FETCH_BROKER_PENSION_PLAN_SUCCESS',
  FETCH_BROKER_PRE_HIRE_SUCCESS: 'FETCH_BROKER_PRE_HIRE_SUCCESS',
  FETCH_BROKER_REIMBURSE_FUND_ADD_FAILURE: 'FETCH_BROKER_REIMBURSE_FUND_ADD_FAILURE',
  FETCH_BROKER_REIMBURSE_FUND_ADD_REQUEST: 'FETCH_BROKER_REIMBURSE_FUND_ADD_REQUEST',
  FETCH_BROKER_REIMBURSE_FUND_ADD_SUCCESS: 'FETCH_BROKER_REIMBURSE_FUND_ADD_SUCCESS',
  FETCH_BROKER_REIMBURSE_INVESTMENT_FAILURE: 'FETCH_BROKER_REIMBURSE_INVESTMENT_FAILURE',
  FETCH_BROKER_REIMBURSE_INVESTMENT_NOT_FOUND: 'FETCH_BROKER_REIMBURSE_INVESTMENT_NOT_FOUND',
  FETCH_BROKER_REIMBURSE_INVESTMENT_REQUEST: 'FETCH_BROKER_REIMBURSE_INVESTMENT_REQUEST',
  FETCH_BROKER_REIMBURSE_INVESTMENT_SUCCESS: 'FETCH_BROKER_REIMBURSE_INVESTMENT_SUCCESS',
  FETCH_BROKER_REIMBURSE_NEW_ORDER_FAILURE: 'FETCH_BROKER_REIMBURSE_NEW_ORDER_FAILURE',
  FETCH_BROKER_REIMBURSE_NEW_ORDER_SUCCESS: 'FETCH_BROKER_REIMBURSE_NEW_ORDER_SUCCESS',
  FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_REQUEST: 'FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_REQUEST',
  FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_SUCCESS: 'FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_SUCCESS',
  FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_FAILURE: 'FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_FAILURE',
  FETCH_CANCEL_POST_PAY_DATA_FAILURE: 'FETCH_CANCEL_POST_PAY_DATA_FAILURE',
  FETCH_CANCEL_POST_PAY_DATA_REQUEST: 'FETCH_CANCEL_POST_PAY_DATA_REQUEST',
  FETCH_CANCEL_POST_PAY_DATA_SUCCESS: 'FETCH_CANCEL_POST_PAY_DATA_SUCCESS',
  FETCH_CARD_REWARDS_FAILURE: 'FETCH_CARD_REWARDS_FAILURE',
  FETCH_CARD_REWARDS_REQUEST: 'FETCH_CARD_REWARDS_REQUEST',
  FETCH_CARD_REWARDS_RESET: 'FETCH_CARD_REWARDS_RESET',
  FETCH_CARD_REWARDS_SUCCESS: 'FETCH_CARD_REWARDS_SUCCESS',
  FETCH_CARDS_FAILURE: 'FETCH_CARDS_FAILURE',
  FETCH_CARDS_PIN_FAILURE: 'FETCH_CARDS_PIN_FAILURE',
  FETCH_CARDS_PIN_REQUEST: 'FETCH_CARDS_PIN_REQUEST',
  FETCH_CARDS_PIN_SUCCESS: 'FETCH_CARDS_PIN_SUCCESS',
  FETCH_CARDS_REQUEST: 'FETCH_CARDS_REQUEST',
  FETCH_CARDS_STATEMENTS_FAILURE: 'FETCH_CARDS_STATEMENTS_FAILURE',
  FETCH_CARDS_STATEMENTS_LOAD_MORE_FAILURE: 'FETCH_CARDS_STATEMENTS_LOAD_MORE_FAILURE',
  FETCH_CARDS_STATEMENTS_LOAD_MORE_REQUEST: 'FETCH_CARDS_STATEMENTS_LOAD_MORE_REQUEST',
  FETCH_CARDS_STATEMENTS_LOAD_MORE_SUCCESS: 'FETCH_CARDS_STATEMENTS_LOAD_MORE_SUCCESS',
  FETCH_CARDS_STATEMENTS_REQUEST: 'FETCH_CARDS_STATEMENTS_REQUEST',
  FETCH_CARDS_STATEMENTS_SUCCESS: 'FETCH_CARDS_STATEMENTS_SUCCESS',
  FETCH_CARDS_STATEMENT_DETAILS_FAILURE: 'FETCH_CARDS_STATEMENT_DETAILS_FAILURE',
  FETCH_CARDS_STATEMENT_DETAILS_REQUEST: 'FETCH_CARDS_STATEMENT_DETAILS_REQUEST',
  FETCH_CARDS_STATEMENT_DETAILS_SUCCESS: 'FETCH_CARDS_STATEMENT_DETAILS_SUCCESS',
  FETCH_CARDS_SUCCESS: 'FETCH_CARDS_SUCCESS',
  FETCH_CARDS_TRANSACTIONS_DOWNLOAD_FAILURE: 'FETCH_CARDS_TRANSACTIONS_DOWNLOAD_FAILURE',
  FETCH_CARDS_TRANSACTIONS_DOWNLOAD_REQUEST: 'FETCH_CARDS_TRANSACTIONS_DOWNLOAD_REQUEST',
  FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SUCCESS: 'FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SUCCESS',
  FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED: 'FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED',
  FETCH_CARD_TRANSACTIONS_DOWNLOAD_SCA_RESET: 'FETCH_CARD_TRANSACTIONS_DOWNLOAD_SCA_RESET',
  FETCH_CARDS_TRANSACTIONS_FAILURE: 'FETCH_CARDS_TRANSACTIONS_FAILURE',
  FETCH_CARDS_TRANSACTIONS_PAGE_REQUEST: 'FETCH_CARDS_TRANSACTIONS_PAGE_REQUEST',
  FETCH_CARDS_TRANSACTIONS_PAGE_SUCCESS: 'FETCH_CARDS_TRANSACTIONS_PAGE_SUCCESS',
  FETCH_CARDS_TRANSACTIONS_REQUEST: 'FETCH_CARDS_TRANSACTIONS_REQUEST',
  FETCH_CARDS_TRANSACTIONS_SUCCESS: 'FETCH_CARDS_TRANSACTIONS_SUCCESS',
  FETCH_CARD_CVV_FAILURE: 'FETCH_CARDS_CVV_FAILURE',
  FETCH_CARD_CVV_REQUEST: 'FETCH_CARDS_CVV_REQUEST',
  FETCH_CARD_CVV_SUCCESS: 'FETCH_CARDS_CVV_SUCCESS',
  FETCH_CARD_LIMITS_FAILURE: 'FETCH_CARD_LIMITS_FAILURE',
  FETCH_CARD_LIMITS_REQUEST: 'FETCH_CARD_LIMITS_REQUEST',
  FETCH_CARD_LIMITS_SUCCESS: 'FETCH_CARD_LIMITS_SUCCESS',
  FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE:
    'FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE',
  FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST:
    'FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST',
  FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS:
    'FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS',
  FETCH_CARD_SECURE_PAYMENT_CONFIGURATION: 'FETCH_CARD_SECURE_PAYMENT_CONFIGURATION',
  FETCH_CARD_STATEMENT_MOVEMENTS_FAILURE: 'FETCH_CARD_STATEMENT_MOVEMENTS_FAILURE',
  FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_FAILURE:
    'FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_FAILURE',
  FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_REQUEST:
    'FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_REQUEST',
  FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_SUCCESS:
    'FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_SUCCESS',
  FETCH_CARD_STATEMENT_MOVEMENTS_REQUEST: 'FETCH_CARD_STATEMENT_MOVEMENTS_REQUEST',
  FETCH_CARD_STATEMENT_MOVEMENTS_SUCCESS: 'FETCH_CARD_STATEMENT_MOVEMENTS_SUCCESS',
  FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR: 'FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR',
  FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR_NO_RECORD:
    'FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR_NO_RECORD',
  FETCH_COMBINED_DEPOSITS_DETAIL_FAILURE: 'FETCH_COMBINED_DEPOSITS_DETAIL_FAILURE',
  FETCH_COMBINED_DEPOSITS_DETAIL_SUCCESS: 'FETCH_COMBINED_DEPOSITS_DETAIL_SUCCESS',
  FETCH_COMBINED_DEPOSITS_FAILURE: 'FETCH_COMBINED_DEPOSITS_FAILURE',
  FETCH_COMBINED_DEPOSITS_SUCCESS: 'FETCH_COMBINED_DEPOSITS_SUCCESS',
  FETCH_CONTRACT_PRECONTRACTUALS_FAILURE: 'FETCH_CONTRACT_PRECONTRACTUALS_FAILURE',
  FETCH_CONTRACT_PRECONTRACTUALS_SUCCESS: 'FETCH_CONTRACT_PRECONTRACTUALS_SUCCESS',
  FETCH_CREDITS_FAILURE: 'FETCH_CREDITS_FAILURE',
  FETCH_CREDITS_REQUEST: 'FETCH_CREDITS_REQUEST',
  FETCH_CREDITS_SUCCESS: 'FETCH_CREDITS_SUCCESS',
  FETCH_CREDIT_GENERAL_DATA: 'FETCH_CREDIT_GENERAL_DATA',
  FETCH_CREDIT_GENERAL_DATA_FAILURE: 'FETCH_CREDIT_GENERAL_DATA_FAILURE',
  FETCH_CREDIT_GENERAL_DATA_SUCCESS: 'FETCH_CREDIT_GENERAL_DATA_SUCCESS',
  FETCH_CREDIT_INTERVENERS: 'FETCH_CREDIT_INTERVENERS',
  FETCH_CREDIT_INTERVENERS_FAILURE: 'FETCH_CREDIT_INTERVENERS_FAILURE',
  FETCH_CREDIT_INTERVENERS_SUCCESS: 'FETCH_CREDIT_INTERVENERS_SUCCESS',
  FETCH_CREDIT_SITUATION_FAILURE: 'FETCH_CREDIT_SITUATION_FAILURE',
  FETCH_CREDIT_SITUATION_REQUEST: 'FETCH_CREDIT_SITUATION_REQUEST',
  FETCH_CREDIT_SITUATION_SUCCESS: 'FETCH_CREDIT_SITUATION_SUCCESS',
  FETCH_DEPOSITS_FAILURE: 'FETCH_DEPOSITS_FAILURE',
  FETCH_DEPOSITS_REQUEST: 'FETCH_DEPOSITS_REQUEST',
  FETCH_DEPOSITS_SUCCESS: 'FETCH_DEPOSITS_SUCCESS',
  FETCH_DEPOSIT_INTERVENERS_FAILURE: 'FETCH_DEPOSIT_INTERVENERS_FAILURE',
  FETCH_DEPOSIT_INTERVENERS_REQUEST: 'FETCH_DEPOSIT_INTERVENERS_REQUEST',
  FETCH_DEPOSIT_INTERVENERS_SUCCESS: 'FETCH_DEPOSIT_INTERVENERS_SUCCESS',
  FETCH_DEVICES_CLEAR: 'FETCH_DEVICES_CLEAR',
  FETCH_DEVICES_CLEAR_SESSIONS: 'FETCH_DEVICES_CLEAR_SESSIONS',
  FETCH_DEVICES_FAILURE: 'FETCH_DEVICES_FAILURE',
  FETCH_DEVICES_IN_PROGRESS: 'FETCH_DEVICES_IN_PROGRESS',
  FETCH_DEVICES_SUCCESS: 'FETCH_DEVICES_SUCCESS',
  FETCH_DEVICE_ACTION_ERROR: 'FETCH_DEVICE_ACTION_ERROR',
  FETCH_DEVICE_ACTION_IN_PROGRESS: 'FETCH_DEVICE_ACTION_IN_PROGRESS',
  FETCH_DEVICE_ACTION_SUCCESS: 'FETCH_DEVICE_ACTION_SUCCESS',
  FETCH_DEVICE_ENROLMENT_DATA_TOKEN: 'FETCH_DEVICE_ENROLMENT_DATA_TOKEN',
  FETCH_DEVICE_ENROLMENT_ERROR: 'FETCH_DEVICE_ENROLMENT_ERROR',
  FETCH_DEVICE_ENROLMENT_INFORMATION: 'FETCH_DEVICE_ENROLMENT_INFORMATION',
  FETCH_DEVICE_ENROLMENT_IN_PROGRESS: 'FETCH_DEVICE_ENROLMENT_IN_PROGRESS',
  FETCH_DEVICE_ENROLMENT_STATE: 'FETCH_DEVICE_ENROLMENT_STATE',
  FETCH_DEVICE_ENROLMENT_SUCCESS: 'FETCH_DEVICE_ENROLMENT_SUCCESS',
  FETCH_DOCUMENTS_FILTER: 'FETCH_DOCUMENTS_FILTER',
  FETCH_DOCUMENTS_FILTER_CLEAR: 'FETCH_DOCUMENTS_FILTER_CLEAR',
  FETCH_DOCUMENTS_FILTER_RESET: 'FETCH_DOCUMENTS_FILTER_RESET',
  FETCH_DOCUMENTS_GROUP_CHANGE: 'FETCH_DOCUMENTS_GROUP_CHANGE',
  FETCH_DOCUMENTS_PDF_FAILURE: 'FETCH_DOCUMENTS_PDF_FAILURE',
  FETCH_DOCUMENTS_PDF_REQUEST: 'FETCH_DOCUMENTS_PDF_REQUEST',
  FETCH_DOCUMENTS_PDF_SUCCESS: 'FETCH_DOCUMENTS_PDF_SUCCESS',
  FETCH_DOCUMENTS_REQUEST: 'FETCH_DOCUMENTS_REQUEST',
  FETCH_DOCUMENTS_RESET: 'FETCH_DOCUMENTS_RESET',
  FETCH_DOCUMENTS_SUCCESS: 'FETCH_DOCUMENTS_SUCCESS',
  FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_FAILURE: 'FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_FAILURE',
  FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_SUCCESS: 'FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_SUCCESS',
  FETCH_ECOMMERCE_KEY_FAILURE: 'FETCH_ECOMMERCE_KEY_FAILURE',
  FETCH_ECOMMERCE_KEY_REQUEST: 'FETCH_ECOMMERCE_KEY_REQUEST',
  FETCH_ECOMMERCE_KEY_SUCCESS: 'FETCH_ECOMMERCE_KEY_SUCCESS',
  FETCH_ECOMMERCE_MODIFY_KEY_REQUEST: 'FETCH_ECOMMERCE_MODIFY_KEY_REQUEST',
  FETCH_ECOMMERCE_MODIFY_KEY_SUCCESS: 'FETCH_ECOMMERCE_MODIFY_KEY_SUCCESS',
  FETCH_ECOMMERCE_MODIFY_KEY_FAILURE: 'FETCH_ECOMMERCE_MODIFY_KEY_FAILURE',
  FETCH_EMITED_TRANSFERS_DETAILS_REQUEST: 'FETCH_EMITED_TRANSFER_DETAILS_REQUEST',
  FETCH_EMITED_TRANSFERS_FAILURE: 'FETCH_EMITED_TRANSFERS_FAILURE',
  FETCH_EMITED_TRANSFERS_NEXT_PAGE_REQUEST: 'FETCH_EMITED_TRANSFERS_NEXT_PAGE_REQUEST',
  FETCH_EMITED_TRANSFERS_NEXT_PAGE_SUCCESS: 'FETCH_EMITED_TRANSFERS_NEXT_PAGE_SUCCESS',
  FETCH_EMITED_TRANSFERS_REQUEST: 'FETCH_EMITED_TRANSFERS_REQUEST',
  FETCH_EMITED_TRANSFERS_SUCCESS: 'FETCH_EMITED_TRANSFERS_SUCCESS',
  FETCH_EMITED_TRANSFER_LIQUIDATIONS_FAILURE: 'FETCH_EMITED_TRANSFER_LIQUIDATIONS_FAILURE',
  FETCH_EMITED_TRANSFER_LIQUIDATIONS_REQUEST: 'FETCH_EMITED_TRANSFER_LIQUIDATIONS_REQUEST',
  FETCH_EMITED_TRANSFER_LIQUIDATIONS_SUCCESS: 'FETCH_EMITED_TRANSFER_LIQUIDATIONS_SUCCESS',
  FETCH_GENERIC_DOCUMENTS_FAILURE: 'FETCH_GENERIC_DOCUMENTS_FAILURE',
  FETCH_GENERIC_DOCUMENTS_REQUEST: 'FETCH_GENERIC_DOCUMENTS_REQUEST',
  FETCH_GENERIC_DOCUMENTS_SUCCESS: 'FETCH_GENERIC_DOCUMENTS_SUCCESS',
  FETCH_OK_API_DOCUMENTS_FAILURE: 'FETCH_OK_API_DOCUMENTS_FAILURE',
  FETCH_OK_API_DOCUMENTS_REQUEST: 'FETCH_OK_API_DOCUMENTS_REQUEST',
  FETCH_OK_API_DOCUMENTS_SUCCESS: 'FETCH_OK_API_DOCUMENTS_SUCCESS',
  FETCH_GET_INTERSTITIAL_CONTENT_FAILURE: 'FETCH_GET_INTERSTITIAL_CONTENT_FAILURE',
  FETCH_GET_INTERSTITIAL_CONTENT_IN_PROGRESS: 'FETCH_GET_INTERSTITIAL_CONTENT_IN_PROGRESS',
  FETCH_GET_INTERSTITIAL_CONTENT_SUCCESS: 'FETCH_GET_INTERSTITIAL_CONTENT_SUCCESS',
  FETCH_GET_INTERSTITIAL_TYPE_FAILURE: 'FETCH_GET_INTERSTITIAL_TYPE_FAILURE',
  FETCH_GET_INTERSTITIAL_TYPE_IN_PROGRESS: 'FETCH_GET_INTERSTITIAL_TYPE_IN_PROGRESS',
  FETCH_GET_INTERSTITIAL_TYPE_SUCCESS: 'FETCH_GET_INTERSTITIAL_TYPE_SUCCESS',
  FETCH_HIRED_PRODUCT_DETAILS_FAILURE: 'FETCH_HIRED_PRODUCT_DETAILS_FAILURE',
  FETCH_HIRED_PRODUCT_DETAILS_REQUEST: 'FETCH_HIRED_PRODUCT_DETAILS_REQUEST',
  FETCH_HIRED_PRODUCT_DETAILS_SUCCESS: 'FETCH_HIRED_PRODUCT_DETAILS_SUCCESS',
  FETCH_HIRING_PRODUCT_BANNERS_FAILURE: 'FETCH_HIRING_PRODUCT_BANNERS_FAILURE',
  FETCH_HIRING_PRODUCT_BANNERS_REQUEST: 'FETCH_HIRING_PRODUCT_BANNERS_REQUEST',
  FETCH_HIRING_PRODUCT_BANNERS_RESET: 'FETCH_HIRING_PRODUCT_BANNERS_RESET',
  FETCH_HIRING_PRODUCT_BANNERS_SUCCESS: 'FETCH_HIRING_PRODUCT_BANNERS_SUCCESS',
  FETCH_IBAN_FAILURE: 'FETCH_IBAN_FAILURE',
  FETCH_IBAN_REQUEST: 'FETCH_IBAN_REQUEST',
  FETCH_IBAN_SUCCESS: 'FETCH_IBAN_SUCCESS',
  FETCH_IMMEDIATE_CREDIT_ACCOUNTS_FAILURE: 'FETCH_IMMEDIATE_CREDIT_ACCOUNTS_FAILURE',
  FETCH_IMMEDIATE_CREDIT_ACCOUNTS_REQUEST: 'FETCH_IMMEDIATE_CREDIT_ACCOUNTS_REQUEST',
  FETCH_IMMEDIATE_CREDIT_ACCOUNTS_SUCCESS: 'FETCH_IMMEDIATE_CREDIT_ACCOUNTS_SUCCESS',
  FETCH_IMPOSITIONS_COMPLETED: 'FETCH_IMPOSITIONS_COMPLETED',
  RESET_FETCHED_IMPOSITIONS: 'RESET_FETCHED_IMPOSITIONS',
  FETCH_IMPOSITIONS_FAILURE: 'FETCH_IMPOSITIONS_FAILURE',
  FETCH_IMPOSITIONS_REQUEST: 'FETCH_IMPOSITIONS_REQUEST',
  FETCH_IMPOSITIONS_SUCCESS: 'FETCH_IMPOSITIONS_SUCCESS',
  FETCH_INCREASE_INTERSTITIAL_SHOWN_FAILURE: 'FETCH_INCREASE_INTERSTITIAL_SHOWN_FAILURE',
  FETCH_INCREASE_INTERSTITIAL_SHOWN_SUCCESS: 'FETCH_INCREASE_INTERSTITIAL_SHOWN_SUCCESS',
  FETCH_INTERSTITIAL_FAILURE: 'FETCH_INTERSTITIAL_FAILURE',
  FETCH_INTERSTITIAL_IN_PROGRESS: 'FETCH_INTERSTITIAL_IN_PROGRESS',
  FETCH_INTERSTITIAL_SUCCESS: 'FETCH_INTERSTITIAL_SUCCESS',
  FETCH_KEY_SIGNATURE_DATA_FAILURE: 'FETCH_KEY_SIGNATURE_DATA_FAILURE',
  FETCH_KEY_SIGNATURE_DATA_REQUEST: 'FETCH_KEY_SIGNATURE_DATA_REQUEST',
  FETCH_KEY_SIGNATURE_DATA_SUCCESS: 'FETCH_KEY_SIGNATURE_DATA_SUCCESS',
  FETCH_LEGAL_DOCUMENTATION_FAILURE: 'FETCH_LEGAL_DOCUMENTATION_FAILURE',
  FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS: 'FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS',
  FETCH_LEGAL_DOCUMENTATION_REQUEST: 'FETCH_LEGAL_DOCUMENTATION_REQUEST',
  FETCH_LEGAL_DOCUMENTATION_SUCCESS: 'FETCH_LEGAL_DOCUMENTATION_SUCCESS',
  FETCH_LIQUIDATIONS_DETAILS_COMPLETED: 'FETCH_LIQUIDATIONS_DETAILS_COMPLETED',
  FETCH_LIQUIDATIONS_DETAILS_FAILURE: 'FETCH_LIQUIDATIONS_DETAILS_FAILURE',
  FETCH_LIQUIDATIONS_DETAILS_SUCCESS: 'FETCH_LIQUIDATIONS_DETAILS_SUCCESS',
  FETCH_LIQUIDATIONS_FAILURE: 'FETCH_LIQUIDATIONS_FAILURE',
  FETCH_LIQUIDATIONS_PERIOD_FAILURE: 'FETCH_LIQUIDATION_PERIODS_FAILURE',
  FETCH_LIQUIDATIONS_PERIOD_REQUEST: 'FETCH_LIQUIDATION_PERIODS_REQUEST',
  FETCH_LIQUIDATIONS_PERIOD_SUCCESS: 'FETCH_LIQUIDATION_PERIODS_SUCCESS',
  FETCH_LIQUIDATIONS_REQUEST: 'FETCH_LIQUIDATIONS_REQUEST',
  RESET_LIQUIDATIONS_FAILURE: 'RESET_LIQUIDATIONS_FAILURE',
  FETCH_LIQUIDATIONS_SUCCESS: 'FETCH_LIQUIDATIONS_SUCCESS',
  FETCH_MORE_DOCUMENTS_FAILURE: 'FETCH_MORE_DOCUMENTS_FAILURE',
  FETCH_MORE_DOCUMENTS_REQUEST: 'FETCH_MORE_DOCUMENTS_REQUEST',
  FETCH_MORE_DOCUMENTS_SUCCESS: 'FETCH_MORE_DOCUMENTS_SUCCESS',
  FETCH_MOVEMENTS_FAILURE: 'FETCH_MOVEMENTS_FAILURE',
  FETCH_MOVEMENTS_REQUEST: 'FETCH_MOVEMENTS_REQUEST',
  FETCH_MOVEMENTS_SUCCESS: 'FETCH_MOVEMENTS_SUCCESS',
  FETCH_NEXT_ACCOUNT_RETENTIONS_PAGE_SUCCESS: 'FETCH_NEXT_ACCOUNT_RETENTIONS_PAGE_SUCCESS',
  FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_REQUEST: 'FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_REQUEST',
  FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_SUCCESS: 'FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_SUCCESS',
  FETCH_NEXT_AMAZON_COUPON_CODE_INFO_PAGE_REQUEST:
    'FETCH_NEXT_AMAZON_COUPON_CODE_INFO_PAGE_REQUEST',
  FETCH_NEXT_AMAZON_COUPON_CODE_INFO_PAGE_SUCCESS:
    'FETCH_NEXT_AMAZON_COUPON_CODE_INFO_PAGE_SUCCESS',
  FETCH_OK_DISCOUNT_DETAIL: 'FETCH_OK_DISCOUNT_DETAIL',
  FETCH_OK_DISCOUNT_DETAIL_FAILURE: 'FETCH_OK_DISCOUNT_DETAIL_FAILURE',
  FETCH_OK_DISCOUNT_DETAIL_SUCCESS: 'FETCH_OK_DISCOUNT_DETAIL_SUCCESS',
  FETCH_PDF_TO_PRINT_URL_FAILURE: 'FETCH_PDF_TO_PRINT_URL_FAILURE',
  FETCH_PDF_TO_PRINT_URL_IN_PROGRESS: 'FETCH_PDF_TO_PRINT_URL_IN_PROGRESS',
  FETCH_PDF_TO_PRINT_URL_SUCCESS: 'FETCH_PDF_TO_PRINT_URL_SUCCESS',
  FETCH_PRE_CONTRACTUALS_FAILURE: 'FETCH_PRE_CONTRACTUALS_FAILURE',
  FETCH_PRE_CONTRACTUALS_REQUEST: 'FETCH_PRE_CONTRACTUALS_REQUEST',
  FETCH_PRE_CONTRACTUALS_SUCCESS: 'FETCH_PRE_CONTRACTUALS_SUCCESS',
  FETCH_PRE_CONTRACTUALS_OK_API_FAILURE: 'FETCH_PRE_CONTRACTUALS_OK_API_FAILURE',
  FETCH_PRE_CONTRACTUALS_OK_REQUEST: 'FETCH_PRE_CONTRACTUALS_OK_REQUEST',
  FETCH_PRE_CONTRACTUALS_OK_SUCCESS: 'FETCH_PRE_CONTRACTUALS_OK_SUCCESS',
  FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_IN_PROGRESS:
    'FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_IN_PROGRESS',
  FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_SUCCESS:
    'FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_SUCCESS',
  FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_ERROR: 'FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_ERROR',
  FETCH_PROFILE_EDIT_FAILURE: 'FETCH_PROFILE_EDIT_FAILURE',
  FETCH_PROFILE_EDIT_REQUEST: 'FETCH_PROFILE_EDIT_REQUEST',
  FETCH_PROFILE_EDIT_SUCCESS: 'FETCH_PROFILE_EDIT_SUCCESS',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',
  FETCH_PROFILE_REQUEST: 'FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROMOTION_FAILURE: 'FETCH_PROMOTION_FAILURE',
  FETCH_PROMOTION_REQUEST: 'FETCH_PROMOTION_REQUEST',
  FETCH_PROMOTION_SUCCESS: 'FETCH_PROMOTION_SUCCESS',
  FETCH_RECEIPT_DOWNLOAD_FAILURE: 'FETCH_RECEIPT_DOWNLOAD_FAILURE',
  FETCH_RECEIPT_DOWNLOAD_REQUEST: 'FETCH_RECEIPT_DOWNLOAD_REQUEST',
  FETCH_RECEIPT_DOWNLOAD_SUCCESS: 'FETCH_RECEIPT_DOWNLOAD_SUCCESS',
  FETCH_RECEIPT_PREVIEW_FAILURE: 'FETCH_RECEIPT_PREVIEW_FAILURE',
  FETCH_RECEIPT_PREVIEW_REQUEST: 'FETCH_RECEIPT_PREVIEW_REQUEST',
  FETCH_RECEIPT_PREVIEW_SUCCESS: 'FETCH_RECEIPT_PREVIEW_SUCCESS',
  FETCH_RECEIVED_TRANSFERS_DETAILS_FAILURE: 'FETCH_RECEIVED_TRANSFERS_DETAILS_FAILURE',
  FETCH_RECEIVED_TRANSFERS_DETAILS_REQUEST: 'FETCH_RECEIVED_TRANSFERS_DETAILS_REQUEST',
  FETCH_RECEIVED_TRANSFERS_DETAILS_SUCCESS: 'FETCH_RECEIVED_TRANSFERS_DETAILS_SUCCESS',
  FETCH_RECEIVED_TRANSFERS_FAILURE: 'FETCH_RECEIVED_TRANSFERS_FAILURE',
  FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_REQUEST: 'FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_REQUEST',
  FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_SUCCESS: 'FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_SUCCESS',
  FETCH_RECEIVED_TRANSFERS_REQUEST: 'FETCH_RECEIVED_TRANSFERS_REQUEST',
  FETCH_RECEIVED_TRANSFERS_SUCCESS: 'FETCH_RECEIVED_TRANSFERS_SUCCESS',
  FETCH_SADDE_DOCUMENTS_FAILURE: 'FETCH_SADDE_DOCUMENTS_FAILURE',
  FETCH_SADDE_DOCUMENTS_REQUEST: 'FETCH_SADDE_DOCUMENTS_REQUEST',
  FETCH_SADDE_DOCUMENTS_SUCCESS: 'FETCH_SADDE_DOCUMENTS_SUCCESS',
  FETCH_SADDE_PDF_DOCUMENTS_FAILURE: 'FETCH_SADDE_PDF_DOCUMENTS_FAILURE',
  FETCH_SADDE_PDF_DOCUMENTS_REQUEST: 'FETCH_SADDE_PDF_DOCUMENTS_REQUEST',
  FETCH_SADDE_PDF_DOCUMENTS_SUCCESS: 'FETCH_SADDE_PDF_DOCUMENTS_SUCCESS',
  FETCH_SCHEDULED_TRANSFERS_DETAILS_FAILURE: 'FETCH_SCHEDULED_TRANSFERS_DETAILS_FAILURE',
  FETCH_SCHEDULED_TRANSFERS_DETAILS_REQUEST: 'FETCH_SCHEDULED_TRANSFERS_DETAILS_REQUEST',
  FETCH_SCHEDULED_TRANSFERS_DETAILS_SUCCESS: 'FETCH_SCHEDULED_TRANSFERS_DETAILS_SUCCESS',
  FETCH_SCHEDULED_TRANSFERS_FAILURE: 'FETCH_SCHEDULED_TRANSFERS_FAILURE',
  FETCH_SCHEDULED_TRANSFERS_NEXT_PAGE_SUCCESS: 'FETCH_SCHEDULED_TRANSFERS_NEXT_PAGE_SUCCESS',
  FETCH_SCHEDULED_TRANSFERS_REQUEST: 'FETCH_SCHEDULED_TRANSFERS_REQUEST',
  FETCH_SCHEDULED_TRANSFERS_SUCCESS: 'FETCH_SCHEDULED_TRANSFERS_SUCCESS',
  FETCH_SECURITY_KIT_CHANGE_FAILURE: 'FETCH_SECURITY_KIT_CHANGE_FAILURE',
  FETCH_SECURITY_KIT_CHANGE_SUCCESS: 'FETCH_SECURITY_KIT_CHANGE_SUCCESS',
  FETCH_SESSIONS_BY_DEVICE_FAILURE: 'FETCH_SESSIONS_BY_DEVICE_FAILURE',
  FETCH_SESSIONS_BY_DEVICE_IN_PROGRESS: 'FETCH_SESSIONS_BY_DEVICE_IN_PROGRESS',
  FETCH_SESSIONS_BY_DEVICE_SUCCESS: 'FETCH_SESSIONS_BY_DEVICE_SUCCESS',
  FETCH_SESSION_ACTION_ERROR: 'FETCH_SESSION_ACTION_ERROR',
  FETCH_SESSION_ACTION_IN_PROGRESS: 'FETCH_SESSION_ACTION_IN_PROGRESS',
  FETCH_SESSION_ACTION_SUCCESS: 'FETCH_SESSION_ACTION_SUCCESS',
  FETCH_SIGNATURE_KEY_STATUS_ERROR: 'FETCH_SIGNATURE_KEY_STATUS_ERROR',
  FETCH_SIGNATURE_KEY_STATUS_REQUEST: 'FETCH_SIGNATURE_KEY_STATUS_REQUEST',
  FETCH_SIGNATURE_KEY_STATUS_SUCCESS: 'FETCH_SIGNATURE_KEY_STATUS_SUCCESS',
  FILTER_CUSTOM_ACCOUNT_SETTINGS: 'FILTER_CUSTOM_ACCOUNT_SETTINGS',
  FILTER_CUSTOM_CARD_SETTINGS: 'FILTER_CUSTOM_CARD_SETTINGS',
  FIOC_ADD_FILE: 'FIOC_ADD_FILE',
  FIOC_REMOVE_FILE: 'FIOC_REMOVE_FILE',
  FIOC_STATUS_RESET: 'FIOC_STATUS_RESET',
  FIOC_UPDATE_FAILURE: 'FIOC_UPDATE_FAILURE',
  FIOC_UPDATE_SUCCESS: 'FIOC_UPDATE_SUCCESS',
  FIOC_STATUS_REQUEST: 'FIOC_STATUS_REQUEST',
  FIOC_STATUS_SUCCESS: 'FIOC_STATUS_SUCCESS',
  FIOC_STATUS_FAILURE: 'FIOC_STATUS_FAILURE',
  FIOC_UPLOAD_DOCUMENTS_ERROR: 'FIOC_UPLOAD_DOCUMENTS_ERROR',
  FIOC_UPLOAD_DOCUMENTS_REQUEST: 'FIOC_UPLOAD_DOCUMENTS_REQUEST',
  FIOC_UPLOAD_DOCUMENTS_SUCCESS: 'FIOC_UPLOAD_DOCUMENTS_SUCCESS',
  FLAG_BASED_FEATURES_ERROR: 'FLAG_BASED_FEATURES_ERROR',
  FLAG_BASED_FEATURES_REQUEST: 'FLAG_BASED_FEATURES_REQUEST',
  FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY: 'FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY',
  FLAG_BASED_FEATURES_SUCCESS: 'FLAG_BASED_FEATURES_SUCCESS',
  GENERATE_ECARD_PDF_FAILURE: 'GENERATE_ECARD_PDF_FAILURE',
  GENERATE_ECARD_PDF_REQUEST: 'GENERATE_ECARD_PDF_REQUEST',
  GENERATE_ECARD_PDF_SUCCESS: 'GENERATE_ECARD_PDF_SUCCESS',
  GENERATE_OFFLOG_TOKEN_FAILURE: 'GENERATE_OFFLOG_TOKEN_FAILURE',
  GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_CLEAR_STATE: 'GET_ACCOUNT_DETAILS_CERTIFICATES_CLEAR_STATE',
  GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_ERROR: 'GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_ERROR',
  GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_FETCHING: 'GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_FETCHING',
  GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_SUCCESS: 'GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_SUCCESS',
  GET_CARD_MOBILE_RECHARGE_FAILURE: 'GET_CARD_MOBILE_RECHARGE_FAILURE',
  GET_CARD_MOBILE_RECHARGE_REQUEST: 'GET_CARD_MOBILE_RECHARGE_REQUEST',
  GET_CARD_MOBILE_RECHARGE_SUCCESS: 'GET_CARD_MOBILE_RECHARGE_SUCCESS',
  GET_COLLECTIVE_INFO_FAILURE: 'GET_COLLECTIVE_INFO_FAILURE',
  GET_COLLECTIVE_INFO_IN_PROGRESS: 'GET_COLLECTIVE_INFO_IN_PROGRESS',
  GET_COLLECTIVE_INFO_SUCCESS: 'GET_COLLECTIVE_INFO_SUCCESS',
  GET_CONSENTS_FAILURE: 'GET_CONSENTS_FAILURE',
  GET_CONSENTS_IN_PROGRESS: 'GET_CONSENTS_IN_PROGRESS',
  GET_CONSENTS_SUCCESS: 'GET_CONSENTS_SUCCESS',
  GET_CONTRACT_ADDRESS_ERROR: 'GET_CONTRACT_ADDRESS_ERROR',
  GET_CONTRACT_ADDRESS_NO_RESULT_SUCCESS: 'GET_CONTRACT_ADDRESS_NO_RESULT_SUCCESS',
  GET_CONTRACT_ADDRESS_REQUEST: 'GET_CONTRACT_ADDRESS_REQUEST',
  GET_CONTRACT_ADDRESS_SUCCESS: 'GET_CONTRACT_ADDRESS_SUCCESS',
  GET_CREDIT_CARD_LIMITS_FAILURE: 'GET_CREDIT_CARD_LIMITS_FAILURE',
  GET_CREDIT_CARD_LIMITS_SUCCESS: 'GET_CREDIT_CARD_LIMITS_SUCCESS',
  GET_FIOC_DOCUMENTATION_LIST: 'GET_FIOC_DOCUMENTATION_LIST',
  GET_FIOC_DOCUMENTATION_LIST_ERROR: 'GET_FIOC_DOCUMENTATION_LIST_ERROR',
  GET_FIOC_DOCUMENTATION_LIST_SUCCESS: 'GET_FIOC_DOCUMENTATION_LIST_SUCCESS',
  GET_FIOC_DOCUMENTATION_STATUS: 'GET_FIOC_DOCUMENTATION_STATUS',
  GET_FIOC_DOCUMENTATION_STATUS_ERROR: 'GET_FIOC_DOCUMENTATION_STATUS_ERROR',
  GET_FIOC_DOCUMENTATION_STATUS_SUCCESS: 'GET_FIOC_DOCUMENTATION_STATUS_SUCCESS',
  GET_FIOC_FORM_STATUS_ERROR: 'GET_FIOC_FORM_STATUS_ERROR',
  GET_FIOC_FORM_STATUS_IN_PROGRESS: 'GET_FIOC_FORM_STATUS_IN_PROGRESS',
  GET_FIOC_FORM_STATUS_RESET: 'GET_FIOC_FORM_STATUS_RESET',
  GET_FIOC_FORM_STATUS_SUCCESS: 'GET_FIOC_FORM_STATUS_SUCCESS',
  GET_FIOC_STATUS_ERROR: 'GET_FIOC_STATUS_ERROR',
  GET_FIOC_STATUS_IN_PROGRESS: 'GET_FIOC_STATUS_IN_PROGRESS',
  GET_FIOC_STATUS_SUCCESS: 'GET_FIOC_STATUS_SUCCESS',
  GET_KITS: 'GET_KITS',
  GET_MORNING_STAR_INVESTMENT_DATA_FAILURE: 'GET_MORNING_STAR_INVESTMENT_DATA_FAILURE,',
  GET_MORNING_STAR_INVESTMENT_DATA_IN_PROGRESS: 'GET_MORNING_STAR_INVESTMENT_DATA_IN_PROGRESS,',
  GET_MORNING_STAR_INVESTMENT_DATA_SUCCESS: 'GET_MORNING_STAR_INVESTMENT_DATA_SUCCESS,',
  GET_NEXT_GENERIC_DOCUMENT_PAGE: 'GET_NEXT_GENERIC_DOCUMENT_PAGE',
  GET_ONBOARD_DOCUMENT_DATE_ERROR: 'GET_ONBOARD_DOCUMENT_DATE_ERROR',
  GET_ONBOARD_DOCUMENT_DATE_IN_PROGRESS: 'GET_ONBOARD_DOCUMENTS_DATE_IN_PROGRESS',
  GET_ONBOARD_DOCUMENT_DATE_SUCCESS: 'GET_ONBOARD_DOCUMENT_DATE_SUCCESS',
  GET_ONBOARD_DOCUMENT_OK_API_ERROR: 'GET_ONBOARD_DOCUMENT_OK_API_ERROR',
  GET_ONBOARD_DOCUMENT_OK_API_IN_PROGRESS: 'GET_ONBOARD_DOCUMENTS_OK_API_IN_PROGRESS',
  GET_ONBOARD_DOCUMENT_OK_API_SUCCESS: 'GET_ONBOARD_DOCUMENT_OK_API_SUCCESS',
  GET_ONBOARD_DOCUMENT_PDF_ERROR: 'GET_ONBOARD_DOCUMENT_PDF_ERROR',
  GET_ONBOARD_DOCUMENT_PDF_IN_PROGRESS: 'GET_ONBOARD_DOCUMENTS_PDF_IN_PROGRESS',
  GET_ONBOARD_DOCUMENT_PDF_SUCCESS: 'GET_ONBOARD_DOCUMENT_PDF_SUCCESS',
  GET_PERSONETICS_CLICKED_TEASER: 'GET_PERSONETICS_CLICKED_TEASER',
  GET_PERSONETICS_INBOX_INSIGHTS_FAILURE: 'GET_PERSONETICS_INBOX_INSIGHTS_FAILURE',
  GET_PERSONETICS_INBOX_INSIGHTS_REQUEST: 'GET_PERSONETICS_INBOX_INSIGHTS_REQUEST',
  GET_PERSONETICS_INBOX_INSIGHTS_SUCCESS: 'GET_PERSONETICS_INBOX_INSIGHTS_SUCCESS',
  GET_PERSONETICS_INSIGHTS_FAILURE: 'GET_PERSONETICS_INSIGHTS_FAILURE',
  GET_PERSONETICS_INSIGHTS_REQUEST: 'GET_PERSONETICS_INSIGHTS_REQUEST',
  GET_PERSONETICS_INSIGHTS_SUCCESS: 'GET_PERSONETICS_INSIGHTS_SUCCESS',
  GET_PERSONETICS_INSIGHT_DETAILS_FAILURE: 'GET_PERSONETICS_INSIGHT_DETAILS_FAILURE',
  GET_PERSONETICS_INSIGHT_DETAILS_REQUEST: 'GET_PERSONETICS_INSIGHT_DETAILS_REQUEST',
  GET_PERSONETICS_INSIGHT_DETAILS_SUCCESS: 'GET_PERSONETICS_INSIGHT_DETAILS_SUCCESS',
  GET_PERSONETICS_SUBSCRIPTION_STATUS_ERROR: 'GET_PERSONETICS_SUBSCRIPTION_STATUS_ERROR',
  GET_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST: 'GET_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST',
  GET_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS: 'GET_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS',
  GET_PRECONTRACTUAL_DOCUMENT_URL_FAILURE: 'GET_PRECONTRACTUAL_DOCUMENT_URL_FAILURE',
  GET_PRECONTRACTUAL_DOCUMENT_URL_REQUEST: 'GET_PRECONTRACTUAL_DOCUMENT_URL_REQUEST',
  GET_PROFESSION_CNAE: 'GET_PROFESSION_CNAE',
  GET_PROFESSION_CNAE_CATEGORY_IS_FETCHING: 'GET_PROFESSION_CNAE_CATEGORY_IS_FETCHING',
  GET_PROFESSION_CNAE_CATEGORY_ITEM_IS_FETCHING: 'GET_PROFESSION_CNAE_CATEGORY_ITEM_IS_FETCHING',
  GET_PROFESSION_CNAE_CATEGORY_ITEM_SUCCESS: 'GET_PROFESSION_CNAE_CATEGORY_ITEM_SUCCESS',
  GET_PROFESSION_CNAE_CATEGORY_SUCCESS: 'GET_PROFESSION_CNAE_CATEGORY_SUCCESS',
  GET_PROFESSION_CNAE_IS_FETCHING: 'GET_PROFESSION_CNAE_IS_FETCHING',
  GET_PROFESSION_CNAE_UNCATEGORIZED_IS_FETCHING: 'GET_PROFESSION_CNAE_UNCATEGORIZED_IS_FETCHING',
  GET_PROFESSION_CNAE_UNCATEGORIZED_SUCCESS: 'GET_PROFESSION_CNAE_UNCATEGORIZED_SUCCESS',
  GET_PROFESSION_CNO: 'GET_PROFESSION_CNO',
  GET_PROFESSION_CNO_IS_FETCHING: 'GET_PROFESSION_CNO_IS_FETCHING',
  GET_PROFESSION_CNO_PRIVATE: 'GET_PROFESSION_CNO_PRIVATE',
  GET_PROFESSION_CNO_PRIVATE_IS_FETCHING: 'GET_PROFESSION_CNO_PRIVATE_IS_FETCHING',
  GET_PROFESSION_CNO_PRIVATE_ITEM_IS_FETCHING:
    'GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_ITEM_IS_FETCHING',
  GET_PROFESSION_CNO_PRIVATE_ITEM_SUCCESS: 'GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_ITEM_SUCCESS',
  GET_PROFESSION_CNO_PUBLIC_ITEM_IS_FETCHING: 'GET_PROFESSION_CNO_PUBLIC_ITEM_IS_FETCHING',
  GET_PROFESSION_CNO_PUBLIC_ITEM_SUCCESS: 'GET_PROFESSION_CNO_PUBLIC_ITEM_SUCCESS',
  GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_IS_FETCHING:
    'GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_IS_FETCHING',
  GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_SUCCESS: 'GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_SUCCESS',
  GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_IS_FETCHING:
    'GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_IS_FETCHING',
  GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_SUCCESS: 'GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_SUCCESS',
  GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',
  GET_REPORTS_IN_PROGRESS: 'GET_REPORTS_IN_PROGRESS',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_SECTOR_ITEM_IS_FETCHING: 'GET_SECTOR_ITEM_IS_FETCHING',
  GET_SECTOR_ITEM_SUCCESS: 'GET_SECTOR_ITEM_SUCCESS',
  GET_SECTOR_SUBCATEGORIES_IS_FETCHING: 'GET_SECTOR_SUBCATEGORIES_IS_FETCHING',
  GET_SECTOR_SUBCATEGORIES_SUCCESS: 'GET_SECTOR_SUBCATEGORIES_SUCCESS',
  GET_SHIPPING_ADDRESS_LIST_FAILURE: 'GET_SHIPPING_ADDRESS_LIST_FAILURE',
  GET_SHIPPING_ADDRESS_LIST_REQUEST: 'GET_SHIPPING_ADDRESS_LIST_REQUEST',
  GET_SHIPPING_ADDRESS_LIST_SUCCESS: 'GET_SHIPPING_ADDRESS_LIST_SUCCESS',
  GET_TOPBAR_IN_PROGRESS: 'GET_TOPBAR_IN_PROGRESS',
  GET_TOPBAR_SUCCESS: 'GET_TOPBAR_SUCCESS',
  GET_FOOTER_IN_PROGRESS: 'GET_FOOTER_IN_PROGRESS',
  GET_FOOTER_SUCCESS: 'GET_FOOTER_SUCCESS',
  GET_FOOTER_ERROR: 'GET_FOOTER_ERROR',
  GET_TINK_CREATE_USER_BANKS_REQUEST: 'GET_TINK_CREATE_USER_BANKS_REQUEST',
  GET_TINK_CREATE_USER_BANKS_SUCCESS: 'GET_TINK_CREATE_USER_BANKS_SUCCESS',
  GET_TINK_CREATE_USER_BANKS_FAILURE: 'GET_TINK_CREATE_USER_BANKS_FAILURE',
  GET_TINK_URL_REQUEST: 'GET_TINK_URL_REQUEST',
  GET_TINK_URL_SUCCESS: 'GET_TINK_URL_SUCCESS',
  GLOBAL_POSITION_CATEGORIES_EXPENSES_RESET: 'GLOBAL_POSITION_CATEGORIES_EXPENSES_RESET',
  GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE: 'GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE',
  GLOBAL_POSITION_EXPENSES_DETAIL_REQUEST: 'GLOBAL_POSITION_EXPENSES_DETAIL_REQUEST',
  GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS: 'GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS',
  GLOBAL_POSITION_EXPENSES_REQUEST: 'GLOBAL_POSITION_EXPENSES_REQUEST',
  GLOBAL_POSITION_EXPENSES_REQUEST_FAILURE: 'GLOBAL_POSITION_EXPENSES_REQUEST_FAILURE',
  GLOBAL_POSITION_EXPENSES_REQUEST_SUCCESS: 'GLOBAL_POSITION_EXPENSES_REQUEST_SUCCESS',
  GLOBAL_POSITION_EXPENSES_RESET: 'GLOBAL_POSITION_EXPENSES_RESET',
  GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_DATE: 'GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_DATE',
  GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_MONTH: 'GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_MONTH',
  GLOBAL_POSITION_EXPENSES_TOTAL_FAILURE: 'GLOBAL_POSITION_EXPENSES_TOTAL_FAILURE',
  GLOBAL_POSITION_EXPENSES_TOTAL_REQUEST: 'GLOBAL_POSITION_EXPENSES_TOTAL_REQUEST',
  GLOBAL_POSITION_EXPENSES_TOTAL_SUCCESS: 'GLOBAL_POSITION_EXPENSES_TOTAL_SUCCESS',
  GLOBAL_POSITION_EXPENSES_UPDATE_EXPENSES: 'GLOBAL_POSITION_EXPENSES_UPDATE_EXPENSES',
  GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS: 'GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS',
  GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS_BY_CATEGORY:
    'GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS_BY_CATEGORY',
  GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_FAILURE: 'GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_FAILURE',
  GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_REQUEST: 'GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_REQUEST',
  GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_SUCCESS: 'GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_SUCCESS',
  GLOBAL_POSITION_REQUEST: 'GLOBAL_POSITION_REQUEST',
  GLOBAL_POSITION_REQUEST_FAILURE: 'GLOBAL_POSITION_REQUEST_FAILURE',
  GLOBAL_POSITION_REQUEST_SUCCESS: 'GLOBAL_POSITION_REQUEST_SUCCESS',
  GLOBAL_POSITION_SET_OUTDATED: 'GLOBAL_POSITION_SET_OUTDATED',
  GLOBAL_WEALTH_REQUEST: 'GLOBAL_WEALTH_REQUEST',
  PG_WEALTH_API_SUCCESS: 'PG_WEALTH_API_SUCCESS',
  PG_WEALTH_API_FAILURE: 'PG_WEALTH_API_FAILURE',
  GLOBAL_VARIABLES_FAILURE: 'GLOBAL_VARIABLES_FAILURE',
  GLOBAL_VARIABLES_REQUEST: 'GLOBAL_VARIABLES_REQUEST',
  GLOBAL_VARIABLES_SUCCESS: 'GLOBAL_VARIABLES_SUCCESS',
  GLOSSARY_CLOSED: 'GLOSSARY_CLOSED',
  GLOSSARY_LETTER_SELECTED: 'GLOSSARY_LETTER_SELECTED',
  GLOSSARY_OPENED: 'GLOSSARY_OPENED',
  GLOSSARY_QUERY_CHANGED: 'GLOSSARY_QUERY_CHANGED',
  GLOSSARY_RESULT_CLOSED: 'GLOSSARY_RESULT_CLOSED',
  GLOSSARY_RESULT_SELECTED: 'GLOSSARY_RESULT_SELECTED',
  HANDLE_ACCEPT_LEGAL_INPROGRESS: 'HANDLE_ACCEPT_LEGAL_INPROGRESS',
  HANDLE_ACCEPT_LEGAL_SUCCESS: 'HANDLE_ACCEPT_LEGAL_SUCCESS',
  HANDLE_ACCEPT_LEGAL_FAILURE: 'HANDLE_ACCEPT_LEGAL_FAILURE',
  HANDLE_ROW_CLICK_PERIODICAL_CONTRIBUTION_TABLE: 'HANDLE_ROW_CLICK_PERIODICAL_CONTRIBUTION_TABLE',
  HIDE_DEPOSIT_OFFER_POST_REQUEST: 'HIDE_DEPOSIT_OFFER_POST_REQUEST',
  HIDE_DEPOSIT_OFFER_POST_SUCCESS: 'HIDE_DEPOSIT_OFFER_POST_SUCCESS',
  HIDE_DEPOSIT_OFFER_POST_FAILED: 'HIDE_DEPOSIT_OFFER_POST_FAILED',
  HIDE_DEPOSIT_OFFER_POST_RESET: 'HIDE_DEPOSIT_OFFER_POST_RESET',
  HIDE_KEEPALIVE_POPUP: 'HIDE_KEEPALIVE_POPUP',
  HIDE_LOGOUT_POPUP: 'HIDE_LOGOUT_POPUP',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  HIDE_NOTIFICATION_MODAL: 'HIDE_NOTIFICATION_MODAL',
  HIDE_TOOLTIP: 'HIDE_TOOLTIP',
  HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_ERROR: 'HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_ERROR',
  HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_SUCCESS: 'HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_SUCCESS',
  HIRE_ACCOUNT_SET_PIN_ERROR: 'HIRE_ACCOUNT_SET_PIN_ERROR',
  HIRE_ACCOUNT_SET_PIN_SUCCESS: 'HIRE_ACCOUNT_SET_PIN_SUCCESS',
  HIRE_ACCOUNT_SUCCESS: 'HIRE_ACCOUNT_SUCCESS',
  HIRE_CARD_LOADING: 'HIRE_CARD_LOADING',
  HIRE_CARD_AT_HOME_ABROAD_FAILURE: 'HIRE_CARD_AT_HOME_ABROAD_FAILURE',
  HIRE_CARD_AT_HOME_ABROAD_REQUEST: 'HIRE_CARD_AT_HOME_ABROAD_REQUEST',
  HIRE_CARD_AT_HOME_ABROAD_RESET: 'HIRE_CARD_AT_HOME_ABROAD_RESET',
  HIRE_CARD_AT_HOME_ABROAD_SET_ACCOUNT: 'HIRE_CARD_AT_HOME_ABROAD_SET_ACCOUNT',
  HIRE_CARD_AT_HOME_ABROAD_SET_AGREEMENT_CHECKBOX:
    'HIRE_CARD_AT_HOME_ABROAD_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_AT_HOME_ABROAD_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_AT_HOME_ABROAD_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_AT_HOME_ABROAD_SET_VISIBLE_STEP: 'HIRE_CARD_AT_HOME_ABROAD_SET_VISIBLE_STEP',
  HIRE_CARD_AT_HOME_ABROAD_SUCCESS: 'HIRE_CARD_AT_HOME_ABROAD_SUCCESS',
  HIRE_CARD_AT_HOME_ABROAD_VALIDATE_STEP: 'HIRE_CARD_AT_HOME_ABROAD_VALIDATE_STEP',
  HIRE_CARD_AT_HOME_ABROAD_WILL_CANCEL: 'HIRE_CARD_AT_HOME_ABROAD_WILL_CANCEL',
  HIRE_CARD_DIAMOND_PACK_FAILURE: 'HIRE_CARD_DIAMOND_PACK_FAILURE',
  HIRE_CARD_DIAMOND_PACK_REQUEST: 'HIRE_CARD_DIAMOND_PACK_REQUEST',
  HIRE_CARD_DIAMOND_PACK_RESET: 'HIRE_CARD_DIAMOND_PACK_RESET',
  HIRE_CARD_DIAMOND_PACK_SET_ACCOUNT: 'HIRE_CARD_DIAMOND_PACK_SET_ACCOUNT',
  HIRE_CARD_DIAMOND_PACK_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_DIAMOND_PACK_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_DIAMOND_PACK_SET_AGREEMENT_CHECKBOX_CC:
    'HIRE_CARD_DIAMOND_PACK_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_DIAMOND_PACK_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_DIAMOND_PACK_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_DIAMOND_PACK_SET_VISIBLE_STEP: 'HIRE_CARD_DIAMOND_PACK_SET_VISIBLE_STEP',
  HIRE_CARD_DIAMOND_PACK_SUCCESS: 'HIRE_CARD_DIAMOND_PACK_SUCCESS',
  HIRE_CARD_DIAMOND_PACK_VALIDATE_ACCOUNT: 'HIRE_CARD_DIAMOND_PACK_VALIDATE_ACCOUNT',
  HIRE_CARD_DIAMOND_PACK_VALIDATE_STEP: 'HIRE_CARD_DIAMOND_PACK_VALIDATE_STEP',
  HIRE_CARD_DIAMOND_PACK_WILL_CANCEL: 'HIRE_CARD_DIAMOND_PACK_WILL_CANCEL',
  HIRE_CARD_ECARD_FAILURE: 'HIRE_CARD_ECARD_FAILURE',
  HIRE_CARD_ECARD_GENERATE_PDF_FAILURE: 'HIRE_CARD_ECARD_GENERATE_PDF_FAILURE',
  HIRE_CARD_ECARD_GENERATE_PDF_REQUEST: 'HIRE_CARD_ECARD_GENERATE_PDF_REQUEST',
  HIRE_CARD_ECARD_GENERATE_PDF_SUCCESS: 'HIRE_CARD_ECARD_GENERATE_PDF_SUCCESS',
  HIRE_CARD_ECARD_GET_TEXT_RELIEF_SUCCESS: 'HIRE_CARD_ECARD_GET_TEXT_RELIEF_SUCCESS',
  HIRE_CARD_ECARD_REQUEST: 'HIRE_CARD_ECARD_REQUEST',
  HIRE_CARD_ECARD_RESET: 'HIRE_CARD_ECARD_RESET',
  HIRE_CARD_ECARD_SET_ACCOUNT: 'HIRE_CARD_ECARD_SET_ACCOUNT',
  HIRE_CARD_ECARD_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_ECARD_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_ECARD_SET_ALL_ACCOUNTS_TO_INACTIVE: 'HIRE_CARD_ECARD_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_ECARD_SET_VISIBLE_STEP: 'HIRE_CARD_ECARD_SET_VISIBLE_STEP',
  HIRE_CARD_ECARD_SUCCESS: 'HIRE_CARD_ECARD_SUCCESS',
  HIRE_CARD_ECARD_TEXT_RELIEF_SUCCESS: 'HIRE_CARD_ECARD_TEXT_RELIEF_SUCCESS',
  HIRE_CARD_ECARD_VALIDATE_STEP: 'HIRE_CARD_ECARD_VALIDATE_STEP',
  HIRE_CARD_ECARD_WILL_CANCEL: 'HIRE_CARD_ECARD_WILL_CANCEL',
  HIRE_CARD_IMAGE_SELECTOR_FAILURE: 'HIRE_CARD_IMAGE_SELECTOR_FAILURE',
  HIRE_CARD_IMAGE_SELECTOR_REQUEST: 'HIRE_CARD_IMAGE_SELECTOR_REQUEST',
  HIRE_CARD_IMAGE_SELECTOR_RESET: 'HIRE_CARD_IMAGE_SELECTOR_RESET',
  HIRE_CARD_IMAGE_SELECTOR_SUCCESS: 'HIRE_CARD_IMAGE_SELECTOR_SUCCESS',
  HIRE_CARD_IMAGE_UPDATE_SELECTED_ID: 'HIRE_CARD_IMAGE_UPDATE_SELECTED_ID',
  HIRE_CARD_IMAGE_UPDATE_VALIDATE_STEP: 'HIRE_CARD_IMAGE_UPDATE_VALIDATE_STEP',
  HIRE_CARD_MAESTRO_FAILURE: 'HIRE_CARD_MAESTRO_FAILURE',
  HIRE_CARD_MAESTRO_REQUEST: 'HIRE_CARD_MAESTRO_REQUEST',
  HIRE_CARD_MAESTRO_RESET: 'HIRE_CARD_MAESTRO_RESET',
  HIRE_CARD_MAESTRO_SET_ACCOUNT: 'HIRE_CARD_MAESTRO_SET_ACCOUNT',
  HIRE_CARD_MAESTRO_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_MAESTRO_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_MAESTRO_SET_ALL_ACCOUNTS_TO_INACTIVE: 'HIRE_CARD_MAESTRO_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_MAESTRO_SET_VISIBLE_STEP: 'HIRE_CARD_MAESTRO_SET_VISIBLE_STEP',
  HIRE_CARD_MAESTRO_SUCCESS: 'HIRE_CARD_MAESTRO_SUCCESS',
  HIRE_CARD_MAESTRO_VALIDATE_STEP: 'HIRE_CARD_MAESTRO_VALIDATE_STEP',
  HIRE_CARD_MAESTRO_WILL_CANCEL: 'HIRE_CARD_MAESTRO_WILL_CANCEL',
  HIRE_CARD_METALLIC_FAILURE: 'HIRE_CARD_METALLIC_FAILURE',
  HIRE_CARD_METALLIC_REQUEST: 'HIRE_CARD_METALLIC_REQUEST',
  HIRE_CARD_METALLIC_RESET: 'HIRE_CARD_METALLIC_RESET',
  HIRE_CARD_METALLIC_SET_ACCOUNT: 'HIRE_CARD_METALLIC_SET_ACCOUNT',
  HIRE_CARD_METALLIC_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_METALLIC_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_METALLIC_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_METALLIC_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_METALLIC_SET_VISIBLE_STEP: 'HIRE_CARD_METALLIC_SET_VISIBLE_STEP',
  HIRE_CARD_METALLIC_SUCCESS: 'HIRE_CARD_METALLIC_SUCCESS',
  HIRE_CARD_METALLIC_VALIDATE_STEP: 'HIRE_CARD_METALLIC_VALIDATE_STEP',
  HIRE_CARD_METALLIC_WILL_CANCEL: 'HIRE_CARD_METALLIC_WILL_CANCEL',
  HIRE_CARD_MINICARD_FAILURE: 'HIRE_CARD_MINICARD_FAILURE',
  HIRE_CARD_MINICARD_REQUEST: 'HIRE_CARD_MINICARD_REQUEST',
  HIRE_CARD_MINICARD_RESET: 'HIRE_CARD_MINICARD_RESET',
  HIRE_CARD_MINICARD_SET_ACCOUNT: 'HIRE_CARD_MINICARD_SET_ACCOUNT',
  HIRE_CARD_MINICARD_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_MINICARD_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_MINICARD_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_MINICARD_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_MINICARD_SET_VISIBLE_STEP: 'HIRE_CARD_MINICARD_SET_VISIBLE_STEP',
  HIRE_CARD_MINICARD_SUCCESS: 'HIRE_CARD_MINICARD_SUCCESS',
  HIRE_CARD_MINICARD_VALIDATE_STEP: 'HIRE_CARD_MINICARD_VALIDATE_STEP',
  HIRE_CARD_MINICARD_WILL_CANCEL: 'HIRE_CARD_MINICARD_WILL_CANCEL',
  HIRE_CARD_NAME_SELECTOR_FAILURE: 'HIRE_CARD_NAME_SELECTOR_FAILURE',
  HIRE_CARD_NAME_SELECTOR_NAME_SELECTED: 'HIRE_CARD_NAME_SELECTOR_NAME_SELECTED',
  HIRE_CARD_NAME_SELECTOR_REQUEST: 'HIRE_CARD_NAME_SELECTOR_REQUEST',
  HIRE_CARD_NAME_SELECTOR_RESET: 'HIRE_CARD_NAME_SELECTOR_RESET',
  HIRE_CARD_NAME_SELECTOR_SET_ID: 'HIRE_CARD_NAME_SELECTOR_SET_ID',
  HIRE_CARD_NAME_SELECTOR_SUCCESS: 'HIRE_CARD_NAME_SELECTOR_SUCCESS',
  HIRE_CARD_NERUDA_FAILURE: 'HIRE_CARD_NERUDA_FAILURE',
  HIRE_CARD_NERUDA_REQUEST: 'HIRE_CARD_NERUDA_REQUEST',
  HIRE_CARD_NERUDA_RESET: 'HIRE_CARD_NERUDA_RESET',
  HIRE_CARD_NERUDA_SET_ACCOUNT: 'HIRE_CARD_NERUDA_SET_ACCOUNT',
  HIRE_CARD_NERUDA_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_NERUDA_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_NERUDA_SET_AGREEMENT_CHECKBOX_CC: 'HIRE_CARD_NERUDA_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_NERUDA_SET_ALL_ACCOUNTS_TO_INACTIVE: 'HIRE_CARD_NERUDA_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_NERUDA_SET_DATA_CARD: 'HIRE_CARD_NERUDA_SET_DATA_CARD',
  HIRE_CARD_NERUDA_SET_VISIBLE_STEP: 'HIRE_CARD_NERUDA_SET_VISIBLE_STEP',
  HIRE_CARD_NERUDA_SUCCESS: 'HIRE_CARD_NERUDA_SUCCESS',
  HIRE_CARD_NERUDA_VALIDATE_ACCOUNT: 'HIRE_CARD_NERUDA_VALIDATE_ACCOUNT',
  HIRE_CARD_NERUDA_VALIDATE_STEP: 'HIRE_CARD_NERUDA_VALIDATE_STEP',
  HIRE_CARD_NERUDA_WILL_CANCEL: 'HIRE_CARD_NERUDA_WILL_CANCEL',
  HIRE_CARD_NERUDA_CREDIT_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_NERUDA_CREDIT_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_NERUDA_CREDIT_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_NERUDA_CREDIT_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_NERUDA_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_NERUDA_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_NERUDA_CHECK_RADIO_BUTTON: 'HIRE_CARD_NERUDA_CHECK_RADIO_BUTTON',
  HIRE_CARD_NERUDA_AGREEINECHECKBOX: 'HIRE_CARD_NERUDA_AGREEINECHECKBOX',
  HIRE_CARD_NERUDA_PRINT_DOCUMENT: 'HIRE_CARD_NERUDA_PRINT_DOCUMENT',
  HIRE_CARD_NERUDA_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_NERUDA_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_CREDIT_CARD_VALIDATE_STEP: 'HIRE_CARD_CREDIT_CARD_VALIDATE_STEP',
  HIRE_CARD_NERUDA_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_NERUDA_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_CREDIT_CARD_FAILURE: 'HIRE_CARD_CREDIT_CARD_FAILURE',
  HIRE_CARD_CREDIT_CARD_REQUEST: 'HIRE_CARD_CREDIT_CARD_REQUEST',
  HIRE_CARD_CREDIT_CARD_RESET: 'HIRE_CARD_CREDIT_CARD_RESET',
  HIRE_CARD_CREDIT_CARD_SET_ACCOUNT: 'HIRE_CARD_CREDIT_CARD_SET_ACCOUNT',
  HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX_CC: 'HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_CREDIT_CARD_RESET_AGREEMENT_CHECKBOX_CC: 'HIRE_CARD_CREDIT_CARD_RESET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR: 'HIRE_CARD_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR',
  HIRE_CARD_CREDIT_CARD_SET_ALL_ACCOUNTS_TO_INACTIVE: 'HIRE_CARD_CREDIT_CARD_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_CREDIT_CARD_SET_DATA_CARD: 'HIRE_CARD_CREDIT_CARD_SET_DATA_CARD',
  HIRE_CARD_CREDIT_CARD_RESET_CHECKBOX: 'HIRE_CARD_CREDIT_CARD_RESET_CHECKBOX',
  HIRE_CARD_CREDIT_CARD_SET_VISIBLE_STEP: 'HIRE_CARD_CREDIT_CARD_SET_VISIBLE_STEP',
  HIRE_CARD_CREDIT_CARD_SUCCESS: 'HIRE_CARD_CREDIT_CARD_SUCCESS',
  HIRE_CARD_CREDIT_CARD_VALIDATE_ACCOUNT: 'HIRE_CARD_CREDIT_CARD_VALIDATE_ACCOUNT',
  HIRE_CARD_CREDIT_CARD_VALIDATE_STEP: 'HIRE_CARD_CREDIT_CARD_VALIDATE_STEP',
  HIRE_CARD_CREDIT_CARD_WILL_CANCEL: 'HIRE_CARD_CREDIT_CARD_WILL_CANCEL',
  HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_CREDIT_CARD_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_CREDIT_CARD_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON: 'HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON',
  HIRE_CARD_CREDIT_CARD_AGREEINECHECKBOX: 'HIRE_CARD_CREDIT_CARD_AGREEINECHECKBOX',
  HIRE_CARD_CREDIT_CARD_PRINT_DOCUMENT: 'HIRE_CARD_CREDIT_CARD_PRINT_DOCUMENT',
  HIRE_CARD_CREDIT_CARD_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_CREDIT_CARD_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON_INE_DIR: 'HIRE_CARD_CREDIT_CARD_CHECK_RADIO_BUTTON_INE_DIR',
  HIRE_CARD_CREDIT_CARD_RESET_INIT_SEND_EMAIL: 'HIRE_CARD_CREDIT_CARD_RESET_INIT_SEND_EMAIL',
  HIRE_NEW_CREDIT_CARD_LOADING: 'HIRE_NEW_CREDIT_CARD_LOADING',
  HIRE_NEW_CREDIT_CARD_VALIDATE_STEP: 'HIRE_NEW_CREDIT_CARD_VALIDATE_STEP',
  HIRE_NEW_CREDIT_CARD_FAILURE: 'HIRE_NEW_CREDIT_CARD_FAILURE',
  HIRE_NEW_CREDIT_CARD_VALIDATE_ACCOUNT: 'HIRE_NEW_CREDIT_CARD_VALIDATE_ACCOUNT',
  HIRE_NEW_CREDIT_CARD_SET_DATA_CARD: 'HIRE_NEW_CREDIT_CARD_SET_DATA_CARD',
  HIRE_NEW_CREDIT_CARD_RESET: 'HIRE_NEW_CREDIT_CARD_RESET',
  HIRE_NEW_CREDIT_CARD_RESET_CHECKBOX: 'HIRE_NEW_CREDIT_CARD_RESET_CHECKBOX',
  HIRE_NEW_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR: 'HIRE_NEW_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR',
  HIRE_NEW_CREDIT_CARD_PRINT_DOCUMENT: 'HIRE_NEW_CREDIT_CARD_PRINT_DOCUMENT',
  HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON: 'HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON',
  HIRE_NEW_CREDIT_CARD_LOADING: 'HIRE_NEW_CREDIT_CARD_LOADING',
  HIRE_NEW_CREDIT_CARD_SET_ACCOUNT: 'HIRE_NEW_CREDIT_CARD_SET_ACCOUNT',
  HIRE_NEW_CREDIT_CARD_WILL_CANCEL: 'HIRE_NEW_CREDIT_CARD_WILL_CANCEL',
  HIRE_NEW_CREDIT_CARD_SHOW_MODAL: 'HIRE_NEW_CREDIT_CARD_SHOW_MODAL',
  HIRE_NEW_CREDIT_CARD_CLOSE_MODAL: 'HIRE_NEW_CREDIT_CARD_CLOSE_MODAL',
  HIRE_NEW_CREDIT_CARD_NEXT_CLOSE_MODAL: 'HIRE_NEW_CREDIT_CARD_NEXT_CLOSE_MODAL',
  HIRE_CARD_OPEN_CREDIT_FAILURE: 'HIRE_CARD_OPEN_CREDIT_FAILURE',
  HIRE_CARD_OPEN_CREDIT_REQUEST: 'HIRE_CARD_OPEN_CREDIT_REQUEST',
  HIRE_CARD_OPEN_CREDIT_RESET: 'HIRE_CARD_OPEN_CREDIT_RESET',
  HIRE_CARD_OPEN_CREDIT_SET_ACCOUNT: 'HIRE_CARD_OPEN_CREDIT_SET_ACCOUNT',
  HIRE_CARD_OPEN_CREDIT_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_OPEN_CREDIT_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_OPEN_CREDIT_SET_AGREEMENT_CHECKBOX_CC:
    'HIRE_CARD_OPEN_CREDIT_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_OPEN_CREDIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_OPEN_CREDIT_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_OPEN_CREDIT_SET_VISIBLE_STEP: 'HIRE_CARD_OPEN_CREDIT_SET_VISIBLE_STEP',
  HIRE_CARD_OPEN_CREDIT_SUCCESS: 'HIRE_CARD_OPEN_CREDIT_SUCCESS',
  HIRE_CARD_OPEN_CREDIT_VALIDATE_ACCOUNT: 'HIRE_CARD_OPEN_CREDIT_VALIDATE_ACCOUNT',
  HIRE_CARD_OPEN_CREDIT_VALIDATE_STEP: 'HIRE_CARD_OPEN_CREDIT_VALIDATE_STEP',
  HIRE_CARD_OPEN_CREDIT_WILL_CANCEL: 'HIRE_CARD_OPEN_CREDIT_WILL_CANCEL',
  HIRE_CARD_OPEN_DEBIT_FAILURE: 'HIRE_CARD_OPEN_DEBIT_FAILURE',
  HIRE_CARD_OPEN_DEBIT_REQUEST: 'HIRE_CARD_OPEN_DEBIT_REQUEST',
  HIRE_CARD_OPEN_DEBIT_RESET: 'HIRE_CARD_OPEN_DEBIT_RESET',
  HIRE_CARD_OPEN_DEBIT_SET_ACCOUNT: 'HIRE_CARD_OPEN_DEBIT_SET_ACCOUNT',
  HIRE_CARD_OPEN_DEBIT_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_OPEN_DEBIT_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_OPEN_DEBIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_OPEN_DEBIT_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_OPEN_DEBIT_SET_VISIBLE_STEP: 'HIRE_CARD_OPEN_DEBIT_SET_VISIBLE_STEP',
  HIRE_CARD_OPEN_DEBIT_SUCCESS: 'HIRE_CARD_OPEN_DEBIT_SUCCESS',
  HIRE_CARD_OPEN_DEBIT_BLOCKED_SUCCESS: 'HIRE_CARD_OPEN_DEBIT_BLOCKED_SUCCESS',
  HIRE_CARD_OPEN_DEBIT_VALIDATE_STEP: 'HIRE_CARD_OPEN_DEBIT_VALIDATE_STEP',
  HIRE_CARD_OPEN_DEBIT_WILL_CANCEL: 'HIRE_CARD_OPEN_DEBIT_WILL_CANCEL',
  HIRE_CARD_OPEN_YOUNG_ADD_ACCOUNT: 'HIRE_CARD_OPEN_YOUNG_ADD_ACCOUNT',
  HIRE_CARD_OPEN_YOUNG_FAILURE: 'HIRE_CARD_OPEN_YOUNG_FAILURE',
  HIRE_CARD_OPEN_YOUNG_REQUEST: 'HIRE_CARD_OPEN_YOUNG_REQUEST',
  HIRE_CARD_OPEN_YOUNG_RESET: 'HIRE_CARD_OPEN_YOUNG_RESET',
  HIRE_CARD_OPEN_YOUNG_SET_ACCOUNT: 'HIRE_CARD_OPEN_YOUNG_SET_ACCOUNT',
  HIRE_CARD_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_OPEN_YOUNG_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_OPEN_YOUNG_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_OPEN_YOUNG_SET_ASSOCIATED_ACCOUNT: 'HIRE_CARD_OPEN_YOUNG_SET_ASSOCIATED_ACCOUNT',
  HIRE_CARD_OPEN_YOUNG_SET_EXISTING_OPENYOUNG: 'HIRE_CARD_OPEN_YOUNG_SET_EXISTING_OPENYOUNG',
  HIRE_CARD_OPEN_YOUNG_SET_VISIBLE_STEP: 'HIRE_CARD_OPEN_YOUNG_SET_VISIBLE_STEP',
  HIRE_CARD_OPEN_YOUNG_SUCCESS: 'HIRE_CARD_OPEN_YOUNG_SUCCESS',
  HIRE_CARD_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX: 'HIRE_CARD_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX',
  HIRE_CARD_OPEN_YOUNG_VALIDATE_ACCOUNT: 'HIRE_CARD_OPEN_YOUNG_VALIDATE_ACCOUNT',
  HIRE_CARD_OPEN_YOUNG_VALIDATE_STEP: 'HIRE_CARD_OPEN_YOUNG_VALIDATE_STEP',
  HIRE_CARD_OPEN_YOUNG_WILL_CANCEL: 'HIRE_CARD_OPEN_YOUNG_WILL_CANCEL',
  HIRE_CARD_PIN_SET_FAILURE: 'HIRE_CARD_PIN_SET_FAILURE',
  HIRE_CARD_PIN_SET_FORCE_VALIDATE: 'HIRE_CARD_PIN_SET_FORCE_VALIDATE',
  HIRE_CARD_PIN_SET_REQUEST: 'HIRE_CARD_PIN_SET_REQUEST',
  HIRE_CARD_PIN_SET_RESET: 'HIRE_CARD_PIN_SET_RESET',
  HIRE_CARD_PIN_SET_SET_PIN: 'HIRE_CARD_PIN_SET_SET_PIN',
  HIRE_CARD_PIN_SET_SUCCESS: 'HIRE_CARD_PIN_SET_SUCCESS',
  HIRE_CARD_PIN_SET_VALIDATE_STEP: 'HIRE_CARD_PIN_SET_VALIDATE_STEP',
  HIRE_CARD_PREMIUM_PACK_FAILURE: 'HIRE_CARD_PREMIUM_PACK_FAILURE',
  HIRE_CARD_PREMIUM_PACK_REQUEST: 'HIRE_CARD_PREMIUM_PACK_REQUEST',
  HIRE_CARD_PREMIUM_PACK_RESET: 'HIRE_CARD_PREMIUM_PACK_RESET',
  HIRE_CARD_PREMIUM_PACK_SET_ACCOUNT: 'HIRE_CARD_PREMIUM_PACK_SET_ACCOUNT',
  HIRE_CARD_PREMIUM_PACK_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_PREMIUM_PACK_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_PREMIUM_PACK_SET_AGREEMENT_CHECKBOX_CC:
    'HIRE_CARD_PREMIUM_PACK_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_PREMIUM_PACK_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_PREMIUM_PACK_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_PREMIUM_PACK_SET_VISIBLE_STEP: 'HIRE_CARD_PREMIUM_PACK_SET_VISIBLE_STEP',
  HIRE_CARD_PREMIUM_PACK_SUCCESS: 'HIRE_CARD_PREMIUM_PACK_SUCCESS',
  HIRE_CARD_PREMIUM_PACK_VALIDATE_ACCOUNT: 'HIRE_CARD_PREMIUM_PACK_VALIDATE_ACCOUNT',
  HIRE_CARD_PREMIUM_PACK_VALIDATE_STEP: 'HIRE_CARD_PREMIUM_PACK_VALIDATE_STEP',
  HIRE_CARD_PREMIUM_PACK_WILL_CANCEL: 'HIRE_CARD_PREMIUM_PACK_WILL_CANCEL',
  HIRE_CARD_REVOLVING_FAILURE: 'HIRE_CARD_REVOLVING_FAILURE',
  HIRE_CARD_REVOLVING_GET_SCORING_FAILURE: 'HIRE_CARD_REVOLVING_GET_SCORING_FAILURE',
  HIRE_CARD_REVOLVING_GET_SCORING_REQUEST: 'HIRE_CARD_REVOLVING_GET_SCORING_REQUEST',
  HIRE_CARD_REVOLVING_GET_SCORING_SUCCESS: 'HIRE_CARD_REVOLVING_GET_SCORING_SUCCESS',
  HIRE_CARD_REVOLVING_REQUEST: 'HIRE_CARD_REVOLVING_REQUEST',
  HIRE_CARD_REVOLVING_RESET: 'HIRE_CARD_REVOLVING_RESET',
  HIRE_CARD_REVOLVING_SET_ACCOUNT: 'HIRE_CARD_REVOLVING_SET_ACCOUNT',
  HIRE_CARD_REVOLVING_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_REVOLVING_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_REVOLVING_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_REVOLVING_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_REVOLVING_SET_VISIBLE_STEP: 'HIRE_CARD_REVOLVING_SET_VISIBLE_STEP',
  HIRE_CARD_REVOLVING_SUCCESS: 'HIRE_CARD_REVOLVING_SUCCESS',
  HIRE_CARD_REVOLVING_VALIDATE_ACCOUNT: 'HIRE_CARD_REVOLVING_VALIDATE_ACCOUNT',
  HIRE_CARD_REVOLVING_VALIDATE_STEP: 'HIRE_CARD_REVOLVING_VALIDATE_STEP',
  HIRE_CARD_REVOLVING_WILL_CANCEL: 'HIRE_CARD_REVOLVING_WILL_CANCEL',
  HIRE_CARD_VIA_T_FAILURE: 'HIRE_CARD_VIA_T_FAILURE',
  HIRE_CARD_VIA_T_REQUEST: 'HIRE_CARD_VIA_T_REQUEST',
  HIRE_CARD_VIA_T_RESET: 'HIRE_CARD_VIA_T_RESET',
  HIRE_CARD_VIA_T_SET_ACCOUNT: 'HIRE_CARD_VIA_T_SET_ACCOUNT',
  HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX_CC: 'HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX_CC',
  HIRE_CARD_VIA_T_SET_ALL_ACCOUNTS_TO_INACTIVE: 'HIRE_CARD_VIA_T_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_VIA_T_SET_VISIBLE_STEP: 'HIRE_CARD_VIA_T_SET_VISIBLE_STEP',
  HIRE_CARD_VIA_T_SUCCESS: 'HIRE_CARD_VIA_T_SUCCESS',
  HIRE_CARD_VIA_T_VALIDATE_ACCOUNT: 'HIRE_CARD_VIA_T_VALIDATE_ACCOUNT',
  HIRE_CARD_VIA_T_VALIDATE_STEP: 'HIRE_CARD_VIA_T_VALIDATE_STEP',
  HIRE_CARD_VIA_T_WILL_CANCEL: 'HIRE_CARD_VIA_T_WILL_CANCEL',
  HIRE_CARD_VIRTUAL_CREDIT_FAILURE: 'HIRE_CARD_VIRTUAL_CREDIT_FAILURE',
  HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_FAILURE: 'HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_FAILURE',
  HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_REQUEST: 'HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_REQUEST',
  HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_SUCCESS: 'HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_SUCCESS',
  HIRE_CARD_VIRTUAL_CREDIT_GET_TEXT_RELIEF_SUCCESS:
    'HIRE_CARD_VIRTUAL_CREDIT_GET_TEXT_RELIEF_SUCCESS',
  HIRE_CARD_VIRTUAL_CREDIT_REQUEST: 'HIRE_CARD_VIRTUAL_CREDIT_REQUEST',
  HIRE_CARD_VIRTUAL_CREDIT_RESET: 'HIRE_CARD_VIRTUAL_CREDIT_RESET',
  HIRE_CARD_VIRTUAL_CREDIT_SET_ACCOUNT: 'HIRE_CARD_VIRTUAL_CREDIT_SET_ACCOUNT',
  HIRE_CARD_VIRTUAL_CREDIT_SET_AGREEMENT_CHECKBOX:
    'HIRE_CARD_VIRTUAL_CREDIT_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_VIRTUAL_CREDIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_VIRTUAL_CREDIT_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_VIRTUAL_CREDIT_SET_VISIBLE_STEP: 'HIRE_CARD_VIRTUAL_CREDIT_SET_VISIBLE_STEP',
  HIRE_CARD_VIRTUAL_CREDIT_SUCCESS: 'HIRE_CARD_VIRTUAL_CREDIT_SUCCESS',
  HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_ACCOUNT: 'HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_ACCOUNT',
  HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_STEP: 'HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_STEP',
  HIRE_CARD_VIRTUAL_CREDIT_WILL_CANCEL: 'HIRE_CARD_VIRTUAL_CREDIT_WILL_CANCEL',
  HIRE_CARD_VIRTUAL_DEBIT_FAILURE: 'HIRE_CARD_VIRTUAL_DEBIT_FAILURE',
  HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_FAILURE: 'HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_FAILURE',
  HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_REQUEST: 'HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_REQUEST',
  HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_SUCCESS: 'HIRE_CARD_VIRTUAL_DEBIT_GENERATE_PDF_SUCCESS',
  HIRE_CARD_VIRTUAL_DEBIT_GET_TEXT_RELIEF_SUCCESS:
    'HIRE_CARD_VIRTUAL_DEBIT_GET_TEXT_RELIEF_SUCCESS',
  HIRE_CARD_VIRTUAL_DEBIT_REQUEST: 'HIRE_CARD_VIRTUAL_DEBIT_REQUEST',
  HIRE_CARD_VIRTUAL_DEBIT_RESET: 'HIRE_CARD_VIRTUAL_DEBIT_RESET',
  HIRE_CARD_VIRTUAL_DEBIT_SET_ACCOUNT: 'HIRE_CARD_VIRTUAL_DEBIT_SET_ACCOUNT',
  HIRE_CARD_VIRTUAL_DEBIT_SET_AGREEMENT_CHECKBOX: 'HIRE_CARD_VIRTUAL_DEBIT_SET_AGREEMENT_CHECKBOX',
  HIRE_CARD_VIRTUAL_DEBIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_CARD_VIRTUAL_DEBIT_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_CARD_VIRTUAL_DEBIT_SET_VISIBLE_STEP: 'HIRE_CARD_VIRTUAL_DEBIT_SET_VISIBLE_STEP',
  HIRE_CARD_VIRTUAL_DEBIT_SUCCESS: 'HIRE_CARD_VIRTUAL_DEBIT_SUCCESS',
  HIRE_CARD_VIRTUAL_DEBIT_VALIDATE_STEP: 'HIRE_CARD_VIRTUAL_DEBIT_VALIDATE_STEP',
  HIRE_CARD_VIRTUAL_DEBIT_WILL_CANCEL: 'HIRE_CARD_VIRTUAL_DEBIT_WILL_CANCEL',
  HIRE_CONTACT_DATA_CREATE_INSTANCE: 'HIRE_CONTACT_DATA_CREATE_INSTANCE',
  HIRE_CONTACT_DATA_FILL_OPEN_YOUNG_PROFILE: 'HIRE_CONTACT_DATA_FILL_OPEN_YOUNG_PROFILE',
  HIRE_CONTACT_DATA_FILL_PROFILE: 'HIRE_CONTACT_DATA_FILL_PROFILE',
  HIRE_CONTACT_DATA_FILL_SHIPPING_ADDRESS_PROFILE:
    'HIRE_CONTACT_DATA_FILL_SHIPPING_ADDRESS_PROFILE',
  HIRE_CONTACT_DATA_RESET: 'HIRE_CONTACT_DATA_RESET',
  HIRE_CONTACT_DATA_SET_FIELD: 'HIRE_CONTACT_DATA_SET_FIELD',
  HIRE_CONTACT_DATA_SET_ORIGIN_COUNTRY: 'HIRE_CONTACT_DATA_SET_ORIGIN_COUNTRY',
  HIRE_CONTACT_DATA_VALIDATE_FIELD: 'HIRE_CONTACT_DATA_VALIDATE_FIELD',
  HIRE_CONTACT_DATA_VALIDATE_STEP: 'HIRE_CONTACT_DATA_VALIDATE_STEP',
  HIRE_CONTRACT_CREDIT_CARD_SUCCESS: 'HIRE_CONTRACT_CREDIT_CARD_SUCCESS',
  HIRE_CONTRACT_FAILURE: 'HIRE_CONTRACT_FAILURE',
  HIRE_CONTRACT_GET_ECARD_TEXTO_RELIEVE_SUCCESS: 'HIRE_CONTRACT_GET_ECARD_TEXTO_RELIEVE_SUCCESS',
  HIRE_CONTRACT_SUCCESS: 'HIRE_CONTRACT_SUCCESS',
  HIRE_CREDIT_LIMIT_FAILURE: 'HIRE_CREDIT_LIMIT_FAILURE',
  HIRE_CREDIT_LIMIT_REQUEST: 'HIRE_CREDIT_LIMIT_REQUEST',
  HIRE_CREDIT_LIMIT_RESET: 'HIRE_CREDIT_LIMIT_RESET',
  HIRE_CREDIT_LIMIT_SET: 'HIRE_CREDIT_LIMIT_SET',
  HIRE_CREDIT_LIMIT_SET_MODAL_VALUE: 'HIRE_CREDIT_LIMIT_SET_MODAL_VALUE',
  HIRE_CREDIT_LIMIT_SUCCESS: 'HIRE_CREDIT_LIMIT_SUCCESS',
  HIRE_CREDIT_LIMIT_TOGGLE_MODAL: 'HIRE_CREDIT_LIMIT_TOGGLE_MODAL',
  HIRE_CREDIT_LIMIT_VALIDATE_STEP: 'HIRE_CREDIT_LIMIT_VALIDATE_STEP',
  HIRE_INFORMATION_FAILURE: 'HIRE_INFORMATION_FAILURE',
  HIRE_INFORMATION_OPEN_DEBIT_ERROR: 'HIRE_INFORMATION_OPEN_DEBIT_ERROR',
  HIRE_INFORMATION_OPEN_DEBIT_SUCCESS: 'HIRE_INFORMATION_OPEN_DEBIT_SUCCESS',
  HIRE_INFORMATION_REQUEST: 'HIRE_INFORMATION_REQUEST',
  HIRE_INFORMATION_RESET: 'HIRE_INFORMATION_RESET',
  HIRE_INFORMATION_SUCCESS: 'HIRE_INFORMATION_SUCCESS',
  HIRE_JUNIOR_ACCOUNT_SUCCESS: 'HIRE_JUNIOR_ACCOUNT_SUCCESS',
  HIRE_PACK_OPEN_YOUNG_FAILURE: 'HIRE_PACK_OPEN_YOUNG_FAILURE',
  HIRE_PACK_OPEN_YOUNG_REQUEST: 'HIRE_PACK_OPEN_YOUNG_REQUEST',
  HIRE_PACK_OPEN_YOUNG_RESET: 'HIRE_PACK_OPEN_YOUNG_RESET',
  HIRE_PACK_OPEN_YOUNG_SET_ACCOUNT: 'HIRE_PACK_OPEN_YOUNG_SET_ACCOUNT',
  HIRE_PACK_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX: 'HIRE_PACK_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX',
  HIRE_PACK_OPEN_YOUNG_SET_ALL_ACCOUNTS_TO_INACTIVE:
    'HIRE_PACK_OPEN_YOUNG_SET_ALL_ACCOUNTS_TO_INACTIVE',
  HIRE_PACK_OPEN_YOUNG_SET_VISIBLE_STEP: 'HIRE_PACK_OPEN_YOUNG_SET_VISIBLE_STEP',
  HIRE_PACK_OPEN_YOUNG_SUCCESS: 'HIRE_PACK_OPEN_YOUNG_SUCCESS',
  HIRE_PACK_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX: 'HIRE_PACK_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX',
  HIRE_PACK_OPEN_YOUNG_VALIDATE_ACCOUNT: 'HIRE_PACK_OPEN_YOUNG_VALIDATE_ACCOUNT',
  HIRE_PACK_OPEN_YOUNG_VALIDATE_STEP: 'HIRE_PACK_OPEN_YOUNG_VALIDATE_STEP',
  HIRE_PACK_OPEN_YOUNG_WILL_CANCEL: 'HIRE_PACK_OPEN_YOUNG_WILL_CANCEL',
  HIRE_PAYMENT_METHOD_RESET: 'HIRE_PAYMENT_METHOD_RESET',
  HIRE_PAYMENT_METHOD_SET_TYPE: 'HIRE_PAYMENT_METHOD_SET_TYPE',
  HIRE_PAYMENT_METHOD_SET_VALUE: 'HIRE_PAYMENT_METHOD_SET_VALUE',
  HIRE_PAYMENT_METHOD_TOOLTIP_SHOWN: 'HIRE_PAYMENT_METHOD_TOOLTIP_SHOWN',
  HIRE_PAYMENT_METHOD_VALIDATE_STEP: 'HIRE_PAYMENT_METHOD_VALIDATE_STEP',
  HIRE_PROMOTION_RESET_STEP: 'HIRE_PROMOTION_RESET_STEP',
  HIRE_PROMOTION_SET_VISIBLE_STEP: 'HIRE_PROMOTION_SET_VISIBLE_STEP',
  HIRE_PROMOTION_WILL_CANCEL: 'HIRE_PROMOTION_WILL_CANCEL',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_FAILURE: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_FAILURE',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_START: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_START',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_SUCCESS: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_SUCCESS',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_FAILURE: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_FAILURE',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_START: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_START',
  HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_SUCCESS: 'HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_SUCCESS',
  HIRE_TRAVEL_PLUS_FAILURE: 'HIRE_TRAVEL_PLUS_FAILURE',
  HIRE_TRAVEL_PLUS_REQUEST: 'HIRE_TRAVEL_PLUS_REQUEST',
  HIRE_TRAVEL_PLUS_RESET: 'HIRE_TRAVEL_PLUS_RESET',
  HIRE_TRAVEL_PLUS_SUCCESS: 'HIRE_TRAVEL_PLUS_SUCCESS',
  HIRING_PRODUCT_INFORMATION_FAILURE: 'HIRING_PRODUCT_INFORMATION_FAILURE',
  HIRING_PRODUCT_INFORMATION_IS_FETCHING: 'HIRING_PRODUCT_INFORMATION_IS_FETCHING',
  HIRING_PRODUCT_INFORMATION_RESET: 'HIRING_PRODUCT_INFORMATION_RESET',
  HIRING_PRODUCT_INFORMATION_SUCCESS: 'HIRING_PRODUCT_INFORMATION_SUCCESS',
  HISTOGRAM_COMPARE_FAILURE: 'HISTOGRAM_COMPARE_FAILURE',
  HISTOGRAM_COMPARE_FETCHING: 'HISTOGRAM_COMPARE_FETCHING',
  HISTOGRAM_COMPARE_RESET: 'HISTOGRAM_COMPARE_RESET',
  HISTOGRAM_COMPARE_SUCCESS: 'HISTOGRAM_COMPARE_SUCCESS',
  HISTOGRAM_EXPENSES_RESET: 'HISTOGRAM_EXPENSES_RESET',
  HISTOGRAM_FAILURE: 'HISTOGRAM_FAILURE',
  HISTOGRAM_FETCHING: 'HISTOGRAM_FETCHING',
  HISTOGRAM_SUCCESS: 'HISTOGRAM_SUCCESS',
  INIT_AMOUNT_FILTER: 'INIT_AMOUNT_FILTER',
  INIT_CHECK_FILTER: 'INIT_CHECK_FILTER',
  INIT_DATE_FILTER: 'INIT_DATE_FILTER',
  INIT_FILTER: 'INIT_FILTER',
  INIT_PERSONETICS_FAILURE: 'INIT_PERSONETICS_FAILURE',
  INIT_PERSONETICS_SUCCESS: 'INIT_PERSONETICS_SUCCESS',
  INIT_SELECT_FILTER: 'INIT_SELECT_FILTER',
  INVESTMENT_FUND_DOCUMENT_STORAGE_ERROR: 'INVESTMENT_FUND_DOCUMENT_STORAGE_ERROR',
  INVESTMENT_FUND_DOCUMENT_STORAGE_REQUEST: 'INVESTMENT_FUND_DOCUMENT_STORAGE_REQUEST',
  INVESTMENT_FUND_DOCUMENT_STORAGE_SUCCESS: 'INVESTMENT_FUND_DOCUMENT_STORAGE_SUCCESS',
  INCREASE_DOCUMENTS_UPLOADED: 'INCREASE_DOCUMENTS_UPLOADED',
  KEEP_ALIVE_ERROR: 'KEEP_ALIVE_ERROR',
  KEEP_ALIVE_SUCCESS: 'KEEP_ALIVE_SUCCESS',
  KEY_SIGNATURE_CHANGE_RETRY_PROCCESS: 'KEY_SIGNATURE_CHANGE_RETRY_PROCCESS',
  KEY_SIGNATURE_CHANGE_RESET: 'KEY_SIGNATURE_CHANGE_RESET',
  KEY_SIGNATURE_CHANGE_SET_FIELD: 'KEY_SIGNATURE_CHANGE_SET_FIELD',
  KEY_SIGNATURE_CHANGE_SET_VISIBLE_STEP: 'KEY_SIGNATURE_CHANGE_SET_VISIBLE_STEP',
  KEY_SIGNATURE_CHANGE_VALIDATE_STEP: 'KEY_SIGNATURE_CHANGE_VALIDATE_STEP',
  KEY_SIGNATURE_CHANGE_WILL_CANCEL: 'KEY_SIGNATURE_CHANGE_WILL_CANCEL',
  KEY_SIGNATURE_CHANGE_WILL_LEAVE: 'KEY_SIGNATURE_CHANGE_WILL_LEAVE',
  KEY_SIGNATURE_UPDATE_FIRST_FIELD: 'KEY_SIGNATURE_UPDATE_FIRST_FIELD',
  KEY_SIGNATURE_UPDATE_SECOND_FIELD: 'KEY_SIGNATURE_UPDATE_SECOND_FIELD',
  LANDING_BOX_TOGGLE: 'LANDING_BOX_TOGGLE',
  LAVANDA_CARDS_FAILURE: 'LAVANDA_CARDS_FAILURE',
  LAVANDA_CARDS_REQUEST: 'LAVANDA_CARDS_REQUEST',
  LAVANDA_CARDS_RESET: 'LAVANDA_CARDS_RESET',
  LAVANDA_CARDS_SUCCESS: 'LAVANDA_CARDS_SUCCESS',
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  ENABLE_SESSION_ERROR_LEVEL_REDIRECT: 'ENABLE_SESSION_ERROR_LEVEL_REDIRECT',
  DISABLE_SESSION_ERROR_LEVEL_REDIRECT: 'DISABLE_SESSION_ERROR_LEVEL_REDIRECT',
  LOGIN_CHANGE_USER: 'LOGIN_CHANGE_USER',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  MARK_INTERSTITIAL_AS_SHOWN: 'MARK_INTERSTITIAL_AS_SHOWN',
  MODIFY_CARD_ACCOUNT_FAILURE: 'MODIFY_CARD_ACCOUNT_FAILURE',
  MODIFY_CARD_ACCOUNT_REQUEST: 'MODIFY_CARD_ACCOUNT_REQUEST',
  MODIFY_CARD_ACCOUNT_RESET: 'MODIFY_CARD_ACCOUNT_RESET',
  MODIFY_CARD_ACCOUNT_SET_CURRENT_ACCOUNT: 'MODIFY_CARD_ACCOUNT_SET_CURRENT_ACCOUNT',
  MODIFY_CARD_ACCOUNT_SET_NEW_ACCOUNT: 'MODIFY_CARD_ACCOUNT_SET_NEW_ACCOUNT',
  MODIFY_CARD_ACCOUNT_SET_VISIBLE_STEP: 'MODIFY_CARD_ACCOUNT_SET_VISIBLE_STEP',
  MODIFY_CARD_ACCOUNT_SUCCESS: 'MODIFY_CARD_ACCOUNT_SUCCESS',
  MODIFY_CARD_ACCOUNT_VALIDATE_STEP: 'MODIFY_CARD_ACCOUNT_VALIDATE_STEP',
  MODIFY_CARD_ACCOUNT_WILL_CANCEL: 'MODIFY_CARD_ACCOUNT_WILL_CANCEL',
  MODIFY_CHORE_AVAILABLE_BALANCE: 'MODIFY_CHORE_AVAILABLE_BALANCE',
  MODIFY_CHORE_CLEAR: 'MODIFY_CHORE_CLEAR',
  MODIFY_CHORE_CONCEPT_CHANGED: 'MODIFY_CHORE_CONCEPT_CHANGED',
  MODIFY_CHORE_CONFIRM_ERROR: 'MODIFY_CHORE_CONFIRM_ERROR',
  MODIFY_CHORE_CONFIRM_REQUEST: 'MODIFY_CHORE_CONFIRM_REQUEST',
  MODIFY_CHORE_CONFIRM_SUCCESS: 'MODIFY_CHORE_CONFIRM_SUCCESS',
  MODIFY_CHORE_DETAILS_ERROR: 'MODIFY_CHORE_DETAILS_ERROR',
  MODIFY_CHORE_DETAILS_REQUEST: 'MODIFY_CHORE_DETAILS_REQUEST',
  MODIFY_CHORE_DETAILS_SUCCESS: 'MODIFY_CHORE_DETAILS_SUCCESS',
  MODIFY_CHORE_RESET: 'MODIFY_CHORE_RESET',
  MODIFY_CHORE_SET_AMOUNT: 'MODIFY_CHORE_SET_AMOUNT',
  MODIFY_CHORE_SET_CHORE_TYPE: 'MODIFY_CHORE_SET_CHORE_TYPE',
  MODIFY_CHORE_SET_REPETITION_OPTION_LIST: 'MODIFY_CHORE_SET_REPETITION_OPTION_LIST',
  MODIFY_CHORE_SET_REPETITION_TYPE: 'MODIFY_CHORE_SET_REPETITION_TYPE',
  MODIFY_CHORE_SET_VISIBLE_STEP: 'MODIFY_CHORE_SET_VISIBLE_STEP',
  MODIFY_CHORE_VALIDATE_STEP: 'MODIFY_CHORE_VALIDATE_STEP',
  MODIFY_CHORE_VALIDITY_DATE_CHANGED: 'MODIFY_CHORE_VALIDITY_DATE_CHANGED',
  MODIFY_CHORE_WILL_CANCEL: 'MODIFY_CHORE_WILL_CANCEL',
  MODIFY_DEVICE_ALIAS_ERROR: 'MODIFY_DEVICE_ALIAS_ERROR',
  MODIFY_DEVICE_ALIAS_SUCCESS: 'MODIFY_DEVICE_ALIAS_SUCCESS',
  MONTHLY__FAILURE: 'MONTHLY__FAILURE',
  MONTHLY__FETCHING: 'MONTHLY__FETCHING',
  MONTHLY__SUCCESS: 'MONTHLY__SUCCESS',
  MONTHLY__TOTALIZED__FAILURE: 'MONTHLY__TOTALIZED__FAILURE',
  MONTHLY__TOTALIZED__FETCHING: 'MONTHLY__TOTALIZED__FETCHING',
  MONTHLY__TOTALIZED__SUCCESS: 'MONTHLY__TOTALIZED__SUCCESS',
  MORTGAGES_STATUS_FAILURE: 'MORTGAGES_STATUS_FAILURE',
  MORTGAGES_STATUS_REQUEST: 'MORTGAGES_STATUS_REQUEST',
  MORTGAGES_STATUS_SUCCESS: 'MORTGAGES_STATUS_SUCCESS',
  MORTGAGE_UPDATE_HOLDERS_INFORMATION: 'MORTGAGE_UPDATE_HOLDERS_INFORMATION',
  MORTGAGE_RESET_COHOLDER_INFORMATION: 'MORTGAGE_RESET_COHOLDER_INFORMATION',
  MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS:
    'MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS',
  MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS: 'MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS',
  MORTGAGE_REQUEST_ACCEPT_CONDITIONS_FAILURE: 'MORTGAGE_REQUEST_ACCEPT_CONDITIONS_FAILURE',
  MORTGAGE_REQUEST_ACCEPT_CONDITIONS_REQUEST: 'MORTGAGE_REQUEST_ACCEPT_CONDITIONS_REQUEST',
  MORTGAGE_REQUEST_ACCEPT_CONDITIONS_SUCCESS: 'MORTGAGE_REQUEST_ACCEPT_CONDITIONS_SUCCESS',
  MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_FAILURE: 'MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_FAILURE',
  MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_REQUEST: 'MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_REQUEST',
  MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_SUCCESS: 'MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_SUCCESS',
  MORTGAGE_REQUEST_ACCEPT_PRE_APPROVAL_CO: 'MORTGAGE_REQUEST_ACCEPT_PRE_APPROVAL_CO',
  MORTGAGE_REQUEST_ADD_COHOLDER_FAILURE: 'MORTGAGE_REQUEST_ADD_COHOLDER_FAILURE',
  MORTGAGE_REQUEST_ADD_COHOLDER_REQUEST: 'MORTGAGE_REQUEST_ADD_COHOLDER_REQUEST',
  MORTGAGE_REQUEST_ADD_COHOLDER_SUCCESS: 'MORTGAGE_REQUEST_ADD_COHOLDER_SUCCESS',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_CLEAR: 'MORTGAGE_REQUEST_APPRAISAL_REQUEST_CLEAR',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_FAILURE:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_FAILURE',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_REQUEST:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_REQUEST',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_SUCCESS:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_SUCCESS',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP: 'MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_FAILURE:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_FAILURE',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_REQUEST:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_REQUEST',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_SUCCESS:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_SUCCESS',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_VISIBLE_STEP:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_VISIBLE_STEP',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP:
    'MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP',
  MORTGAGE_REQUEST_APPRAISAL_REQUEST_WILL_CANCEL: 'MORTGAGE_REQUEST_APPRAISAL_REQUEST_WILL_CANCEL',
  MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE',
  MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST',
  MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS',
  MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE',
  MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION',
  MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION',
  MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE:
    'MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE',
  MORTGAGE_REQUEST_ASSET_FILE_FAILURE: 'MORTGAGE_REQUEST_ASSET_FILE_FAILURE',
  MORTGAGE_REQUEST_ASSET_FILE_REQUEST: 'MORTGAGE_REQUEST_ASSET_FILE_REQUEST',
  MORTGAGE_REQUEST_ASSET_FILE_RESET: 'MORTGAGE_REQUEST_ASSET_FILE_RESET',
  MORTGAGE_REQUEST_ASSET_FILE_SUCCESS: 'MORTGAGE_REQUEST_ASSET_FILE_SUCCESS',
  MORTGAGE_REQUEST_CALCULATE_PROGRESS_BAR_SUCCESS:
    'MORTGAGE_REQUEST_CALCULATE_PROGRESS_BAR_SUCCESS',
  MORTGAGE_REQUEST_CLEAR_ESTATES_STATE: 'MORTGAGE_REQUEST_CLEAR_ESTATES_STATE',
  MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_FAILURE:
    'MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_FAILURE',
  MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_REQUEST:
    'MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_REQUEST',
  MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_SUCCESS:
    'MORTGAGE_REQUEST_DELETE_DATA_PROPRIETOR_SUCCESS',
  MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_FAILURE: 'MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_FAILURE',
  MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_REQUEST: 'MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_REQUEST',
  MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_SUCCESS: 'MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_SUCCESS',
  MORTGAGE_REQUEST_DELETE_OTHER_LOAN_REQUEST: 'MORTGAGE_REQUEST_DELETE_OTHER_LOAN_REQUEST',
  MORTGAGE_REQUEST_DELETE_OTHER_LOAN_SUCCESS: 'MORTGAGE_REQUEST_DELETE_OTHER_LOAN_SUCCESS',
  MORTGAGE_REQUEST_DOCUMENTATION_RESET: 'MORTGAGE_REQUEST_DOCUMENTATION_RESET',
  MORTGAGE_REQUEST_ECONOMIC_FLAG: 'MORTGAGE_REQUEST_ECONOMIC_FLAG',
  MORTGAGE_REQUEST_EDIT_COHOLDER_FAILURE: 'MORTGAGE_REQUEST_EDIT_COHOLDER_FAILURE',
  MORTGAGE_REQUEST_EDIT_COHOLDER_REQUEST: 'MORTGAGE_REQUEST_EDIT_COHOLDER_REQUEST',
  MORTGAGE_REQUEST_EDIT_COHOLDER_RESET: 'MORTGAGE_REQUEST_EDIT_COHOLDER_RESET',
  MORTGAGE_REQUEST_EDIT_COHOLDER_SUCCESS: 'MORTGAGE_REQUEST_EDIT_COHOLDER_SUCCESS',
  MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_FAILURE: 'MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_FAILURE',
  MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_REQUEST: 'MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_REQUEST',
  MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_SUCCESS: 'MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_SUCCESS',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_130_REQUEST:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_130_REQUEST',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_ADD_FILE: 'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_ADD_FILE',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_FAILURE: 'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_FAILURE',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_IRPF_REQUEST:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_IRPF_REQUEST',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_REQUEST: 'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_REQUEST',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_RESET: 'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_RESET',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_RESET_FILE:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_RESET_FILE',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_SUCCESS: 'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_SUCCESS',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_FAILURE:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_FAILURE',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_SUCCESS:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_SUCCESS',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_FAILURE:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_FAILURE',
  MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_SUCCESS:
    'MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_SUCCESS',
  MORTGAGE_REQUEST_EDIT_HOLDER_FAILURE: 'MORTGAGE_REQUEST_EDIT_HOLDER_FAILURE',
  MORTGAGE_REQUEST_EDIT_HOLDER_REQUEST: 'MORTGAGE_REQUEST_EDIT_HOLDER_REQUEST',
  MORTGAGE_REQUEST_EDIT_HOLDER_SUCCESS: 'MORTGAGE_REQUEST_EDIT_HOLDER_SUCCESS',
  MORTGAGE_REQUEST_ESTATES_DATA_DOCUMENT: 'MORTGAGE_REQUEST_ESTATES_DATA_DOCUMENT',
  MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_FAILURE:
    'MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_FAILURE',
  MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_REQUEST:
    'MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_REQUEST',
  MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_SUCCESS:
    'MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_SUCCESS',
  MORTGAGE_REQUEST_ESTATES_DATA_FILES_LOADED: 'MORTGAGE_REQUEST_ESTATES_DATA_FILES_LOADED',
  MORTGAGE_REQUEST_ESTATES_DATA_RESET: 'MORTGAGE_REQUEST_ESTATES_DATA_RESET',
  MORTGAGE_REQUEST_ESTATES_DELETE_FAILURE: 'MORTGAGE_REQUEST_ESTATES_DELETE_FAILURE',
  MORTGAGE_REQUEST_ESTATES_DELETE_REQUEST: 'MORTGAGE_REQUEST_ESTATES_DELETE_REQUEST',
  MORTGAGE_REQUEST_ESTATES_DELETE_RESET: 'MORTGAGE_REQUEST_ESTATES_DELETE_RESET',
  MORTGAGE_REQUEST_ESTATES_DELETE_SHOW_MODAL: 'MORTGAGE_REQUEST_ESTATES_DELETE_SHOW_MODAL',
  MORTGAGE_REQUEST_ESTATES_DELETE_SUCCESS: 'MORTGAGE_REQUEST_ESTATES_DELETE_SUCCESS',
  MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_FAILURE: 'MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_FAILURE',
  MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_REQUEST: 'MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_REQUEST',
  MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_SUCCESS: 'MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_SUCCESS',
  MORTGAGE_REQUEST_ESTATES_FETCH_DATA_FAILURE: 'MORTGAGE_REQUEST_ESTATES_FETCH_DATA_FAILURE',
  MORTGAGE_REQUEST_ESTATES_FETCH_DATA_REQUEST: 'MORTGAGE_REQUEST_ESTATES_FETCH_DATA_REQUEST',
  MORTGAGE_REQUEST_ESTATES_REQUESTS_POPULATIONS: 'MORTGAGE_REQUEST_ESTATES_REQUESTS_POPULATIONS',
  MORTGAGE_REQUEST_ESTATES_REQUEST_REGISTERS: 'MORTGAGE_REQUEST_ESTATES_REQUEST_REGISTERS',
  MORTGAGE_REQUEST_ESTATES_RESET: 'MORTGAGE_REQUEST_ESTATES_RESET',
  MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_FAILURE: 'MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_FAILURE',
  MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_REQUEST: 'MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_REQUEST',
  MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_SUCCESS: 'MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_SUCCESS',
  MORTGAGE_REQUEST_ESTATES_SAVE_REQUEST_DONE: 'MORTGAGE_REQUEST_ESTATES_SAVE_REQUEST_DONE',
  MORTGAGE_REQUEST_ESTATES_SET_POPULATION_NAME: 'MORTGAGE_REQUEST_ESTATES_SET_POPULATION_NAME',
  MORTGAGE_REQUEST_ESTATES_SET_PROVINCE_NAME: 'MORTGAGE_REQUEST_ESTATES_SET_PROVINCE_NAME',
  MORTGAGE_REQUEST_ESTATES_SET_REGISTER_NAME: 'MORTGAGE_REQUEST_ESTATES_SET_REGISTER_NAME',
  MORTGAGE_REQUEST_ESTATES_UPDATE_FAILURE: 'MORTGAGE_REQUEST_ESTATES_UPDATE_FAILURE',
  MORTGAGE_REQUEST_ESTATES_UPDATE_REQUEST: 'MORTGAGE_REQUEST_ESTATES_UPDATE_REQUEST',
  MORTGAGE_REQUEST_ESTATES_UPDATE_SUCCESS: 'MORTGAGE_REQUEST_ESTATES_UPDATE_SUCCESS',
  MORTGAGE_REQUEST_EXPENSES_PROVISION_SET_FIELD: 'MORTGAGE_REQUEST_EXPENSES_PROVISION_SET_FIELD',
  MORTGAGE_REQUEST_FAILURE_OFFER: 'MORTGAGE_REQUEST_FAILURE_OFFER',
  MORTGAGE_REQUEST_FETCH_ACTIVITIES_FAILURE: 'MORTGAGE_REQUEST_FETCH_ACTIVITIES_FAILURE',
  MORTGAGE_REQUEST_FETCH_ACTIVITIES_REQUEST: 'MORTGAGE_REQUEST_FETCH_ACTIVITIES_REQUEST',
  MORTGAGE_REQUEST_FETCH_ACTIVITIES_SUCCESS: 'MORTGAGE_REQUEST_FETCH_ACTIVITIES_SUCCESS',
  MORTGAGE_REQUEST_FETCH_ADDEMAIL_FAILURE: 'MORTGAGE_REQUEST_FETCH_ADDEMAIL_FAILURE',
  MORTGAGE_REQUEST_FETCH_ADDEMAIL_REQUEST: 'MORTGAGE_REQUEST_FETCH_ADDEMAIL_REQUEST',
  MORTGAGE_REQUEST_FETCH_ADDEMAIL_SUCCESS: 'MORTGAGE_REQUEST_FETCH_ADDEMAIL_SUCCESS',
  MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT:
    'MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT',
  MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST:
    'MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST',
  MORTGAGE_REQUEST_FETCH_CLEAR_UNPAID_FEES_FILTER:
    'MORTGAGE_REQUEST_FETCH_CELAR_UNPAID_FEES_FILTER',
  MORTGAGE_REQUEST_FETCH_COHOLDER_FAILURE: 'MORTGAGE_REQUEST_FETCH_COHOLDER_FAILURE',
  MORTGAGE_REQUEST_FETCH_COHOLDER_REQUEST: 'MORTGAGE_REQUEST_FETCH_COHOLDER_REQUEST',
  MORTGAGE_REQUEST_FETCH_COHOLDER_SUCCESS: 'MORTGAGE_REQUEST_FETCH_COHOLDER_SUCCESS',
  MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE: 'MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE',
  MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST: 'MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST',
  MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT:
    'MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT',
  MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS: 'MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS',
  MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_ECONOMIC:
    'MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_ECONOMIC',
  MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_FAILURE: 'MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_FAILURE',
  MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_INFO: 'MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_INFO',
  MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_REQUEST: 'MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_REQUEST',
  MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_SUCCESS: 'MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_SUCCESS',
  MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_FAILURE: 'MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_FAILURE',
  MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_REQUEST: 'MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_REQUEST',
  MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_SUCCESS: 'MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_SUCCESS',
  MORTGAGE_REQUEST_FETCH_DOCUMENTATION_FAILURE: 'MORTGAGE_REQUEST_FETCH_DOCUMENTATION_FAILURE',
  MORTGAGE_REQUEST_FETCH_DOCUMENTATION_REQUEST: 'MORTGAGE_REQUEST_FETCH_DOCUMENTATION_REQUEST',
  MORTGAGE_REQUEST_FETCH_DOCUMENTATION_SUCCESS: 'MORTGAGE_REQUEST_FETCH_DOCUMENTATION_SUCCESS',
  MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_FAILURE:
    'MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_FAILURE',
  MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_REQUEST:
    'MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_REQUEST',
  MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_SUCCESS',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_FAILURE: 'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_FAILURE',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_FAILURE:
    'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_FAILURE',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_REQUEST:
    'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_REQUEST',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_SUCCESS',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_REQUEST: 'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_REQUEST',
  MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_SUCCESS: 'MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_SUCCESS',
  MORTGAGE_REQUEST_FETCH_HOLDER_WORK_FAILURE: 'MORTGAGE_REQUEST_FETCH_HOLDER_WORK_FAILURE',
  MORTGAGE_REQUEST_FETCH_HOLDER_WORK_REQUEST: 'MORTGAGE_REQUEST_FETCH_HOLDER_WORK_REQUEST',
  MORTGAGE_REQUEST_FETCH_HOLDER_WORK_SUCCESS: 'MORTGAGE_REQUEST_FETCH_HOLDER_WORK_SUCCESS',
  MORTGAGE_REQUEST_FETCH_INMUEBLES_FAILURE: 'MORTGAGE_REQUEST_FETCH_INMUEBLE_FAILURE',
  MORTGAGE_REQUEST_FETCH_INMUEBLES_REQUEST: 'MORTGAGE_REQUEST_FETCH_INMUEBLE_REQUEST',
  MORTGAGE_REQUEST_FETCH_INMUEBLES_RESET: 'MORTGAGE_REQUEST_FETCH_INMUEBLES_RESET',
  MORTGAGE_REQUEST_FETCH_INMUEBLES_SUCCESS: 'MORTGAGE_REQUEST_FETCH_INMUEBLE_SUCCESS',
  MORTGAGE_REQUEST_FETCH_LOCATION_DATA_DONE: 'MORTGAGE_REQUEST_FETCH_LOCATION_DATA_DONE',
  MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_FAILURE:
    'MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_FAILURE',
  MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_REQUEST:
    'MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_REQUEST',
  MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_SUCCESS',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_FAILURE:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_FAILURE',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_REQUEST:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_REQUEST',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_SUCCESS',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_FAILURE:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_FAILURE',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_REQUEST:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_REQUEST',
  MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PANEL_TITLE_FAILURE: 'MORTGAGE_REQUEST_FETCH_PANEL_TITLE_FAILURE',
  MORTGAGE_REQUEST_FETCH_PANEL_TITLE_REQUEST: 'MORTGAGE_REQUEST_FETCH_PANEL_TITLE_REQUEST',
  MORTGAGE_REQUEST_FETCH_PANEL_TITLE_SUCCESS: 'MORTGAGE_REQUEST_FETCH_PANEL_TITLE_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_FAILURE:
    'MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_FAILURE',
  MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_REQUEST:
    'MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_REQUEST',
  MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PAYMENT_FAILURE: 'MORTGAGE_REQUEST_FETCH_PAYMENT_FAILURE',
  MORTGAGE_REQUEST_FETCH_PAYMENT_REQUEST: 'MORTGAGE_REQUEST_FETCH_PAYMENT_REQUEST',
  MORTGAGE_REQUEST_FETCH_PAYMENT_SUCCESS: 'MORTGAGE_REQUEST_FETCH_PAYMENT_SUCCESS',
  MORTGAGE_REQUEST_FETCH_POPULATION_FAILURE: 'MORTGAGE_REQUEST_FETCH_POPULATION_FAILURE',
  MORTGAGE_REQUEST_FETCH_POPULATION_REQUEST: 'MORTGAGE_REQUEST_FETCH_POPULATION_REQUEST',
  MORTGAGE_REQUEST_FETCH_POPULATION_SUCCESS: 'MORTGAGE_REQUEST_FETCH_POPULATION_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_FAILURE: 'MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_FAILURE',
  MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_REQUEST: 'MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_REQUEST',
  MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_SUCCESS: 'MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_FAILURE:
    'MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_FAILURE',
  MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_REQUEST:
    'MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_REQUEST',
  MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_SUCCESS',
  MORTGAGE_REQUEST_FETCH_PROVINCES_FAILURE: 'MORTGAGE_REQUEST_FETCH_PROVINCES_FAILURE',
  MORTGAGE_REQUEST_FETCH_PROVINCES_REQUEST: 'MORTGAGE_REQUEST_FETCH_PROVINCES_REQUEST',
  MORTGAGE_REQUEST_FETCH_PROVINCES_SUCCESS: 'MORTGAGE_REQUEST_FETCH_PROVINCES_SUCCESS',
  MORTGAGE_REQUEST_FETCH_REGISTERS_FAILURE: 'MORTGAGE_REQUEST_FETCH_REGISTERS_FAILURE',
  MORTGAGE_REQUEST_FETCH_REGISTERS_REQUEST: 'MORTGAGE_REQUEST_FETCH_REGISTERS_REQUEST',
  MORTGAGE_REQUEST_FETCH_REGISTERS_SUCCESS: 'MORTGAGE_REQUEST_FETCH_REGISTERS_SUCCESS',
  MORTGAGE_REQUEST_FETCH_SET_UNPAID_FEES_FILTER: 'MORTGAGE_REQUEST_FETCH_SET_UNPAID_FEES_FILTER',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_CLEAR:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_CLEAR',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_SET_VISIBLE_STEP:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_SET_VISIBLE_STEP',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP',
  MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_WILL_CANCEL:
    'MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_WILL_CANCEL',
  MORTGAGE_REQUEST_FETCH_STATE_FAILURE: 'MORTGAGE_REQUEST_FETCH_STATE_FAILURE',
  MORTGAGE_REQUEST_FETCH_STATE_REQUEST: 'MORTGAGE_REQUEST_FETCH_STATE_REQUEST',
  MORTGAGE_REQUEST_FETCH_STATE_SUCCESS: 'MORTGAGE_REQUEST_FETCH_STATE_SUCCESS',
  MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_FAILURE:
    'MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_FAILURE',
  MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_REQUEST:
    'MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_REQUEST',
  MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SET:
    'MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SET',
  MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SUCCESS:
    'MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SUCCESS',
  MORTGAGE_REQUEST_FETCH_TYPE_FAILURE: 'MORTGAGE_REQUEST_FETCH_TYPE_FAILURE',
  MORTGAGE_REQUEST_FETCH_TYPE_REQUEST: 'MORTGAGE_REQUEST_FETCH_TYPE_REQUEST',
  MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS: 'MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS',
  MORTGAGE_REQUEST_FETCH_UNPAID_FEES_FAILURE: 'MORTGAGE_REQUEST_FETCH_UNPAID_FEES_FAILURE',
  MORTGAGE_REQUEST_FETCH_UNPAID_FEES_REQUEST: 'MORTGAGE_REQUEST_FETCH_UNPAID_FEES_REQUEST',
  MORTGAGE_REQUEST_FETCH_UNPAID_FEES_SUCCESS: 'MORTGAGE_REQUEST_FETCH_UNPAID_FEES_SUCCESS',
  MORTGAGE_REQUEST_GET_PERSONAL_DATA_FAILURE: 'MORTGAGE_REQUEST_GET_PERSONAL_DATA_FAILURE',
  MORTGAGE_REQUEST_GET_PERSONAL_DATA_REQUEST: 'MORTGAGE_REQUEST_GET_PERSONAL_DATA_REQUEST',
  MORTGAGE_REQUEST_GET_PERSONAL_DATA_SUCCESS: 'MORTGAGE_REQUEST_GET_PERSONAL_DATA_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_FAILURE:
    'MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_FAILURE',
  MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_REQUEST:
    'MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_REQUEST',
  MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_SUCCESS:
    'MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_FAILURE: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_FAILURE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_REQUEST: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_REQUEST',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_SUCCESS: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_SUCCESS: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_FAILURE: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_FAILURE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_SUCCESS:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_FAILURE:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_FAILURE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_BUTTON_OFFICE_ENABLE:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_BUTTON_OFFICE_ENABLE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_SCROLL_DOWN: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_SCROLL_DOWN',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_FAILURE:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_FAILURE',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_REQUEST:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_REQUEST',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_RESET:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_RESET',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_SUCCESS:
    'MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_CONFIRM_EDIT_SET_FIELD: 'MORTGAGE_REQUEST_HOLDER_CONFIRM_EDIT_SET_FIELD',
  MORTGAGE_REQUEST_HOLDER_ECONOMIC_EDIT_SET_FIELD:
    'MORTGAGE_REQUEST_HOLDER_ECONOMIC_EDIT_SET_FIELD',
  MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_FAILURE:
    'MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_FAILURE',
  MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_REQUEST:
    'MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_REQUEST',
  MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_SUCCESS:
    'MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_ECONOMIC_RESET: 'MORTGAGE_REQUEST_HOLDER_ECONOMIC_RESET',
  MORTGAGE_REQUEST_HOLDER_EDIT_ADD_FILE: 'MORTGAGE_REQUEST_HOLDER_EDIT_ADD_FILE',
  MORTGAGE_REQUEST_HOLDER_EDIT_SET_FIELD: 'MORTGAGE_REQUEST_HOLDER_EDIT_SET_FIELD',
  MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_ERROR: 'MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_ERROR',
  MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_REQUEST:
    'MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_REQUEST',
  MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_SUCCESS:
    'MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_FAILURE: 'MORTGAGE_REQUEST_HOLDER_FAILURE',
  MORTGAGE_REQUEST_HOLDER_FLAG: 'MORTGAGE_REQUEST_HOLDER_FLAG',
  MORTGAGE_REQUEST_HOLDER_ID_SET: 'MORTGAGE_REQUEST_HOLDER_ID_SET',
  MORTGAGE_REQUEST_HOLDER_REQUEST: 'MORTGAGE_REQUEST_HOLDER_REQUEST',
  MORTGAGE_REQUEST_HOLDER_RESET: 'MORTGAGE_REQUEST_HOLDER_RESET',
  MORTGAGE_REQUEST_HOLDER_SUCCESS: 'MORTGAGE_REQUEST_HOLDER_SUCCESS',
  MORTGAGE_REQUEST_HOLDER_WORK_RESET: 'MORTGAGE_REQUEST_HOLDER_WORK_RESET',
  MORTGAGE_REQUEST_INSURANCE_PRE_APPROVAL_SUCCESS:
    'MORTGAGE_REQUEST_INSURANCE_PRE_APPROVAL_SUCCESS',
  MORTGAGE_REQUEST_INSURANCE_REQUEST_ERROR: 'MORTGAGE_REQUEST_INSURANCE_REQUEST_ERROR',
  MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE:
    'INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE',
  MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST:
    'INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST',
  MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS:
    'INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS',
  MORTGAGE_REQUEST_INSURANCE_REQUEST_SUCCESS: 'MORTGAGE_REQUEST_INSURANCE_REQUEST_SUCCESS',
  MORTGAGE_REQUEST_LINK_USER_ERROR: 'MORTGAGE_REQUEST_LINK_USER_ERROR',
  MORTGAGE_REQUEST_LINK_USER_REQUEST: 'MORTGAGE_REQUEST_LINK_USER_REQUEST',
  MORTGAGE_REQUEST_LINK_USER_SUCCESS: 'MORTGAGE_REQUEST_LINK_USER_SUCCESS',
  MORTGAGE_REQUEST_MANAGER_INFO: 'MORTGAGE_REQUEST_MANAGER_INFO',
  MORTGAGE_REQUEST_MANAGER_INFO_FAILURE: 'MORTGAGE_REQUEST_MANAGER_INFO_FAILURE',
  MORTGAGE_REQUEST_MANAGER_INFO_SUCCESS: 'MORTGAGE_REQUEST_MANAGER_INFO_SUCCESS',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST',
  MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS:
    'MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS',
  MORTGAGE_REQUEST_MONTHLY_FEE_REQUEST: 'MORTGAGE_REQUEST_MONTHLY_FEE_REQUEST',
  MORTGAGE_REQUEST_MORTGAGE_DATA_FAILURE: 'MORTGAGE_REQUEST_MORTGAGE_DATA_FAILURE',
  MORTGAGE_REQUEST_MORTGAGE_DATA_REQUEST: 'MORTGAGE_REQUEST_MORTGAGE_DATA_REQUEST',
  MORTGAGE_REQUEST_MORTGAGE_DATA_SET_FIELD: 'MORTGAGE_REQUEST_MORTGAGE_DATA_SET_FIELD',
  MORTGAGE_REQUEST_MORTGAGE_DATA_SUCCESS: 'MORTGAGE_REQUEST_MORTGAGE_DATA_SUCCESS',
  MORTGAGE_REQUEST_MORTGAGE_DELETE_FAILURE: 'MORTGAGE_REQUEST_MORTGAGE_DELETE_FAILURE',
  MORTGAGE_REQUEST_MORTGAGE_DELETE_REQUEST: 'MORTGAGE_REQUEST_MORTGAGE_DELETE_REQUEST',
  MORTGAGE_REQUEST_MORTGAGE_DELETE_SUCCESS: 'MORTGAGE_REQUEST_MORTGAGE_DELETE_SUCCESS',
  MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_FAILURE: 'MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_FAILURE',
  MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_REQUEST: 'MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_REQUEST',
  MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_SUCCESS: 'MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_SUCCESS',
  MORTGAGE_REQUEST_MORTGAGE_FEE_FAILURE: 'MORTGAGE_REQUEST_MORTGAGE_FEE_FAILURE',
  MORTGAGE_REQUEST_MORTGAGE_FEE_REQUEST: 'MORTGAGE_REQUEST_MORTGAGE_FEE_REQUEST',
  MORTGAGE_REQUEST_MORTGAGE_FEE_RESET: 'MORTGAGE_REQUEST_MORTGAGE_FEE_RESET',
  MORTGAGE_REQUEST_MORTGAGE_FEE_SUCCESS: 'MORTGAGE_REQUEST_MORTGAGE_FEE_SUCCESS',
  MORTGAGE_REQUEST_MOVING_FEE_FAILURE: 'MORTGAGE_REQUEST_MOVING_FEE_FAILURE',
  MORTGAGE_REQUEST_MOVING_FEE_SUCCESS: 'MORTGAGE_REQUEST_MOVING_FEE_SUCCESS',
  MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_FAILURE: 'MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_FAILURE',
  MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_INIT: 'MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_INIT',
  MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_SUCCESS: 'MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_SUCCESS',
  MORTGAGE_REQUEST_ORDER_STATUS: 'MORTGAGE_REQUEST_ORDER_STATUS',
  MORTGAGE_REQUEST_ORDER_STATUS_FAILURE: 'MORTGAGE_REQUEST_ORDER_STATUS_FAILURE',
  MORTGAGE_REQUEST_ORDER_STATUS_SUCCESS: 'MORTGAGE_REQUEST_ORDER_STATUS_SUCCESS',
  MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD: 'MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD',
  MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD_FINISH:
    'MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD_FINISH',
  MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_ERROR:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_ERROR',
  MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_REQUEST:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CATALOG_INFORMATION_REQUEST',
  MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_SUCCESS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CATALOG_INFORMATION_SUCCESS',
  MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE',
  MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE_PREVIOUS_NEXT_BUTTON:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE_PREVIOUS_NEXT_BUTTON',
  MORTGAGE_REQUEST_PRE_APPROVAL_CLEAR_TEMPORAL_CHANGES_IN_CURRENT_MODAL:
    'MORTGAGE_REQUEST_PRE_APPROVAL_CLEAR_TEMPORAL_CHANGES_IN_CURRENT_MODAL',
  MORTGAGE_REQUEST_PRE_APPROVAL_CO: 'MORTGAGE_REQUEST_PRE_APPROVAL_CO',
  MORTGAGE_REQUEST_PRE_APPROVAL_DISCARD_TEMPORAL_CHANGES_IN_CURRENT_MODAL:
    'MORTGAGE_REQUEST_PRE_APPROVAL_DISCARD_TEMPORAL_CHANGES_IN_CURRENT_MODAL',
  MORTGAGE_REQUEST_PRE_APPROVAL_ERROR: 'MORTGAGE_REQUEST_PRE_APPROVAL_ERROR',
  MORTGAGE_REQUEST_PRE_APPROVAL_HOME_INSURANCE_DATA_ERROR:
    'MORTGAGE_REQUEST_PRE_APPROVAL_HOME_INSURANCE_DATA_ERROR',
  MORTGAGE_REQUEST_PRE_APPROVAL_HOME_INSURANCE_DATA_SUCCESS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_HOME_INSURANCE_DATA_SUCCESS',
  MORTGAGE_REQUEST_PRE_APPROVAL_KO: 'MORTGAGE_REQUEST_PRE_APPROVAL_KO',
  MORTGAGE_REQUEST_PRE_APPROVAL_KO_RETURN_WIZARD: 'MORTGAGE_REQUEST_PRE_APPROVAL_KO_RETURN_WIZARD',
  MORTGAGE_REQUEST_PRE_APPROVAL_LOAD_VALIDATE_OBLIGATORY_FIELDS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_LOAD_VALIDATE_OBLIGATORY_FIELDS',
  MORTGAGE_REQUEST_PRE_APPROVAL_PRODUCT_TYPE: 'MORTGAGE_REQUEST_PRE_APPROVAL_PRODUCT_TYPE',
  MORTGAGE_REQUEST_PRE_APPROVAL_REQUEST: 'MORTGAGE_REQUEST_PRE_APPROVAL_REQUEST',
  MORTGAGE_REQUEST_PRE_APPROVAL_RESET_STATE: 'MORTGAGE_REQUEST_PRE_APPROVAL_RESET_STATE',
  MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_ERROR:
    'MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_ERROR',
  MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_REQUEST:
    'MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_REQUEST',
  MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_SUCCESS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_SUCCESS',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER: 'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DAY_OF_BIRTH:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_ONE_DAY_OF_BIRTH',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DEPENDENTS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DEPENDENTS',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_EXPENSES:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_EXPENSES',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_INCOMES:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_INCOMES',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MARITAL_STATUS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MARITAL_STATUS',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MONTH_OF_BIRTH:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MONTH_OF_BIRTH',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_OWNER: 'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_OWNER',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_STUDIES:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_STUDIES',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_WORK: 'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_WORK',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_YEAR_OF_BIRTH:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_YEAR_OF_BIRTH',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_OTHER_LOANS_MORTGAGES:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_OTHER_LOANS_MORTGAGES',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_STEP_ONE:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_STEP_ONE',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_MAX_HOUSING_COST:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_MAX_HOUSING_COST',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_ERROR:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_ERROR',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_VALUE:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_VALUE',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_NEED_MONEY_BY_USER:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_NEED_MONEY_BY_USER',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP: 'MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP_ONE_FIELD_VALID:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP_ONE_FIELD_VALID',
  MORTGAGE_REQUEST_PRE_APPROVAL_SET_VALID_FIRST_STEP:
    'MORTGAGE_REQUEST_PRE_APPROVAL_SET_VALID_FIRST_STEP',
  MORTGAGE_REQUEST_PRE_APPROVAL_SUCCESS: 'MORTGAGE_REQUEST_PRE_APPROVAL_SUCCESS',
  MORTGAGE_REQUEST_PRE_APPROVAL_TOGGLE_CO_VIEW: 'MORTGAGE_REQUEST_PRE_APPROVAL_TOGGLE_CO_VIEW',
  MORTGAGE_REQUEST_PRE_APPROVAL_UUID: 'MORTGAGE_REQUEST_PRE_APPROVAL_UUID',
  MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_OBLIGATORY_FIELDS:
    'MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_OBLIGATORY_FIELDS',
  MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_STEP: 'MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_STEP',
  MORTGAGE_REQUEST_PRE_APPROVAL_WHERE_HOUSING_DIRTY:
    'MORTGAGE_REQUEST_PRE_APPROVAL_WHERE_HOUSING_DIRTY',
  MORTGAGE_REQUEST_PROPERTY_VALUE_ADD_FILE: 'MORTGAGE_REQUEST_PROPERTY_VALUE_ADD_FILE',
  MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_FAILURE:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_FAILURE',
  MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_REQUEST:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_REQUEST',
  MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_SUCCESS:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_SUCCESS',
  MORTGAGE_REQUEST_PROPERTY_VALUE_DEFINED_RESET: 'MORTGAGE_REQUEST_PROPERTY_VALUE_DEFINED_RESET',
  MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_FAILURE:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_FAILURE',
  MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_REQUEST:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_REQUEST',
  MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_SUCCESS:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_GET_CERT_SUCCESS',
  MORTGAGE_REQUEST_PROPERTY_VALUE_PAYMENT_FAILURE:
    'MORTGAGE_REQUEST_PROPERTY_VALUE_PAYMENT_FAILURE',
  MORTGAGE_REQUEST_PROPERTY_VALUE_RESET_VIEW: 'MORTGAGE_REQUEST_PROPERTY_VALUE_RESET_VIEW',
  MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_FAILURE: 'MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_FAILURE',
  MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_REQUEST: 'MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_REQUEST',
  MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_SUCCESS: 'MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_SUCCESS',
  MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER: 'MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER',
  MORTGAGE_REQUEST_RESET_LOCATION_DATA: 'MORTGAGE_REQUEST_RESET_LOCATION_DATA',
  MORTGAGE_REQUEST_RESET_UNPAID_FEES: 'MORTGAGE_REQUEST_RESET_UNPAID_FEES',
  MORTGAGE_REQUEST_RESET_UPDATE_DATA_RESULT: 'MORTGAGE_REQUEST_RESET_UPDATE_DATA_RESULT',
  MORTGAGE_REQUEST_SAVE_ADDEMAIL_FAILURE: 'MORTGAGE_REQUEST_SAVE_ADDEMAIL_FAILURE',
  MORTGAGE_REQUEST_SAVE_ADDEMAIL_REQUEST: 'MORTGAGE_REQUEST_SAVE_ADDEMAIL_REQUEST',
  MORTGAGE_REQUEST_SAVE_ADDEMAIL_SUCCESS: 'MORTGAGE_REQUEST_SAVE_ADDEMAIL_SUCCESS',
  MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_FAILURE:
    'MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_FAILURE',
  MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_REQUEST:
    'MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_REQUEST',
  MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_SUCCESS:
    'MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_SUCCESS',
  MORTGAGE_REQUEST_SET_ESTATES_STATE: 'MORTGAGE_REQUEST_SET_ESTATES_STATE',
  MORTGAGE_REQUEST_SET_ESTATES_WITH_PROVINCES: 'MORTGAGE_REQUEST_SET_ESTATES_WITH_PROVINCES',
  MORTGAGE_REQUEST_SET_ESTATE_POPULATION: 'MORTGAGE_REQUEST_SET_ESTATE_POPULATION',
  MORTGAGE_REQUEST_SET_ESTATE_REGISTER: 'MORTGAGE_REQUEST_SET_ESTATE_REGISTER',
  MORTGAGE_REQUEST_SET_ESTATE_STATE: 'MORTGAGE_REQUEST_SET_ESTATE_STATE',
  MORTGAGE_REQUEST_SET_INITIAL_STATE: 'MORTGAGE_REQUEST_SET_INITIAL_STATE',
  MORTGAGE_REQUEST_RESET_STATE: 'MORTGAGE_REQUEST_RESET_STATE',
  MORTGAGE_REQUEST_SET_MULTIPLE_ESTATE_STATE: 'MORTGAGE_REQUEST_SET_MULTIPLE_ESTATE_STATE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_ADD_FILE: 'MORTGAGE_REQUEST_SIMPLE_NOTE_ADD_FILE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_FAILURE: 'MORTGAGE_REQUEST_SIMPLE_NOTE_FAILURE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_GET_FAILURE: 'MORTGAGE_REQUEST_SIMPLE_NOTE_GET_FAILURE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_GET_REQUEST: 'MORTGAGE_REQUEST_SIMPLE_NOTE_GET_REQUEST',
  MORTGAGE_REQUEST_SIMPLE_NOTE_GET_SUCCESS: 'MORTGAGE_REQUEST_SIMPLE_NOTE_GET_SUCCESS',
  MORTGAGE_REQUEST_SIMPLE_NOTE_REQUEST: 'MORTGAGE_REQUEST_SIMPLE_NOTE_REQUEST',
  MORTGAGE_REQUEST_SIMPLE_NOTE_RESET_STATE: 'MORTGAGE_REQUEST_SIMPLE_NOTE_RESET_STATE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_REQUEST: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_REQUEST',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_SUCCESS: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_SUCCESS',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_FAILURE: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_FAILURE',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_REQUEST: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_REQUEST',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_SUCCESS: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_SUCCESS',
  MORTGAGE_REQUEST_SIMPLE_NOTE_SUCCESS: 'MORTGAGE_REQUEST_SIMPLE_NOTE_SUCCESS',
  MORTGAGE_REQUEST_SIMPLE_NOTE_UPLOAD_FILE_ERROR: 'MORTGAGE_REQUEST_SIMPLE_NOTE_UPLOAD_FILE_ERROR',
  MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_FAILURE:
    'MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_FAILURE',
  MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_REQUEST:
    'MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_REQUEST',
  MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_SUCCESS:
    'MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_SUCCESS',
  MORTGAGE_REQUEST_SMS_CHANGE_CODE: 'MORTGAGE_REQUEST_SMS_CHANGE_CODE',
  MORTGAGE_REQUEST_SMS_CODE_CLOSE_MODAL: 'MORTGAGE_REQUEST_SMS_CODE_CLOSE_MODAL',
  MORTGAGE_REQUEST_SMS_CODE_FAILURE: 'MORTGAGE_REQUEST_SMS_CODE_FAILURE',
  MORTGAGE_REQUEST_SMS_CODE_REQUEST: 'MORTGAGE_REQUEST_SMS_CODE_REQUEST',
  MORTGAGE_REQUEST_SMS_CODE_SUCCESS: 'MORTGAGE_REQUEST_SMS_CODE_SUCCESS',
  MORTGAGE_REQUEST_STATE_SET_DATE: 'MORTGAGE_REQUEST_STATE_SET_DATE',
  MORTGAGE_REQUEST_SUMMARY_DOCUMENTATION_RESET: 'MORTGAGE_REQUEST_SUMMARY_DOCUMENTATION_RESET',
  MORTGAGE_REQUEST_TAXES_INFORMATION_ERROR: 'MORTGAGE_REQUEST_TAXES_INFORMATION_ERROR',
  MORTGAGE_REQUEST_TAXES_INFORMATION_REQUEST: 'MORTGAGE_REQUEST_TAXES_INFORMATION_REQUEST',
  MORTGAGE_REQUEST_TAXES_INFORMATION_SUCCESS: 'MORTGAGE_REQUEST_TAXES_INFORMATION_SUCCESS',
  MORTGAGE_REQUEST_UPDATE_ADDEMAIL_SUCCESS: 'MORTGAGE_REQUEST_UPDATE_ADDEMAIL_SUCCESS',
  MORTGAGE_REQUEST_UPDATE_DATA_PROPRIETOR_SUCCESS:
    'MORTGAGE_REQUEST_UPDATE_DATA_PROPRIETOR_SUCCESS',
  MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_FAILURE: 'MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_FAILURE',
  MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_REQUEST: 'MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_REQUEST',
  MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_SUCCESS: 'MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_SUCCESS',
  MORTGAGE_REQUEST_UPDATE_PRIVACY_SUCCESS: 'MORTGAGE_REQUEST_UPDATE_PRIVACY_SUCCESS',
  MORTGAGE_REQUEST_UPDATE_TARGET_DATE_FAILURE: 'MORTGAGE_REQUEST_UPDATE_TARGET_DATE_FAILURE',
  MORTGAGE_REQUEST_UPDATE_TARGET_DATE_REQUEST: 'MORTGAGE_REQUEST_UPDATE_TARGET_DATE_REQUEST',
  MORTGAGE_REQUEST_UPDATE_TARGET_DATE_SUCCESS: 'MORTGAGE_REQUEST_UPDATE_TARGET_DATE_SUCCESS',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FAILURE: 'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FAILURE',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_FAILURE:
    'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_FAILURE',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_REQUEST:
    'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_REQUEST',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_SUCCESS:
    'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_SUCCESS',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_REQUEST: 'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_REQUEST',
  MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_SUCCESS: 'MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_SUCCESS',
  MORTGAGE_REQUEST_USER_COLLECTIVE_ERROR: 'MORTGAGE_REQUEST_USER_COLLECTIVE_ERROR',
  MORTGAGE_REQUEST_USER_COLLECTIVE_PROGRESS: 'MORTGAGE_REQUEST_USER_COLLECTIVE_PROGRESS',
  MORTGAGE_REQUEST_USER_COLLECTIVE_SUCCESS: 'MORTGAGE_REQUEST_USER_COLLECTIVE_SUCCESS',
  MORTGAGE_REQUEST_VIEW_PROCESS_DATA_FAILURE: 'MORTGAGE_REQUEST_VIEW_PROCESS_DATA_FAILURE',
  MORTGAGE_REQUEST_VIEW_PROCESS_DATA_REQUEST: 'MORTGAGE_REQUEST_VIEW_PROCESS_DATA_REQUEST',
  MORTGAGE_REQUEST_VIEW_PROCESS_DATA_SUCESS: 'MORTGAGE_REQUEST_VIEW_PROCESS_DATA_SUCESS',
  MORTGAGE_REQUEST_VIEW_PROCESS_FILE_FAILURE: 'MORTGAGE_REQUEST_VIEW_PROCESS_FILE_FAILURE',
  MORTGAGE_REQUEST_VIEW_PROCESS_FILE_REQUEST: 'MORTGAGE_REQUEST_VIEW_PROCESS_FILE_REQUEST',
  MORTGAGE_REQUEST_VIEW_PROCESS_FILE_SUCESS: 'MORTGAGE_REQUEST_VIEW_PROCESS_FILE_SUCESS',
  MORTGAGE_REQUEST_WORK_FLAG: 'MORTGAGE_REQUEST_WORK_FLAG',
  MOVEMENTS_CATEGORY_FAILURE: 'MOVEMENTS_CATEGORY_FAILURE',
  MOVEMENTS_CATEGORY_FETCHING: 'MOVEMENTS_CATEGORY_FETCHING',
  MOVEMENTS_CATEGORY_SUCCESS: 'MOVEMENTS_CATEGORY_SUCCESS',
  NGO_FOUNDATION_LIST_ERROR: 'NGO_FOUNDATION_LIST_ERROR',
  NGO_FOUNDATION_LIST_SUCCESS: 'NGO_FOUNDATION_LIST_SUCCESS',
  OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_FAILURE: 'OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_FAILURE',
  OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_REQUEST: 'OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_REQUEST',
  OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_SUCCESS: 'OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_SUCCESS',
  OKDISCOUNT_REQUEST_EXTERNAL_CODE_FAILURE: 'OKDISCOUNT_REQUEST_EXTERNAL_CODE_FAILURE',
  OKDISCOUNT_REQUEST_EXTERNAL_CODE_IN_PROGRESS: 'OKDISCOUNT_REQUEST_EXTERNAL_CODE_IN_PROGRESS',
  OKDISCOUNT_REQUEST_EXTERNAL_CODE_SUCCESS: 'OKDISCOUNT_REQUEST_EXTERNAL_CODE_SUCCESS',
  OKDISCOUNT_REQUEST_FETCH_USER_STATUS: 'OKDISCOUNT_REQUEST_FETCH_USER_STATUS',
  OKDISCOUNT_REQUEST_FETCH_USER_STATUS_FAILURE: 'OKDISCOUNT_REQUEST_FETCH_USER_STATUS_FAILURE',
  OKDISCOUNT_REQUEST_FETCH_USER_STATUS_SUCCESS: 'OKDISCOUNT_REQUEST_FETCH_USER_STATUS_SUCCESS',
  OKDISCOUNT_REQUEST_SET_SUBSCRIPTION: 'OKDISCOUNT_REQUEST_SET_SUBSCRIPTION',
  OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_ERROR: 'OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_ERROR',
  OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_FAILURE: 'OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_FAILURE',
  OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_SUCCESS: 'OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_SUCCESS',
  OKDISCOUNT_REFERRAL_PROMOTION_FAILURE: 'OKDISCOUNT_REFERRAL_PROMOTION_FAILURE',
  OKDISCOUNT_REFERRAL_PROMOTION_REQUEST: 'OKDISCOUNT_REFERRAL_PROMOTION_REQUEST',
  OKDISCOUNT_REFERRAL_PROMOTION_SUCCESS: 'OKDISCOUNT_REFERRAL_PROMOTION_SUCCESS',
  OK_WIZARD_ADD_STEP: 'OK_WIZARD_ADD_STEP',
  OK_WIZARD_CONFIRM_SOLIDARITY_CARD_ERROR: 'OK_WIZARD_CONFIRM_SOLIDARITY_CARD_ERROR',
  OK_WIZARD_CONFIRM_SOLIDARITY_CARD_FETCHING: 'OK_WIZARD_CONFIRM_SOLIDARITY_CARD_FETCHING',
  OK_WIZARD_CONFIRM_SOLIDARITY_CARD_SUCCESS: 'OK_WIZARD_CONFIRM_SOLIDARITY_CARD_SUCCESS',
  OK_WIZARD_GET_INFO_ERROR: 'OK_WIZARD_GET_INFO_ERROR',
  OK_WIZARD_GET_INFO_FETCHING: 'OK_WIZARD_GET_INFO_FETCHING',
  OK_WIZARD_GET_INFO_SUCCESS: 'OK_WIZARD_GET_INFO_SUCCESS',
  OK_WIZARD_GET_RESTRICTION_ERROR: 'OK_WIZARD_GET_RESTRICTION_ERROR',
  OK_WIZARD_REMOVE_STEP: 'OK_WIZARD_REMOVE_STEP',
  OK_WIZARD_REQUEST_SOLIDARITY_CARD_ERROR: 'OK_WIZARD_REQUEST_SOLIDARITY_CARD_ERROR',
  OK_WIZARD_REQUEST_SOLIDARITY_CARD_FETCHING: 'OK_WIZARD_REQUEST_SOLIDARITY_CARD_FETCHING',
  OK_WIZARD_REQUEST_SOLIDARITY_CARD_NOT_FOUND: 'OK_WIZARD_REQUEST_SOLIDARITY_CARD_NOT_FOUND',
  OK_WIZARD_REQUEST_SOLIDARITY_CARD_SUCCESS: 'OK_WIZARD_REQUEST_SOLIDARITY_CARD_SUCCESS',
  OK_WIZARD_RESET_DATA: 'OK_WIZARD_RESET_DATA',
  OK_WIZARD_SET_ALL_ACCOUNTS_TO_INACTIVE: 'OK_WIZARD_SET_ALL_ACCOUNTS_TO_INACTIVE',
  OK_WIZARD_SET_NGO_SELECTED: 'OK_WIZARD_SET_NGO_SELECTED',
  OK_WIZARD_SET_ASSOCIATE_ACCOUNT_SELECTED: 'OK_WIZARD_SET_ASSOCIATE_ACCOUNT_SELECTED',
  OK_WIZARD_SET_BUTTON_STATUS: 'OK_WIZARD_SET_BUTTON_STATUS',
  OK_WIZARD_SET_CURRENT_STEP: 'OK_WIZARD_SET_CURRENT_STEP',
  OK_WIZARD_SET_NGOS_DATA: 'OK_WIZARD_SET_NGOS_DATA',
  OK_WIZARD_SET_TYPE_CHANGE: 'OK_WIZARD_SET_TYPE_CHANGE',
  OK_WIZARD_SET_WIZARD_ERROR: 'OK_WIZARD_SET_WIZARD_ERROR',
  OK_WIZARD_SET_WIZARD_SUCCESS: 'OK_WIZARD_SET_WIZARD_SUCCESS',
  OK_WIZARD_SOLIDARITY_RESET: 'OK_WIZARD_SOLIDARITY_RESET',
  OK_WIZARD_SOLIDARITY_SET_MAX_AMOUNT_LIMIT: 'OK_WIZARD_SOLIDARITY_SET_MAX_AMOUNT_LIMIT',
  OK_WIZARD_SOLIDARITY_SET_MIN_AMOUNT_LIMIT: 'OK_WIZARD_SOLIDARITY_SET_MIN_AMOUNT_LIMIT',
  OK_WIZARD_SOLIDARITY_SET_VISIBLE_STEP: 'OK_WIZARD_SOLIDARITY_SET_VISIBLE_STEP',
  OK_WIZARD_SOLIDARITY_SET_WILL_CANCEL: 'OK_WIZARD_SOLIDARITY_SET_WILL_CANCEL',
  OK_WIZARD_SOLIDARITY_VALIDATE_STEP: 'OK_WIZARD_SOLIDARITY_VALIDATE_STEP',
  OK_WIZARD_HIRE_CARD_LOADING: 'OK_WIZARD_HIRE_CARD_LOADING',
  OPENYOUNG_CARD_ACCOUNTS_REQUEST: 'OPENYOUNG_CARD_ACCOUNTS_REQUEST',
  OPENYOUNG_CARD_ACCOUNTS_REQUEST_ERROR: 'OPENYOUNG_CARD_ACCOUNTS_REQUEST_ERROR',
  OPENYOUNG_CARD_ACCOUNTS_REQUEST_SUCCESS: 'OPENYOUNG_CARD_ACCOUNTS_REQUEST_SUCCESS',
  OPENYOUNG_CARD_CHORE_RESET_STATUS_UPDATE_INFO: 'OPENYOUNG_CARD_CHORE_RESET_STATUS_UPDATE_INFO',
  OPENYOUNG_CARD_CHORE_STATUS_UPDATE_ERROR: 'OPENYOUNG_CARD_CHORE_STATUS_UPDATE_ERROR',
  OPENYOUNG_CARD_CHORE_STATUS_UPDATE_REQUEST: 'OPENYOUNG_CARD_CHORE_STATUS_UPDATE_REQUEST',
  OPENYOUNG_CARD_CHORE_STATUS_UPDATE_SUCCESS: 'OPENYOUNG_CARD_CHORE_STATUS_UPDATE_SUCCESS',
  OPENYOUNG_CARD_GET_CHORE_APPLY_FILTER: 'OPENYOUNG_CARD_GET_CHORE_APPLY_FILTER',
  OPENYOUNG_CARD_GET_CHORE_CLEAR_FILTER: 'OPENYOUNG_CARD_GET_CHORE_CLEAR_FILTER',
  OPENYOUNG_CARD_GET_CHORE_DETAIL_ERROR: 'OPENYOUNG_CARD_GET_CHORE_DETAIL_ERROR',
  OPENYOUNG_CARD_GET_CHORE_DETAIL_REQUEST: 'OPENYOUNG_CARD_GET_CHORE_DETAIL_REQUEST',
  OPENYOUNG_CARD_GET_CHORE_DETAIL_SUCCESS: 'OPENYOUNG_CARD_GET_CHORE_DETAIL_SUCCESS',
  OPENYOUNG_CARD_GET_CHORE_LIST_ERROR: 'OPENYOUNG_CARD_GET_CHORE_LIST_ERROR',
  OPENYOUNG_CARD_GET_CHORE_LIST_REQUEST: 'OPENYOUNG_CARD_GET_CHORE_LIST_REQUEST',
  OPENYOUNG_CARD_GET_CHORE_LIST_SUCCESS: 'OPENYOUNG_CARD_GET_CHORE_LIST_SUCCESS',
  OPENYOUNG_CARD_GET_CHORE_RESET_FILTER: 'OPENYOUNG_CARD_GET_CHORE_RESET_FILTER',
  OPENYOUNG_CARD_GET_EXPENSE_LIST_APPLY_FILTER: 'OPENYOUNG_CARD_GET_EXPENSE_LIST_APPLY_FILTER',
  OPENYOUNG_CARD_GET_EXPENSE_LIST_CLEAR_FILTER: 'OPENYOUNG_CARD_GET_EXPENSE_LIST_CLEAR_FILTER',
  OPENYOUNG_CARD_GET_EXPENSE_LIST_ERROR: 'OPENYOUNG_CARD_GET_EXPENSE_LIST_ERROR',
  OPENYOUNG_CARD_GET_EXPENSE_LIST_REQUEST: 'OPENYOUNG_CARD_GET_EXPENSE_LIST_REQUEST',
  OPENYOUNG_CARD_GET_EXPENSE_LIST_SUCCESS: 'OPENYOUNG_CARD_GET_EXPENSE_LIST_SUCCESS',
  OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION: 'OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION',
  OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION_SUCCESS:
    'OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION_SUCCESS',
  OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_ERROR: 'OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_ERROR',
  OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_REQUEST:
    'OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_REQUEST',
  OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_SUCCESS:
    'OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_SUCCESS',
  OPENYOUNG_CARD_GET_SAVINGS_REQUEST_CONFIRMATION_SUCCESS:
    'OPENYOUNG_CARD_GET_SAVINGS_REQUEST_CONFIRMATION_SUCCESS',
  OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST: 'OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST',
  OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST_ERROR:
    'OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST_ERROR',
  OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST_SUCCESS:
    'OPENYOUNG_CARD_MONEY_SAVINGS_ACCEPT_REQUEST_SUCCESS',
  OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST: 'OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST',
  OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_ERROR:
    'OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_ERROR',
  OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_SUCCESS:
    'OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_SUCCESS',
  OPENYOUNG_CARD_MONEY_SAVINGS_NO_BALANCE: 'OPENYOUNG_CARD_MONEY_SAVINGS_NO_BALANCE',
  OPENYOUNG_CARD_MONEY_SAVINGS_NO_OY_ACCOUNT: 'OPENYOUNG_CARD_MONEY_SAVINGS_NO_OY_ACCOUNT',
  OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST: 'OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST',
  OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST_ERROR:
    'OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST_ERROR',
  OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST_SUCCESS:
    'OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST_SUCCESS',
  OPENYOUNG_CARD_PAYMENT_CHORE_RESET: 'OPENYOUNG_CARD_PAYMENT_CHORE_RESET',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT: 'OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT_AVAILABLE_BALANCE:
    'OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT_AVAILABLE_BALANCE',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_AMOUNT: 'OPENYOUNG_CARD_PAYMENT_CHORE_SET_AMOUNT',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_CARD_AVAILABLE_BALANCE:
    'OPENYOUNG_CARD_PAYMENT_CHORE_SET_CARD_AVAILABLE_BALANCE',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_REMARK: 'OPENYOUNG_CARD_PAYMENT_CHORE_SET_REMARK',
  OPENYOUNG_CARD_PAYMENT_CHORE_SET_VISIBLE_STEP: 'OPENYOUNG_CARD_PAYMENT_CHORE_SET_VISIBLE_STEP',
  OPENYOUNG_CARD_PAYMENT_CHORE_VALIDATE_STEP: 'OPENYOUNG_CARD_PAYMENT_CHORE_VALIDATE_STEP',
  OPENYOUNG_CARD_PAYMENT_CHORE_WILL_CANCEL: 'OPENYOUNG_CARD_PAYMENT_CHORE_WILL_CANCEL',
  OPENYOUNG_CARD_REJECT_CHORE_RESET: 'OPENYOUNG_CARD_REJECT_CHORE_RESET',
  OPENYOUNG_CARD_REJECT_CHORE_SET_REMARK: 'OPENYOUNG_CARD_REJECT_CHORE_SET_REMARK',
  OPENYOUNG_CARD_REJECT_CHORE_SET_VISIBLE_STEP: 'OPENYOUNG_CARD_REJECT_CHORE_SET_VISIBLE_STEP',
  OPENYOUNG_CARD_REJECT_CHORE_VALIDATE_STEP: 'OPENYOUNG_CARD_REJECT_CHORE_VALIDATE_STEP',
  OPENYOUNG_CARD_REJECT_CHORE_WILL_CANCEL: 'OPENYOUNG_CARD_REJECT_CHORE_WILL_CANCEL',
  OPENYOUNG_CARD_REJECT_REQUEST: 'OPENYOUNG_CARD_REJECT_REQUEST',
  OPENYOUNG_CARD_REJECT_REQUEST_SUCCESS: 'OPENYOUNG_CARD_REJECT_REQUEST_SUCCESS',
  OPENYOUNG_DASHBOARD_CHILD_AVATAR_ERROR: 'OPENYOUNG_DASHBOARD_CHILD_AVATAR_ERROR',
  OPENYOUNG_DASHBOARD_CHILD_AVATAR_REQUEST: 'OPENYOUNG_DASHBOARD_CHILD_AVATAR_REQUEST',
  OPENYOUNG_DASHBOARD_CHILD_AVATAR_SUCCESS: 'OPENYOUNG_DASHBOARD_CHILD_AVATAR_SUCCESS',
  OPENYOUNG_DASHBOARD_CHILD_DETAILS_ERROR: 'OPENYOUNG_DASHBOARD_CHILD_DETAILS_ERROR',
  OPENYOUNG_DASHBOARD_CHILD_DETAILS_REQUEST: 'OPENYOUNG_DASHBOARD_CHILD_DETAILS_REQUEST',
  OPENYOUNG_DASHBOARD_CHILD_DETAILS_SUCCESS: 'OPENYOUNG_DASHBOARD_CHILD_DETAILS_SUCCESS',
  OPENYOUNG_DASHBOARD_CHILD_LIST_ERROR: 'OPENYOUNG_DASHBOARD_CHILD_LIST_ERROR',
  OPENYOUNG_DASHBOARD_CHILD_LIST_REQUEST: 'OPENYOUNG_DASHBOARD_CHILD_LIST_REQUEST',
  OPENYOUNG_DASHBOARD_CHILD_LIST_SUCCESS: 'OPENYOUNG_DASHBOARD_CHILD_LIST_SUCCESS',
  OPENYOUNG_HISTORY_CLEAR_FILTER: 'OPENYOUNG_HISTORY_CLEAR_FILTER',
  OPENYOUNG_HISTORY_FAILURE: 'OPENYOUNG_HISTORY_FAILURE',
  OPENYOUNG_HISTORY_FILTER: 'OPENYOUNG_HISTORY_FILTER',
  OPENYOUNG_HISTORY_IS_FETCHING: 'OPENYOUNG_HISTORY_IS_FETCHING',
  OPENYOUNG_HISTORY_RESET_FILTER: 'OPENYOUNG_HISTORY_RESET_FILTER',
  OPENYOUNG_HISTORY_RESET: 'OPENYOUNG_HISTORY_RESET',
  OPENYOUNG_HISTORY_SUCCESS: 'OPENYOUNG_HISTORY_SUCCESS',
  OPENYOUNG_PERIODIC_LOADS_CLEAR_FILTERS: 'OPENYOUNG_PERIODIC_LOADS_CLEAR_FILTERS',
  OPENYOUNG_PERIODIC_LOADS_LIST_ERROR: 'OPENYOUNG_PERIODIC_LOADS_LIST_ERROR',
  OPENYOUNG_PERIODIC_LOADS_LIST_REQUEST: 'OPENYOUNG_PERIODIC_LOADS_LIST_REQUEST',
  OPENYOUNG_PERIODIC_LOADS_LIST_SUCCESS: 'OPENYOUNG_PERIODIC_LOADS_LIST_SUCCESS',
  OPENYOUNG_PERIODIC_LOADS_RESET_FILTERS: 'OPENYOUNG_PERIODIC_LOADS_RESET_FILTERS',
  OPENYOUNG_PERIODIC_LOADS_SET_FILTERS: 'OPENYOUNG_PERIODIC_LOADS_SET_FILTERS',
  OPEN_PERSONETICS_SUBSCRIPTION_MODAL: 'OPEN_PERSONETICS_SUBSCRIPTION_MODAL',
  OPEN_PERSONETICS_UNSUBSCRIPTION_MODAL: 'OPEN_PERSONETICS_UNSUBSCRIPTION_MODAL',
  OPEN_YOUNG_BUDGET_ERROR: 'OPEN_YOUNG_BUDGET_ERROR',
  OPEN_YOUNG_BUDGET_REQUEST: 'OPEN_YOUNG_BUDGET_REQUEST',
  OPEN_YOUNG_BUDGET_SUCCESS: 'OPEN_YOUNG_BUDGET_SUCCESS',
  OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE:
    'OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE',
  OPEN_YOUNG_GET_CARD_BENEFICIARY_REQUEST: 'OPEN_YOUNG_GET_CARD_BENEFICIARY_REQUEST',
  OPEN_YOUNG_GET_CARD_BENEFICIARY_SUCCESS: 'OPEN_YOUNG_GET_CARD_BENEFICIARY_SUCCESS',
  OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_REQUEST: 'OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_REQUEST',
  OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_SUCCESS: 'OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_SUCCESS',
  OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_RESET: 'OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_RESET',
  OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_FAILURE:
    'OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_FAILURE',
  OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_IS_FETCHING:
    'OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_IS_FETCHING',
  OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_SUCCESS:
    'OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_SUCCESS',
  OPEN_YOUNG_GET_PROFILES_FAILURE: 'OPEN_YOUNG_GET_PROFILES_FAILURE',
  OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_FAILURE: 'OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_FAILURE',
  OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_REQUEST: 'OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_REQUEST',
  OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_SUCCESS: 'OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_SUCCESS',
  OPEN_YOUNG_GET_PROFILES_REQUEST: 'OPEN_YOUNG_GET_PROFILES_REQUEST',
  OPEN_YOUNG_GET_PROFILES_SUCCESS: 'OPEN_YOUNG_GET_PROFILES_SUCCESS',
  OPEN_YOUNG_GET_PROFILE_DETAILS_FAILURE: 'OPEN_YOUNG_GET_PROFILE_DETAILS_FAILURE',
  OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_FAILURE:
    'OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_FAILURE',
  OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_REQUEST:
    'OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_REQUEST',
  OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_SUCCESS:
    'OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_SUCCESS',
  OPEN_YOUNG_GET_PROFILE_DETAILS_REQUEST: 'OPEN_YOUNG_GET_PROFILE_DETAILS_REQUEST',
  OPEN_YOUNG_GET_PROFILE_DETAILS_RESET: 'OPEN_YOUNG_GET_PROFILE_DETAILS_RESET',
  OPEN_YOUNG_GET_PROFILE_DETAILS_SET_VALIDATE_STEP:
    'OPEN_YOUNG_GET_PROFILE_DETAILS_SET_VALIDATE_STEP',
  OPEN_YOUNG_GET_PROFILE_DETAILS_SUCCESS: 'OPEN_YOUNG_GET_PROFILE_DETAILS_SUCCESS',
  OPEN_YOUNG_GET_PROFILE_DETAILS_VALIDATE_STEP: 'OPEN_YOUNG_GET_PROFILE_DETAILS_VALIDATE_STEP',
  OPEN_YOUNG_GET_PROFILE_DETAILS_WILL_CANCEL: 'OPEN_YOUNG_GET_PROFILE_DETAILS_WILL_CANCEL',
  OPEN_YOUNG_INTERVENERS_EXCLUDE_INTERVENER: 'OPEN_YOUNG_INTERVENERS_EXCLUDE_INTERVENER',
  OPEN_YOUNG_INTERVENERS_GET_FAILURE: 'OPEN_YOUNG_INTERVENERS_GET_FAILURE',
  OPEN_YOUNG_INTERVENERS_GET_REQUEST: 'OPEN_YOUNG_INTERVENERS_GET_REQUEST',
  OPEN_YOUNG_INTERVENERS_GET_SUCCESS: 'OPEN_YOUNG_INTERVENERS_GET_SUCCESS',
  OPEN_YOUNG_INTERVENERS_RESET: 'OPEN_YOUNG_INTERVENERS_RESET',
  OPEN_YOUNG_INTERVENERS_RESTORE_DEFAULT_INTERVENERS:
    'OPEN_YOUNG_INTERVENERS_RESTORE_DEFAULT_INTERVENERS',
  OPEN_YOUNG_INTERVENERS_UPDATE_RELATIONSHIP: 'OPEN_YOUNG_INTERVENERS_UPDATE_RELATIONSHIP',
  OPEN_YOUNG_INTERVENERS_VALIDATE_STEP: 'OPEN_YOUNG_INTERVENERS_VALIDATE_STEP',
  OPEN_YOUNG_PERSONAL_DATA_FILL: 'OPEN_YOUNG_PERSONAL_DATA_FILL',
  OPEN_YOUNG_PERSONAL_DATA_RESET: 'OPEN_YOUNG_PERSONAL_DATA_RESET',
  OPEN_YOUNG_PERSONAL_DATA_SET_FIELD: 'OPEN_YOUNG_PERSONAL_DATA_SET_FIELD',
  OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_FAILURE:
    'OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_FAILURE',
  OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_REQUEST:
    'OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_REQUEST',
  OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_SUCCESS:
    'OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_SUCCESS',
  OPEN_YOUNG_PERSONAL_DATA_VALIDATE_FIELD: 'OPEN_YOUNG_PERSONAL_DATA_VALIDATE_FIELD',
  OPEN_YOUNG_PERSONAL_DATA_VALIDATE_STEP: 'OPEN_YOUNG_PERSONAL_DATA_VALIDATE_STEP',
  OPEN_YOUNG_RESET_BENEFICIARY_DOCUMENTATION_DATA:
    'OPEN_YOUNG_RESET_BENEFICIARY_DOCUMENTATION_DATA',
  OPEN_YOUNG_SET_COMPARATIVE_MONTH: 'OPEN_YOUNG_SET_COMPARATIVE_MONTH',
  OPEN_YOUNG_TAX_DATA_ADD_NEW: 'OPEN_YOUNG_TAX_DATA_ADD_NEW',
  OPEN_YOUNG_TAX_DATA_CANCEL_ADD_NEW: 'OPEN_YOUNG_TAX_DATA_CANCEL_ADD_NEW',
  OPEN_YOUNG_TAX_DATA_CANCEL_UPDATE: 'OPEN_YOUNG_TAX_DATA_CANCEL_UPDATE',
  OPEN_YOUNG_TAX_DATA_FILL_OPENYOUNG_PROFILE: 'OPEN_YOUNG_TAX_DATA_FILL_OPENYOUNG_PROFILE',
  OPEN_YOUNG_TAX_DATA_REMOVE: 'OPEN_YOUNG_TAX_DATA_REMOVE',
  OPEN_YOUNG_TAX_DATA_RESET: 'OPEN_YOUNG_TAX_DATA_RESET',
  OPEN_YOUNG_TAX_DATA_SET_FIELD: 'OPEN_YOUNG_TAX_DATA_SET_FIELD',
  OPEN_YOUNG_TAX_DATA_SET_FORM_FIELD: 'OPEN_YOUNG_TAX_DATA_SET_FORM_FIELD',
  OPEN_YOUNG_TAX_DATA_START_EDIT: 'OPEN_YOUNG_TAX_DATA_START_EDIT',
  OPEN_YOUNG_TAX_DATA_TOGGLE_ADD_FORM: 'OPEN_YOUNG_TAX_DATA_TOGGLE_ADD_FORM',
  OPEN_YOUNG_TAX_DATA_UPDATE: 'OPEN_YOUNG_TAX_DATA_UPDATE',
  OPEN_YOUNG_TAX_DATA_VALIDATE_FORM: 'OPEN_YOUNG_TAX_DATA_VALIDATE_FORM',
  OPEN_YOUNG_TAX_DATA_VALIDATE_FORM_FIELD: 'OPEN_YOUNG_TAX_DATA_VALIDATE_FORM_FIELD',
  OPEN_YOUNG_TAX_DATA_VALIDATE_STEP: 'OPEN_YOUNG_TAX_DATA_VALIDATE_STEP',
  OPEN_YOUNG_TUTOR_TYPE_RESET: 'OPEN_YOUNG_TUTOR_TYPE_RESET',
  OPEN_YOUNG_TUTOR_TYPE_SET: 'OPEN_YOUNG_TUTOR_TYPE_SET',
  OPEN_YOUNG_TUTOR_TYPE_VALIDATE_STEP: 'OPEN_YOUNG_TUTOR_TYPE_VALIDATE_STEP',
  OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE: 'OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE',
  OPEN_YOUNG_UPDATE_PROFILE_DETAILS_REQUEST: 'OPEN_YOUNG_UPDATE_PROFILE_DETAILS_REQUEST',
  OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS: 'OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS',
  PASS_AMOUNT: 'PASS_AMOUNT',
  PASS_CONCEPT: 'PASS_CONCEPT',
  PASS_FROM_ACCOUNT: 'PASS_FROM_ACCOUNT',
  PASS_PREDEFINED_TO_ACCOUNT: 'PASS_PREDEFINED_TO_ACCOUNT',
  PASS_REQUEST_TRANSFER_FAILURE: 'PASS_REQUEST_TRANSFER_FAILURE',
  PASS_REQUEST_TRANSFER_REQUEST: 'PASS_REQUEST_TRANSFER_REQUEST',
  PASS_REQUEST_TRANSFER_SUCCESS: 'PASS_REQUEST_TRANSFER_SUCCESS',
  PASS_RESET: 'PASS_RESET',
  PASS_SET_FUTURE_TRANSFER: 'PASS_SET_FUTURE_TRANSFER',
  PASS_SET_NEW_ACCOUNT_NOT_FOUND_ERROR: 'PASS_SET_NEW_ACCOUNT_NOT_FOUND_ERROR',
  PASS_SET_VISIBLE_STEP: 'PASS_SET_VISIBLE_STEP',
  PASS_TO_ACCOUNT: 'PASS_TO_ACCOUNT',
  PASS_VALIDATE_STEP_FAILURE: 'PASS_VALIDATE_STEP_FAILURE',
  PASS_VALIDATE_STEP_REQUEST: 'PASS_VALIDATE_STEP_REQUEST',
  PASS_VALIDATE_STEP_SUCCESS: 'PASS_VALIDATE_STEP_SUCCESS',
  PASS_VALIDATE_UI_STEP: 'PASS_VALIDATE_UI_STEP',
  PASS_WILL_CANCEL: 'PASS_WILL_CANCEL',
  PASS_WILL_LEAVE: 'PASS_WILL_LEAVE',
  PDF_DOCUMENTS_DOWNLOAD_ERROR: 'PDF_DOCUMENTS_DOWNLOAD_ERROR',
  PDF_DOCUMENTS_DOWNLOAD_SUCCESS: 'PDF_DOCUMENTS_DOWNLOAD_SUCCESS',
  PDF_DOCUMENTS_MERGE_ERROR: 'PDF_DOCUMENTS_MERGE_ERROR',
  PDF_DOCUMENTS_MERGE_REQUEST: 'PDF_DOCUMENTS_MERGE_REQUEST',
  PDF_DOCUMENTS_MERGE_RESET: 'PDF_DOCUMENTS_MERGE_RESET',
  PDF_DOCUMENTS_MERGE_SUCCESS: 'PDF_DOCUMENTS_MERGE_SUCCESS',
  POST_ACCESS_REPORTS_FAILURE: 'POST_ACCESS_REPORTS_FAILURE',
  POST_ACCESS_REPORTS_SUCCESS: 'POST_ACCESS_REPORTS_SUCCESS',
  POST_PERSONETICS_SUBSCRIPTION_STATUS_ERROR: 'POST_PERSONETICS_SUBSCRIPTION_STATUS_ERROR',
  POST_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST: 'POST_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST',
  POST_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS: 'POST_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS',
  POST_PERSONETICS_UNSUBSCRIPTION_STATUS_ERROR: 'POST_PERSONETICS_UNSUBSCRIPTION_STATUS_ERROR',
  POST_PERSONETICS_UNSUBSCRIPTION_STATUS_REQUEST: 'POST_PERSONETICS_UNSUBSCRIPTION_STATUS_REQUEST',
  POST_PERSONETICS_UNSUBSCRIPTION_STATUS_SUCCESS: 'POST_PERSONETICS_UNSUBSCRIPTION_STATUS_SUCCESS',
  POST_REPORTS_FAILURE: 'POST_REPORTS_FAILURE',
  POST_REPORTS_IN_PROGRESS: 'POST_REPORTS_IN_PROGRESS',
  POST_REPORTS_SUCCESS: 'POST_REPORTS_SUCCESS',
  POST_SIGNATURE_KEY_STATUS_ERROR: 'POST_SIGNATURE_KEY_STATUS_ERROR',
  POST_SIGNATURE_KEY_STATUS_REQUEST: 'POST_SIGNATURE_KEY_STATUS_REQUEST',
  POST_SIGNATURE_KEY_STATUS_SUCCESS: 'POST_SIGNATURE_KEY_STATUS_SUCCESS',
  PRE_CONTRACTUAL_INFO_FAILURE: 'PRE_CONTRACTUAL_INFO_FAILURE',
  PRE_CONTRACTUAL_INFO_REQUEST: 'PRE_CONTRACTUAL_INFO_REQUEST',
  PRE_CONTRACTUAL_INFO_RESET: 'PRE_CONTRACTUAL_INFO_RESET',
  PRE_CONTRACTUAL_MAIL_SENT_FAILURE: 'PRE_CONTRACTUAL_MAIL_SENT_FAILURE',
  PRE_CONTRACTUAL_INFO_SUCCESS: 'PRE_CONTRACTUAL_INFO_SUCCESS',
  PRE_CONTRACTUAL_RAW_DOCUMENT_REQUEST: 'PRE_CONTRACTUAL_RAW_DOCUMENT_REQUEST',
  PRE_CONTRACTUAL_RAW_DOCUMENT_SUCCESS: 'PRE_CONTRACTUAL_RAW_DOCUMENT_SUCCESS',
  PRE_CONTRACTUAL_RAW_DOCUMENT_FAILURE: 'PRE_CONTRACTUAL_RAW_DOCUMENT_FAILURE',
  PRE_CONTRACTUAL_DOCUMENTATION_LIST_REQUEST: 'PRE_CONTRACTUAL_DOCUMENTATION_LIST_REQUEST',
  PRE_CONTRACTUAL_DOCUMENTATION_LIST_SUCCESS: 'PRE_CONTRACTUAL_DOCUMENTATION_LIST_SUCCESS',
  PRE_CONTRACTUAL_DOCUMENTATION_LIST_FAILURE: 'PRE_CONTRACTUAL_DOCUMENTATION_LIST_FAILURE',
  PRE_CONTRACTUAL_POLLING_DOCUMENTS_FAILURE: 'PRE_CONTRACTUAL_POLLING_DOCUMENTS_FAILURE',
  PRE_CONTRACTUAL_POLLING_DOCUMENTS_REQUEST: 'PRE_CONTRACTUAL_POLLING_DOCUMENTS_REQUEST',
  PRE_CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS: 'PRE_CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS',
  PRE_CONTRACTUAL_POLLING_INIT_FAILURE: 'PRE_CONTRACTUAL_POLLING_INIT_FAILURE',
  PRE_CONTRACTUAL_POLLING_INIT_REQUEST: 'PRE_CONTRACTUAL_POLLING_INIT_REQUEST',
  PRE_CONTRACTUAL_POLLING_INIT_SUCCESS: 'PRE_CONTRACTUAL_POLLING_INIT_SUCCESS',
  PRE_CONTRACTUAL_POLLING_MAIL_FAILURE: 'PRE_CONTRACTUAL_POLLING_MAIL_FAILURE',
  PRE_CONTRACTUAL_POLLING_MAIL_REQUEST: 'PRE_CONTRACTUAL_POLLING_MAIL_REQUEST',
  PRE_CONTRACTUAL_POLLING_MAIL_SUCCESS: 'PRE_CONTRACTUAL_POLLING_MAIL_SUCCESS',
  PRE_CONTRACTUAL_POLLING_MERGE_FAILURE: 'PRE_CONTRACTUAL_POLLING_MERGE_FAILURE',
  PRE_CONTRACTUAL_POLLING_MERGE_REQUEST: 'PRE_CONTRACTUAL_POLLING_MERGE_REQUEST',
  PRE_CONTRACTUAL_POLLING_MERGE_SUCCESS: 'PRE_CONTRACTUAL_POLLING_MERGE_SUCCESS',
  PRE_CONTRACTUAL_POLLING_RESET: 'PRE_CONTRACTUAL_POLLING_RESET',
  PROFILE_ACTION_CHANGE_VALUE: 'PROFILE_ACTION_CHANGE_VALUE',
  PROFILE_DELETE_ADDITIONAL_TAX_COUNTRIES: 'PROFILE_DELETE_ADDITIONAL_TAX_COUNTRIES',
  PROFILE_EDIT_ADDING_TAX_ADDITIONAL_COUNTRY_SET: 'PROFILE_EDIT_ADDING_TAX_ADDITIONAL_COUNTRY_SET',
  PROFILE_EDIT_AVATAR_ERROR: 'PROFILE_EDIT_AVATAR_ERROR',
  PROFILE_EDIT_AVATAR_MODAL: 'PROFILE_EDIT_AVATAR_MODAL',
  PROFILE_EDIT_EDITING_TAX_ADDITIONAL_COUNTRY_SET:
    'PROFILE_EDIT_EDITING_TAX_ADDITIONAL_COUNTRY_SET',
  PROFILE_EDIT_RESET: 'PROFILE_EDIT_RESET',
  PROFILE_EDIT_SET_EDIT_MODE: 'PROFILE_EDIT_SET_EDIT_MODE',
  PROFILE_EDIT_SET_FIELD: 'PROFILE_EDIT_SET_FIELD',
  PROFILE_EDIT_SET_VISIBLE_STEP: 'PROFILE_EDIT_SET_VISIBLE_STEP',
  PROFILE_EDIT_VALIDATE_STEP: 'PROFILE_EDIT_VALIDATE_STEP',
  PROFILE_EDIT_WILL_CANCEL: 'PROFILE_EDIT_WILL_CANCEL',
  PROFILE_EDIT_WILL_LEAVE: 'PROFILE_EDIT_WILL_LEAVE',
  PROFILE_GET_SHIPPING_ADDRESS_FAILURE: 'PROFILE_GET_SHIPPING_ADDRESS_FAILURE',
  PROFILE_GET_SHIPPING_ADDRESS_NO_RESULT_SUCCESS: 'PROFILE_GET_SHIPPING_ADDRESS_NO_RESULT_SUCCESS',
  PROFILE_GET_SHIPPING_ADDRESS_ONE_RESULT_SUCCESS:
    'PROFILE_GET_SHIPPING_ADDRESS_ONE_RESULT_SUCCESS',
  PROFILE_GET_SHIPPING_ADDRESS_REQUEST: 'PROFILE_GET_SHIPPING_ADDRESS_REQUEST',
  PROFILE_SET_ALIAS_ERROR: 'PROFILE_SET_ALIAS_ERROR',
  PROFILE_SET_ALIAS_IN_PROGRESS: 'PROFILE_SET_ALIAS_IN_PROGRESS',
  PROFILE_SET_ALIAS_SUCCESS: 'PROFILE_SET_ALIAS_SUCCESS',
  PROFILE_SET_BROWSING_LANGUAGE_ERROR: 'PROFILE_SET_BROWSING_LANGUAGE_ERROR',
  PROFILE_SET_BROWSING_LANGUAGE_IN_PROGRESS: 'PROFILE_SET_BROWSING_LANGUAGE_IN_PROGRESS',
  PROFILE_SET_BROWSING_LANGUAGE_SUCCESS: 'PROFILE_SET_BROWSING_LANGUAGE_SUCCESS',
  PROFILE_SHIPPING_ADDRESS_ADD_FAILURE: 'PROFILE_SHIPPING_ADDRESS_ADD_FAILURE',
  PROFILE_SHIPPING_ADDRESS_ADD_SUCCESS: 'PROFILE_SHIPPING_ADDRESS_ADD_SUCCESS',
  PROFILE_SHIPPING_ADDRESS_DELETE: 'PROFILE_SHIPPING_ADDRESS_DELETE',
  PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE: 'PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE',
  PROFILE_SHIPPING_ADDRESS_DELETE_SUCCESS: 'PROFILE_SHIPPING_ADDRESS_DELETE_SUCCESS',
  PROFILE_SHIPPING_ADDRESS_RESET: 'PROFILE_SHIPPING_ADDRESS_RESET',
  PROFILE_SHIPPING_ADDRESS_SCROLL_TO_TOP_TOGGLE: 'PROFILE_SHIPPING_ADDRESS_SCROLL_TO_TOP_TOGGLE',
  PROFILE_SHIPPING_ADDRESS_SET_EDIT_MODE: 'PROFILE_SHIPPING_ADDRESS_SET_EDIT_MODE',
  PROFILE_SHIPPING_ADDRESS_SET_VISIBLE_STEP: 'PROFILE_SHIPPING_ADDRESS_SET_VISIBLE_STEP',
  PROFILE_SHIPPING_ADDRESS_UPDATE: 'PROFILE_SHIPPING_ADDRESS_UPDATE',
  PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE: 'PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE',
  PROFILE_SHIPPING_ADDRESS_UPDATE_SUCCESS: 'PROFILE_SHIPPING_ADDRESS_UPDATE_SUCCESS',
  PROFILE_SHIPPING_ADDRESS_VALIDATE_STEP: 'PROFILE_SHIPPING_ADDRESS_VALIDATE_STEP',
  PROFILE_SHIPPING_ADDRESS_WILL_CANCEL: 'PROFILE_SHIPPING_ADDRESS_WILL_CANCEL',
  PROFILE_UPDATE_ADDITIONAL_TAX_COUNTRIES: 'PROFILE_UPDATE_ADDITIONAL_TAX_COUNTRIES',
  PROFILE_UPDATE_CONSENTS_STATUS: 'PROFILE_UPDATE_CONSENTS_STATUS',
  PROFILE_UPDATE_CONSENTS_STATUS_FAILURE: 'PROFILE_UPDATE_CONSENTS_STATUS_FAILURE',
  PROFILE_UPDATE_CONSENTS_STATUS_SUCCESS: 'PROFILE_UPDATE_CONSENTS_STATUS_SUCCESS',
  PROFILE_UPDATE_PICTURE: 'PROFILE_UPDATE_PICTURE',
  PROMOTIONS_CANCEL_PROMOTIONAL_CODE: 'PROMOTIONS_CANCEL_PROMOTIONAL_CODE',
  RECEIPTS_FILTER: 'RECEIPTS_FILTER',
  RECEIPTS_FILTER_BY_NAME: 'RECEIPTS_FILTER_BY_NAME',
  RECEIPT_GET_PREVIEW: 'RECEIPT_GET_PREVIEW',
  RECEIPT_GET_PREVIEW_FAILURE: 'RECEIPT_GET_PREVIEW_FAILURE',
  RECEIPT_SET_PREVIEW: 'RECEIPT_SET_PREVIEW',
  REFERRALS_FORM_RESET: 'REFERRALS_FORM_RESET',
  REFERRALS_FORM_SET_PROMO_CODE: 'REFERRALS_FORM_SET_PROMO_CODE',
  REFERRALS_FORM_SUBMIT_CLIPBOARD_SUCCESS: 'REFERRALS_FORM_SUBMIT_CLIPBOARD_SUCCESS',
  REFERRALS_FORM_SUBMIT_FAILURE: 'REFERRALS_FORM_SUBMIT_FAILURE',
  REFERRALS_FORM_SUBMIT_REQUEST: 'REFERRALS_FORM_SUBMIT_REQUEST',
  REFERRALS_FORM_SUBMIT_SUCCESS: 'REFERRALS_FORM_SUBMIT_SUCCESS',
  REFERRALS_GET_PROMOTION_DETAILS_ERROR: 'REFERRALS_GET_PROMOTION_DETAILS_ERROR',
  REFERRALS_GET_PROMOTION_DETAILS_IS_FETCHING: 'REFERRALS_GET_PROMOTION_DETAILS_IS_FETCHING',
  REFERRALS_GET_PROMOTION_DETAILS_SUCCESS: 'REFERRALS_GET_PROMOTION_DETAILS_SUCCESS',
  REFERRALS_INVITATIONS_CLEAR_FILTERS: 'REFERRALS_INVITATIONS_CLEAR_FILTERS',
  REFERRALS_INVITATIONS_RESET_FILTER: 'REFERRALS_INVITATIONS_RESET_FILTER',
  REFERRALS_INVITATIONS_SET_FILTERS: 'REFERRALS_INVITATIONS_SET_FILTERS',
  REFERRALS_SET_GUARDIAN_INVITATION_ERROR: 'REFERRALS_SET_GUARDIAN_INVITATION_ERROR',
  REFERRALS_SET_GUARDIAN_INVITATION_REQUEST: 'REFERRALS_SET_GUARDIAN_INVITATION_REQUEST',
  REFERRALS_SET_GUARDIAN_INVITATION_SUCCESS: 'REFERRALS_SET_GUARDIAN_INVITATION_SUCCESS',
  REFRESH_AGGREGATION_TINK_REQUEST: '  REFRESH_AGGREGATION_TINK_REQUEST',
  REFRESH_AGGREGATION_TINK_SUCCESS: '  REFRESH_AGGREGATION_TINK_SUCCESS',
  REFRESH_AGGREGATION_TINK_FAILURE: '  REFRESH_AGGREGATION_TINK_FAILURE',
  REMOVE_AMOUNT_FILTER: 'REMOVE_AMOUNT_FILTER',
  REMOVE_BENEFICIARY_FAILURE: 'REMOVE_BENEFICIARY_FAILURE',
  REMOVE_BENEFICIARY_REQUEST: 'REMOVE_BENEFICIARY_REQUEST',
  REMOVE_BENEFICIARY_SUCCESS: 'REMOVE_BENEFICIARY_SUCCESS',
  REMOVE_CHECK_FILTER: 'REMOVE_CHECK_FILTER',
  REMOVE_DATE_FILTER: 'REMOVE_DATE_FILTER',
  REMOVE_DOCUMENT_LIST_ERROR: 'REMOVE_DOCUMENT_LIST_ERROR',
  REMOVE_FILTER: 'REMOVE_FILTER',
  REMOVE_FILTER_TAG: 'REMOVE_FILTER_TAG',
  REMOVE_SCHEDULED_TRANSFER_FAILURE: 'REMOVE_SCHEDULED_TRANSFER_FAILURE',
  REMOVE_SCHEDULED_TRANSFER_REQUEST: 'REMOVE_SCHEDULED_TRANSFER_REQUEST',
  REMOVE_SCHEDULED_TRANSFER_SUCCESS: 'REMOVE_SCHEDULED_TRANSFER_SUCCESS',
  REMOVE_SELECT_FILTER: 'REMOVE_SELECT_FILTER',
  REMOVE_TOOLTIP: 'REMOVE_TOOLTIP',
  REQUEST_GET_COUNTRIES_FAILURE: 'REQUEST_GET_COUNTRIES_FAILURE',
  REQUEST_GET_COUNTRIES_IN_PROGRESS: 'REQUEST_GET_COUNTRIES_IN_PROGRESS',
  REQUEST_GET_COUNTRIES_SUCCESS: 'REQUEST_GET_COUNTRIES_SUCCESS',
  REQUEST_INITIAL_KEEP_ALIVE: 'REQUEST_INITIAL_KEEP_ALIVE',
  REQUEST_SHARED_ACCOUNTS_FAILURE: 'REQUEST_REQUEST_SHARED_ACCOUNTS_FAILURE',
  REQUEST_SHARED_ACCOUNTS_REQUEST: 'REQUEST_SHARED_ACCOUNTS_REQUEST',
  REQUEST_SHARED_ACCOUNTS_SUCCESS: 'REQUEST_SHARED_ACCOUNTS_SUCCESS',
  RESET_ACCOUNT_TRANSACTIONS_FILTER: 'RESET_ACCOUNT_TRANSACTIONS_FILTER',
  RESET_AMAZON_COUPON_CODE: 'RESET_AMAZON_COUPON_CODE',
  RESET_CARDS_CANCEL_DEFER_PURCHASE: 'RESET_CARDS_CANCEL_DEFER_PURCHASE',
  RESET_CARDS_CANCEL_PAY_LATER: 'RESET_CARDS_CANCEL_PAY_LATER',
  RESET_CARD_SHIPPING_ADDRESS: 'RESET_CARD_SHIPPING_ADDRESS',
  RESET_CARD_TRANSACTIONS_FILTER: 'RESET_CARD_TRANSACTIONS_FILTER',
  RESET_EMITED_TRANSFERS_FILTER: 'RESET_EMITED_TRANSFERS_FILTER',
  RESET_IMMEDIATE_TRANSFER: 'RESET_IMMEDIATE_TRANSFER',
  RESET_PRODUCT_OFFERS: 'RESET_PRODUCT_OFFERS',
  RESET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT: 'RESET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT',
  RESET_LOGOUT_TIMER: 'RESET_LOGOUT_TIMER',
  RESET_NOTIFICATION_EVENT_TYPE: 'RESET_NOTIFICATION_EVENT_TYPE',
  RESET_PDF_TO_PRINT: 'RESET_PDF_TO_PRINT',
  RESET_RECEIVED_TRANSFERS_FILTER: 'RESET_RECEIVED_TRANSFERS_FILTER',
  RESET_RIGHTS_PROFILE: 'RESET_RIGHTS_PROFILE',
  RESET_SCHEDULED_TRANSFERS_FILTER: 'RESET_SCHEDULED_TRANSFERS_FILTER',
  RESET_SEGMENTED_LOAN_PRODUCTS_STATE: 'RESET_SEGMENTED_LOAN_PRODUCTS_STATE',
  RESET_TRANSFER_DATES: 'RESET_TRANSFER_DATES',
  REVOLVING_DOCUMENT_REQUEST: 'REVOLVING_DOCUMENT_REQUEST',
  REVOLVING_DOCUMENT_SUCCESS: 'REVOLVING_DOCUMENT_SUCCESS',
  REVOLVING_DOCUMENT_ERROR: 'REVOLVING_DOCUMENT_ERROR',
  REVOLVING_DOCUMENT_DOWNLOAD_SUCCESS: 'REVOLVING_DOCUMENT_DOWNLOAD_SUCCESS',
  REVOLVING_DOCUMENT_DOWNLOAD_ERROR: 'REVOLVING_DOCUMENT_DOWNLOAD_ERROR',
  REVOLVING_INNE_REQUEST: 'REVOLVING_INNE_REQUEST',
  REVOLVING_INNE_SUCCESS: 'REVOLVING_INNE_SUCCESS',
  REVOLVING_INNE_ERROR: 'REVOLVING_INNE_ERROR',
  REVOLVING_INNE_DOWNLOAD_SUCCESS: 'REVOLVING_INNE_DOWNLOAD_SUCCESS',
  REVOLVING_INNE_DOWNLOAD_ERROR: 'REVOLVING_INNE_DOWNLOAD_ERROR',
  REVOLVING_INNE_DOCUMENT_READED: 'REVOLVING_INNE_DOCUMENT_READED',
  REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST:
    'REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST',
  REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS:
    'REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS',
  REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR:
    'REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR',
  REVOLVING_TERMS_AND_CONDS_SET_TEXT: 'REVOLVING_TERMS_AND_CONDS_SET_TEXT',
  REVOLVING_TERMS_AND_CONDS_REQUEST: 'REVOLVING_TERMS_AND_CONDS_REQUEST',
  REVOLVING_TERMS_AND_CONDS_SUCCESS: 'REVOLVING_TERMS_AND_CONDS_SUCCESS',
  REVOLVING_TERMS_AND_CONDS_FAILURE: 'REVOLVING_TERMS_AND_CONDS_FAILURE',
  REVOLVING_SET_AFI_PARAMS: 'REVOLVING_SET_AFI_PARAMS',
  REVOLVING_SET_INNE_PARAMS: 'REVOLVING_SET_INNE_PARAMS',
  REVOLVING_RESET_PROCESS: 'REVOLVING_RESET_PROCESS',
  REVOLVING_INSURANCE_URL_FETCHING: 'REVOLVING_INSURANCE_URL_FETCHING',
  REVOLVING_INSURANCE_URL_SUCCESS: 'REVOLVING_INSURANCE_URL_SUCCESS',
  REVOLVING_INSURANCE_URL_ERROR: 'REVOLVING_INSURANCE_URL_ERROR',
  REVOLVING_AFI_INFORMATION_FETCHING: 'REVOLVING_AFI_INFORMATION_FETCHING',
  REVOLVING_AFI_INFORMATION_RESET: 'REVOLVING_AFI_INFORMATION_RESET',
  REVOLVING_AFI_INFORMATION_SUCCESS: 'REVOLVING_AFI_INFORMATION_SUCCESS',
  REVOLVING_AFI_INFORMATION_ERROR: 'REVOLVING_AFI_INFORMATION_ERROR',
  SCREEN_HIDE_APP_HEADER: 'SCREEN_HIDE_APP_HEADER',
  SCREEN_HIDE_APP_FOOTER: 'SCREEN_HIDE_APP_FOOTER',
  SCREEN_IS_DESKTOP: 'SCREEN_IS_DESKTOP',
  SCREEN_IS_MOBILE: 'SCREEN_IS_MOBILE',
  SCREEN_IS_TABLET: 'SCREEN_IS_TABLET',
  SCREEN_SHOW_APP_HEADER: 'SCREEN_SHOW_APP_HEADER',
  SCREEN_SHOW_APP_FOOTER: 'SCREEN_SHOW_APP_FOOTER',
  SECURITY_KIT_CHANGE_SET_KIT: 'SECURITY_KIT_CHANGE_SET_KIT',
  SECURITY_KIT_CHANGE_SET_VISIBLE_STEP: 'SECURITY_KIT_CHANGE_SET_VISIBLE_STEP',
  SECURITY_KIT_CHANGE_VALIDATE_STEP: 'SECURITY_KIT_CHANGE_VALIDATE_STEP',
  SECURITY_KIT_GET_KIT_IS_FETCHING: 'SECURITY_KIT_GET_KIT_IS_FETCHING',
  SECURITY_KIT_MODIFY_FAILURE: 'SECURITY_KIT_MODIFY_FAILURE',
  SECURITY_KIT_MODIFY_SUCCESS: 'SECURITY_KIT_MODIFY_SUCCESS',
  SECURITY_KIT_RESET: 'SECURITY_KIT_RESET',
  SEGMENTED_LOAN_FAILURE: 'SEGMENTED_LOAN_FAILURE',
  SEGMENTED_LOAN_PRODUCTS_FAILURE: 'SEGMENTED_LOAN_PRODUCTS_FAILURE',
  SEGMENTED_LOAN_PRODUCTS_REQUEST: 'SEGMENTED_LOAN_PRODUCTS_REQUEST',
  SEGMENTED_LOAN_PRODUCTS_SUCCESS: 'SEGMENTED_LOAN_PRODUCTS_SUCCESS',
  SEGMENTED_LOAN_REQUEST: 'SEGMENTED_LOAN_REQUEST',
  SEGMENTED_LOAN_SUCCESS: 'SEGMENTED_LOAN_SUCCESS',
  SEND_AMAZON_GIFT_CARD_RETURN_DETAILS: 'SEND_AMAZON_GIFT_CARD_RETURN_DETAILS',
  SEND_PIN_CANCEL: 'SEND_PIN_CANCEL',
  SEND_PIN_FAILURE: 'SEND_PIN_FAILURE',
  SEND_PIN_REQUEST: 'SEND_PIN_REQUEST',
  SEND_PIN_RESET: 'SEND_PIN_RESET',
  SEND_PIN_SET_STEP_ERROR: 'SEND_PIN_SET_STEP_ERROR',
  SEND_PIN_SET_STEP_SUCCESS: 'SEND_PIN_SET_STEP_SUCCESS',
  SEND_PIN_SET_STEP_VALID_VALIDATE: 'SEND_PIN_SET_STEP_VALID_VALIDATE',
  SEND_PIN_SET_VISIBLE_STEP: 'SEND_PIN_SET_VISIBLE_STEP',
  SEND_PIN_SUCCESS: 'SEND_PIN_SUCCESS',
  SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR: 'SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR',
  SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST: 'SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST',
  SEND_PRECONTRACTUAL_DOCUMENT_MAIL_RESET: 'SEND_PRECONTRACTUAL_DOCUMENT_MAIL_RESET',
  SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS: 'SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS',
  SEND_SIGNATURE_KEY: 'SEND_SIGNATURE_KEY',
  SET_ACCESS_KEY_CHANGE_FAILURE: 'SET_ACCESS_KEY_CHANGE_FAILURE',
  SET_ACCESS_KEY_CHANGE_REQUEST: 'SET_ACCESS_KEY_CHANGE_REQUEST',
  SET_ACCESS_KEY_CHANGE_SUCCESS: 'SET_ACCESS_KEY_CHANGE_SUCCESS',
  SET_ACCOUNT_ALIAS_FAILURE: 'SET_ACCOUNT_ALIAS_FAILURE',
  SET_ACCOUNT_ALIAS_REQUEST: 'SET_ACCOUNT_ALIAS_REQUEST',
  SET_ACCOUNT_ALIAS_SUCCESS: 'SET_ACCOUNT_ALIAS_SUCCESS',
  SET_ACCOUNT_CHECKBOOK_INFO_FAILURE: 'SET_ACCOUNT_CHECKBOOK_INFO_FAILURE',
  SET_ACCOUNT_CHECKBOOK_INFO_REQUEST: 'SET_ACCOUNT_CHECKBOOK_INFO_REQUEST',
  SET_ACCOUNT_CHECKBOOK_INFO_SUCCESS: 'SET_ACCOUNT_CHECKBOOK_INFO_SUCCESS',
  SET_ACCOUNT_DEBITS_EDIT_FAILURE: 'SET_ACCOUNT_DEBITS_EDIT_FAILURE',
  SET_ACCOUNT_DEBITS_EDIT_REQUEST: 'SET_ACCOUNT_DEBITS_EDIT_REQUEST',
  SET_ACCOUNT_DEBITS_EDIT_SUCCESS: 'SET_ACCOUNT_DEBITS_EDIT_SUCCESS',
  SET_ACCOUNT_NEW_CHECKBOOK_FAILURE: 'SET_ACCOUNT_NEW_CHECKBOOK_FAILURE',
  SET_ACCOUNT_NEW_CHECKBOOK_INITIAL: 'SET_ACCOUNT_NEW_CHECKBOOK_INITIAL',
  SET_ACCOUNT_NEW_CHECKBOOK_REQUEST: 'SET_ACCOUNT_NEW_CHECKBOOK_REQUEST',
  SET_ACCOUNT_NEW_CHECKBOOK_SUCCESS: 'SET_ACCOUNT_NEW_CHECKBOOK_SUCCESS',
  SET_ACCOUNT_PREFERENCES_FAILURE: 'SET_ACCOUNT_PREFERENCES_FAILURE',
  SET_ACCOUNT_PREFERENCES_SUCCESS: 'SET_ACCOUNT_PREFERENCES_SUCCESS',
  SET_ACCOUNT_SCA_MODAL_SHOW: 'SET_ACCOUNT_SCA_MODAL_SHOW',
  SET_ACCOUNT_SETTLEMENTS_FILTER: 'SET_ACCOUNT_SETTLEMENTS_FILTER',
  SET_APPLIED: 'SET_APPLIED',
  SET_AUTHORIZATIONS: 'SET_AUTHORIZATIONS',
  SET_CARDS_PIN_EXPIRED: 'SET_CARDS_PIN_EXPIRED',
  SET_CARDS_PIN_VALID_PATTERN: 'SET_CARDS_PIN_VALID_PATTERN',
  SET_CARD_ACTIVATION_FAILURE: 'SET_CARD_ACTIVATION_FAILURE',
  SET_CARD_ACTIVATION_REQUEST: 'SET_CARD_ACTIVATION_REQUEST',
  SET_CARD_ACTIVATION_RESET: 'SET_CARD_ACTIVATION_RESET',
  SET_CARD_ACTIVATION_SUCCESS: 'SET_CARD_ACTIVATION_SUCCESS',
  SET_CARD_ACTIVATION_VALID_PATTERN: 'SET_CARD_ACTIVATION_VALID_PATTERN',
  SET_CARD_ACTIVATION_R42_FAILURE: 'SET_CARD_ACTIVATION_R42_FAILURE',
  SET_CARD_ACTIVATION_R42_HIRE_TRAVEL_PLUS_VALUE: 'SET_CARD_ACTIVATION_R42_HIRE_TRAVEL_PLUS_VALUE',
  SET_CARD_ACTIVATION_R42_REQUEST: 'SET_CARD_ACTIVATION_R42_REQUEST',
  SET_CARD_ACTIVATION_R42_RESET: 'SET_CARD_ACTIVATION_R42_RESET',
  SET_CARD_ACTIVATION_R42_SET_VISIBLE_STEP: 'SET_CARD_ACTIVATION_R42_SET_VISIBLE_STEP',
  SET_CARD_ACTIVATION_R42_SUCCESS: 'SET_CARD_ACTIVATION_R42_SUCCESS',
  SET_CARD_ACTIVATION_R42_TRAVEL_PLUS_STATE: 'SET_CARD_ACTIVATION_R42_TRAVEL_PLUS_STATE',
  SET_CARD_ACTIVATION_R42_VALIDATE_STEP: 'SET_CARD_ACTIVATION_R42_VALIDATE_STEP',
  SET_CARD_ACTIVATION_R42_WILL_CANCEL: 'SET_CARD_ACTIVATION_R42_WILL_CANCEL',
  SET_CARD_ALIAS_FAILURE: 'SET_CARD_ALIAS_FAILURE',
  SET_CARD_ALIAS_REQUEST: 'SET_CARD_ALIAS_REQUEST',
  SET_CARD_ALIAS_SUCCESS: 'SET_CARD_ALIAS_SUCCESS',
  SET_CARD_BLOCK_VALID_PATTERN: 'SET_CARD_BLOCK_VALID_PATTERN',
  SET_CARD_CVV_EXPIRED: 'SET_CARD_CVV_EXPIRED',
  SET_CARD_MOBILE_RECHARGE_FAILURE: 'SET_CARD_MOBILE_RECHARGE_FAILURE',
  SET_CARD_MOBILE_RECHARGE_REQUEST: 'SET_CARD_MOBILE_RECHARGE_REQUEST',
  SET_CARD_MOBILE_RECHARGE_SUCCESS: 'SET_CARD_MOBILE_RECHARGE_SUCCESS',
  SET_CARD_REPLACE_FAILURE: 'SET_CARD_REPLACE_FAILURE',
  SET_CARD_REPLACE_INITIAL: 'SET_CARD_REPLACE_INITIAL',
  SET_CARD_REPLACE_REQUEST: 'SET_CARD_REPLACE_REQUEST',
  SET_CARD_REPLACE_SUCCESS: 'SET_CARD_REPLACE_SUCCESS',
  SET_CARD_REPLACE_VALIDATE_DIGITS_FAILURE: 'SET_CARD_REPLACE_VALIDATE_DIGITS_FAILURE',
  SET_CARD_REPLACE_VALIDATE_DIGITS_REQUEST: 'SET_CARD_REPLACE_VALIDATE_DIGITS_REQUEST',
  SET_CARD_REPLACE_VALIDATE_DIGITS_SUCCESS: 'SET_CARD_REPLACE_VALIDATE_DIGITS_SUCCESS',
  SET_CARD_REPLACE_VALID_PATTERN: 'SET_CARD_REPLACE_VALID_PATTERN',
  SET_CARD_SHIPPING_ADDRESS: 'SET_CARD_SHIPPING_ADDRESS',
  SET_CARD_TRAVEL_PLUS_ON_FAILURE: 'SET_CARD_TRAVEL_PLUS_ON_FAILURE',
  SET_CARD_TRAVEL_PLUS_ON_REQUEST: 'SET_CARD_TRAVEL_PLUS_ON_REQUEST',
  SET_CARD_TRAVEL_PLUS_ON_SUCCESS: 'SET_CARD_TRAVEL_PLUS_ON_SUCCESS',
  SET_CHECK_FILTER: 'SET_CHECK_FILTER',
  SET_COLLECTIVES_DATA: 'SET_COLLECTIVES_DATA',
  SET_COLLECTIVE_INFO: 'SET_COLLECTIVE_INFO',
  SET_COLLECTIVE_USER_PROMPT_NOTIFICATION: 'SET_COLLECTIVE_USER_PROMPT_NOTIFICATION',
  SET_DATE_FILTER: 'SET_DATE_FILTER',
  SET_ECOMMERCE_KEY: 'SET_ECOMMERCE_KEY',
  SET_ECOMMERCE_KEY_EXPIRED: 'SET_ECOMMERCE_KEY_EXPIRED',
  SET_EMITED_TRANSFERS_FILTERS: 'SET_EMITED_TRANSFERS_FILTERS',
  SET_EMITED_TRANSFER_DETAILS_FAILURE: 'SET_EMITED_TRANSFER_DETAILS_FAILURE',
  SET_EMITED_TRANSFER_DETAILS_SUCCESS: 'SET_EMITED_TRANSFER_DETAILS_SUCCESS',
  SET_FISCAL_DATA: 'SET_FISCAL_DATA',
  SET_FROM_DATE_FILTER: 'SET_FROM_DATE_FILTER',
  SET_HIRE_PROMOTION_FAILURE: 'SET_HIRE_PROMOTION_FAILURE',
  SET_HIRE_PROMOTION_REQUEST: 'SET_HIRE_PROMOTION_REQUEST',
  SET_HIRE_PROMOTION_SUCCESS: 'SET_HIRE_PROMOTION_SUCCESS',
  SET_IMMEDIATE_CREDIT_ACCOUNTS_FAILURE: 'SET_IMMEDIATE_CREDIT_ACCOUNTS_FAILURE',
  SET_IMMEDIATE_CREDIT_ACCOUNTS_REQUEST: 'SET_IMMEDIATE_CREDIT_ACCOUNTS_REQUEST',
  SET_IMMEDIATE_CREDIT_ACCOUNTS_SUCCESS: 'SET_IMMEDIATE_CREDIT_ACCOUNTS_SUCCESS',
  SET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT: 'SET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT',
  SET_JUNIOR_DATA_FAILURE: 'SET_JUNIOR_DATA_FAILURE',
  SET_JUNIOR_DATA_SUCCESS: 'SET_JUNIOR_DATA_SUCCESS',
  SET_KEY_SIGNATURE_CHANGE_FAILURE: 'SET_KEY_SIGNATURE_CHANGE_FAILURE',
  SET_KEY_SIGNATURE_CHANGE_REQUEST: 'SET_KEY_SIGNATURE_CHANGE_REQUEST',
  SET_KEY_SIGNATURE_CHANGE_SUCCESS: 'SET_KEY_SIGNATURE_CHANGE_SUCCESS',
  SET_WAFER_ID: 'SET_WAFER_ID',
  SET_MAX_AMOUNT_FILTER: 'SET_MAX_AMOUNT_FILTER',
  SET_MIN_AMOUNT_FILTER: 'SET_MIN_AMOUNT_FILTER',
  SET_NAME_IN_WELCOME_MESSAGE: 'SET_NAME_IN_WELCOME_MESSAGE',
  SET_NOTIFICATION_EVENT_TYPE: 'SET_NOTIFICATION_EVENT_TYPE',
  SET_ORIGIN_ACCOUNT_ID: 'SET_ORIGIN_ACCOUNT_ID',
  SET_PAYROLL_ACCOUNT_PROMO_CODE: 'SET_PAYROLL_ACCOUNT_PROMO_CODE',
  SET_PERSONETICS_ACCOUNT_MOVEMENT_REQUEST: 'SET_PERSONETICS_ACCOUNT_MOVEMENT_REQUEST',
  SET_PERSONETICS_CARD_MOVEMENT_REQUEST: 'SET_PERSONETICS_CARD_MOVEMENT_REQUEST',
  SET_PERSONETICS_EMITED_TRANSFER_MOVEMENT_REQUEST:
    'SET_PERSONETICS_EMITED_TRANSFER_MOVEMENT_REQUEST',
  SET_PERSONETICS_INSIGHT_CLOSE_STATUS: 'SET_PERSONETICS_INSIGHT_CLOSE_STATUS',
  SET_PRECONTRACTUAL_DOCUMENT_ID: 'SET_PRECONTRACTUAL_DOCUMENT_ID',
  GET_PRECONTRACTUAL_DOCUMENT_ID_FAILURE: 'GET_PRECONTRACTUAL_DOCUMENT_ID_FAILURE',
  SET_PRODUCT_OFFERS_REQUEST: 'SET_PRODUCT_OFFERS_REQUEST',
  SET_PRODUCT_OFFERS_FAILURE: 'SET_PRODUCT_OFFERS_FAILURE',
  SET_PRODUCT_OFFERS_SUCCESS: 'SET_PRODUCT_OFFERS_SUCCESS',
  SET_PROFILE_EDIT_FAILURE: 'SET_PROFILE_EDIT_FAILURE',
  SET_PROFILE_EDIT_REQUEST: 'SET_PROFILE_EDIT_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_ACCOUNT_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_ACCOUNT_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_ACCOUNT_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_ACCOUNT_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_ACCOUNT_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_ACCOUNT_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_CARD_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_CARD_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_CARD_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_CARD_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_CARD_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_CARD_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_FUND_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_FUND_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_FUND_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_FUND_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_FUND_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_FUND_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_FUTURE_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_FUTURE_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_FUTURE_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_FUTURE_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_LOAN_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_LOAN_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_LOAN_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_LOAN_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_PLAN_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_PLAN_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_PLAN_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_PLAN_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_PLAN_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_PLAN_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_SAVING_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_SAVING_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_SAVING_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_SAVING_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_SAVING_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_SAVING_SUCCESS',
  SET_PROFILE_EDIT_SETTINGS_WALLET_FAILURE: 'SET_PROFILE_EDIT_SETTINGS_WALLET_FAILURE',
  SET_PROFILE_EDIT_SETTINGS_WALLET_REQUEST: 'SET_PROFILE_EDIT_SETTINGS_WALLET_REQUEST',
  SET_PROFILE_EDIT_SETTINGS_WALLET_SUCCESS: 'SET_PROFILE_EDIT_SETTINGS_WALLET_SUCCESS',
  SET_PROFILE_EDIT_SUCCESS: 'SET_PROFILE_EDIT_SUCCESS',
  SET_PROFILE_INIT_STEP: 'SET_PROFILE_INIT_STEP',
  SET_RECEIPTS: 'SET_RECEIPTS',
  SET_RECEIVED_TRANSFERS_FILTERS: 'SET_RECEIVED_TRANSFERS_FILTERS',
  SET_REMOVE_BENEFICIARY_DEFAULT_VALUES: 'SET_REMOVE_BENEFICIARY_DEFAULT_VALUES',
  SET_REMOVE_SCHEDULED_TRANSFER_INITIAL: 'SET_REMOVE_SCHEDULED_TRANSFER_INITIAL',
  SET_SCHEDULED_TRANSFERS_FILTERS: 'SET_SCHEDULED_TRANSFERS_FILTERS',
  SET_SCROLL: 'SET_SCROLL',
  SET_SELECT_FILTER: 'SET_SELECT_FILTER',
  SET_SHIPPING_ADDRESS_TYPE: 'SET_SHIPPING_ADDRESS_TYPE',
  SET_TO_DATE_FILTER: 'SET_TO_DATE_FILTER',
  SET_USER_NOTIFICATIONS_FAILURE: 'SET_USER_NOTIFICATIONS_FAILURE',
  SET_USER_NOTIFICATIONS_REQUEST: 'SET_USER_NOTIFICATIONS_REQUEST',
  SET_USER_NOTIFICATIONS_SUCCESS: 'SET_USER_NOTIFICATIONS_SUCCESS',
  SET_USER_NOTIFICATION_PROSPECT_CLOSED: 'SET_USER_NOTIFICATION_PROSPECT_CLOSED',
  SET_USER_NOTIFICATION_PROSPECT_PROMPT: 'SET_USER_NOTIFICATION_PROSPECT_PROMPT',
  SET_WALLET_ALIAS_FAILURE: 'SET_WALLET_ALIAS_FAILURE',
  SET_WALLET_ALIAS_REQUEST: 'SET_WALLET_ALIAS_REQUEST',
  SET_WALLET_ALIAS_SUCCESS: 'SET_WALLET_ALIAS_SUCCESS',
  SET_PROCESS_SELECTED: 'SET_PROCESS_SELECTED',
  RESET_PROCESS_SELECTED: 'RESET_PROCESS_SELECTED',
  SHOW_ACCOUNT_BLOCKED_ERROR_MODAL: 'SHOW_ACCOUNT_BLOCKED_ERROR_MODAL',
  SHOW_KEEPALIVE_POPUP: 'SHOW_KEEPALIVE_POPUP',
  SHOW_LAST_STEP_RIGHTS_ACCESS: 'SHOW_LAST_STEP_RIGHTS_ACCESS',
  SHOW_LAST_STEP_RIGHTS_PORTABLE: 'SHOW_LAST_STEP_RIGHTS_PORTABLE',
  SHOW_LOGOUT_POPUP: 'SHOW_LOGOUT_POPUP',
  SHOW_NEXT_STEP_RIGHTS_ACCESS: 'SHOW_NEXT_STEP_RIGHTS_ACCESS',
  SHOW_NEXT_STEP_RIGHTS_PORTABLE: 'SHOW_NEXT_STEP_RIGHTS_PORTABLE',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  SHOW_NOTIFICATIONS_EMAIL_ERROR: 'SHOW_NOTIFICATIONS_EMAIL_ERROR',
  SHOW_NOTIFICATIONS_EMAIL_IN_PROGRESS: 'SHOW_NOTIFICATIONS_EMAIL_IN_PROGRESS',
  SHOW_NOTIFICATIONS_EMAIL_SUCCESS: 'SHOW_NOTIFICATIONS_EMAIL_SUCCESS',
  SHOW_NOTIFICATON_MODAL: 'SHOW_NOTIFICATON_MODAL',
  SHOW_TOOLTIP: 'SHOW_TOOLTIP',
  SHOWED_NOTIFICATION: 'SHOWED_NOTIFICATION',
  SIGNATURE_PATTERN_BIOCATCH_EXCEPTION: 'SIGNATURE_PATTERN_BIOCATCH_EXCEPTION',
  SIGNATURE_PATTERN_CALLBACK_CALLED: 'SIGNATURE_PATTERN_CALLBACK_CALLED',
  SIGNATURE_PATTERN_CONFIG_REQUEST: 'SIGNATURE_PATTERN_CONFIG_REQUEST',
  SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE: 'SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE',
  SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS: 'SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS',
  SIGNATURE_PATTERN_CONFIG_RESTART_REQUEST: 'SIGNATURE_PATTERN_CONFIG_RESTART_REQUEST',
  SIGNATURE_PATTERN_PASS_CODE_VALIDATION: 'SIGNATURE_PATTERN_PASS_CODE_VALIDATION',
  SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE: 'SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE',
  SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS: 'SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS',
  SIGNATURE_PATTERN_RESET: 'SIGNATURE_PATTERN_RESET',
  SIGNATURE_PATTERN_SET_WITH_VALIDATE_BUTTON: 'SIGNATURE_PATTERN_SET_WITH_VALIDATE_BUTTON',
  SIGNATURE_PATTERN_SHOW_REQUIRED: 'SIGNATURE_PATTERN_SHOW_REQUIRED',
  SIGNATURE_PATTERN_SHOW_WARNING_SMS: 'SIGNATURE_PATTERN_SHOW_WARNING_SMS',
  SIGNATURE_PATTERN_SMS_VALIDATION: 'SIGNATURE_PATTERN_SMS_VALIDATION',
  SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE: 'SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE',
  SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS: 'SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS',
  SIGNATURE_PATTERN_SUCCESS: 'SIGNATURE_PATTERN_SUCCESS',
  SIGNATURE_PATTERN_TRUSTED_DEVICE_FAILURE: 'SIGNATURE_PATTERN_TRUSTED_DEVICE_FAILURE',
  SIGNATURE_PATTERN_TRUSTED_DEVICE_REQUEST: 'SIGNATURE_PATTERN_TRUSTED_DEVICE_REQUEST',
  SIGNATURE_PATTERN_TRUSTED_DEVICE_SUCCESS: 'SIGNATURE_PATTERN_TRUSTED_DEVICE_SUCCESS',
  SIGNATURE_PATTERN_UPDATE_INPUT_SMS: 'SIGNATURE_PATTERN_UPDATE_INPUT_SMS',
  SIGNATURE_PATTERN_VALIDATE_DIGIT: 'SIGNATURE_PATTERN_VALIDATE_DIGIT',
  STORE_ID_LIST: 'STORE_ID_LIST',
  TAXATION_PAYMENT_DEBIT_CONSULT_FAILURE: 'TAXATION_PAYMENT_DEBIT_CONSULT_FAILURE',
  TAXATION_PAYMENT_DEBIT_CONSULT_REQUEST: 'TAXATION_PAYMENT_DEBIT_CONSULT_REQUEST',
  TAXATION_PAYMENT_DEBIT_CONSULT_SUCCESS: 'TAXATION_PAYMENT_DEBIT_CONSULT_SUCCESS',
  TAXES_AND_RENTS_CHANGE_ACCOUNT: 'TAXES_AND_RENTS_CHANGE_ACCOUNT',
  TAXES_AND_RENTS_CLEAR_FILTER: 'TAXES_AND_RENTS_CLEAR_FILTER',
  TAXES_AND_RENTS_FAILURE: 'TAXES_AND_RENTS_FAILURE',
  TAXES_AND_RENTS_IN_PROGRESS: 'TAXES_AND_RENTS_IN_PROGRESS',
  TAXES_AND_RENTS_SET_FILTER: 'TAXES_AND_RENTS_SET_FILTER',
  TAXES_AND_RENTS_SUCCESS: 'TAXES_AND_RENTS_SUCCESS',
  TAXES_AND_RENTS_SUCCESS_DETAIL: 'TAXES_AND_RENTS_SUCCESS_DETAIL',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_CLEAR: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_CLEAR',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_FAILURE: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_FAILURE',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_REQUEST: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_REQUEST',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_ACCOUNT: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_ACCOUNT',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_AMOUNT: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_AMOUNT',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DECLARATION_RESULT:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DECLARATION_RESULT',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DIFFERENTIAL_FEE:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DIFFERENTIAL_FEE',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DOMICILIATE_SECOND_PAYMENT:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DOMICILIATE_SECOND_PAYMENT',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_FRACTION_PAYMENT:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_FRACTION_PAYMENT',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_JUSTIFICATIVE:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_JUSTIFICATIVE',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_VISIBLE_STEP:
    'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_VISIBLE_STEP',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SUCCESS: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SUCCESS',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_VALIDATE_STEP: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_VALIDATE_STEP',
  TAX_AND_RENTS_PAYMENT_WITH_DRAFT_WILL_CANCEL: 'TAX_AND_RENTS_PAYMENT_WITH_DRAFT_WILL_CANCEL',
  PERSONETICS_SUBSCRIPTION_TERMS_AND_CONDS_CHECKBOX_CLICK:
    'PERSONETICS_SUBSCRIPTION_TERMS_AND_CONDS_CHECKBOX_CLICK',
  TERMS_AND_CONDS_FAILURE: 'TERMS_AND_CONDS_FAILURE',
  TERMS_AND_CONDS_REQUEST: 'TERMS_AND_CONDS_REQUEST',
  TERMS_AND_CONDS_RESET: 'TERMS_AND_CONDS_RESET',
  TERMS_AND_CONDS_SET_INFO: 'TERMS_AND_CONDS_SET_INFO',
  TERMS_AND_CONDS_SET_TEXT: 'TERMS_AND_CONDS_SET_TEXT',
  TERMS_AND_CONDS_SUCCESS: 'TERMS_AND_CONDS_SUCCESS',
  TERMS_AND_CONDS_LEGAL_DIC_REQUEST: 'TERMS_AND_CONDS_LEGAL_DIC_REQUEST',
  TERMS_AND_CONDS_LEGAL_DIC_SUCCESS: 'TERMS_AND_CONDS_LEGAL_DIC_SUCCESS',
  TERMS_AND_CONDS_LEGAL_DIC_FAILURE: 'TERMS_AND_CONDS_LEGAL_DIC_FAILURE',
  TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_RESET: 'TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_RESET',
  TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_FAILURE: 'TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_FAILURE',
  TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_REQUEST: 'TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_REQUEST',
  TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_SUCCESS: 'TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_SUCCESS',
  TERMS_AND_CONDS_LEGAL_WINDOW_FAILURE: 'TERMS_AND_CONDS_LEGAL_WINDOW_FAILURE',
  TERMS_AND_CONDS_LEGAL_WINDOW_REQUEST: 'TERMS_AND_CONDS_LEGAL_WINDOW_REQUEST',
  TERMS_AND_CONDS_LEGAL_WINDOW_RESET: 'TERMS_AND_CONDS_LEGAL_WINDOW_RESET',
  TERMS_AND_CONDS_LEGAL_WINDOW_SUCCESS: 'TERMS_AND_CONDS_LEGAL_WINDOW_SUCCESS',
  TERMS_AND_CONDS_LEGAL_WINDOW_SET_PARAMS: 'TERMS_AND_CONDS_LEGAL_WINDOW_SET_PARAMS',
  TOGGLE_IBAN_MODAL: 'TOGGLE_IBAN_MODAL',
  TPP_KEYS_CONSENT_TPP_SUCCESS: 'TPP_KEYS_CONSENT_TPP_SUCCESS',
  TPP_KEYS_CONSENT_TPP_FAILURE: 'TPP_KEYS_CONSENT_TPP_FAILURE',
  TPP_KEYS_CONSENT_RETRY_REMOVE: 'TPP_KEYS_CONSENT_RETRY_REMOVE',
  TPP_KEYS_GET_CONSENTS_FAILURE: 'TPP_KEYS_GET_CONSENTS_FAILURE',
  TPP_KEYS_GET_CONSENTS_IN_PROGRESS: 'TPP_KEYS_GET_CONSENTS_IN_PROGRESS',
  TPP_KEYS_GET_CONSENTS_SUCCESS: 'TPP_KEYS_GET_CONSENTS_SUCCESS',
  GET_ALL_CONSENTS_IN_PROGRESS: 'GET_ALL_CONSENTS_IN_PROGRESS',
  GET_ALL_CONSENTS_FAILURE: 'GET_ALL_CONSENTS_FAILURE',
  GET_ALL_CONSENTS_SUCCESS: 'GET_ALL_CONSENTS_SUCCESS',
  TPP_KEYS_GET_MOVEMENTS_FAILURE: 'TPP_KEY_GET_MOVEMENTS_FAILURE',
  TPP_KEYS_GET_MOVEMENTS_IN_PROGRESS: 'TPP_KEY_GET_MOVEMENTS_IN_PROGRESS',
  TPP_KEYS_GET_MOVEMENTS_SUCCESS: 'TPP_KEY_GET_MOVEMENTS_SUCCESS',
  TPP_KEYS_REMOVE_TPP_KEY_IN_PROGRESS: 'TPP_KEYS_REMOVE_TPP_KEY_IN_PROGRESS',
  TPP_KEYS_REQUEST_DATA_FAILURE: 'TPP_KEYS_REQUEST_DATA_FAILURE',
  TPP_KEYS_REQUEST_DATA_IN_PROGRESS: 'TPP_KEYS_REQUEST_DATA_IN_PROGRESS',
  TPP_KEYS_REQUEST_DATA_SUCCESS: 'TPP_KEYS_REQUEST_DATA_SUCCESS',
  TPP_KEYS_RESET_TPP_KEY: 'TPP_KEYS_RESET_TPP_KEY',
  TPP_KEYS_SET_EXPANDED_TPP_ACCORDION: 'TPP_KEYS_SET_EXPANDED_TPP_ACCORDION',
  TPP_KEYS_UPDATE_TAB: 'TPP_KEYS_UPDATE_TAB',
  TRANSFER_ADD_BENEFICIARY_SUCCESS: 'TRANSFER_ADD_BENEFICIARY_SUCCESS',
  TRANSFER_AMOUNT: 'TRANSFER_AMOUNT',
  TRANSFER_BENEFICIARY_ACCOUNT_NUMBER: 'TRANSFER_BENEFICIARY_ACCOUNT_NUMBER',
  TRANSFER_BENEFICIARY_ADDRESS: 'TRANSFER_BENEFICIARY_ADDRESS',
  TRANSFER_BENEFICIARY_ADD_AGENDA: 'TRANSFER_BENEFICIARY_ADD_AGENDA',
  TRANSFER_BENEFICIARY_ALIAS: 'TRANSFER_BENEFICIARY_ALIAS',
  TRANSFER_BENEFICIARY_BANK_ADDRESS: 'TRANSFER_BENEFICIARY_BANK_ADDRESS',
  TRANSFER_BENEFICIARY_BANK_CITY: 'TRANSFER_BENEFICIARY_BANK_CITY',
  TRANSFER_BENEFICIARY_BANK_NAME: 'TRANSFER_BENEFICIARY_BANK_NAME',
  TRANSFER_BENEFICIARY_BANK_OPTION: 'TRANSFER_BENEFICIARY_BANK_OPTION',

  TRANSFER_BENEFICIARY_BIC: 'TRANSFER_BENEFICIARY_BIC',
  TRANSFER_BENEFICIARY_CANCEL_EDIT_FORM: 'TRANSFER_BENEFICIARY_CANCEL_EDIT_FORM',
  TRANSFER_BENEFICIARY_CITY: 'TRANSFER_BENEFICIARY_CITY',
  TRANSFER_BENEFICIARY_COUNTRY: 'TRANSFER_BENEFICIARY_COUNTRY',
  TRANSFER_BENEFICIARY_CURRENCY: 'TRANSFER_BENEFICIARY_CURRENCY',
  TRANSFER_BENEFICIARY_EXISTING: 'TRANSFER_BENEFICIARY_EXISTING',
  TRANSFER_BENEFICIARY_FILL_EDIT_FORM: 'TRANSFER_BENEFICIARY_FILL_EDIT_FORM',
  TRANSFER_BENEFICIARY_IBAN: 'TRANSFER_BENEFICIARY_IBAN',
  TRANSFER_BENEFICIARY_NAME: 'TRANSFER_BENEFICIARY_NAME',
  TRANSFER_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE:
    'TRANSFER_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE',
  TRANSFER_BENEFICIARY_TYPE: 'TRANSFER_BENEFICIARY_TYPE',
  TRANSFER_CONCEPT: 'TRANSFER_CONCEPT',
  TRANSFER_DATE: 'TRANSFER_DATE',
  TRANSFER_DO_IMMEDIATE: 'TRANSFER_DO_IMMEDIATE',
  TRANSFER_EDIT_BENEFICIARY_SUCCESS: 'TRANSFER_EDIT_BENEFICIARY_SUCCESS',
  TRANSFER_FILL_FORM: 'TRANSFER_FILL_FORM',
  TRANSFER_FROM_ACCOUNT: 'TRANSFER_FROM_ACCOUNT',
  TRANSFER_IS_FETCHING: 'TRANSFER_IS_FETCHING',
  TRANSFER_IMMEDIATE_FAILURE: 'TRANSFER_IMMEDIATE_FAILURE',
  TRANSFER_IMMEDIATE_FETCHING: 'TRANSFER_IMMEDIATE_FETCHING',
  TRANSFER_IMMEDIATE_SUCCESS: 'TRANSFER_IMMEDIATE_SUCCESS',
  TRANSFER_LOAD_OLD_EMITTER: 'TRANSFER_LOAD_OLD_EMITTER',
  TRANSFER_LIMIT_ERROR: 'TRANSFER_LIMIT_ERROR',
  TRANSFER_SCAM_WARNING: 'TRANSFER_SCAM_WARNING',
  TRANSFER_PAYMENT_BY_CHANGED: 'TRANSFER_PAYMENT_BY_CHANGED',
  TRANSFER_REQUEST_TRANSFER_FAILURE: 'TRANSFER_REQUEST_TRANSFER_FAILURE',
  TRANSFER_REQUEST_EDIT_TRANSFER_FAILURE: 'TRANSFER_REQUEST_EDIT_TRANSFER_FAILURE',
  TRANSFER_REQUEST_CHARITY_TRANSFER_FAILURE: 'TRANSFER_REQUEST_CHARITY_TRANSFER_FAILURE',
  TRANSFER_REQUEST_TRANSFER_REQUEST: 'TRANSFER_REQUEST_TRANSFER_REQUEST',
  TRANSFER_REQUEST_TRANSFER_SUCCESS: 'TRANSFER_REQUEST_TRANSFER_SUCCESS',
  TRANSFER_REQUEST_EDIT_TRANSFER_SUCCESS: 'TRANSFER_REQUEST_EDIT_TRANSFER_SUCCESS',
  TRANSFER_REQUEST_CHARITY_TRANSFER_SUCCESS: 'TRANSFER_REQUEST_CHARITY_TRANSFER_SUCCESS',
  TRANSFER_RESET: 'TRANSFER_RESET',
  TRANSFER_SET_ALL_FIELDS: 'TRANSFER_SET_ALL_FIELDS',
  TRANSFER_SET_COSTS: 'TRANSFER_SET_COSTS',
  TRANSFER_SET_EMAIL_NOTIFICATION: 'TRANSFER_SET_EMAIL_NOTIFICATION',
  TRANSFER_SET_EMITTER: 'TRANSFER_SET_EMITTER',
  TRANSFER_SET_NGO_VALIDITY: 'TRANSFER_SET_NGO_VALIDITY',
  TRANSFER_SET_PERIODICITY_COUNT: 'TRANSFER_SET_PERIODICITY_COUNT',
  TRANSFER_SET_PERIODICITY_DATE: 'TRANSFER_SET_PERIODICITY_DATE',
  TRANSFER_SET_PERIODICITY_ENABLED: 'TRANSFER_SET_PERIODICITY_ENABLED',
  TRANSFER_SET_PERIODICITY_RANGE: 'TRANSFER_SET_PERIODICITY_RANGE',
  TRANSFER_SET_PERIODICITY_TYPE: 'TRANSFER_SET_PERIODICITY_TYPE',
  TRANSFER_SET_TREATMENT_NON_WORKABLE_DAY: 'TRANSFER_SET_TREATMENT_NON_WORKABLE_DAY',
  TRANSFER_SET_VISIBLE_STEP: 'TRANSFER_SET_VISIBLE_STEP',
  TRANSFER_VALIDATE_STEP_FAILURE: 'TRANSFER_VALIDATE_STEP_FAILURE',
  TRANSFER_VALIDATE_STEP_REQUEST: 'TRANSFER_VALIDATE_STEP_REQUEST',
  TRANSFER_VALIDATE_STEP_SUCCESS: 'TRANSFER_VALIDATE_STEP_SUCCESS',
  TRANSFER_VALIDATE_UI_STEP: 'TRANSFER_VALIDATE_UI_STEP',
  TRANSFER_WILL_CANCEL: 'TRANSFER_WILL_CANCEL',
  TRANSFER_WILL_LEAVE: 'TRANSFER_WILL_LEAVE',
  TRANSLATIONS_FETCH_REQUEST: 'TRANSLATIONS_FETCH_REQUEST',
  TRANSLATIONS_REQUEST_FAILURE: 'TRANSLATIONS_REQUEST_FAILURE',
  TRANSLATIONS_REQUEST_SUCCESS: 'TRANSLATIONS_REQUEST_SUCCESS',
  TRUST_DEVICES_UPDATE_PROCESS: 'TRUST_DEVICES_UPDATE_PROCESS',
  TUTORIAL_GET_LIST_FAILURE: 'TUTORIAL_GET_LIST_FAILURE',
  TUTORIAL_GET_LIST_REQUEST: 'TUTORIAL_GET_LIST_REQUEST',
  TUTORIAL_GET_LIST_SUCCESS: 'TUTORIAL_GET_LIST_SUCCESS',
  TUTORIAL_SET_SEEN_FAILURE: 'TUTORIAL_SET_SEEN_FAILURE',
  TUTORIAL_SET_SEEN_REQUEST: 'TUTORIAL_SET_SEEN_REQUEST',
  TUTORIAL_SET_SEEN_SUCCESS: 'TUTORIAL_SET_SEEN_SUCCESS',
  TUTORIAL_SHOULD_OPEN: 'TUTORIAL_SHOULD_OPEN',
  TUTORIAL_TOGGLE_OPEN: 'TUTORIAL_TOGGLE_OPEN',
  UPDATE_ADOBE_STATUS: 'UPDATE_ADOBE_STATUS',
  UPDATE_CHAT_TOKENS: 'UPDATE_CHAT_TOKENS',
  UPDATE_CONSENTS_GDPR_ERROR: 'UPDATE_CONSENTS_GDPR_ERROR',
  UPDATE_CONSENTS_GDPR_SUCCESS: 'UPDATE_CONSENTS_GDPR_SUCCESS',
  UPDATE_CONSENTS_LAST_STEP: 'UPDATE_CONSENTS_LAST_STEP',
  UPDATE_CONSENTS_NEXT_STEP: 'UPDATE_CONSENTS_NEXT_STEP',
  UPDATE_CONSENTS_PROFILE_SUCCESS: 'UPDATE_CONSENTS_PROFILE_SUCCESS',
  UPDATE_DEPOSITS: 'UPDATE_DEPOSITS',
  UPDATE_RETENTION_REQUESTED_DEPOSITS: 'UPDATE_RETENTION_REQUESTED_DEPOSITS',
  UPDATE_RESET_CONSENTS_GDPR: 'UPDATE_RESET_CONSENTS_GDPR',
  UPDATE_RIGHTS_PROFILE: 'UPDATE_RIGHTS_PROFILE',
  UPDATE_SHOULD_SHOW_UPGRADE_TO_PAYROLL_PRE_STEP: 'UPDATE_SHOULD_SHOW_UPGRADE_TO_PAYROLL_PRE_STEP',
  UPDATE_TOKEN_USER_INTERSTITIAL: 'UPDATE_TOKEN_USER_INTERSTITIAL',
  UPDATE_TYPE_RIGHTS_LIST: 'UPDATE_TYPE_RIGHTS_LIST',
  UPDATE_IS_FETCHING_OVERDRAFT_FILE: 'UPDATE_IS_FETCHING_OVERDRAFT_FILE',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_DNI: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_DNI',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_FAMILY_BOOK:
    'UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_FAMILY_BOOK',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_NEW_CONTAINER_FOR_FILE:
    'UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_NEW_CONTAINER_FOR_FILE',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_DNI: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_DNI',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_FAMILY_BOOK:
    'UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_FAMILY_BOOK',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_RESET: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_RESET',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_REQUEST: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_REQUEST',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_SUCCESS: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_SUCCESS',
  UPLOAD_DOCUMENTS_OPEN_YOUNG_VALIDATE_STEP: 'UPLOAD_DOCUMENTS_OPEN_YOUNG_VALIDATE_STEP',
  USER_NOTIFICATIONS_FAILURE: 'USER_NOTIFICATIONS_FAILURE',
  USER_NOTIFICATIONS_FILTERS_CLEAR: 'USER_NOTIFICATIONS_FILTERS_CLEAR',
  USER_NOTIFICATIONS_NEXT_PAGE_REQUEST: 'USER_NOTIFICATIONS_NEXT_PAGE_REQUEST',
  USER_NOTIFICATIONS_REQUEST: 'USER_NOTIFICATIONS_REQUEST',
  USER_NOTIFICATIONS_SET_FILTERS: 'USER_NOTIFICATIONS_SET_FILTERS',
  USER_NOTIFICATIONS_SUCCESS: 'USER_NOTIFICATIONS_SUCCESS',
  USER_UNREAD_NOTIFICATIONS_COUNT_FAILURE: 'USER_UNREAD_NOTIFICATIONS_COUNT_FAILURE',
  USER_UNREAD_NOTIFICATIONS_COUNT_INVALIDATE_CACHE:
    'USER_UNREAD_NOTIFICATIONS_COUNT_INVALIDATE_CACHE',
  USER_UNREAD_NOTIFICATIONS_COUNT_REQUEST: 'USER_UNREAD_NOTIFICATIONS_COUNT_REQUEST',
  USER_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: 'USER_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
  VALID_NGO_CARD_FAIL: 'VALID_NGO_CARD_FAIL',
  VALID_NGO_CARD_SUCCESS: 'VALID_NGO_CARD_SUCCESS',
  VALIDATE_BLOCKED_ACCOUNT_FAILURE: 'VALIDATE_BLOCKED_ACCOUNT_FAILURE',
  VALIDATE_BLOCKED_ACCOUNT_REQUEST: 'VALIDATE_BLOCKED_ACCOUNT_REQUEST',
  VALIDATE_BLOCKED_ACCOUNT_SUCCESS: 'VALIDATE_BLOCKED_ACCOUNT_SUCCESS',
  VALIDATE_PERSONETICS_VIRTUAL_OFFICES_FAILURE: 'VALIDATE_PERSONETICS_VIRTUAL_OFFICES_FAILURE',
  VALIDATE_PERSONETICS_VIRTUAL_OFFICES_REQUEST: 'VALIDATE_PERSONETICS_VIRTUAL_OFFICES_REQUEST',
  VALIDATE_PERSONETICS_VIRTUAL_OFFICES_SUCCESS: 'VALIDATE_PERSONETICS_VIRTUAL_OFFICES_SUCCESS',
  VALIDATE_USER_VIRTUAL_OFFICES_FAILURE: 'VALIDATE_USER_VIRTUAL_OFFICES_FAILURE',
  VALIDATE_USER_VIRTUAL_OFFICES_REQUEST: 'VALIDATE_USER_VIRTUAL_OFFICES_REQUEST',
  VALIDATE_USER_VIRTUAL_OFFICES_SUCCESS: 'VALIDATE_USER_VIRTUAL_OFFICES_SUCCESS',
  VOICE_PERFORM_CLEAN: 'VOICE_PERFORM_CLEAN',
  VOICE_PERFORM_READ: 'VOICE_PERFORM_READ',
  WELCOME_ACCOUNT_ADD_INTERVENER: 'WELCOME_ACCOUNT_ADD_INTERVENER',
  WELCOME_ACCOUNT_CHECKBOX_CHANGE: 'WELCOME_ACCOUNT_CHECKBOX_CHANGE',
  WELCOME_ACCOUNT_HIRE_FAILURE: 'WELCOME_ACCOUNT_HIRE_FAILURE',
  WELCOME_ACCOUNT_HIRE_IN_PROGRESS: 'WELCOME_ACCOUNT_HIRE_IN_PROGRESS',
  WELCOME_ACCOUNT_HIRE_SUCCESS: 'WELCOME_ACCOUNT_HIRE_SUCCESS',
  WELCOME_ACCOUNT_REMOVE_INTERVENER: 'WELCOME_ACCOUNT_REMOVE_INTERVENER',
  WELCOME_ACCOUNT_RESET: 'WELCOME_ACCOUNT_RESET',
  WELCOME_ACCOUNT_RESET_PRECONTRACTUAL_DOCUMENT: 'WELCOME_ACCOUNT_RESET_PRECONTRACTUAL_DOCUMENT',
  WELCOME_ACCOUNT_RESTORE_DEFAULT_INTERVENERS: 'WELCOME_ACCOUNT_RESTORE_DEFAULT_INTERVENERS',
  WELCOME_ACCOUNT_SET_ACCOUNT_INTERVENER_TYPE: 'WELCOME_ACCOUNT_SET_ACCOUNT_INTERVENER_TYPE',
  WELCOME_ACCOUNT_SET_LEGAL_TEXT_READ: 'WELCOME_ACCOUNT_SET_LEGAL_TEXT_READ',
  WELCOME_ACCOUNT_SET_SELECTED_ACCOUNT: 'WELCOME_ACCOUNT_SET_SELECTED_ACCOUNT',
  WELCOME_ACCOUNT_SET_VISIBLE_STEP: 'WELCOME_ACCOUNT_SET_VISIBLE_STEP',
  WELCOME_ACCOUNT_SET_WILL_CANCEL: 'WELCOME_ACCOUNT_SET_WILL_CANCEL',
  WELCOME_ACCOUNT_UPDATE_INTERVENERS: 'WELCOME_ACCOUNT_UPDATE_INTERVENERS',
  WELCOME_ACCOUNT_UPDATE_INTERVENERS_FAILURE: 'WELCOME_ACCOUNT_UPDATE_INTERVENERS_FAILURE',
  WELCOME_ACCOUNT_UPDATE_INTERVENERS_IN_PROGRESS: 'WELCOME_ACCOUNT_UPDATE_INTERVENERS_IN_PROGRESS',
  WELCOME_ACCOUNT_VALIDATE_STEP: 'WELCOME_ACCOUNT_VALIDATE_STEP',
  WIZARD_RESET: 'WIZARD_RESET',
  WIZARD_SET_ERROR: 'WIZARD_SET_ERROR',
  WIZARD_SET_LOADING: 'WIZARD_SET_LOADING',
  WIZARD_SET_SUCCESS: 'WIZARD_SET_SUCCESS',
  SET_MAIN_ACCOUNT_FAILURE: 'SET_MAIN_ACCOUNT_FAILURE',
  SET_MAIN_ACCOUNT_REQUEST: 'SET_MAIN_ACCOUNT_REQUEST',
  SET_MAIN_ACCOUNT_SUCCESS: 'SET_MAIN_ACCOUNT_SUCCESS',
  SET_MAIN_DEPOSITS_SUCCESS: 'SET_MAIN_DEPOSITS_SUCCESS',
  SET_LIMIT_ACCOUNT_FAILURE: 'SET_LIMIT_ACCOUNT_FAILURE',
  SET_LIMIT_ACCOUNT_REQUEST: 'SET_LIMIT_ACCOUNT_REQUEST',
  SET_LIMIT_ACCOUNT_SUCCESS: 'SET_LIMIT_ACCOUNT_SUCCESS',
  SET_PRECONTRACTUAL_DOCUMENT_URL: 'SET_PRECONTRACTUAL_DOCUMENT_URL',
  SET_MAIN_DEPOSITS_FAILURE: 'SET_MAIN_DEPOSITS_FAILURE',
  SET_MAIN_DEPOSITS_REQUEST: 'SET_MAIN_DEPOSITS_REQUEST',
  SET_MAIN_DEPOSITS_SUCCESS: 'SET_MAIN_DEPOSITS_SUCCESS',
  HIRE_CARD_CREDIT_INE_SUCCESS: 'HIRE_CARD_CREDIT_INE_SUCCESS',
  HIRE_CARD_CREDIT_DIR_SUCCESS: 'HIRE_CARD_CREDIT_DIR_SUCCESS',
  HIRE_CARD_CREDIT_CARD_DOCUMENT_READ: 'HIRE_CARD_CREDIT_CARD_DOCUMENT_READ',
  HIRE_CARD_VIAT_CARD_DOCUMENT_READ: 'HIRE_CARD_VIAT_CARD_DOCUMENT_READ',
  HIRE_CARD_VIRTUAL_CARD_DOCUMENT_READ: 'HIRE_CARD_VIRTUAL_CARD_DOCUMENT_READ',
  HIRE_CARD_CREDIT_CARD_ALL_DOCUMENT_READ: 'HIRE_CARD_CREDIT_CARD_ALL_DOCUMENT_READ',
  HIRE_CARD_VIAT_CARD_ALL_DOCUMENT_READ: 'HIRE_CARD_VIAT_CARD_ALL_DOCUMENT_READ',
  HIRE_CARD_VIRTUAL_CARD_ALL_DOCUMENT_READ: 'HIRE_CARD_VIRTUAL_CARD_ALL_DOCUMENT_READ',
  HIRE_CARD_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED: 'HIRE_CARD_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED',
  HIRE_CARD_VIAT_CARD_SAVE_DOCUMENT_ACCEPTED: 'HIRE_CARD_VIAT_CARD_SAVE_DOCUMENT_ACCEPTED',
  HIRE_CARD_VIRTUAL_CARD_SAVE_DOCUMENT_ACCEPTED: 'HIRE_CARD_VIRTUAL_CARD_SAVE_DOCUMENT_ACCEPTED',
  HIRE_CARD_CREDIT_INE_ERROR: 'HIRE_CARD_CREDIT_INE_ERROR',
  HIRE_CARD_CREDIT_INE_: 'HIRE_CARD_CREDIT_INE_ERROR',
  HIRE_CARD_CREDIT_POLICY_SUCCESS: 'HIRE_CARD_CREDIT_POLICY_SUCCESS',
  HIRE_CARD_CREDIT_POLICY_ERROR: 'HIRE_CARD_CREDIT_POLICY_ERROR',
  HIRE_CARD_CREDIT_INIT_SUCCESS: 'HIRE_CARD_CREDIT_INIT_SUCCESS',
  HIRE_CARD_CREDIT_INIT_ERROR: 'HIRE_CARD_CREDIT_INIT_ERROR',
  HIRE_CARD_CREDIT_DIR_ERROR: 'HIRE_CARD_CREDIT_DIR_ERROR',
  HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS: 'HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS',
  HIRE_CARD_CREDIT_INIT_POLICY_ERROR: 'HIRE_CARD_CREDIT_INIT_POLICY_ERROR',
  HIRE_CARD_OPEN_CREDIT_NEW_VALIDATE_STEP: 'HIRE_CARD_OPEN_CREDIT_NEW_VALIDATE_STEP',
  CARDS_REQUEST_CONFIRM_SCROLL_DOWN: 'CARDS_REQUEST_CONFIRM_SCROLL_DOWN',
  CARDS_REQUEST_CONFIRM_SCROLL_DOWN_POLICY: 'CARDS_REQUEST_CONFIRM_SCROLL_DOWN_POLICY',
  CARDS_CHECKED_DOCUMENT: 'CARDS_CHECKED_DOCUMENT',
  HIRE_CARD_OPEN_CREDIT_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_OPEN_CREDIT_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_OPEN_CREDIT_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_OPEN_CREDIT_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_DIAMOND_PACK_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_DIAMOND_PACK_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_OPEN_CREDIT_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_OPEN_CREDIT_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_OPEN_CREDIT_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_OPEN_CREDIT_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_OPEN_CREDIT_AGREEINECHECKBOX: 'HIRE_CARD_OPEN_CREDIT_AGREEINECHECKBOX',
  HIRE_CARD_OPEN_CREDIT_CHECK_RADIO_BUTTON: 'HIRE_CARD_OPEN_CREDIT_CHECK_RADIO_BUTTON',
  HIRE_CARD_OPEN_CREDIT_PRINT_DOCUMENT: 'HIRE_CARD_OPEN_CREDIT_PRINT_DOCUMENT',
  HIRE_CARD_DIAMOND_PACK_AGREEINECHECKBOX: 'HIRE_CARD_DIAMOND_PACK_AGREEINECHECKBOX',
  HIRE_CARD_DIAMOND_PACK_CHECK_RADIO_BUTTON: 'HIRE_CARD_DIAMOND_PACK_CHECK_RADIO_BUTTON',
  HIRE_CARD_DIAMOND_PACK_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_DIAMOND_PACK_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_DIAMOND_PACK_NEW_VALIDATE_STEP: 'HIRE_CARD_DIAMOND_PACK_NEW_VALIDATE_STEP',
  HIRE_CARD_DIAMOND_PACK_PRINT_DOCUMENT: 'HIRE_CARD_DIAMOND_PACK_PRINT_DOCUMENT',
  HIRE_CARD_DIAMOND_PACK_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_DIAMOND_PACK_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_DIAMOND_PACK_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_DIAMOND_PACK_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_PREMIUM_PACK_AGREEINECHECKBOX: 'HIRE_CARD_PREMIUM_PACK_AGREEINECHECKBOX',
  HIRE_CARD_PREMIUM_PACK_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_PREMIUM_PACK_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_PREMIUM_PACK_CHECK_RADIO_BUTTON: 'HIRE_CARD_PREMIUM_PACK_CHECK_RADIO_BUTTON',
  HIRE_CARD_PREMIUM_PACK_PRINT_DOCUMENT: 'HIRE_CARD_PREMIUM_PACK_PRINT_DOCUMENT',
  HIRE_CARD_PREMIUM_PACK_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_PREMIUM_PACK_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_PREMIUM_PACK_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_PREMIUM_PACK_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_PREMIUM_PACK_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_PREMIUM_PACK_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_PREMIUM_PACK_NEW_VALIDATE_STEP: 'HIRE_CARD_PREMIUM_PACK_NEW_VALIDATE_STEP',
  HIRE_CARD_VIA_T_AGREEINECHECKBOX: 'HIRE_CARD_VIA_T_AGREEINECHECKBOX',
  HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON: 'HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON',
  HIRE_CARD_VIA_T_PRINT_DOCUMENT: 'HIRE_CARD_VIA_T_PRINT_DOCUMENT',
  HIRE_CARD_VIA_T_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_VIA_T_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_VIA_T_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_VIA_T_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_VIA_T_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_VIA_T_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_VIA_T_NEW_VALIDATE_STEP: 'HIRE_CARD_VIA_T_NEW_VALIDATE_STEP',
  RESET_INIT_CARDS_DOCUMENTS: 'RESET_INIT_CARDS_DOCUMENTS',
  HIRE_CARD_VIRTUAL_CREDIT_AGREEINECHECKBOX: 'HIRE_CARD_VIRTUAL_CREDIT_AGREEINECHECKBOX',
  HIRE_CARD_VIRTUAL_CREDIT_CHECK_RADIO_BUTTON_POLICY: 'HIRE_CARD_VIRTUAL_CREDIT_CHECK_RADIO_BUTTON_POLICY',
  HIRE_CARD_VIRTUAL_CREDIT_CHECK_RADIO_BUTTON: 'HIRE_CARD_VIRTUAL_CREDIT_CHECK_RADIO_BUTTON',
  HIRE_CARD_VIRTUAL_CREDIT_PRINT_DOCUMENT: 'HIRE_CARD_VIRTUAL_CREDIT_PRINT_DOCUMENT',
  HIRE_CARD_VIRTUAL_CREDIT_PRINT_DOCUMENT_POLICY: 'HIRE_CARD_VIRTUAL_CREDIT_PRINT_DOCUMENT_POLICY',
  HIRE_CARD_VIRTUAL_CREDIT_PROCCESS_EMAIL_ERROR: 'HIRE_CARD_VIRTUAL_CREDIT_PROCCESS_EMAIL_ERROR',
  HIRE_CARD_VIRTUAL_CREDIT_PROCCESS_EMAIL_SUCCESS: 'HIRE_CARD_VIRTUAL_CREDIT_PROCCESS_EMAIL_SUCCESS',
  HIRE_CARD_VIRTUAL_CREDIT_NEW_VALIDATE_STEP: 'HIRE_CARD_VIRTUAL_CREDIT_NEW_VALIDATE_STEP',
  HIRE_CARD_VIRTUAL_CREDIT_SET_AGREEMENT_CHECKBOX_CC: 'HIRE_CARD_VIRTUAL_CREDIT_SET_AGREEMENT_CHECKBOX_CC',
};
// PLEASE KEEP ALPHABETIC ORDER!
