export const MIGRATION_STATE = {
  INITIAL: 'initial',
  REJECTED: 'rejected',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  VIDEOCALL_INFO: 'videoCallInfo',
  VIDEOCALL_PROCESS: 'videoCallProcess',
  MIGRATION_KO: 'migrationKO',
  MIGRATION_SUCCESS: 'migrationSuccess',
};

export const API_MIGRATION_STATE = {
  ALLOW_MIGRATION: 'P',
  REJECTED_MIGRATION: 'C',
  SIGNED_CONTRACT: 'D',
  VIDEOCALL_KO: 'K',
  VIDEOCALL_SUCCESS: 'W',
};

export const actionTypes = {
  FETCH_GET_MIGRATION_DATA_IN_PROGRESS: 'FETCH_GET_MIGRATION_DATA_IN_PROGRESS',
  FETCH_GET_MIGRATION_DATA_SUCCESS: 'FETCH_GET_MIGRATION_DATA_SUCCESS',
  FETCH_GET_MIGRATION_DATA_FAILURE: 'FETCH_GET_MIGRATION_DATA_FAILURE',
};

export const CALL_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const DATA_PROTECTION_PATH =
  '/assets/MB_DEU/de-DE/documents/v1/DE.OB.ONBOARDING.PRIVACY.POLICY.pdf';

export const IDENTIFICATION_QUERY_RESULT = {
  SUCCESS: 'SUCCESS',
  KO: 'KO',
};

export const MODAL_TYPE = {
  DEFAULT: 'DEFAULT',
  NOT_MIGRATE: 'NOT_MIGRATE',
};

export const SIGNATURE_PATTERN_OPERATION_TYPE = 261;

const VIEWER_HEIGHT_MOBILE = 376;
const VIEWER_HEIGHT_DEFAULT = 654;

export const VIEWER_HEIGHT = {
  MOBILE: VIEWER_HEIGHT_MOBILE,
  DEFAULT: VIEWER_HEIGHT_DEFAULT,
};

export const LOADER_WIDTH = 80;
